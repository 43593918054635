import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { refreshToken, setDisconnect } from "features/auth/authSlice";
import { RootState } from "../../app/store";
import {
  GET_PORTFOLIO_OPTION_LIST,
  UPDATE_PORTFOLIO_OPTION_LIST,
  GET_PORTFOLIO_OPTION,
} from "./constants";
import { Api } from "api";
import axios from "axios";

export interface PortfolioOptionsState {
  isLoading: boolean;
  isError: boolean;
  option: IPortfolioOption | null;
}

export interface IPortfolioOption {
  id: number;
  target_year: number;
  // role: "Producer" | "Consumer";
  hedging_method: "Value hedge" | "Quantity hedge" | "Value and Quantity hedge";
  currency: "EUR" | "CHF";
  width: number | null;
  target: number | null;
  period: string | null;
}

const initialState: PortfolioOptionsState = {
  isLoading: false,
  isError: false,
  option: null,
};

interface IPortfolioOptonsRequest {
  portfolio_id: string;
}

export const fetchPortfolioOptions = createAsyncThunk(
  GET_PORTFOLIO_OPTION_LIST,
  async ({ portfolio_id }: IPortfolioOptonsRequest, api) => {
    try {
      const res = await axios.get(
        `${Api.GetPortfolioOptions}?portfolio_id=${portfolio_id}`
      );

      if (!res) {
        throw new Error("Error");
      }

      return res?.data;
    } catch (error: any) {
      api.dispatch(setPortfolioOptionsError(true));
      if (error?.request?.status === 403) {
        api.dispatch(setDisconnect(true));
      } else if (error?.request?.status === 401) {
        api.dispatch(
          refreshToken({
            callback: fetchPortfolioOptions,
            parameter: { portfolio_id },
          })
        );
      }
    }
  }
);

interface IOptionRequest {
  id: string;
}

export const fetchPortfolioOptionById = createAsyncThunk(
  GET_PORTFOLIO_OPTION,
  async ({ id }: IOptionRequest, api) => {
    try {
      const res = await axios.get(`${Api.GetPortfolioOptions}${id}/`);

      if (!res) {
        throw new Error("Error");
      }

      return res?.data;
    } catch (error: any) {
      api.dispatch(setPortfolioOptionsError(true));
      if (error?.request?.status === 403) {
        api.dispatch(setDisconnect(true));
      } else if (error?.request?.status === 401) {
        api.dispatch(
          refreshToken({
            callback: fetchPortfolioOptionById,
            parameter: { id },
          })
        );
      }
    }
  }
);

interface IPutPortfolioOptionRequest {
  item: IPortfolioOption;
  id: string;
}

export const updatePortfolioOptionById = createAsyncThunk(
  UPDATE_PORTFOLIO_OPTION_LIST,
  async ({ item, id }: IPutPortfolioOptionRequest, api) => {
    try {
      const res = await axios.put(`${Api.GetPortfolioOptions}${id}/`, {
        ...item,
      });

      if (!res) {
        throw new Error("Error");
      }

      return res?.data;
    } catch (error: any) {
      api.dispatch(setPortfolioOptionsError(true));
      if (error?.request?.status === 403) {
        api.dispatch(setDisconnect(true));
      } else if (error?.request?.status === 401) {
        api.dispatch(
          refreshToken({
            callback: updatePortfolioOptionById,
            parameter: { item },
          })
        );
      }
    }
  }
);

interface IOptionPayload {
  payload: Partial<IPortfolioOption>;
}

export const portfolioOptionsSlice = createSlice({
  name: "portfolioOptions",
  initialState,
  reducers: {
    setPortfolioOptionsError(state, action) {
      state.isError = action.payload;
    },
    initAllPortfolioOptionsData(state) {
      state.isError = false;
      state.isLoading = false;
      state.option = null;
    },
    setLoading(state, action) {
      state.isLoading = action.payload;
    },
    setOption(state, action) {
      if (!state.option) return;
      const { payload }: IOptionPayload = action;
      const keys = Object.keys(payload);

      keys.forEach((key) => {
        (state.option as any)![key as any]! = (payload as any)[key as any];
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPortfolioOptions.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchPortfolioOptions.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.isError = false;
        // state.list = payload || null;
      })
      .addCase(fetchPortfolioOptions.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      })
      .addCase(fetchPortfolioOptionById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchPortfolioOptionById.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.isError = false;
        state.option = state.option ? { ...state.option, ...payload } : payload;
      })
      .addCase(fetchPortfolioOptionById.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      })
      .addCase(updatePortfolioOptionById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updatePortfolioOptionById.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.isError = false;
        state.option = state.option ? { ...state.option, ...payload } : payload;
      })
      .addCase(updatePortfolioOptionById.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      });
  },
});
export const {
  setPortfolioOptionsError,
  initAllPortfolioOptionsData,
  setLoading,
  setOption,
} = portfolioOptionsSlice.actions;
export const isLoading$ = (state: RootState) =>
  state.portfolioOptions.isLoading;
export const isError$ = (state: RootState) => state.portfolioOptions.isError;
export const options$ = (state: RootState) => state.portfolioOptions.option;

export default portfolioOptionsSlice.reducer;
