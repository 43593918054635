import { useRef } from "react";
import style from "./analyze.module.scss";
import { useTranslation } from "react-i18next";
import { AnalyzeContent } from "containers/analyzeContent";

export const Analyze = () => {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement | null>(null);

  return (
    <div className={style.root} ref={ref} id="analyze-container">
      <h2 className={style.head}>{t("nav_portfolio_analyze")}</h2>
      <AnalyzeContent />
    </div>
  );
};
