export interface ITokenData {
  refresh: string;
  access: string;
}

// export const setStorageType = (value: boolean) => {
//   localStorage.setItem("storage-type", JSON.stringify(value));
// };

// const typeOfStorage = () => {
//   if (
//     localStorage.getItem("storage-type") &&
//     JSON.parse(localStorage.getItem("storage-type")!)
//   ) {
//     return localStorage;
//   } else {
//     return sessionStorage;
//   }
// };

export const setTokenData = (data: ITokenData) => {
  localStorage.setItem("refresh", data.refresh);
  localStorage.setItem("access", data.access);
};

export const getAccessToken = () => {
  return localStorage.getItem("access") || null;
};

export const getRefreshToken = () => {
  return localStorage.getItem("refresh") || null;
};

export const setSideMenuPosition = (position: boolean) => {
  return localStorage.setItem("is_open_side_menu", JSON.stringify(position));
};

export const getSideMenuPosition = () => {
  const result = localStorage.getItem("is_open_side_menu");
  return result === "true";
};
