import { useCallback, useMemo, useState, FC } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "app/hooks";
import { DropdownSelect, ISelectValue } from "components/dropdownSelect";
import { SortedSignalsWithTitle } from "components/sortedSignalsWithTitle";
import {
  IFormattedSignal,
  isLoading$,
  sortedSignalsByMonthBase$,
  sortedSignalsByMonthPeak$,
  sortedSignalsByQuartalBase$,
  sortedSignalsByQuartalPeak$,
  sortedSignalsByYearBase$,
  sortedSignalsByYearPeak$,
  yearNoSignal$,
  monthNoSignal$,
  quartalNoSignal$,
} from "features/signals/slice";
import style from "./sortedSignalList.module.scss";

interface IProps {
  onSelect: (v: IFormattedSignal) => void;
}

export const SortedSignalList: FC<IProps> = ({ onSelect }) => {
  const { t } = useTranslation();
  const isLoading = useAppSelector(isLoading$);
  const sortedSignalsByYearBase = useAppSelector(sortedSignalsByYearBase$);
  const sortedSignalsByYearPeak = useAppSelector(sortedSignalsByYearPeak$);
  const sortedSignalsByQuartalBase = useAppSelector(
    sortedSignalsByQuartalBase$
  );

  const sortedSignalsByQuartalPeak = useAppSelector(
    sortedSignalsByQuartalPeak$
  );

  const sortedSignalsByMonthBase = useAppSelector(sortedSignalsByMonthBase$);
  const sortedSignalsByMonthPeak = useAppSelector(sortedSignalsByMonthPeak$);
  const yearNoSignal = useAppSelector(yearNoSignal$);
  const monthNoSignal = useAppSelector(monthNoSignal$);
  const quartalNoSignal = useAppSelector(quartalNoSignal$);

  const isShowNoSignalItems = useMemo(
    () =>
      !sortedSignalsByYearBase.length &&
      !sortedSignalsByYearPeak.length &&
      !sortedSignalsByQuartalBase.length &&
      !sortedSignalsByQuartalPeak.length &&
      !sortedSignalsByMonthBase &&
      !sortedSignalsByMonthPeak,
    [
      sortedSignalsByMonthBase,
      sortedSignalsByMonthPeak,
      sortedSignalsByQuartalBase.length,
      sortedSignalsByQuartalPeak.length,
      sortedSignalsByYearBase.length,
      sortedSignalsByYearPeak.length,
    ]
  );

  const isShowYearNoSignal = useMemo(
    () => isShowNoSignalItems && yearNoSignal.length,
    [isShowNoSignalItems, yearNoSignal.length]
  );

  const isShowMonthNoSignal = useMemo(
    () =>
      isShowNoSignalItems &&
      !yearNoSignal.length &&
      !quartalNoSignal.length &&
      monthNoSignal.length,
    [
      isShowNoSignalItems,
      monthNoSignal.length,
      quartalNoSignal.length,
      yearNoSignal.length,
    ]
  );

  const isShowQuartalNoSignal = useMemo(
    () => isShowNoSignalItems && !yearNoSignal.length && quartalNoSignal.length,
    [isShowNoSignalItems, quartalNoSignal.length, yearNoSignal.length]
  );

  const periods = [
    { label: t("signals_period"), value: "" },
    { label: t("annually"), value: "annually" },
    { label: t("quarterly"), value: "quarterly" },
    { label: t("monthly"), value: "monthly" },
  ];

  const types = [
    { label: "Base or Peak", value: "" },
    { label: "Base", value: "base" },
    { label: "Peak", value: "peak" },
  ];
  const [period, setPeriod] = useState<ISelectValue | any>(periods[0]);
  const [type, setType] = useState<ISelectValue | any>(types[0]);

  const handlePeriodChange = useCallback((item: ISelectValue) => {
    setPeriod(item);
  }, []);

  const handleTypeChange = useCallback((item: ISelectValue) => {
    setType(item);
  }, []);

  return (
    <div className={style.root}>
      <header className={style.header}>
        <DropdownSelect
          items={periods}
          isAllValueNeed
          value={period}
          placeholder={""}
          onChange={handlePeriodChange}
          disabled={isLoading}
          className={style.dropdown}
        />
        <DropdownSelect
          items={types}
          isAllValueNeed
          value={type}
          placeholder={""}
          onChange={handleTypeChange}
          disabled={isLoading}
          className={style.dropdown}
        />
      </header>

      <div className={style.body}>
        {(period?.value === "" || period?.value === "annually") &&
        (type?.value === "" || type?.value === "base") ? (
          <SortedSignalsWithTitle
            signals={sortedSignalsByYearBase}
            onSelect={onSelect}
            title={t("signal_year_base_title")}
          />
        ) : null}

        {isShowYearNoSignal && (
          <SortedSignalsWithTitle
            signals={yearNoSignal}
            onSelect={onSelect}
            title={t("signal_year_peak_title")}
          />
        )}

        {isShowMonthNoSignal && (
          <SortedSignalsWithTitle
            signals={monthNoSignal}
            onSelect={onSelect}
            title={t("signal_year_peak_title")}
          />
        )}

        {isShowQuartalNoSignal && (
          <SortedSignalsWithTitle
            signals={quartalNoSignal}
            onSelect={onSelect}
            title={t("signal_year_peak_title")}
          />
        )}

        {(period?.value === "" || period?.value === "annually") &&
        (type?.value === "" || type?.value === "peak") ? (
          <SortedSignalsWithTitle
            signals={sortedSignalsByYearPeak}
            onSelect={onSelect}
            title={t("signal_year_peak_title")}
          />
        ) : null}

        {(period?.value === "" || period?.value === "quarterly") &&
        (type?.value === "" || type?.value === "base") ? (
          <SortedSignalsWithTitle
            signals={sortedSignalsByQuartalBase}
            onSelect={onSelect}
            title={t("signal_quartal_base_title")}
          />
        ) : null}

        {(period?.value === "" || period?.value === "quarterly") &&
        (type?.value === "" || type?.value === "peak") ? (
          <SortedSignalsWithTitle
            signals={sortedSignalsByQuartalPeak}
            onSelect={onSelect}
            title={t("signal_quartal_peak_title")}
          />
        ) : null}

        {(period?.value === "" || period?.value === "monthly") &&
        (type?.value === "" || type?.value === "base") ? (
          <SortedSignalsWithTitle
            signals={sortedSignalsByMonthBase}
            onSelect={onSelect}
            title={t("signal_month_base_title")}
          />
        ) : null}

        {(period?.value === "" || period?.value === "monthly") &&
        (type?.value === "" || type?.value === "peak") ? (
          <SortedSignalsWithTitle
            signals={sortedSignalsByMonthPeak}
            onSelect={onSelect}
            title={t("signal_month_peak_title")}
          />
        ) : null}
      </div>
    </div>
  );
};
