const workercode = () => {
  // eslint-disable-next-line no-restricted-globals
  self.onmessage = function (e) {
    // self.importScripts("./testModule"); // eslint-disable-line no-restricted-globals
    // eslint-disable-line no-restricted-globals
    console.log("Message received from main script - Corridor line");
    const result = e.data.data;
    const { key1, key2, isCreateNew } = e.data;
    const { neutral_line, lower_line, upper_line, value_build_line, date } =
      result;

    if (!isCreateNew) {
      if (key2 === "value_build_line") {
        const sum = result[key2].reduce((acc, cur) => acc + cur, 0);

        if (!sum) {
          const resultData = [];
          self.postMessage(resultData); // eslint-disable-line no-restricted-globals
        } else {
          const resultData = result[key1].map((x, index) => ({
            y: result[key2][index],
            x,
          }));

          self.postMessage(resultData); // eslint-disable-line no-restricted-globals
        }
      }
      const resultData = result[key1].map((x, index) => ({
        y: result[key2][index],
        x,
      }));

      self.postMessage(resultData); // eslint-disable-line no-restricted-globals
    } else {
      const max =
        [
          ...neutral_line,
          ...lower_line,
          ...upper_line,
          ...value_build_line,
        ].reduce(function (p, v) {
          return p > v ? p : v;
        }) + 5;

      const filledArray = Array(date.length)
        .fill(max)
        ?.map((y, index) => ({
          x: date[index],
          y,
        }));

      self.postMessage({ max, filledArray }); // eslint-disable-line no-restricted-globals
    }

    console.log("Posting message back to main script - Corridor line");
    // self.postMessage(api.message()); // eslint-disable-line no-restricted-globals
  };
};

let code = workercode.toString();
code = code.substring(code.indexOf("{") + 1, code.lastIndexOf("}"));

const blob = new Blob([code], { type: "application/javascript" });
const worker_script = URL.createObjectURL(blob);

export default worker_script;
