import { Range } from "react-range";
import { useEffect, useMemo, useState } from "react";
import style from "./slider.module.scss";
import "./style.scss";
import { Direction, ITrackBackground } from "react-range/lib/types";

interface IProps {
  min: number;
  max: number;
  marks?: { [key: number]: string | React.ReactNode };
  step?: number;
  vertical?: boolean;
  included?: boolean;
  reverse?: boolean;
  disabled?: boolean;
  dots?: boolean;
  values: number[];
  onBeforeChange?: (value: number | number[]) => void;
  onChange: (value: number[]) => void;
  onAfterChange?: (value: number[]) => void;
  prefix?: string;
  suffix?: string;
  title?: string;
  rtl?: boolean;
}

export function getTrackBackground({
  values,
  colors,
  min,
  max,
  direction = Direction.Right,
  rtl = false,
}: ITrackBackground) {
  if (rtl && direction === Direction.Right) {
    direction = Direction.Left;
  } else if (rtl && Direction.Left) {
    direction = Direction.Right;
  }
  // sort values ascending
  const progress = values
    .slice(0)
    .sort((a, b) => a - b)
    .map((value) => ((value - min) / (max - min)) * 100);
  const middle = progress.reduce(
    (acc, point, index) =>
      `${acc}, ${colors[index]} ${point}%, ${colors[index + 1]} ${point}%`,
    ""
  );
  return `linear-gradient(${direction}, ${colors[0]} 0%${middle}, ${
    colors[colors.length - 1]
  } 100%)`;
}

export const SliderComponent = ({
  min,
  max,
  values,
  prefix,
  suffix,
  title,
  step,
  onChange,
}: IProps) => {
  const [localValues, setLocalValues] = useState(values);

  useEffect(() => {
    setLocalValues(values);
  }, [values]);
  const renderMinValue = useMemo(() => {
    return `${prefix ? prefix : ""}${min}${suffix ? suffix : ""}`;
  }, [min, prefix, suffix]);

  const renderMaxValue = useMemo(() => {
    return `${prefix ? prefix : ""}${max}${suffix ? suffix : ""}`;
  }, [max, prefix, suffix]);

  const handleChange = (value: number[]) => {
    onChange(value);
  };

  const handleBeforeChange = (value: number[]) => {
    setLocalValues(value);
  };

  return (
    <div className={style.root}>
      <div className={style.min}>{renderMinValue}</div>
      <div className={style.max}>{renderMaxValue}</div>
      <div className={style.title}>{title}</div>
      <Range
        min={min}
        max={max}
        step={step}
        values={localValues}
        onChange={handleBeforeChange}
        onFinalChange={handleChange}
        renderTrack={({ props, children }) => (
          <div
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            style={{
              ...props.style,
              height: "36px",
              display: "flex",
              width: "100%",
            }}
          >
            <div
              ref={props.ref}
              style={{
                height: "3px",
                width: "100%",
                borderRadius: "4px",
                background: getTrackBackground({
                  values: localValues,
                  colors: ["#8BBA05", "#ccc"],
                  min,
                  max,
                }),
                alignSelf: "center",
              }}
            >
              {children}
            </div>
          </div>
        )}
        renderThumb={({ props }) => (
          <div
            {...props}
            style={{
              ...props.style,
              height: "15px",
              width: "15px",
              backgroundColor: "#8BBA05",
              borderRadius: "50%",
              outline: "none",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "-28px",
                color: "#8BBA05",
                fontWeight: "bold",
                fontSize: "14px",
                fontFamily: "Arial,Helvetica Neue,Helvetica,sans-serif",
                padding: "4px",
                borderRadius: "4px",
                right: " -11px",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                {localValues[0].toFixed(0) + suffix}
              </div>
            </div>
          </div>
        )}
      />
    </div>
  );
};
