import { Toggler } from "components/toggler";
import style from "./portfolioProfileOptions.module.scss";

interface IProps {
  year: number;
  setYear: (year: number) => void;
  text: string;
  title: string;
  min: number;
  max: number;
  disabled: boolean;
}

export const PortfolioProfileOptions = ({
  year,
  setYear,
  text,
  title,
  min,
  max,
  disabled,
}: IProps) => {
  return (
    <div className={style.root}>
      <h4 className={style.title}>{title}</h4>
      <Toggler
        type="year"
        value={year}
        onChange={setYear}
        placeholder={""}
        min={min}
        max={max}
        disabled={disabled}
      />
      <div className={style.text}>{text}</div>
    </div>
  );
};
