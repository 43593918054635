import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./app/store";
import { App } from "./App";
import { Loader } from "components/loader";
import CacheBuster from "react-cache-buster";
import pack from "../package.json";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import "./i18n";
import { createBrowserHistory } from "history";
import { unstable_HistoryRouter as HistoryRouter } from "react-router-dom";

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

const container = document.getElementById("root")!;
const root = createRoot(container);
const history = createBrowserHistory({ window });

const isProduction = process.env.NODE_ENV === "production";

Sentry.init({
  dsn: "https://80adc9c56705407d99c743b417e047d9@o1290604.ingest.sentry.io/6631466",
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

root.render(
  <CacheBuster
    currentVersion={pack.version}
    isEnabled={isProduction} //If false, the library is disabled.
    isVerboseMode={false} //If true, the library writes verbose logs to console.
    loadingComponent={<Loader />} //If not pass, nothing appears at the time of new version check.
  >
    <Provider store={store}>
      <HistoryRouter history={history}>
        <App />
      </HistoryRouter>
    </Provider>
  </CacheBuster>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
