import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { refreshToken, setDisconnect } from "features/auth/authSlice";
import { RootState } from "app/store";
import { Api } from "api";
import { getProductsPeriod, months } from "./utils";
import { capitalizeFirstLetter } from "utils";
import { GET_SIGNALS } from "./constants";
import {
  fetchAnalyzeData,
  setAnalyzeChartDatatype,
  setAnalyzeChartTitle,
  setAnalyzeProducts,
} from "features/analyze/slice";

export interface SignalState {
  list: ISignal[];
  isLoading: boolean;
  isError: boolean;
}

export interface ISignal {
  portfolio: string;
  base_message: string;
  peak_message: string;
  products: string;
  base_color: string;
  peak_color: string;
  start_date: string;
  end_date: string;
}

const initialState: SignalState = {
  list: [],
  isLoading: false,
  isError: false,
};

interface ISignalRequest {
  year: number;
  portfolio_id: string;
}

export enum SignalType {
  BASE = "base",
  PEAK = "peak",
}

export const fetchSignals = createAsyncThunk(
  GET_SIGNALS,
  async ({ year, portfolio_id }: ISignalRequest, api) => {
    try {
      const res = await axios
        .get(`${Api.GetSignals}`, {
          params: { year, portfolio_id },
        })
        .finally(() => {
          setTimeout(() => {
            const products =
              filteredFormattedSignals$(api.getState() as RootState)[0]
                ?.products ||
              yearNoSignal$(api.getState() as RootState)[0]?.products ||
              quartalNoSignal$(api.getState() as RootState)[0]?.products ||
              monthNoSignal$(api.getState() as RootState)[0]?.products;

            const type =
              filteredFormattedSignals$(api.getState() as RootState)[0]?.type ||
              yearNoSignal$(api.getState() as RootState)[0]?.type ||
              quartalNoSignal$(api.getState() as RootState)[0]?.type ||
              monthNoSignal$(api.getState() as RootState)[0]?.type;

            api.dispatch(
              setAnalyzeChartTitle(
                `Analyze ${products ? products : ""} ${
                  type ? capitalizeFirstLetter(type) : ""
                }`
              )
            );
            api.dispatch(setAnalyzeChartDatatype(type));
            api.dispatch(setAnalyzeProducts(products));
          }, 500);
        });

      api.dispatch(setSignals(res.data!));

      api.dispatch(
        setAnalyzeChartDatatype(currentFirstType$(api.getState() as RootState))
      );

      api.dispatch(
        setAnalyzeProducts(currentFirstProduct$(api.getState() as RootState))
      );

      api.dispatch(
        fetchAnalyzeData({
          period: currentFirstProduct$(api.getState() as RootState),
          year,
        })
      );

      api.dispatch(
        setAnalyzeChartTitle(
          `Analyze ${products(
            api.getState() as RootState
          )} ${capitalizeFirstLetter(
            currentFirstType$(api.getState() as RootState) || ""
          )}`
        )
      );
    } catch (error: any) {
      api.dispatch(setError(true));
      if (error?.request?.status === 403) {
        api.dispatch(setDisconnect(true));
      } else if (error?.request?.status === 401) {
        api.dispatch(
          refreshToken({
            callback: fetchSignals,
            parameter: { year, portfolio_id },
          })
        );
      }

      api.rejectWithValue("No signals");
    }
  }
);

export const signalsSlice = createSlice({
  name: "signals",
  initialState,
  reducers: {
    setError(state, action) {
      state.isError = action.payload;
    },
    initAllSignalsData(state) {
      state.isError = false;
      state.isLoading = false;
      state.list = [];
    },
    setLoading(state, action) {
      state.isLoading = action.payload;
    },
    setSignals(state, action) {
      state.isError = false;
      state.isLoading = false;
      state.list = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSignals.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchSignals.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        // state.list = action.payload;
      })
      .addCase(fetchSignals.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      });
  },
});

export const { setError, initAllSignalsData, setLoading, setSignals } =
  signalsSlice.actions;

export const signals$ = (state: RootState): ISignal[] => state.signals.list;

export interface IFormattedSignal {
  id: string;
  type: SignalType.BASE | SignalType.PEAK;
  portfolio: string;
  message: string;
  products: string;
  color: string;
  start_date: string;
  end_date: string;
}

export const formattedSignals$ = (state: RootState) => {
  let result = [] as IFormattedSignal[];
  state.signals.list.forEach(
    ({
      portfolio,
      base_message,
      peak_message,
      products,
      base_color,
      peak_color,
      start_date,
      end_date,
    }: ISignal) => {
      if (!result.some((sign) => sign.id === products + SignalType.BASE)) {
        result.push({
          id: products + SignalType.BASE,
          type: SignalType.BASE,
          portfolio,
          message: base_message,
          products,
          color: base_color,
          start_date,
          end_date,
        });
      }
      if (!result.some((sign) => sign.id === products + SignalType.PEAK)) {
        result.push({
          id: products + SignalType.PEAK,
          type: SignalType.PEAK,
          portfolio,
          message: peak_message,
          products,
          color: peak_color,
          start_date,
          end_date,
        });
      }
    }
  );

  const sortedResult = result.sort((signal) => {
    if (signal?.color === "success") {
      return -1;
    } else if (signal?.color === "danger") {
      return +1;
    } else if (signal?.color === "primary") {
      return 0;
    } else {
      return 0;
    }
  });

  return sortedResult;
};

export const yearBuyBaseSignals = (state: RootState) =>
  formattedSignals$(state).filter(
    (sign) =>
      (sign.products.toLowerCase()?.includes("year") ||
        !isNaN(+sign.products)) &&
      sign.type === SignalType.BASE &&
      sign.message?.toLowerCase().includes("buy")
  );

export const yearBuyPeakSignals = (state: RootState) =>
  formattedSignals$(state).filter(
    (sign) =>
      (sign.products.toLowerCase()?.includes("year") ||
        !isNaN(+sign.products)) &&
      sign.type === SignalType.PEAK &&
      sign.message?.toLowerCase()?.includes("buy")
  );

export const yearSellBaseSignals = (state: RootState) =>
  formattedSignals$(state).filter(
    (sign) =>
      (sign.products.toLowerCase()?.includes("year") ||
        !isNaN(+sign.products)) &&
      sign.type === SignalType.BASE &&
      sign.message?.toLowerCase().includes("sell")
  );

export const yearSellPeakSignals = (state: RootState) =>
  formattedSignals$(state).filter(
    (sign) =>
      (sign.products.toLowerCase()?.includes("year") ||
        !isNaN(+sign.products)) &&
      sign.type === SignalType.PEAK &&
      sign.message?.toLowerCase()?.includes("sell")
  );

export const yearOtherBaseSignals = (state: RootState) =>
  formattedSignals$(state).filter(
    (sign) =>
      (sign.products.toLowerCase()?.includes("year") ||
        !isNaN(+sign.products)) &&
      sign.type === SignalType.BASE &&
      !sign.message?.toLowerCase().includes("sell") &&
      !sign.message?.toLowerCase().includes("buy")
  );

export const yearNoSignalBaseSignals = (state: RootState) =>
  formattedSignals$(state).filter(
    (sign) =>
      (sign.products.toLowerCase()?.includes("year") ||
        !isNaN(+sign.products)) &&
      sign.type === SignalType.BASE &&
      sign.message === "No signal"
  );

export const yearNoSignalPeakSignals = (state: RootState) =>
  formattedSignals$(state).filter(
    (sign) =>
      (sign.products.toLowerCase()?.includes("year") ||
        !isNaN(+sign.products)) &&
      sign.type === SignalType.PEAK &&
      sign.message === "No signal"
  );

export const yearOtherPeakSignals = (state: RootState) =>
  formattedSignals$(state).filter(
    (sign) =>
      (sign.products.toLowerCase()?.includes("year") ||
        !isNaN(+sign.products)) &&
      sign.type === SignalType.PEAK &&
      !sign.message?.toLowerCase().includes("sell") &&
      !sign.message?.toLowerCase().includes("buy")
  );

export const monthBuyBaseSignals = (state: RootState) =>
  formattedSignals$(state)
    .filter((sign) => {
      const month = sign.products.split(" ")[0];
      return (
        months.includes(capitalizeFirstLetter(month)) &&
        sign.type === SignalType.BASE &&
        sign.message.toLowerCase().includes("buy")
      );
    })
    .sort((signal) => {
      const month = signal.products.split(" ")[0];

      const monthIndex = months.findIndex(
        (m) => m.toLowerCase() === month.toLowerCase()
      );
      return -12 - monthIndex;
    });

export const monthBuyPeakSignals = (state: RootState) =>
  formattedSignals$(state)
    .filter((sign) => {
      const month = sign.products.split(" ")[0];
      return (
        months.includes(capitalizeFirstLetter(month)) &&
        sign.type === SignalType.PEAK &&
        sign.message.toLowerCase().includes("buy")
      );
    })
    .sort((signal) => {
      const month = signal.products.split(" ")[0];

      const monthIndex = months.findIndex(
        (m) => m.toLowerCase() === month.toLowerCase()
      );
      return 12 - monthIndex;
    });

export const monthNoSignalPeakSignals = (state: RootState) =>
  formattedSignals$(state)
    .filter((sign) => {
      const month = sign.products.split(" ")[0];
      return (
        months.includes(capitalizeFirstLetter(month)) &&
        sign.type === SignalType.PEAK &&
        sign.message === "No signal"
      );
    })
    .sort((signal) => {
      const month = signal.products.split(" ")[0];

      const monthIndex = months.findIndex(
        (m) => m.toLowerCase() === month.toLowerCase()
      );
      return 12 - monthIndex;
    });

export const monthNoSignalBaseSignals = (state: RootState) =>
  formattedSignals$(state)
    .filter((sign) => {
      const month = sign.products.split(" ")[0];
      return (
        months.includes(capitalizeFirstLetter(month)) &&
        sign.type === SignalType.BASE &&
        sign.message === "No signal"
      );
    })
    .sort((signal) => {
      const month = signal.products.split(" ")[0];

      const monthIndex = months.findIndex(
        (m) => m.toLowerCase() === month.toLowerCase()
      );
      return 12 - monthIndex;
    });

export const monthSellBaseSignals = (state: RootState) =>
  formattedSignals$(state)
    .filter((sign) => {
      const month = sign.products.split(" ")[0];
      return (
        months.includes(capitalizeFirstLetter(month)) &&
        sign.type === SignalType.BASE &&
        sign.message.toLowerCase().includes("sell")
      );
    })
    .sort((signal) => {
      const month = signal.products.split(" ")[0];

      const monthIndex = months.findIndex(
        (m) => m.toLowerCase() === month.toLowerCase()
      );
      return -12 - monthIndex;
    });

export const monthSellPeakSignals = (state: RootState) =>
  formattedSignals$(state)
    .filter((sign) => {
      const month = sign.products.split(" ")[0];
      return (
        months.includes(capitalizeFirstLetter(month)) &&
        sign.type === SignalType.PEAK &&
        sign.message.toLowerCase().includes("sell")
      );
    })
    .sort((signal) => {
      const month = signal.products.split(" ")[0];

      const monthIndex = months.findIndex(
        (m) => m.toLowerCase() === month.toLowerCase()
      );
      return -12 - monthIndex;
    });

export const monthsOtherPeakSignals = (state: RootState) =>
  formattedSignals$(state)
    .filter((sign) => {
      const month = sign.products.split(" ")[0];

      return (
        months.includes(capitalizeFirstLetter(month)) &&
        sign.type === SignalType.PEAK &&
        sign.message === "No signal"
      );
    })
    .sort((signal) => {
      const month = signal.products.split(" ")[0];

      const monthIndex = months.findIndex(
        (m) => m.toLowerCase() === month.toLowerCase()
      );
      return -12 - monthIndex;
    });

export const monthsOtherBaseSignals = (state: RootState) =>
  formattedSignals$(state)
    .filter((sign) => {
      const month = sign.products.split(" ")[0];

      return (
        months.includes(capitalizeFirstLetter(month)) &&
        sign.type === SignalType.BASE &&
        sign.message === "No signal"
      );
    })
    .sort((signal) => {
      const month = signal.products.split(" ")[0];

      const monthIndex = months.findIndex(
        (m) => m.toLowerCase() === month.toLowerCase()
      );
      return -12 - monthIndex;
    });

export const quartalBuyBaseSignals = (state: RootState) =>
  formattedSignals$(state)
    .filter((sign) => {
      return (
        sign.products.toLowerCase().includes("quartal") &&
        sign.type === SignalType.BASE &&
        sign.message?.toLowerCase()?.includes("buy")
      );
    })
    .sort((signal) => {
      const index = signal.products.split(" ")[1]?.slice(0, 1);
      return 4 - +index;
    });

export const quartalNoSignalPeakSignals = (state: RootState) =>
  formattedSignals$(state)
    .filter((sign) => {
      return (
        sign.products.toLowerCase().includes("quartal") &&
        sign.type === SignalType.PEAK &&
        sign.message === "No signal"
      );
    })
    .sort((signal) => {
      const index = signal.products.split(" ")[1]?.slice(0, 1);
      return 4 - +index;
    });

export const quartalNoSignalBaseSignals = (state: RootState) =>
  formattedSignals$(state)
    .filter((sign) => {
      return (
        sign.products.toLowerCase().includes("quartal") &&
        sign.type === SignalType.BASE &&
        sign.message === "No signal"
      );
    })
    .sort((signal) => {
      const index = signal.products.split(" ")[1]?.slice(0, 1);
      return 4 - +index;
    });

export const quartalBuyPeakSignals = (state: RootState) =>
  formattedSignals$(state)
    .filter((sign) => {
      return (
        sign.products.toLowerCase().includes("quartal") &&
        sign.type === SignalType.PEAK &&
        sign.message?.toLowerCase()?.includes("buy")
      );
    })
    .sort((signal) => {
      const index = signal.products.split(" ")[1]?.slice(0, 1);
      return 4 - +index;
    });

export const quartalSellBaseSignals = (state: RootState) =>
  formattedSignals$(state)
    .filter((sign) => {
      return (
        sign.products.toLowerCase().includes("quartal") &&
        sign.type === SignalType.BASE &&
        sign.message?.toLowerCase()?.includes("sell")
      );
    })
    .sort((signal) => {
      const index = signal.products.split(" ")[1]?.slice(0, 1);
      return 4 - +index;
    });

export const quartalSellPeakSignals = (state: RootState) =>
  formattedSignals$(state)
    .filter((sign) => {
      return (
        sign.products.toLowerCase().includes("quartal") &&
        sign.type === SignalType.PEAK &&
        sign.message?.toLowerCase()?.includes("sell")
      );
    })
    .sort((signal) => {
      const index = signal.products.split(" ")[1]?.slice(0, 1);
      return 4 - +index;
    });

export const quartalOtherBaseSignals = (state: RootState) =>
  formattedSignals$(state)
    .filter((sign) => {
      return (
        sign.products.toLowerCase().includes("quartal") &&
        sign.type === SignalType.BASE &&
        sign.message === "No signal"
      );
    })
    .sort((signal) => {
      const index = signal.products.split(" ")[1]?.slice(0, 1);
      return 4 - +index;
    });

export const quartalOtherPeakSignals = (state: RootState) =>
  formattedSignals$(state)
    .filter((sign) => {
      return (
        sign.products.toLowerCase().includes("quartal") &&
        sign.type === SignalType.PEAK &&
        sign.message === "No signal"
      );
    })
    .sort((signal) => {
      const index = signal.products.split(" ")[1]?.slice(0, 1);
      return 4 - +index;
    });

export const sortedSignalsByYearBase$ = (state: RootState) => {
  return [
    ...yearBuyBaseSignals(state),
    ...yearSellBaseSignals(state),
    ...yearOtherBaseSignals(state),
  ];
};

export const sortedSignalsByYearPeak$ = (state: RootState) => {
  return [
    ...yearBuyPeakSignals(state),
    ...yearSellPeakSignals(state),
    ...yearOtherPeakSignals(state),
  ];
};

export const sortedSignalsByMonthBase$ = (state: RootState) => {
  return [
    ...monthBuyBaseSignals(state),
    ...monthSellBaseSignals(state),
    ...monthsOtherBaseSignals(state),
  ];
};

export const sortedSignalsByMonthPeak$ = (state: RootState) => {
  return [
    ...monthBuyPeakSignals(state),
    ...monthSellPeakSignals(state),
    ...monthsOtherPeakSignals(state),
  ];
};

export const sortedSignalsByQuartalBase$ = (state: RootState) => {
  return [
    ...quartalBuyBaseSignals(state),
    ...quartalSellBaseSignals(state),
    ...quartalOtherBaseSignals(state),
  ];
};

export const sortedSignalsByQuartalPeak$ = (state: RootState) => {
  return [
    ...quartalBuyPeakSignals(state),
    ...quartalSellPeakSignals(state),
    ...quartalOtherPeakSignals(state),
  ];
};

export const filteredFormattedSignals$ = (state: RootState) => {
  return formattedSignals$(state).filter(
    (signal) => signal.message !== "No signal"
  );
};

export const yearNoSignal$ = (state: RootState) => {
  return [...yearNoSignalBaseSignals(state), ...yearNoSignalPeakSignals(state)];
};

export const monthNoSignal$ = (state: RootState) => {
  return [
    ...monthNoSignalBaseSignals(state),
    ...monthNoSignalPeakSignals(state),
  ];
};

export const quartalNoSignal$ = (state: RootState) => {
  return [
    ...quartalNoSignalPeakSignals(state),
    ...quartalNoSignalBaseSignals(state),
  ];
};

export const isLoading$ = (state: RootState) => state.signals.isLoading;
export const isError$ = (state: RootState) => state.signals.isError;

export const products = (state: RootState) =>
  filteredFormattedSignals$(state)[0]?.products ||
  yearNoSignal$(state)[0]?.products ||
  quartalNoSignal$(state)[0]?.products ||
  monthNoSignal$(state)[0]?.products;

export const currentFirstProduct$ = (state: RootState) =>
  getProductsPeriod({ products: products(state) });

export const currentFirstType$ = (state: RootState) =>
  filteredFormattedSignals$(state)[0]?.type ||
  yearNoSignal$(state)[0]?.type ||
  quartalNoSignal$(state)[0]?.type ||
  monthNoSignal$(state)[0]?.type;

export default signalsSlice.reducer;
