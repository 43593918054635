import { memo } from "react";
import style from "./overview.module.scss";
import { OverviewContent } from "containers/overviewContent";
import { OverviewName } from "components/overviewName";

export const Overview = memo(() => {
  return (
    <div className={style.root} id="overview-container">
      <OverviewName />
      <OverviewContent />
    </div>
  );
});
