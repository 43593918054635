import { useAppSelector } from "app/hooks";
import { user$ } from "features/user/slice";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import style from "./overviewName.module.scss";

export const OverviewName = () => {
  const user = useAppSelector(user$);
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const [portfolio_id, setPortFolioId] = useState<string | null>(null);

  useEffect(() => {
    if (searchParams.get("id") !== portfolio_id) {
      const id = searchParams.get("id");
      setPortFolioId(id);
    }
  }, [searchParams, portfolio_id]);

  const name = useMemo(
    () =>
      user?.portfolio?.find((portfolio) => portfolio.id === portfolio_id)?.name,
    [portfolio_id, user?.portfolio]
  );

  return (
    <div className={style.root}>{`${t("nav_portfolio_overview")} ${t(
      "for_portfolio"
    )} ${name ? name : ""}`}</div>
  );
};
