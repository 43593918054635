import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { refreshToken, setDisconnect } from "features/auth/authSlice";
import { RootState } from "../../app/store";
import { GET_PORTFOLIO_PROFILE_GRAPH } from "./constants";
import { Api } from "api";
import worker_script from "utils/chart";
import axios from "axios";

export interface PortfolioProfileState {
  isLoading: boolean;
  isLoadingPlotting: boolean;
  isError: boolean;
  graph: null | { [id: number]: IPortfolioGraphItem[] };
  dataForPlotting: null | { [id: number | string]: IPortfolioPlotting };
  loadedId: null | number;
  loadedProfileId: null | number;
}

export interface IPortfolioPlotting {
  [key: string]: [string, number, number];
}

export type IPortfolioGraphItem = [Date, number];

const initialState: PortfolioProfileState = {
  isLoading: false,
  isLoadingPlotting: false,
  isError: false,
  graph: null,
  dataForPlotting: null,
  loadedId: null,
  loadedProfileId: null,
};

interface IPortfolioProfileRequest {
  id: number;
}

export const fetchPortfolioProfileGraph = createAsyncThunk(
  GET_PORTFOLIO_PROFILE_GRAPH,
  async ({ id }: IPortfolioProfileRequest, api) => {
    api.dispatch(setLoadedId(id));
    api.dispatch(setChartData(null));
    api.dispatch(setPlottingData(null));
    api.dispatch(setLoadingPlotting(true));
    api.dispatch(setLoadedProfileId(null));
    try {
      const res = await axios.post(`${Api.GetPortfolioProfileGraph}`, {
        id,
      });

      if (!res) {
        throw new Error("Error");
      }
      const worker = new Worker(worker_script);

      worker.postMessage(res.data);
      api.dispatch(setLoading(true));
      worker.onmessage = (e) => {
        api.dispatch(setChartData({ id, data: e.data.result }));
        api.dispatch(setPlottingData({ id, data: e.data.additionalData }));
        api.dispatch(setLoading(false));
        worker.terminate();
      };
    } catch (error: any) {
      api.dispatch(setPortfolioGraphError(true));
      if (error?.request?.status === 403) {
        api.dispatch(setDisconnect(true));
      } else if (error?.request?.status === 401) {
        api.dispatch(
          refreshToken({
            callback: fetchPortfolioProfileGraph,
            parameter: { id },
          })
        );
      }
      return api.rejectWithValue("portfolioProfileGraph error");
    } finally {
      api.dispatch(setLoadingPlotting(false));
    }
  }
);

export const portfolioProfileGraphSlice = createSlice({
  name: "portfolioProfileGraph",
  initialState,
  reducers: {
    setPortfolioGraphError(state, action) {
      state.isError = action.payload;
    },
    initAllRollOutData(state) {
      state.isError = false;
      state.isLoading = false;
      state.isLoadingPlotting = false;
      state.graph = null;
      state.dataForPlotting = null;
      state.loadedId = null;
    },
    setLoading(state, action) {
      state.isLoading = action.payload;
    },
    setLoadedId(state, action) {
      state.loadedId = action.payload;
    },
    setLoadedProfileId(state, action) {
      state.loadedProfileId = action.payload;
    },
    setLoadingPlotting(state, action) {
      state.isLoadingPlotting = action.payload;
    },
    setChartData(state, action) {
      state.graph = action.payload
        ? { [action.payload.id]: action.payload.data }
        : null;
    },
    setPlottingData(state, action) {
      state.dataForPlotting = action.payload
        ? {
            [action.payload.id]: action.payload.data,
          }
        : null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPortfolioProfileGraph.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchPortfolioProfileGraph.fulfilled, (state, { payload }) => {
        state.isLoading = false;

        state.isError = false;
        // state.graph = payload;
      })
      .addCase(fetchPortfolioProfileGraph.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      });
  },
});

export const {
  setPortfolioGraphError,
  initAllRollOutData,
  setLoading,
  setChartData,
  setPlottingData,
  setLoadedId,
  setLoadedProfileId,
  setLoadingPlotting,
} = portfolioProfileGraphSlice.actions;

export const isLoading$ = (state: RootState) =>
  state.portfolioProfileGraph.isLoading;
export const isLoadingPlotting$ = (state: RootState) =>
  state.portfolioProfileGraph.isLoadingPlotting;
export const loadedId$ = (state: RootState) =>
  state.portfolioProfileGraph.loadedId;
export const loadedProfileId$ = (state: RootState) =>
  state.portfolioProfileGraph.loadedProfileId;
export const isError$ = (state: RootState) =>
  state.portfolioProfileGraph.isError;
export const graph$ = (state: RootState) => state.portfolioProfileGraph.graph;
export const portfolioProfileGraph$ = createSelector(graph$, (data) => data);
export const portfolioProfileDataForPlotting$ = (state: RootState) =>
  state.portfolioProfileGraph.dataForPlotting;

export default portfolioProfileGraphSlice.reducer;
