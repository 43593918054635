import { FC, useMemo } from "react";
import { useAppSelector } from "app/hooks";
import { Signal } from "components/signal";
import { isLoading$ } from "features/analyze/slice";
import {
  filteredFormattedSignals$,
  IFormattedSignal,
  monthNoSignal$,
  yearNoSignal$,
  quartalNoSignal$,
} from "features/signals/slice";
import style from "./filteredSignalList.module.scss";

interface IProps {
  onSelect: (item: IFormattedSignal) => void;
}

export const FilteredSignalList: FC<IProps> = ({ onSelect }: IProps) => {
  const filteredFormattedSignals = useAppSelector(filteredFormattedSignals$);
  const yearNoSignal = useAppSelector(yearNoSignal$);
  const monthNoSignal = useAppSelector(monthNoSignal$);
  const quartalNoSignal = useAppSelector(quartalNoSignal$);
  const isLoading = useAppSelector(isLoading$);

  const isShowNosignals = useMemo(
    () => !filteredFormattedSignals.length,
    [filteredFormattedSignals.length]
  );

  const noSignalItems = useMemo(() => {
    if (yearNoSignal.length) {
      return yearNoSignal;
    } else if (!yearNoSignal.length && quartalNoSignal.length) {
      return quartalNoSignal;
    } else {
      return monthNoSignal;
    }
  }, [monthNoSignal, quartalNoSignal, yearNoSignal]);

  const items = useMemo(
    () => (isShowNosignals ? noSignalItems : filteredFormattedSignals),
    [filteredFormattedSignals, isShowNosignals, noSignalItems]
  );

  return (
    <div className={style.root}>
      {items.map((item) => (
        <Signal
          key={item.id}
          item={item}
          onClick={onSelect}
          disabled={isLoading}
        />
      ))}
    </div>
  );
};
