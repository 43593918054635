import React, { useCallback } from "react";
import { useAppDispatch } from "app/hooks";
import { useDropzone } from "react-dropzone";
import cx from "classnames";
import { ReactComponent as UploadIcon } from "assets/svg/cloud-upload-outlined.svg";
import style from "./rollOutDropArea.module.scss";
import { useTranslation } from "react-i18next";
import {
  fileList$,
  uploadRollOutFile,
  isLoading$,
  setInitAllRollOutData,
} from "features/rollOutUploadFiles/slice";
import { useSelector } from "react-redux";
import { UploadFile } from "components/uploadFile";
import { Loader } from "components/loader";
import { ReactComponent as RefreshIcon } from "assets/svg/ic_round-refresh.svg";
import {
  initRollout,
  setCalculate,
  setCurrentFileId,
  setErrorText,
  setIds,
  setRecalculateLoadig,
} from "features/rollout/slice";

interface IProps {
  files: File[];
  setFiles: (f: File[]) => void;
}

export const RollOutDropArea = React.memo(({ setFiles, files }: IProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const uploadedFiles = useSelector(fileList$);
  const isLoading = useSelector(isLoading$);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (acceptedFiles?.length) {
        setFiles(acceptedFiles);
        acceptedFiles.forEach((file) => {
          dispatch(uploadRollOutFile({ file }));
        });
      }
    },
    [dispatch, setFiles]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: true,
    accept: {
      "application/xlsx": [".xlsx", ".xls", ".csv"],
      "application/csv": [".csv"],
    },
  });

  const DropzoneCX = cx(style.dropzone, {
    [style.active]: isDragActive,
    [style.files]: uploadedFiles.length && !isLoading,
  });

  const handleClearClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      dispatch(setInitAllRollOutData());
      dispatch(initRollout());
      setFiles([]);
      dispatch(setRecalculateLoadig(false));
      dispatch(setCalculate(false));
      dispatch(setIds(""));
      dispatch(setCurrentFileId(null));
      dispatch(setErrorText(null));
    },
    [dispatch, setFiles]
  );

  return (
    <div className={style.root}>
      <div {...getRootProps()} className={DropzoneCX}>
        {!uploadedFiles?.length && !isLoading && (
          <>
            <UploadIcon className={style.icon} />
            <div className={style.title}>
              {t("profile_roll_out_drop_title")}
            </div>
            <div className={style.subtitle}>
              {t("profile_roll_out_drop_subtitle")}
            </div>
          </>
        )}
        <div className={style.uploadFiles}>
          {!isLoading &&
            uploadedFiles.map((uploadFile) => (
              <UploadFile item={uploadFile} key={uploadFile.id} />
            ))}
        </div>

        {isLoading && (
          <div className={style.loader}>
            <Loader />
          </div>
        )}

        {!isLoading && uploadedFiles?.length ? (
          <button className={style.clearBtn} onClick={handleClearClick}>
            <RefreshIcon className={style.clearIcon} />
            <span className={style.text}>{t("clear")}</span>
          </button>
        ) : null}
        <input {...getInputProps()} />
      </div>
    </div>
  );
});
