import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import { tableValues } from "./utils";
import { PortfolioContractTableItem } from "components/portfolioContractTableItem";
import style from "./portfolioContractCreator.module.scss";

interface IProps {
  onChange: (value: string, id: number) => void;
  valueObject: { [value: number]: string };
}

export const PortfolioContractCreator = ({ onChange, valueObject }: IProps) => {
  const { t } = useTranslation();

  const handleChange = useCallback(
    (value: string, id: number) => {
      onChange(value, id);
    },
    [onChange]
  );

  const RootCX = (id: number) =>
    cx(style.item, {
      [style.first]: id === 0,
      [style.last]: id === tableValues.length - 1,
    });
  const renderItemsByType = useCallback(() => {
    return tableValues.map((item) => {
      return (
        <div className={RootCX(item.id)} key={item.id}>
          <div className={style.head}>{t(item.label)}</div>
          <div className={style.value}>
            <PortfolioContractTableItem
              type={item.type}
              items={item.items}
              value={valueObject[item.id]}
              id={item.id}
              onChange={handleChange}
            />
          </div>
        </div>
      );
    });
  }, [handleChange, t, valueObject]);

  return <div className={style.root}>{renderItemsByType()}</div>;
};
