// A mock function to mimic making an async request for data
import { Api } from "api";
import axios from "axios";
import { IUser } from "./slice";

export const getUserData = async () => {
  const res = await axios
    .get(`${Api.User}`)
    .then((resp) => resp)
    .catch((err) => err);
  return res;
};

export const editUserConfig = async (user: Partial<IUser>) => {
  const res = await axios.patch(`${Api.User}`, { ...user });
  return res;
};

export const uploadUserAvatar = async (file: File) => {
  let formData = new FormData();
  formData.append("file", file);
  const res = await axios.post(`${Api.UploadUserAvatar}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return res;
};
