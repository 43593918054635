import {
  ChangeEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import cx from "classnames";
import { ReactComponent as WarnIcon } from "assets/svg/warning.svg";
import { ReactComponent as EyeIcon } from "assets/svg/eye.svg";
import { ReactComponent as EyeClosedIcon } from "assets/svg/eyeClosed.svg";
import style from "./input.module.scss";

interface IProps {
  className?: string;
  disabled?: boolean;
  placeholder?: string;
  type?: string;
  isPassword?: boolean;
  value?: string;
  onChange: (e: ChangeEventHandler<HTMLInputElement>) => void;
  isError?: boolean;
  focused?: boolean;
}

export const Input = (props: IProps | any) => {
  const [showPass, setShowPass] = useState(false);
  const ref = useRef<HTMLElement | null>(null);

  const togglePassClick = useCallback(() => {
    setShowPass((prev) => !prev);
  }, []);

  useEffect(() => {
    if (props.focused) {
      ref?.current?.focus();
    }
  }, [props.focused]);

  const passcontainerCX = cx(style.passIconContainer, {
    [style.hidden]: !props.isPassword,
  });

  return (
    <div className={style.root}>
      <input
        ref={ref}
        className={style.input}
        disabled={props.disabled}
        placeholder={props.placeholder}
        type={props.type}
        autoComplete={"test"}
        onChange={props.onChange}
        {...props}
      />
      <div className={passcontainerCX} onClick={togglePassClick}>
        {showPass ? (
          <EyeClosedIcon className={style.passIconClosed} />
        ) : (
          <EyeIcon className={style.passIcon} />
        )}
      </div>
      {props.isError && <WarnIcon className={style.icon} />}
    </div>
  );
};
