import { Corridor } from "components/corridor";
import { CorridorPerformanceIndicator } from "components/corridorPerformanceIndicator";
import { ICorridorData } from "features/corridor/slice";
import style from "./corridorChartItem.module.scss";
import cx from "classnames";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { useEffect, useMemo } from "react";
import {
  options$,
  fetchPortfolioOptionById,
} from "features/portfolioOptions/slice";
interface IProps {
  portfolio_id: string | null;
  year?: number;
  isLoading: boolean;
  priceBenchmark?: number;
  pricePortfolio?: number;
  data: ICorridorData | null;
}
export const CorridorChartItem = ({
  portfolio_id,
  isLoading,
  data,
  year,
  priceBenchmark,
  pricePortfolio,
}: IProps) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (portfolio_id) dispatch(fetchPortfolioOptionById({ id: portfolio_id! }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portfolio_id]);

  const options = useAppSelector(options$);

  const isNeedToHidePerformanceIndicator = useMemo(
    () => options?.target_year === year,
    [options?.target_year, year]
  );

  const ChartCXRoot = cx(style.root, {
    [style.fullWidth]: isNeedToHidePerformanceIndicator,
  });
  return (
    <div className={ChartCXRoot}>
      <div className={style.chart}>
        <Corridor
          portfolio_id={portfolio_id}
          year={year}
          isLoading={isLoading}
          data={data}
        />
      </div>

      {!isNeedToHidePerformanceIndicator && (
        <CorridorPerformanceIndicator
          isLoading={isLoading}
          portfolioBenchmark={priceBenchmark}
          portfolioPrice={pricePortfolio}
        />
      )}
    </div>
  );
};
