import style from "./options.module.scss";
import { useTranslation } from "react-i18next";
import { OptionsContent } from "containers/optionsContent";

export const Options = () => {
  const { t } = useTranslation();

  return (
    <div className={style.root}>
      <h2 className={style.head}>{t("nav_portfolio_options")}</h2>
      <div className={style.content}>
        <OptionsContent />
      </div>
    </div>
  );
};
