import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { MarketDataCharts } from "components/marketDataCharts";
import {
  currencyExchange$,
  fetchMarketData,
  filteredChartData$,
  isLoading$ as isMarketDataLoading$,
} from "features/marketData/slice";
import {
  fetchMarketRange,
  marketEndDate$,
  marketStartDate$,
  isLoading$ as isMarketRangeLoading$,
} from "features/marketRange/slice";
import {
  getCurrentDate,
  getFriday,
  isWeekDay,
} from "containers/marketDataContent/utils";

import style from "./dashboardMarketData.module.scss";

export const DashboardMarketData = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const currencyExchange = useAppSelector(currencyExchange$);
  const chartData = useAppSelector(filteredChartData$);
  const isMarketDataLoading = useAppSelector(isMarketDataLoading$);
  const endDate = useAppSelector(marketEndDate$);
  const startDate = useAppSelector(marketStartDate$);
  const isMarketRangeLoading = useAppSelector(isMarketRangeLoading$);

  const isMarketRangeLoaded = useMemo(
    () => startDate && endDate && !isMarketRangeLoading,
    [startDate, endDate, isMarketRangeLoading]
  );

  const [date, setDate] = useState<string>("");

  useEffect(() => {
    dispatch(fetchMarketRange());
  }, [dispatch]);

  useEffect(() => {
    if (isMarketRangeLoaded) {
      const chartDate = isWeekDay(endDate as string)
        ? getFriday(endDate as string)
        : getCurrentDate(endDate as string);
      dispatch(fetchMarketData(chartDate));
      setDate(chartDate);
    }
  }, [dispatch, endDate, isMarketRangeLoaded]);

  const navigateToMarketDataPage = () => {
    navigate("/app/market-data");
  };

  return (
    <div className={style.root}>
      <header className={style.header}>
        <h2>{t("nav_market_data")}</h2>
        <div>
          <div className={style.dateContainer}>
            <label>
              {t("prices_of")} {date}
            </label>
          </div>
          <div className={style.priceContainer}>
            <label>EUR/CHF: {currencyExchange?.toFixed(4)}</label>
            <button className={style.btn} onClick={navigateToMarketDataPage}>
              {t("see_all_btn_text")}
            </button>
          </div>
        </div>
      </header>
      <MarketDataCharts data={chartData} isLoading={isMarketDataLoading} />
    </div>
  );
};
