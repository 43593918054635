import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { refreshToken, setDisconnect } from "features/auth/authSlice";
import { GET_MARKET_DATA } from "./constants";
import { RootState } from "app/store";
import { Api } from "api";
import axios from "axios";

export enum MarketDataType {
  QUARTERLY = "quarterly",
  MONTHLY = "monthly",
  YEARLY = "yearly",
}

export interface MarketDataState {
  isLoading: boolean;
  isError: boolean;
  data: IMarketData | null;
}

const initialState: MarketDataState = {
  isLoading: false,
  isError: false,
  data: null,
};

export interface IMarketData {
  market_data: IMarketDataForPeriod[];
  currency_exchange: number;
  market_data_download_url: string;
  history_data_download_url: string;
}

export interface IMarketDataForPeriod {
  date: string;
  data: IMarketDataItem[];
}

export interface IMarketDataItem {
  peak: number;
  base: number;
  period: string;
  period_type: string;
}

export interface ChartData {
  type: string;
  period: string;
  xAxis: string[];
  yAxis: number[];
}

export const fetchMarketData = createAsyncThunk(
  GET_MARKET_DATA,
  async (date: string, api) => {
    try {
      const res = await axios.get(`${Api.GetMarketData}?limit=10`, {
        params: {
          date,
        },
      });

      if (!res) {
        throw new Error("Error");
      }

      return res?.data || null;
    } catch (error: any) {
      api.dispatch(setMarketDataError(true));
      if (error?.request?.status === 403) {
        api.dispatch(setDisconnect(true));
      } else if (error?.request?.status === 401) {
        api.dispatch(
          refreshToken({ callback: fetchMarketData, parameter: date })
        );
      }
      return api.rejectWithValue("No Market Data found");
    }
  }
);

export const marketDataSlice = createSlice({
  name: "marketData",
  initialState,
  reducers: {
    setMarketDataError(state, action) {
      state.isError = action.payload;
    },
    initMarketData(state) {
      state.isError = false;
      state.isLoading = false;
      state.data = null;
    },
    setLoading(state, action) {
      state.isLoading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMarketData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchMarketData.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.isError = false;
        state.data = payload || null;
      })
      .addCase(fetchMarketData.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      });
  },
});

export const { setMarketDataError, initMarketData, setLoading } =
  marketDataSlice.actions;

export const isLoading$ = (state: RootState) => state.marketData.isLoading;
export const isError$ = (state: RootState) => state.marketData.isError;
export const marketData$ = (state: RootState) =>
  state.marketData.data?.market_data;
export const currencyExchange$ = (state: RootState) =>
  state.marketData.data?.currency_exchange;
export const downloadDayHPFCUrl$ = (state: RootState) =>
  state.marketData.data?.market_data_download_url;
export const downloadDataHistoryUrl$ = (state: RootState) =>
  state.marketData.data?.history_data_download_url;

export const quaterlyTableData$ = (state: RootState) => {
  return state.marketData.data?.market_data[0].data.filter(
    (item) => item.period_type === MarketDataType.QUARTERLY
  );
};

export const monthlyTableData$ = (state: RootState) => {
  return state.marketData.data?.market_data[0].data.filter(
    (item) => item.period_type === MarketDataType.MONTHLY
  );
};

export const getInitialChartData = (state: RootState) => {
  return state.marketData.data?.market_data.map((item) => ({
    date: item.date,
    data: item.data.filter(
      (chartPoint) => chartPoint.period_type === MarketDataType.YEARLY
    ),
  }));
};

const onSortChartData = (data: ChartData[]) => {
  const sortedBaseCharts = data
    .filter((item) => item.type === "base")
    .sort((a: ChartData, b: ChartData) => (a.period > b.period ? 1 : -1));
  const sortedPeakCharts = data
    .filter((item) => item.type === "peak")
    .sort((a: ChartData, b: ChartData) => (a.period > b.period ? 1 : -1));

  return [...sortedBaseCharts, ...sortedPeakCharts];
};

export const filteredChartData$ = (state: RootState) => {
  let finalChartData: ChartData[] = [];

  const initialChartData = getInitialChartData(state);

  initialChartData?.forEach((item) => {
    item?.data.forEach((chartPoint) => {
      const isChartDataExists = !!finalChartData.find(
        (chart) => chart.period === chartPoint.period
      );

      if (isChartDataExists) {
        const updatedFinalData = finalChartData.map((existingItem) => {
          return existingItem.period === chartPoint.period
            ? {
                ...existingItem,
                yAxis: [
                  ...existingItem.yAxis,
                  existingItem.type === "base"
                    ? chartPoint.base
                    : chartPoint.peak,
                ],
                xAxis: [...existingItem.xAxis, item.date],
              }
            : existingItem;
        });
        finalChartData = updatedFinalData;
      } else {
        finalChartData.push(
          {
            type: "base",
            yAxis: [chartPoint.base],
            period: chartPoint.period,
            xAxis: [item.date],
          },
          {
            type: "peak",
            yAxis: [chartPoint.peak],
            period: chartPoint.period,
            xAxis: [item.date],
          }
        );
      }
    });
  });

  return onSortChartData(finalChartData);
};

export default marketDataSlice.reducer;
