import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import style from "./navigationChildItem.module.scss";
import "./style.scss";
import cx from "classnames";

interface IProps {
  id: number | string;
  label: string;
  link: string;
  isOpenNavigation: boolean;
}

export const NavigationChildItem = ({
  id,
  label,
  link,
  isOpenNavigation,
}: IProps) => {
  const { t } = useTranslation();
  const LabelCX = cx(style.label, {
    [style.hidden]: !isOpenNavigation,
  });

  const RootCX = cx("sidebar-item-subnav", {
    hidden: !isOpenNavigation,
  });

  return (
    <NavLink className={RootCX} to={link}>
      <span className={LabelCX}>{t(label)}</span>
    </NavLink>
  );
};
