import style from "./avatarUploader.module.scss";
import { ReactComponent as EditIcon } from "assets/svg/edit.svg";
import { useDropzone } from "react-dropzone";
import { useCallback } from "react";
import { useAppDispatch } from "app/hooks";
import { uploadAvatar } from "features/user/slice";

export const AvatarUploader = () => {
  const dispatch = useAppDispatch();
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      // Do something with the files
      if (acceptedFiles?.length) {
        dispatch(uploadAvatar(acceptedFiles[0]));
      }
    },
    [dispatch]
  );

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    multiple: false,
    noClick: true,
    accept: {
      "image/png": [".png", ".jpeg", ".jpg"],
    },
  });

  return (
    <div className={style.root}>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <EditIcon className={style.editIcon} onClick={open} />
      </div>
    </div>
  );
};
