import { IPortfolioContractsData } from "features/portfolioContracts/slice";
import style from "./PortfolioContractsTableItem.module.scss";
import cx from "classnames";
import { ReactComponent as DeleteIcon } from "assets/svg/delete.svg";
import { ReactComponent as EditIcon } from "assets/svg/clarity_edit-solid.svg";
import { addSpaceEveryNth } from "utils";

interface IProps {
  item: IPortfolioContractsData;
  hideSomeColumns?: boolean;
  onDelete: (id: string) => void;
  onEdit: (id: string) => void;
  isDisabled?: boolean;
  hasScroll: boolean;
  isHideActions?: boolean;
}

export const PortfolioContractsTableItem = ({
  item,
  hideSomeColumns,
  onDelete,
  onEdit,
  isDisabled,
  hasScroll,
  isHideActions,
}: IProps) => {
  const RootCX = cx(style.root, {
    [style.hideSomeColumns]: hideSomeColumns,
    [style.hasScroll]: hasScroll,
    [style.isHideActions]: isHideActions,
  });

  const handleDelete = () => {
    onDelete(item.id);
  };

  const handleEdit = () => {
    onEdit(item.id);
  };

  const IconCX = cx(style.icon, {
    [style.disabled]: isDisabled,
  });
  return (
    <div className={RootCX}>
      <div>{item?.date}</div>
      <div>{item?.deal}</div>
      <div>{item?.year}</div>
      <div>{item?.period}</div>
      <div>{item?.type}</div>
      <div>{item?.power}</div>
      <div>{item?.supplier}</div>
      <div>{item?.currency}</div>
      <div>{item?.amount}</div>
      {!hideSomeColumns && <div>{item?.price_CHF}</div>}
      <div>{item?.price_EUR}</div>
      {!hideSomeColumns && <div>{addSpaceEveryNth(item?.worth_CHF!)}</div>}
      <div>{addSpaceEveryNth(item?.worth_EUR!)}</div>
      {!isHideActions && (
        <div className={style.actions}>
          <DeleteIcon onClick={handleDelete} className={IconCX} />
          <EditIcon className={IconCX} onClick={handleEdit} />
        </div>
      )}
    </div>
  );
};
