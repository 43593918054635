import { useMemo } from "react";
import { useAppSelector } from "app/hooks";

import style from "./hedgeContent.module.scss";
import { BarChartMulty } from "components/barChartMulty";
import { DropdownSelect, ISelectFullValue } from "components/dropdownSelect";
import { types } from "./utils";
import {
  baseHedgeTableHeaders$,
  peakHedgeTableHeaders$,
  baseHedgeTableData$,
  peakHedgeTableData$,
  HedgeY,
} from "features/hedge/slice";
import { useElementSize } from "utils/hooks";
import { HedgeTable } from "components/hedgeTable";
import { useTranslation } from "react-i18next";
import { Loader } from "components/loader";
import cx from "classnames";
import { user$ } from "features/user/slice";
import { isOpenNavigation$ } from "features/nav/slice";

interface IProps {
  year: number;
  type: ISelectFullValue;
  hedgeXBase: string[];
  hedgeXPeak: string[];
  hedgeYBase: HedgeY[];
  hedgeYPeak: HedgeY[];
  isLoading: boolean | null;
  portfolio_id: string;
  hedgeError: string | boolean | null;
  isError: boolean;
  isPageForDashboard?: boolean;
  handleTypeChange: (v: ISelectFullValue, id?: string) => void;
}

export const HedgeContent = ({
  year,
  type,
  hedgeXBase,
  hedgeXPeak,
  hedgeYBase,
  hedgeYPeak,
  portfolio_id,
  hedgeError,
  isError,
  isPageForDashboard,
  isLoading,
  handleTypeChange,
}: IProps) => {
  const [wrapperRef, { width }] = useElementSize();
  const { t } = useTranslation();
  const user = useAppSelector(user$);

  const baseHedgeTableHeaders = useAppSelector(baseHedgeTableHeaders$);
  const peakHedgeTableHeaders = useAppSelector(peakHedgeTableHeaders$);
  const baseHedgeTableData = useAppSelector(baseHedgeTableData$);
  const peakHedgeTableData = useAppSelector(peakHedgeTableData$);
  const isOpenNavigation = useAppSelector(isOpenNavigation$);

  const name = useMemo(
    () =>
      user?.portfolio?.find((portfolio) => portfolio.id === portfolio_id)?.name,
    [portfolio_id, user?.portfolio]
  );

  const RootCX = cx(style.root, {
    [style.loading]: isLoading,
    [style.closedNavigation]: !isOpenNavigation,
  });

  const ContentCX = cx(style.content, {
    [style.error]: isError,
  });

  const DropdownCX = cx(style.dropdown, {
    [style.hidden]: isError,
  });
  const TableCX = cx(style.table, {
    [style.hidden]: isError,
  });

  const onChange = (id: string) => (event: ISelectFullValue | any) => {
    handleTypeChange(event, id);
  };

  return (
    <div className={RootCX}>
      {isLoading && (
        <div className={style.loader}>
          <Loader />
        </div>
      )}
      <h3 className={style.title}>{`Hedge ${year || ""}`}</h3>
      <div className={DropdownCX}>
        <DropdownSelect
          items={types as any}
          isAllValueNeed
          value={type as any}
          onChange={onChange(portfolio_id)}
          disabled={!!isLoading}
          small
          withoutBorder
        />
      </div>
      <div className={ContentCX}>
        {isError && !hedgeError && !isLoading && (
          <div className={style.errorText}>{t("hedge_error_title")}</div>
        )}
        {hedgeError && !isLoading && (
          <div className={style.errorText}>{hedgeError}</div>
        )}
        <div className={style.wrapper} ref={wrapperRef}>
          {!hedgeError && (
            <>
              <BarChartMulty
                dataType="MW"
                x={hedgeXBase}
                y={hedgeYBase}
                width={width && width / 2}
                title="Base"
                tooltipPrefix="MW"
                filename={`hedge_base_${name}_${year}`}
              />
              <BarChartMulty
                dataType="MW"
                x={hedgeXPeak}
                y={hedgeYPeak}
                width={width && width / 2}
                title="Peak"
                tooltipPrefix="MW"
                filename={`hedge_peak_${name}_${year}`}
              />
            </>
          )}
        </div>
        {!isPageForDashboard && (
          <div className={TableCX}>
            <HedgeTable
              title={t("hedge_table_title")}
              name={"Base"}
              leftTableHeaders={baseHedgeTableHeaders}
              leftTableData={baseHedgeTableData}
              rightTableHeaders={peakHedgeTableHeaders}
              rightTableData={peakHedgeTableData}
              isLoading={!!isLoading}
              isMonthly={type?.value === types[2]?.value}
            />
          </div>
        )}
      </div>
    </div>
  );
};
