import { Logo } from "components/logo";
import { Navigation } from "containers/navigation";
import { useNavigate } from "react-router-dom";
import style from "./aside.module.scss";
import { ReactComponent as AngleIcon } from "assets/svg/angle.svg";
import cx from "classnames";
import { isOpenNavigation$, setNavPosition } from "features/nav/slice";
import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "app/hooks";

export const Aside = () => {
  const navigate = useNavigate();
  const isOpenNavigation = useAppSelector(isOpenNavigation$);
  const dispatch = useAppDispatch();

  const handleLogoClick = () => {
    navigate("/app");
  };

  const toggleClick = useCallback(() => {
    dispatch(setNavPosition(!isOpenNavigation));
  }, [dispatch, isOpenNavigation]);

  const RootCX = cx(style.root, {
    [style.closed]: !isOpenNavigation,
  });

  const LogoCX = cx(style.logo, {
    [style.closed]: !isOpenNavigation,
  });

  const AngleCX = cx(style.icon, {
    [style.closed]: !isOpenNavigation,
  });

  return (
    <aside className={RootCX}>
      <div className={style.angleContainer} onClick={toggleClick}>
        <AngleIcon className={AngleCX} />
      </div>
      <Logo
        className={LogoCX}
        onClick={handleLogoClick}
        isShort={!isOpenNavigation}
      />
      <Navigation isOpenNavigation={isOpenNavigation} />
    </aside>
  );
};
