import style from "./profiles.module.scss";
import { useTranslation } from "react-i18next";
import { PortfolioProfileContent } from "components/portfolioProfileContent";

export const Profiles = () => {
  const { t } = useTranslation();

  return (
    <div className={style.root}>
      <h2 className={style.head}>{t("nav_portfolio_profiles")}</h2>
      <PortfolioProfileContent />
    </div>
  );
};
