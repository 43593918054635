import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import style from "./backToSignIn.module.scss";

export const BackToSignIn = () => {
  const { t } = useTranslation();
  return (
    <NavLink to={"/app"} className={style.root}>
      {t("back_to_sign_in_btn")}
    </NavLink>
  );
};
