import { useAppSelector } from "app/hooks";
import { user$ } from "features/user/slice";
import { useMemo } from "react";
import { defaultLettersForAvatar } from "utils";
import style from "./avatar.module.scss";
interface IProps {
  className?: string;
}
export const Avatar = ({ className }: IProps) => {
  const user = useAppSelector(user$);
  const avatarUrl = useMemo(() => user.image, [user]);

  const defaultAvatarLetters = defaultLettersForAvatar(user);

  return (
    <>
      {avatarUrl && (
        <img
          src={avatarUrl}
          className={className ? `${style.avatar} ${className}` : style.avatar}
          alt="default avatar"
        />
      )}
      {!avatarUrl && (
        <div
          className={
            className ? `${style.default} ${className}` : style.default
          }
        >
          {defaultAvatarLetters}
        </div>
      )}
    </>
  );
};
