import { useTranslation } from "react-i18next";
import { IMarketDataItem } from "features/marketData/slice";
import { Loader } from "components/loader";

import style from "./marketDataTable.module.scss";
import cx from "classnames";
import { getCurrencyByMarket } from "utils";
import { useMemo } from "react";
import { useAppSelector } from "app/hooks";
import { market$ } from "features/user/slice";

export enum TableType {
  QUARTERLY = "quarterly",
  MONTHLY = "monthly",
  YEARLY = "yearly",
}

interface IProps {
  tableData?: IMarketDataItem[];
  tableType?: string;
  isLoading: boolean;
}

export const MarketDataTable = ({
  tableData,
  tableType,
  isLoading,
}: IProps) => {
  const { t } = useTranslation();
  const market = useAppSelector(market$);

  const tableHeader: string[] = useMemo(
    () => [`${getCurrencyByMarket(market)}/MWh`, "Base", "Peak"],
    [market]
  );

  const TableCx = cx(style.tableBody, {
    [style.monthlyTable]: tableType === TableType.MONTHLY,
    [style.quarterlyTable]: tableType === TableType.QUARTERLY,
  });

  const onSwap = (arr: string[], index: number) =>
    index === 0 ? arr[1] : `${arr[0]}, `;

  const onUpdatePeriodTime = (string: string) => {
    return string
      .split(" ")
      .map((item, index) =>
        (tableType === TableType.QUARTERLY && index === 1) ||
        (tableType === TableType.MONTHLY && index === 0)
          ? t(item)
          : item
      )
      .map((item, index, arr) =>
        tableType === TableType.QUARTERLY && index !== 2
          ? onSwap(arr, index)
          : item
      )
      .join(" ");
  };

  return (
    <div className={style.root}>
      {isLoading ? (
        <div className={style.loaderContainer}>
          <Loader />
        </div>
      ) : (
        <>
          <div className={style.tableHeaderColumn}>
            {tableHeader.map((item: string, index: number) => (
              <div key={index}>{item}</div>
            ))}
          </div>

          <div className={TableCx}>
            {tableData?.map((item: IMarketDataItem, index: number) => (
              <div className={style.tableBodyColumn} key={index}>
                <div>{onUpdatePeriodTime(item.period)}</div>
                <div>{item.base}</div>
                <div>{item.peak}</div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};
