import { useTranslation } from "react-i18next";
import { useAppSelector } from "app/hooks";
import {
  downloadDayHPFCUrl$,
  downloadDataHistoryUrl$,
  isLoading$,
  marketData$,
} from "features/marketData/slice";
import { uploadFile } from "utils";
import style from "./marketDataHeader.module.scss";

export const MarketDataHeader = () => {
  const { t } = useTranslation();

  const downloadDayHPFCUrl = useAppSelector(downloadDayHPFCUrl$);
  const downloadDataHistoryUrl = useAppSelector(downloadDataHistoryUrl$);
  const isLoading = useAppSelector(isLoading$);
  const marketData = useAppSelector(marketData$);

  const buttons = [
    {
      title: "export_day_hpfc",
      cssClass: style.exportDayBtn,
      url: downloadDayHPFCUrl as string,
    },
    {
      title: "export_data_history",
      cssClass: style.exportDataBtn,
      url: downloadDataHistoryUrl as string,
    },
  ];

  const onExportFile = (url: string) => () => uploadFile(url, "");

  return (
    <header className={style.root}>
      <h2 className={style.head}>{t("nav_market_data")}</h2>
      {marketData && !isLoading ? (
        <div className={style.exportButtons_container}>
          {buttons.map((button) => (
            <button
              key={button.title}
              className={`${style.exportBtn} ${button.cssClass}`}
              onClick={onExportFile(button.url)}
            >
              {t(button.title)}
            </button>
          ))}
        </div>
      ) : null}
    </header>
  );
};
