import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { refreshToken, setDisconnect } from "features/auth/authSlice";
import { RootState } from "../../app/store";
import { GET_HEDGE_CHART } from "./constants";
import { Api } from "api";
import i18n from "i18n";
import axios from "axios";

export interface PortfolioContractsState {
  isLoading: boolean;
  isError: boolean;
  data: null | IProtfolioHedge;
  hedgeError: null | string;
}

const initialState: PortfolioContractsState = {
  isLoading: false,
  isError: false,
  data: null,
  hedgeError: null,
};

export interface IProtfolioHedge {
  base: IProtfolioHedgeData;
  peak: IProtfolioHedgeData;
}

export interface IProtfolioHedgeData {
  x: string[];
  val_1: number[];
  val_2: number[];
}

export interface IPortfolioHedgeRequest {
  period: string;
  year: number;
  portfolio_id: string;
}

export interface HedgeY {
  name: string;
  data: number[];
}

export const fetchPortfolioHedge = createAsyncThunk(
  GET_HEDGE_CHART,
  async ({ period, year, portfolio_id }: IPortfolioHedgeRequest, api) => {
    try {
      const res = await axios.get(`${Api.GetHedge}`, {
        params: {
          period,
          year,
          portfolio_id,
        },
      });

      if (!res) {
        throw new Error("Error");
      }

      return res?.data || null;
    } catch (error: any) {
      api.dispatch(setPortfolioContractsError(true));
      if (error?.request?.status === 403) {
        api.dispatch(setDisconnect(true));
      } else if (error?.request?.status === 400) {
        api.dispatch(
          setHedgeErrorText(i18n.t("rollout_processing_status_error"))
        );
      } else if (error?.request?.status === 401) {
        api.dispatch(
          refreshToken({
            callback: fetchPortfolioHedge,
            parameter: { period, year, portfolio_id },
          })
        );
      }
      return api.rejectWithValue("No user found");
    }
  }
);

export const hedgeSlice = createSlice({
  name: "hedge",
  initialState,
  reducers: {
    setPortfolioContractsError(state, action) {
      state.isError = action.payload;
    },
    initHedgeData(state) {
      state.isError = false;
      state.isLoading = false;
      state.data = null;
      state.hedgeError = null;
    },
    setLoading(state, action) {
      state.isLoading = action.payload;
      state.hedgeError = null;
    },
    setHedgeErrorText(state, action) {
      state.hedgeError = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPortfolioHedge.pending, (state) => {
        state.isLoading = true;
        state.hedgeError = null;
      })
      .addCase(fetchPortfolioHedge.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.isError = false;
        state.data = payload || null;
        state.hedgeError = null;
      })
      .addCase(fetchPortfolioHedge.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      });
  },
});
export const {
  setPortfolioContractsError,
  initHedgeData,
  setLoading,
  setHedgeErrorText,
} = hedgeSlice.actions;
export const isLoading$ = (state: RootState) => state.hedge.isLoading;
export const isError$ = (state: RootState) => state.hedge.isError;
export const contracts$ = (state: RootState) => state.hedge.data;
export const hedgeError$ = (state: RootState) => state.hedge.hedgeError;
export const hedgeXBase$ = (state: RootState) => {
  return state.hedge.data?.base?.x ? state.hedge.data?.base?.x : [];
};

export const hedgeXPeak$ = (state: RootState) => {
  return state.hedge.data?.peak?.x ? state.hedge.data?.peak?.x : [];
};

export const hedgeYBase$ = (state: RootState) => {
  return state.hedge.data?.base?.val_1?.length &&
    state.hedge.data?.base?.val_2?.length
    ? [
        { name: "Hedge", data: state.hedge.data?.base?.val_1 },
        { name: "Position", data: state.hedge.data?.base?.val_2 },
      ]
    : [];
};

export const hedgeYPeak$ = (state: RootState) => {
  return state.hedge.data?.peak?.val_1?.length &&
    state.hedge.data?.peak?.val_2?.length
    ? [
        { name: "Hedge", data: state.hedge.data?.peak?.val_1 },
        { name: "Position", data: state.hedge.data?.peak?.val_2 },
      ]
    : [];
};

export const baseHedgeTableData$ = (state: RootState) => {
  const leftValues = state.hedge.data?.base?.val_1;
  const rightValues = state.hedge.data?.base?.val_2;
  if (leftValues?.length && rightValues?.length) {
    return leftValues.map((item, index) => {
      return item - rightValues[index];
    });
  } else {
    return [];
  }
};

export const peakHedgeTableData$ = (state: RootState) => {
  const leftValues = state.hedge.data?.peak?.val_1;
  const rightValues = state.hedge.data?.peak?.val_2;
  if (leftValues?.length && rightValues?.length) {
    return leftValues.map((item, index) => {
      return item - rightValues[index];
    });
  } else {
    return [];
  }
};

export const baseHedgeTableHeaders$ = (state: RootState) => {
  return state.hedge.data?.base?.x || [];
};

export const peakHedgeTableHeaders$ = (state: RootState) => {
  return state.hedge.data?.peak?.x || [];
};

export default hedgeSlice.reducer;
