import { PortfolioYearPicker } from "components/portfolioYearPicker";
import { memo, useEffect, useCallback, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  currentProfileData$,
  fetchPortfolioProfile,
  IPortfolioProfileData,
  profileData$,
} from "features/portfolioProfile/slice";
import {
  fetchPortfolioProfileGraph,
  isLoading$ as isLoadingGraph$,
  setPlottingData,
  setChartData,
} from "features/portfolioProfileGraph/slice";

import { isLoading$ as isLoadingProfile$ } from "features/portfolioProfile/slice";
import { isLoading$ as isLoadingHedge$ } from "features/hedge/slice";

export const YearPicker = memo(() => {
  const dispatch = useAppDispatch();
  const [portfolio_id, setPortFolioId] = useState<string | null>(null);
  const [searchParams] = useSearchParams();

  const isLoadingGraph = useAppSelector(isLoadingGraph$);
  const isLoadingProfile = useAppSelector(isLoadingProfile$);
  const isLoadingHedge = useAppSelector(isLoadingHedge$);

  const currentProfileData = useAppSelector(currentProfileData$);
  const profileData = useAppSelector(profileData$);

  useEffect(() => {
    if (searchParams.get("id") !== portfolio_id) {
      const id = searchParams.get("id");
      setPortFolioId(id);
    }
  }, [searchParams, portfolio_id]);

  const handleYearClick = useCallback(
    ({ id }: IPortfolioProfileData) => {
      if (id) {
        dispatch(fetchPortfolioProfileGraph({ id }));
      } else {
        dispatch(setChartData(null));
        dispatch(setPlottingData(null));
      }
    },
    [dispatch]
  );
  const handleInitLoad = useCallback(() => {
    if (portfolio_id) dispatch(fetchPortfolioProfile({ id: portfolio_id }));
  }, [dispatch, portfolio_id]);

  return (
    <PortfolioYearPicker
      onClick={handleYearClick}
      disable={isLoadingGraph || isLoadingProfile || isLoadingHedge}
      onInitLoad={handleInitLoad}
      year={currentProfileData?.year as number}
      data={profileData}
      portfolioId={portfolio_id as string}
      pageName="overview"
      isLoading={isLoadingProfile}
    />
  );
});
