import { memo } from "react";
import { useSelector } from "react-redux";
import { accessToken$, isLoading$ } from "features/auth/authSlice";
import style from "./authHandler.module.scss";
import { Loader } from "components/loader";
import { PrivateModule } from "modules/private";
import { PublicModule } from "modules/public";

export const AuthHandler = memo(() => {
  const accessToken = useSelector(accessToken$);
  const isLoading = useSelector(isLoading$);

  const renderModule = accessToken?.length ? (
    <PrivateModule />
  ) : (
    <PublicModule />
  );

  return (
    <>
      {renderModule}
      {isLoading && (
        <div className={style.loaderContainer}>
          <Loader />
        </div>
      )}
    </>
  );
});
