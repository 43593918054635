import DropdownInternal from "rc-dropdown";
import "rc-dropdown/assets/index.css";
interface IProps {
  placeholder?: string;
  overlay: JSX.Element;
  children: JSX.Element;
  onVisibleChange?: (visible: boolean) => void;
  visible?: boolean;
}

export const Dropdown = ({
  overlay,
  children,
  onVisibleChange,
  visible,
}: IProps) => {
  const dropdownInternalProps = {
    trigger: "click",
    overlay,
    animation: "slide-up",
    onVisibleChange,
    ...(visible !== undefined && { visible }),
  };
  return (
    <>
      <DropdownInternal {...dropdownInternalProps}>{children}</DropdownInternal>
    </>
  );
};
