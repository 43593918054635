import { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { GraphListSkeleton } from "components/graphListSkeleton";
import { GraphListToggleItem } from "components/graphListToggleItem";
import {
  currentGraphId$,
  fetchRollOutFile,
  fetchRollOutGraph,
  graphFiles$,
  IGraphFile,
  isCalculatePressed$,
  isSum$,
  setErrorText,
} from "features/rollout/slice";
import { fileList$ } from "features/rollOutUploadFiles/slice";
import { useTranslation } from "react-i18next";
import style from "./graphListToggler.module.scss";

interface IProps {
  list: IGraphFile[];
}

export const GraphListToggler = ({ list }: IProps) => {
  const [current, setCurrent] = useState<IGraphFile | null>(null);
  const isCalculatePressed = useAppSelector(isCalculatePressed$);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const currentGraphId = useAppSelector(currentGraphId$);
  const graphFiles = useAppSelector(graphFiles$);
  const fileList = useAppSelector(fileList$);
  const isSum = useAppSelector(isSum$);

  useEffect(() => {
    if (list.length && !current) {
      setCurrent(list[0]);
    }
  }, [list, current]);

  useEffect(() => {
    if (currentGraphId && graphFiles.length) {
      const currentGraphFile = graphFiles.find((f) => f.id === currentGraphId);
      if (currentGraphFile?.id) {
        setCurrent(currentGraphFile);
      }
    }
  }, [currentGraphId, graphFiles]);

  const handleChangeChartById = (data: IGraphFile) => {
    if (!data?.error) {
      setCurrent(data);
      const { id } = data;
      const isLoading = true;
      dispatch(fetchRollOutGraph({ id, isLoading }));
      dispatch(setErrorText(null));
    } else {
      dispatch(setErrorText(t("rollout_processing_status_error")));
    }
  };

  const handleExport = () => {
    if (current) {
      dispatch(fetchRollOutFile({ id: current.id }));
    }
  };

  const skeletonRender = useMemo(() => {
    const result = isCalculatePressed
      ? isSum
        ? 1 - list.length
        : fileList.length - list.length
      : 0;
    return Array.from(
      { length: result >= 0 ? result : 0 },
      (_, index) => index
    );
  }, [isCalculatePressed, isSum, fileList.length, list.length]);

  return (
    <div className={style.root}>
      <div className={style.title}>{t("rollout_list_title")}</div>
      <div className={style.list}>
        {list.map((item) => (
          <GraphListToggleItem
            key={item.id}
            item={item}
            selected={current?.id === item.id}
            onClick={handleChangeChartById}
          />
        ))}
        {skeletonRender.map((_, index) => (
          <GraphListSkeleton key={index} />
        ))}
      </div>
      <button
        className={style.btn}
        onClick={handleExport}
        disabled={!currentGraphId}
      >
        {t("export_btn_label")}
      </button>
    </div>
  );
};
