import { useAppSelector } from "app/hooks";
import { currentProfileData$ } from "features/portfolioProfile/slice";
import { memo, useMemo } from "react";
import style from "./portfolioWithChartData.module.scss";
import { PortfolioProfileData } from "components/portfolioProfileData";
import { getCurrentYear } from "utils";
import { PortfolioChart } from "components/portfolioChart";
import { PortfolioChartPlaceholder } from "components/portfolioChartPlaceholder";

export const PortfolioWithChartData = memo(() => {
  const currentProfileData = useAppSelector(currentProfileData$);

  const year = useMemo(
    () => currentProfileData?.year || getCurrentYear(),
    [currentProfileData]
  );

  return (
    <div className={style.root}>
      <PortfolioChartPlaceholder />
      <PortfolioChart />
      <PortfolioProfileData year={year} />
    </div>
  );
});
