import { useAppDispatch, useAppSelector } from "app/hooks";
import { Dropdown } from "components/dropdown";
import { accessToken$ } from "features/auth/authSlice";
import { editUser, user$ } from "features/user/slice";
import i18n from "i18n";
import { useCallback, useState } from "react";
import { ILanguage, languages } from "utils";
import style from "./dropdownTranslation.module.scss";
import { ReactComponent as ArrowIcon } from "assets/svg/ep_arrow-right-bold.svg";
import cx from "classnames";

export const DropdownTranslation = () => {
  const [lang, setLang] = useState<ILanguage>({ title: "En", value: "en" });
  const [isOpen, setOpen] = useState(false);
  const accessToken = useAppSelector(accessToken$);
  const user = useAppSelector(user$);
  const dispatch = useAppDispatch();

  const handleLangClick = useCallback(
    (lang: ILanguage) => () => {
      i18n.changeLanguage(lang.value);
      setLang(lang);
      if (accessToken) {
        dispatch(editUser({ localisation: lang.value.toUpperCase() }));
      }
    },
    [accessToken, dispatch]
  );

  const renderLanguages = useCallback(() => {
    return languages.map((l) => {
      return (
        <div
          key={l.value}
          className={style.langItem}
          onClick={handleLangClick(l)}
        >
          {l.title}
        </div>
      );
    });
  }, [handleLangClick]);

  const renderLangContainer = useCallback(() => {
    return <div className={style.container}>{renderLanguages()}</div>;
  }, [renderLanguages]);

  const ArrowCX = cx(style.arrow, {
    [style.open]: isOpen,
  });

  const onVisibleChange = (c: boolean) => {
    setOpen(c);
  };
  return (
    <Dropdown overlay={renderLangContainer()} onVisibleChange={onVisibleChange}>
      <button className={style.btn}>
        {user?.localisation || lang.title}
        <ArrowIcon className={ArrowCX} />
      </button>
    </Dropdown>
  );
};
