import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import cx from "classnames";
import style from "./profileContent.module.scss";
import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  deleteUserAvatar,
  editUser,
  isError$,
  isLoading$,
  setUserError,
  user$,
} from "features/user/slice";
import { useEffect } from "react";
import { Avatar } from "components/avatar";
import { AvatarUploader } from "containers/avatarUploader";
import { ReactComponent as DeleteIcon } from "assets/svg/delete.svg";
import { showDialogue } from "utils";

export const ProfileContent = () => {
  const { t } = useTranslation();
  const isError = useAppSelector(isError$);
  const isLoading = useAppSelector(isLoading$);
  const dispatch = useAppDispatch();
  const user = useAppSelector(user$);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm();

  useEffect(() => {
    if (user) {
      setValue("last_name", user.last_name || "");
      setValue("first_name", user.first_name || "");
      setValue("position_company", user.position_company || "");
      setValue("company", user.company || "");
      setValue("phone", user.phone || "");
      setValue("postal_code", user.postal_code || "");
      setValue("address", user.address || "");
      setValue("city", user.city || "");
    }
  }, [setValue, user]);

  const handleFormClick = () => {
    if (isError) {
      dispatch(setUserError(false));
    }
  };

  const handleDeleteClick = () => {
    showDialogue({
      title: t("delete_avatar_modal_title"),
      text: t("delete_avatar_modal_text"),
      confirmButtonText: t("yes"),
      cancelButtonText: t("no"),
      confirmButtonColor: "#3F1FED",
    }).then((result) => {
      if (result?.isConfirmed) {
        dispatch(deleteUserAvatar());
      }
    });
  };

  const formCX = cx(style.root, {
    [style.error]: isError || errors?.password_repeat || errors?.password,
  });

  const onSubmit = async (data: any) => {
    const userData = { ...data, id: user.id };
    const res = await dispatch(editUser(userData));
    return res;
  };

  const FirstNameCX = cx(style.input, {
    [style.error]: errors?.first_name || isError,
  });

  const CompanyCX = cx(style.input, {
    [style.error]: errors?.company || isError,
  });

  const PhoneCX = cx(style.input, {
    [style.error]: errors?.phone || isError,
  });

  const PostalCodeCX = cx(style.input, {
    [style.error]: errors?.postal_code || isError,
  });

  const LastNameCX = cx(style.input, {
    [style.error]: errors?.last_name || isError,
  });

  const PositionCompanyCX = cx(style.input, {
    [style.error]: errors?.position_company || isError,
  });

  const AddressCX = cx(style.input, {
    [style.error]: errors?.address || isError,
  });

  const CityCX = cx(style.input, {
    [style.error]: errors?.city || isError,
  });

  const labelCX = (value: string) =>
    cx(style.label, {
      [style.hidden]: !value?.length,
    });

  return (
    <form
      className={formCX}
      onSubmit={handleSubmit(onSubmit)}
      onClick={handleFormClick}
    >
      <div className={style.avatarContainer}>
        {user.image && (
          <DeleteIcon
            className={style.deleteIcon}
            onClick={handleDeleteClick}
          />
        )}
        <Avatar className={style.avatar} />
        <AvatarUploader />
      </div>
      <div className={style.content}>
        <div className={style.leftSide}>
          <div className={style.inputContainer}>
            <label className={labelCX(watch("first_name"))}>
              {t("first_name_placeholder")}
            </label>
            <input
              className={FirstNameCX}
              disabled={isLoading}
              id="first_name"
              {...register("first_name", {
                maxLength: {
                  value: 225,
                  message: t("input_max_length"),
                },
              })}
              placeholder={t("first_name_placeholder")}
              type={"text"}
            />
            {errors?.first_name && !isError ? (
              <div className={style.err}>{errors?.first_name?.message}</div>
            ) : null}
          </div>
          <div className={style.inputContainer}>
            <label className={labelCX(watch("company"))}>
              {t("company_placeholder")}
            </label>
            <input
              className={CompanyCX}
              disabled={isLoading}
              placeholder={t("company_placeholder")}
              id="company"
              type={"text"}
              {...register("company", {
                maxLength: {
                  value: 225,
                  message: t("input_max_length"),
                },
              })}
            />
            {errors?.company && !isError ? (
              <div className={style.err}>{errors?.company?.message}</div>
            ) : null}
          </div>
          <div className={style.inputContainer}>
            <label className={labelCX(watch("phone"))}>
              {t("phone_placeholder")}
            </label>
            <input
              className={PhoneCX}
              disabled={isLoading}
              placeholder={t("phone_placeholder")}
              id="phone"
              type={"text"}
              {...register("phone", {
                maxLength: {
                  value: 225,
                  message: t("input_max_length"),
                },
              })}
            />

            {errors?.phone && !isError ? (
              <div className={style.err}>{errors?.phone?.message}</div>
            ) : null}
          </div>
          <div className={style.inputContainer}>
            <label className={labelCX(watch("postal_code"))}>
              {t("postal_code_placeholder")}
            </label>
            <input
              className={PostalCodeCX}
              disabled={isLoading}
              placeholder={t("postal_code_placeholder")}
              id="postal_code"
              type={"text"}
              {...register("postal_code", {
                maxLength: {
                  value: 225,
                  message: t("input_max_length"),
                },
              })}
            />

            {errors?.postal_code && !isError ? (
              <div className={style.err}>{errors?.postal_code?.message}</div>
            ) : null}
          </div>
        </div>
        <div className={style.rightSide}>
          <div className={style.inputContainer}>
            <label className={labelCX(watch("last_name"))}>
              {t("last_name_placeholder")}
            </label>
            <input
              className={LastNameCX}
              disabled={isLoading}
              id="last_name"
              {...register("last_name", {
                maxLength: {
                  value: 225,
                  message: t("input_max_length"),
                },
              })}
              placeholder={t("last_name_placeholder")}
              type={"text"}
            />
          </div>
          <div className={style.inputContainer}>
            <label className={labelCX(watch("position_company"))}>
              {t("company_position_placeholder")}
            </label>
            <input
              className={PositionCompanyCX}
              disabled={isLoading}
              placeholder={t("company_position_placeholder")}
              id="position_company"
              type={"text"}
              {...register("position_company", {
                maxLength: {
                  value: 225,
                  message: t("input_max_length"),
                },
              })}
            />
            {errors?.position_company && !isError ? (
              <div className={style.err}>
                {errors?.position_company?.message}
              </div>
            ) : null}
          </div>
          <div className={style.inputContainer}>
            <label className={labelCX(watch("address"))}>
              {t("address_placeholder")}
            </label>
            <input
              className={AddressCX}
              disabled={isLoading}
              placeholder={t("address_placeholder")}
              id="address"
              type={"text"}
              {...register("address", {
                maxLength: {
                  value: 225,
                  message: t("input_max_length"),
                },
              })}
            />

            {errors?.address && !isError ? (
              <div className={style.err}>{errors?.address?.message}</div>
            ) : null}
          </div>
          <div className={style.inputContainer}>
            <label className={labelCX(watch("city"))}>
              {t("city_placeholder")}
            </label>
            <input
              className={CityCX}
              disabled={isLoading}
              placeholder={t("city_placeholder")}
              id="city"
              type={"text"}
              {...register("city")}
            />

            {errors?.city && !isError ? (
              <div className={style.err}>{errors?.city?.message}</div>
            ) : null}
          </div>
        </div>
      </div>
      <button type="submit" className={style.btn} disabled={isLoading}>
        {t("save")}
      </button>
    </form>
  );
};
