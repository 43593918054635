import { UserMarket } from "features/user/slice";
import { getCurrencyByMarket } from "utils";

interface IChartOptions {
  // x: string[];
  title: string;
  filename: string;
  isNeedToShowPoints?: boolean;
  market: UserMarket.DE | UserMarket.CH;
}
export const getOptions = ({
  title,
  filename,
  isNeedToShowPoints,
  market,
}: IChartOptions) => {
  return {
    legend: {
      show: false,
    },
    chart: {
      zoom: {
        enabled: true,
        type: "x",
        autoScaleYaxis: false,
        zoomedArea: {
          fill: {
            color: "#90CAF9",
            opacity: 0.4,
          },
          stroke: {
            color: "#0D47A1",
            opacity: 0.4,
            width: 1,
          },
        },
      },
      toolbar: {
        show: true,
        export: {
          svg: {
            filename,
          },
          png: {
            filename,
          },
          csv: {
            filename,
          },
        },
      },
      // animations: {
      //   enabled: false,
      // },
    },
    title: {
      text: title,
      align: "left" as any,
      offsetX: 8,
      offsetY: -8,
      style: {
        fontFamily: "Poppins",
        fontSize: "20px",
        fontWeight: 600,
        color: "#000",
      },
    },
    subtitle: {
      text: `${getCurrencyByMarket(market)}/MWh`,
      align: "left" as any,
      offsetX: 15,
      offsetY: 50,
      style: {
        fontFamily: "Poppins",
        fontWeight: 500,
        fontSize: "9px",
        color: "#6E7991",
      },
    },
    grid: {
      show: true,
      strokeDashArray: 6,
      borderColor: "rgba(110, 121, 145, 0.2)",
      xaxis: {
        lines: {
          show: true,
        },
      },
    },
    fill: { type: ["gradient", "solid"], colors: ["#6E7991", "transparent"] },
    yaxis: {
      // tickAmount: 6,
      axisBorder: {
        show: true,
        width: 1.5,
        color: "#6E7991",
      },
      labels: {
        formatter: (val: number) => val.toFixed(0),
        style: {
          fontFamily: "Poppins",
          fontWeight: 400,
          fontSize: "9px",
          colors: "#6E7991",
        },
      },
    },
    xaxis: {
      type: "datetime" as any,
      // tickamount: 2,
      // categories: x,
      axisBorder: {
        show: true,
        color: "#6E7991",
        showDuplicates: false,
      },
      labels: {
        format: "dd MMM yyyy",
        style: {
          fontFamily: "Poppins",
          fontWeight: 400,
          fontSize: "10px",
          colors: "#6E7991",
        },
        offsetY: -4,
        datetimeFormatter: {
          year: "yyyy",
          month: "MMM 'yy",
          day: "dd MMM",
          hour: "HH:mm",
        },
      },
    },
    stroke: {
      curve: "straight" as any,
      width: 1,
      colors: ["#6E7991", "transparent"],
    },
    dataLabels: { enabled: false },
    markers: {
      size: isNeedToShowPoints ? [0, 3] : [0],
      colors: ["#6E7991", "#8BBA05"],
      strokeColors: ["#6E7991", "#8BBA05"],
    },
    tooltip: {
      shared: false,
      // followCursor: true,
      marker: {
        show: !!isNeedToShowPoints,
      },
      style: {
        fontFamily: "Poppins",
      },
      y: {
        formatter: (value: number) => {
          return `${value} ${getCurrencyByMarket(market)}/MWh`;
        },
      },
      x: {
        format: "dd MMM yyyy",
      },
      fixed: {
        enabled: false,
        position: "topRight",
        offsetX: 0,
        offsetY: 0,
      },
    },
  } as any;
};
