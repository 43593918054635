import { ChartData } from "features/marketData/slice";
import { UserMarket } from "features/user/slice";
import { capitalizeFirstLetter, getCurrencyByMarket } from "utils";

export const getOptions = (
  data: ChartData,
  market: UserMarket.DE | UserMarket.CH
) => {
  return {
    chart: {
      zoom: { enabled: false },
      toolbar: { show: false },
      background: "#FFFFFF",
      animations: {
        enabled: false,
      },
    },
    title: {
      text: `Cal ${data.period.slice(2)} ${capitalizeFirstLetter(data.type)}`,
      align: "left" as any,
      offsetX: 8,
      offsetY: 15,
      style: {
        fontFamily: "Poppins",
        fontSize: "20px",
        fontWeight: 600,
        color: "#000000",
      },
    },
    subtitle: {
      text: `${getCurrencyByMarket(market)}/MWh`,
      align: "left" as any,
      offsetX: 15,
      offsetY: 50,
      style: {
        fontFamily: "Poppins",
        fontWeight: 500,
        fontSize: "9px",
        color: "#6E7991",
      },
    },
    grid: {
      show: true,
      strokeDashArray: 6,
      borderColor: "rgba(110, 121, 145, 0.2)",
      xaxis: {
        lines: {
          show: true,
        },
      },
    },
    fill: { colors: ["#6E7991"] },
    yaxis: {
      tickAmount: 6,
      axisBorder: {
        show: true,
        width: 1.5,
        color: "#6E7991",
      },
      labels: {
        formatter: (val: number) => val.toFixed(0),
        style: {
          fontFamily: "Poppins",
          fontWeight: 400,
          fontSize: "9px",
          colors: "#6E7991",
        },
      },
    },
    xaxis: {
      type: "datetime" as any,
      categories: data.xAxis,
      axisBorder: {
        show: true,
        color: "#6E7991",
      },
      labels: {
        format: "dd MMM",
        style: {
          fontFamily: "Poppins",
          fontWeight: 400,
          fontSize: "10px",
          colors: "#6E7991",
        },
        offsetY: -4,
      },
    },
    stroke: {
      curve: "straight" as any,
      width: 1,
      colors: ["#6E7991"],
    },
    dataLabels: { enabled: false },
    markers: { size: 2, colors: "#6E7991", strokeColors: "#6E7991" },
    tooltip: {
      marker: {
        show: false,
      },
      style: {
        fontFamily: "Poppins",
      },
      y: {
        formatter: (value: number) => String(value),
      },
    },
  };
};
