export const headers = [
  "contracts_table_header_date",
  "contracts_table_header_deal",
  "contracts_table_header_year",
  "contracts_table_header_period",
  "contracts_table_header_type",
  "contracts_table_header_power",
  "contracts_table_header_supplier",
  "contracts_table_header_currency",
  "contracts_table_header_amount",
  "contracts_table_header_price_chf",
  "contracts_table_header_price_eur",
  "contracts_table_header_worth_chf",
  "contracts_table_header_worth_eur",
  "",
];

export const headersWithoutActions = [
  "contracts_table_header_date",
  "contracts_table_header_deal",
  "contracts_table_header_year",
  "contracts_table_header_period",
  "contracts_table_header_type",
  "contracts_table_header_power",
  "contracts_table_header_supplier",
  "contracts_table_header_currency",
  "contracts_table_header_amount",
  "contracts_table_header_price_chf",
  "contracts_table_header_price_eur",
  "contracts_table_header_worth_chf",
  "contracts_table_header_worth_eur",
];
