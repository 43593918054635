import i18n from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

declare global {
  interface Window {
    translation: any;
    CustomEvent: any;
    Bokeh: any;
  }
}

const initialRes = {
  en: {
    translation: {
      sign_in_btn_text: "Sign In",
      email_label: "Email*",
      password_label: "Password*",
      sign_in_title: "Welcome",
      sign_in_subtitle: "Enter your email and password to sign in!",
      sign_in_copyright: "Copyright © RION Energie. All rights reserved.",
      remember_me: "Remember me",
      forget_password: "Forgot your password?",
      conditions: "Conditions",
      help: "Help",
      privacy: "Privacy",
      contact_us: "Contact us",
      terms: "Terms",
      recovery_btn_text: "Reset",
      recovery_page_title: "Reset Password",
      nav_dashboard: "Dashboard",
      nav_market_data: "Market Data",
      nav_portfolio: "Portfolio",
      nav_profile_roll_out: "Profile Roll Out",
      nav_longterm_prices: "Longterm prices",
      nav_profile: "Profile",
      change_password_title: "Password change confirmation",
      change_password_loading_subtitle: "Loading. Please wait!",
      change_password_error_subtitle:
        "An error occurred while confirming the password change",
      change_password_success_subtitle:
        "Congratulations! Password has been successfully changed",
      go_to_main: "Go to the main page",
      submit_button: "Submit",
      change_password_head: "Enter new password",
      nav_logout: "Log Out",
      logout_modal_title: "Log Out",
      logout_modal_text: "Are you sure you would like to sign out?",
      yes: "Yes",
      no: "No",
      sign_in_password_placeholder: "Min. 8 characters",
      sign_in_email_placeholder: "Your Email",
      sign_in_form_error:
        "Your email address or password is incorrect. Please try again.",
      password_min_length_error: "Min length is 8 characters",
      recovery_pass_error: "Your email address is incorrect. Please try again.",
      email_validation_error: "Entered value does not match email format",
      required_email: "Email field is required",
      required_password: "Password field is required",
      change_password_error: "Your password is incorrect. Please try again",
      back_to_sign_in_btn: "Back to Sign in",
      password_match_error: "Password do not match. Please try again.",
      new_password_placeholder: "New password",
      new_password_confirm_placeholder: "Confirm new password",
      password_validation_rules:
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character",
      block_modal_title: "Your account is blocked",
      block_modal_text_first_part: "For the upcoming steps, please",
      block_modal_link_text: "contact",
      block_modal_second_part: "the administrator.",
      recovery_password_confirm_text:
        "An email with instructions has been sent to the specified email",
      profile_page_head: "Profile information",
      save: "Save",
      input_max_length: "This is the max. length for this field",
      first_name_placeholder: "First name",
      company_placeholder: "Company",
      phone_placeholder: "Phone",
      postal_code_placeholder: "Postal code",
      last_name_placeholder: "Last name",
      company_position_placeholder: "Position in the company",
      address_placeholder: "Address",
      city_placeholder: "City",
      nav_portfolio_overview: "Overview",
      nav_portfolio_analyze: "Analyze",
      nav_portfolio_contacts: "Contracts",
      nav_portfolio_options: "Options",
      nav_portfolio_profiles: "Profiles",
      nav_change_password: "Change password",
      password_validation_length:
        "Password is too short - should be 8 chars minimum.",
      delete_avatar_modal_title: "Delete avatar",
      delete_avatar_modal_text: "Are you sure you want to delete your avatar?",
      change_old_password_placeholder: "Old password",
      change: "Save changes",
      back_to_dashboard_link: "Back to Dashboard",
      profile_roll_out_drop_title: "Drop energy profiles",
      profile_roll_out_upload_btn: "Click to upload",
      roll_out_options_title: "Options",
      profile_roll_out_drop_subtitle: "unit kW - hourly or 15 minutes interval",
      roll_out_options_year: "Target year",
      switzerland_label: "Switzerland",
      germany_label: "Germany",
      all_germany: "All Germany",
      all_switzerland: "All Switzerland",
      city: "City",
      scale_ratio: "Scale ratio",
      output_profile: "Output profile",
      roll_out_sum_option: "Sum all results in one file",
      calculate_btn: "Calculate",
      clear: "Clear",
      rollout_list_title: "List of profiles",
      export_btn_label: "Export",
      region: "Origin",
      without_name: "Without name",
      faq_rollout_btn:
        "To zoom the chart, make two-fingers pinch on the touchpad or use the mouse wheel./n  /n To navigate left and right through the chart, press the left mouse button or left touch bar side and move your finger. /n /n To reset zoom level, press the right mouse button or right touchpad part.",
      file_without_name: "File without name",
      rollout_processing_status_error:
        "An error has occurred. Please contact the administrator or try again later.",
      roll_out_files_error: "File error",
      portfolio_profile_content_text:
        "If a profile exists in the database, it can be replaced by uploading a new profile",
      portfolio_profile_filename: "Saved in database: Energy profile",
      energy: "Energy",
      power_max: "Power max",
      power_min: "Power min",
      number_hours_base: "Number hours Base",
      number_hours_peak: "Number hours Peak",
      profile_load_data: "Data load profile",
      portfolio_profile_empty: "No profile in database for selected year",
      portfolio_profile_override_text:
        "Are you sure you want to override the profile of the year {{year}}? The profile you previously had would be deleted.",
      portfolio_profile_override_title: "Override profile",
      description: "Description",
      width: "Width",
      target: "Target",
      actual_year: "Actual year",
      date: "Date",
      year: "Year",
      role: "Role",
      hedging_method: "Hedging method",
      corridor_parameters: "Corridor parameters",
      quarter_label_profile_options:
        "Quarter at which the corridor should reach the target (of the year before the delivery year)",
      last_quarter: "Last quarter",
      quarter: "Quarter",
      quartal: "Quarter",
      contracts_header_title: "Contracts",
      create_contract_btn_title_return: "Return",
      contracts_header_title_new: "New contract",
      new_contract_btn_title: "New",
      contract_export_all_btn_title: "Export all",
      create_contract_btn_title: "Apply",
      new_portfolio_contract_date: "Date",
      new_portfolio_contract_deal: "Deal",
      new_portfolio_contract_year: "Year",
      new_portfolio_contract_type: "Type",
      new_portfolio_contract_power: "Power (MW)",
      new_portfolio_contract_supplier: "Supplier",
      new_portfolio_contract_amount: "Amount (MWh)",
      new_portfolio_contract_currency: "Currency",
      new_portfolio_contract_input_price: "Input price",
      new_portfolio_contract_period: "Period",
      new_portfolio_contract_price: "Price",
      january_datepicker: "January",
      february_datepicker: "February",
      march_datepicker: "March",
      april_datepicker: "April",
      may_datepicker: "May",
      june_datepicker: "June",
      july_datepicker: "July",
      august_datepicker: "August",
      september_datepicker: "September",
      october_datepicker: "October",
      november_datepicker: "November",
      december_datepicker: "December",
      January: "Jan",
      February: "Feb",
      March: "Mar",
      April: "Apr",
      May: "May",
      June: "Jun",
      July: "Jul",
      August: "Aug",
      September: "Sep",
      October: "Oct",
      November: "Nov",
      December: "Dec",
      january: "January",
      february: "February",
      march: "March",
      april: "April",
      may: "May",
      june: "June",
      july: "July",
      august: "August",
      september: "September",
      october: "October",
      november: "November",
      december: "December",
      contracts_table_header_date: "Date",
      contracts_table_header_deal: "Deal",
      contracts_table_header_year: "Year",
      contracts_table_header_type: "Type",
      contracts_table_header_power: "Power (MW)",
      contracts_table_header_supplier: "Supplier",
      contracts_table_header_currency: "Currency",
      contracts_table_header_amount: "Amount (MWh)",
      contracts_table_header_price_chf: "Price (CHF)",
      contracts_table_header_price_eur: "Price (EUR)",
      contracts_table_header_worth_chf: "Worth (CHF)",
      contracts_table_header_worth_eur: "Worth (EUR)",
      contracts_table_header_period: "Period",
      loading: "Loading",
      delete_contract_modal_title: "Delete contract",
      delete_contract_modal_text:
        "Are you sure you want to delete this contract?",
      edit_contract_btn_title: "Save",
      save_contract_modal_title: "Save contract",
      save_contract_modal_text:
        "Are you sure you want to update this contract?",
      contracts_header_title__edit: "Contracts (Edit)",
      input_number_validation_error: "Please enter a valid number",
      input_year_validation_error: "Please enter a valid year",
      monthly: "Monthly",
      annually: "Annually",
      quarterly: "Quarterly",
      hedge_table_title: "Open position",
      hedge_error_title:
        "A load profile and market data are needed to display a hedge chart.",
      export_btn_title: "Export",
      contract: "Contract",
      for_portfolio: "for portfolio",
      export_contract_filename: "_Contract_per_actual_date",
      signals_title: "Signals",
      see_all_btn_text: "See all",
      empty_signals_text: "No signals found for this year",
      hedge_error_text: "Profile not loaded",
      no_profile_text: "No profile",
      signals_period: "Period",
      signals_hide_btn: "Hide",
      signal_year_base_title: "Year Base",
      signal_year_peak_title: "Year Peak",
      signal_quartal_base_title: "Quartal Base",
      signal_quartal_peak_title: "Quartal Peak",
      signal_month_base_title: "Monthly Base",
      signal_month_peak_title: "Monthly Peak",
      export_day_hpfc: "Export Day HPFC",
      export_data_history: "Export data history",
      no_market_data_text:
        "There is no market data for the selected time period!",
      prices_of: "Prices of",
      no_signal: "No signal",
      buy: "Buy!",
      sell: "Sell!",
      corridor_title_text: "Strategic corridor for the delivery year",
      corridor_performance_title: "Performance indicators",
      portfolio_price: "Portfolio price",
      portfolio_benchmark: "Portfolio benchmark",
      analyze_plot_btn: "Plot contracts",
      analyze_unplot_btn: "Unplot contracts",
      show_details_btn_title: "Show details",
      tool: "Tool",
      dashboard_tool_description:
        "Estimate your load profiles for the coming years based on historical profiles",
      plot_contracts_empty_signal_message:
        "There are no contracts for the selected signal",
      upper_bound_corridor: "Upper bound",
      lower_bound_corridor: "Lower bound",
      ideal_profile_corridor: "Benchmark profile",
      your_profile_corridor: "Your profile",
      plotting_portfolio_btn: "Plot contracts",
      ok_btn: "Okey",
      scenario_title: "Scenario",
      first_scenario_description: "Today's best guess",
      second_scenario_description: "Successful energy transition",
      third_scenario_description: "Delayed energy transition",
      longterm_prices_chart_error_message: "Something went wrong. Please reload the page to try again.",
      summary: "Summary",
    },
  },
  de: {
    translation: {
      sign_in_btn_text: "Anmelden",
      email_label: "E-Mail*",
      password_label: "Passwort*",
      sign_in_title: "Willkommen",
      sign_in_subtitle:
        "Geben Sie Ihre E-Mail-Adresse und Ihr Passwort ein, um sich anzumelden!",
      sign_in_copyright: "Copyright © RION Energie. Alle Rechte vorbehalten.",
      remember_me: "Passwort merken",
      forget_password: "Passwort vergessen?",
      conditions: "Bedingungen",
      help: "Hilfe",
      privacy: "Privatsphäre",
      contact_us: "Kontaktiere Sie uns",
      terms: "Bedingungen",
      recovery_btn_text: "Zurücksetzen",
      recovery_page_title: "Passwort zurücksetzen",
      nav_dashboard: "Dashboard",
      nav_market_data: "Marktdaten",
      nav_portfolio: "Portfolio",
      nav_profile_roll_out: "Lastgang ausrollen Tool",
      nav_longterm_prices: "Langfristige Preise",
      nav_profile: "Lastgang",
      change_password_title: "Bestätigung der Passwortänderung",
      change_password_loading_subtitle: "Wird geladen. Bitte warten!",
      change_password_error_subtitle:
        "Beim Bestätigen der Passwortänderung ist ein Fehler aufgetreten",
      change_password_success_subtitle:
        "Herzlichen Glückwunsch! Das Passwort wurde erfolgreich geändert",
      go_to_main: "Gehe zur Hauptseite",
      submit_button: "Senden",
      change_password_head: "Neues Passwort eingeben",
      nav_logout: "Abmelden",
      logout_modal_title: "Abmelden",
      logout_modal_text: "Sind Sie sicher, dass Sie sich abmelden möchten?",
      yes: "Ja",
      no: "Nein",
      sign_in_password_placeholder: "Min. 8 Zeichen",
      sign_in_email_placeholder: "Ihre E-Mail",
      sign_in_form_error:
        "Ihre E-Mail-Adresse oder Ihr Passwort ist falsch. Bitte versuchen Sie es erneut.",
      password_min_length_error: "Die Mindestlänge beträgt 8 Zeichen",
      recovery_pass_error:
        "Ihre E-Mail-Adresse ist falsch. Bitte versuchen Sie es erneut.",
      email_validation_error:
        "Der eingegebene Wert stimmt nicht mit dem E-Mail-Format überein",
      required_email: "Das E-Mail-Feld ist erforderlich",
      required_password: "Das Passwortfeld ist erforderlich",
      change_password_error:
        "Ihr Passwort ist falsch. Bitte versuchen Sie es erneut",
      back_to_sign_in_btn: "Zurück zum Anmelden",
      password_match_error:
        "Passwort stimmt nicht überein. Bitte versuchen Sie es erneut.",
      new_password_placeholder: "Neues Passwort",
      new_password_confirm_placeholder: "Bitte bestätigen Sie Ihr Passwort",
      password_validation_rules:
        "Muss 8 Zeichen enthalten, einen Großbuchstaben, einen Kleinbuchstaben, eine Zahl und ein Sonderzeichen",
      block_modal_title: "Ihr Konto ist gesperrt",
      block_modal_text_first_part: "Für die nächsten Schritte bitte",
      block_modal_link_text: "kontaktieren Sie",
      block_modal_second_part: "den Administrator.",
      recovery_password_confirm_text:
        "Eine E-Mail mit Anweisungen wurde an die angegebene E-Mail gesendet",
      profile_page_head: "Informationen zum Lastgang",
      speichern: "Speichern",
      input_max_length: "Dies ist die maximale Länge für dieses Feld",
      first_name_placeholder: "Vorname",
      company_placeholder: "Unternehmen",
      phone_placeholder: "Telefon",
      postal_code_placeholder: "Postleitzahl",
      last_name_placeholder: "Nachname",
      company_position_placeholder: "Position im Unternehmen",
      address_placeholder: "Adresse",
      city_placeholder: "Stadt",
      nav_portfolio_overview: "Übersicht",
      nav_portfolio_analyze: "Analysieren",
      nav_portfolio_contacts: "Verträge",
      nav_portfolio_options: "Optionen",
      nav_portfolio_profiles: "Lastgänge",
      nav_change_password: "Passwort ändern",
      password_validation_length:
        "Passwort ist zu kurz - sollte mindestens 8 Zeichen lang sein.",
      delete_avatar_modal_title: "Avatar löschen",
      delete_avatar_modal_text:
        "Sind Sie sicher, dass Sie Ihren Avatar löschen möchten?",
      change_old_password_placeholder: "Altes Passwort",
      change: "Änderungen speichern",
      back_to_dashboard_link: "Zurück zum Dashboard",
      profile_roll_out_drop_title: "Lastgang Drop-Area",
      profile_roll_out_upload_btn: "Zum Hochladen klicken",
      roll_out_options_title: "Optionen",
      profile_roll_out_drop_subtitle:
        "Einheit kW - Stündlicher oder 15-Minuten-Intervall",
      roll_out_options_year: "Zieljahr",
      switzerland_label: "Schweiz",
      germany_label: "Deutschland",
      all_germany: "Ganz Deutschland",
      all_switzerland: "Ganze Schweiz",
      city: "Stadt",
      scale_ratio: "Skalierungsverhältnis",
      output_profile: "Ausgabeprofil",
      roll_out_sum_option: "Alle Ergebnisse in einer Datei zusammenfassen",
      compute_btn: "Berechnen",
      clear: "Löschen",
      rollout_list_title: "Liste der Lastgänge",
      export_btn_label: "Exportieren",
      region: "Kanton",
      without_name: "Ohne Namen",
      faq_rollout_btn:
        "Um das Diagramm zu zoomen, drücken Sie zwei Finger auf das Touchpad oder verwenden Sie das Mausrad. /n /n Um nach links und rechts durch das Diagramm zu navigieren, drücken Sie die linke Maustaste oder die linke Seite der Touch-Leiste und bewegen Sie Ihren Finger. /n  /n Um die Zoomstufe zurückzusetzen , drücken Sie die rechte Maustaste oder den rechten Touchpad-Teil.",
      file_without_name: "Datei ohne Namen",
      rollout_processing_status_error:
        "Ein Fehler ist aufgetreten. Bitte wenden Sie sich an den Administrator oder versuchen Sie es später erneut",
      roll_out_files_error: "Datei-Fehler",
      portfolio_profile_content_text:
        "Wenn ein Lastgang in der Datenbank vorhanden ist, kann es durch Hochladen einen neuen Lastgang ersetzt werden",
      portfolio_profile_filename: "In Datenbank gespeichert: Lastgang",
      energy: "Energie",
      power_max: "Maximale Leistung",
      power_min: "Leistung min",
      number_hours_base: "Anzahl Stunden Base",
      number_hours_peak: "Anzahl Stunden Peak",
      profile_load_data: "Daten Lastgang",
      portfolio_profile_empty: "Kein Lastgang im Datenbank für Jahr",
      portfolio_profile_override_text:
        "Sind Sie sicher, dass Sie den Lastgang für das Jahr {{year}} überschreiben möchten. Der vorherige Lastgang wird gelöscht",
      portfolio_profile_override_title: "Lastgang überschreiben",
      description: "Beschreibung",
      width: "Breite",
      target: "Ziel",
      actual_year: "Aktuelles Jahr",
      date: "Datum",
      year: "Jahr",
      role: "Rolle",
      hedging_method: "Hedging-Methode",
      corridor_parameters: "Korridor-Parameter",
      quarter_label_profile_options:
        "Quartal, an dem der Korridor das Ziel erreichen soll (des Jahres vor dem Lieferjahr)",
      last_quarter: "Letztes Quartal",
      quarter: "Quartal",
      quartal: "Quartal",
      contracts_header_title: "Verträge",
      contracts_header_title_new: "Neuer Vertrag",
      new_contract_btn_title: "Neuer Vertrag",
      contract_export_all_btn_title: "Alle exportieren",
      create_contract_btn_title: "Speichern",
      create_contract_btn_title_return: "Zurück",
      new_portfolio_contract_date: "Datum",
      new_portfolio_contract_deal: "Geschäft",
      new_portfolio_contract_year: "Jahr",
      new_portfolio_contract_type: "Typ",
      new_portfolio_contract_power: "Leistung (MW)",
      new_portfolio_contract_supplier: "Lieferant",
      new_portfolio_contract_amount: "Menge (MWh)",
      new_portfolio_contract_currency: "Währung",
      new_portfolio_contract_input_price: "Input-Preis",
      new_portfolio_contract_period: "Zeitraum",
      new_portfolio_contract_price: "Preis",
      january_datepicker: "Januar",
      february_datepicker: "Februar",
      march_datepicker: "März",
      april_datepicker: "April",
      may_datepicker: "Mai",
      june_datepicker: "Juni",
      july_datepicker: "Juli",
      august_datepicker: "August",
      september_datepicker: "September",
      october_datepicker: "Oktober",
      november_datepicker: "November",
      december_datepicker: "Dezember",
      January: "Jan",
      February: "Feb",
      March: "Mär",
      April: "Apr",
      May: "Mai",
      June: "Jun",
      July: "Jul",
      August: "Aug",
      September: "Sep",
      October: "Okt",
      November: "Nov",
      December: "Dez",
      january: "Januar",
      february: "Februar",
      march: "März",
      april: "April",
      may: "Mai",
      june: "Juni",
      july: "Juli",
      august: "August",
      september: "September",
      october: "Oktober",
      november: "November",
      december: "Dezember",
      contracts_table_header_date: "Datum",
      contracts_table_header_deal: "Deal",
      contracts_table_header_year: "Jahr",
      contracts_table_header_type: "Typ",
      contracts_table_header_power: "Leistung (MW)",
      contracts_table_header_supplier: "Lieferant",
      contracts_table_header_currency: "Währung",
      contracts_table_header_amount: "Menge (MWh)",
      contracts_table_header_price_chf: "Preis (CHF)",
      contracts_table_header_price_eur: "Preis (EUR)",
      contracts_table_header_worth_chf: "Wert (CHF)",
      contracts_table_header_worth_eur: "Wert (EUR)",
      contracts_table_header_period: "Zeitraum",
      loading: "Laden",
      delete_contract_modal_title: "Vertrag löschen",
      delete_contract_modal_text:
        "Möchten Sie diesen Vertrag wirklich löschen?",
      edit_contract_btn_title: "Speichern",
      save_contract_modal_title: "Vertrag speichern",
      save_contract_modal_text:
        "Möchten Sie diesen Vertrag wirklich aktualisieren?",
      contracts_header_title__edit: "Verträge (Bearbeiten)",
      input_number_validation_error: "Bitte geben Sie eine gültige Nummer ein",
      input_year_validation_error: "Bitte geben Sie ein gültiges Jahr ein",
      monthly: "Monatlich",
      annually: "Jährlich",
      quarterly: "Vierteljährlich",
      hedge_table_title: "Freie Stelle",
      hedge_error_title:
        "Für die Anzeige eines Hedge-Charts werden ein Lastgang und Marktdaten benötigt.",
      export_btn_title: "Exportieren",
      contract: "Verträge",
      for_portfolio: "für Portfolio",
      export_contract_filename: "_Verträge_per_actual_date",
      signals_title: "Signale",
      see_all_btn_text: "Alle Signale",
      empty_signals_text: "Kein Signal für dieses Jahr gefunden",
      hedge_error_text: "Lastgang nicht geladen",
      no_profile_text: "Kein Lastgang",
      signals_period: "Zeitraum",
      signals_hide_btn: "Ausblenden",
      signal_year_base_title: "Jahr Base",
      signal_year_peak_title: "Jahr Peak",
      signal_quartal_base_title: "Quartal Base",
      signal_quartal_peak_title: "Quartal Peak",
      signal_month_peak_title: "Monat Peak",
      signal_month_base_title: "Monat Base",
      export_day_hpfc: "HPFC exportieren",
      export_data_history: "Historische Daten exportieren",
      no_market_data_text:
        "Für den ausgewählten Zeitraum sind keine Marktdaten vorhanden!",
      prices_of: "Preise von",
      no_signal: "Kein Signal",
      buy: "Kaufen!",
      sell: "Verkaufen!",
      corridor_title_text: "Korridorstrategie Lieferjahr",
      corridor_performance_title: "Leistungsindikatoren",
      portfolio_price: "Preis Portfolio",
      portfolio_benchmark: "Preis Benchmark",
      analyze_plot_btn: "Plot-Verträge",
      show_details_btn_title: "Details anzeigen",
      tool: "Werkzeug",
      dashboard_tool_description:
        "Schätzen Sie Ihre Lastprofile für die kommenden Jahre auf der Grundlage historischer Profile",
      analyze_unplot_btn: "Unplot Verträge",
      plot_contracts_empty_signal_message:
        "Es gibt keine Verträge für das ausgewählte Signal",
      upper_bound_corridor: "Obere Grenze",
      lower_bound_corridor: "Untere Grenze",
      ideal_profile_corridor: "Benchmark Profil",
      your_profile_corridor: "Ihres Profil",
      plotting_portfolio_btn: "Plot-Verträge",
      ok_btn: "Ok",
      scenario_title: "Szenario",
      first_scenario_description: "Heutige Sicht",
      second_scenario_description: "Erfolgreiche Energiewende",
      third_scenario_description: "Verzögerte Energiewende",
      longterm_prices_chart_error_message: "Etwas ist schief gelaufen. Bitte laden Sie die Seite neu, um es erneut zu versuchen.",
      summary: "Resümee",
    },
  },
  fr: {
    translation: {
      sign_in_btn_text: "Connexion",
      email_label: "E-mail*",
      password_label: "Mot de passe*",
      sign_in_title: "Bienvenue",
      sign_in_subtitle:
        "Entrez votre email et votre mot de passe pour vous connecter !",
      sign_in_copyright: "Copyright © RION Energie. Tous droits réservés.",
      remember_me: "Se souvenir de moi",
      forget_password: "Mot de passe oublié ?",
      conditions: "Conditions",
      help: "Aide",
      privacy: "Confidentialité",
      contact_us: "Nous contacter",
      terms: "Conditions",
      recovery_btn_text: "Réinitialiser",
      recovery_page_title: "Réinitialiser le mot de passe",
      nav_dashboard: "Tableau de bord",
      nav_market_data: "Données de marché",
      nav_portfolio: "Portfolio",
      nav_profile_roll_out: "Dérouler un profil",
      nav_longterm_prices: "Prix à long terme",
      nav_profile: "Profil",
      change_password_title: "Confirmation du changement de mot de passe",
      change_password_loading_subtitle: "Chargement. Veuillez patienter !",
      change_password_error_subtitle:
        "Une erreur s'est produite lors de la confirmation du changement de mot de passe",
      change_password_success_subtitle:
        "Félicitations ! Le mot de passe a été modifié avec succès",
      go_to_main: "Aller à la page principale",
      submit_button: "Envoyer",
      change_password_head: "Entrez le nouveau mot de passe",
      nav_logout: "Déconnexion",
      logout_modal_title: "Déconnexion",
      logout_modal_text: "Êtes-vous sûr de vouloir vous déconnecter ?",
      yes: "Oui",
      no: "Non",
      sign_in_password_placeholder: "Min. 8 caractères",
      sign_in_email_placeholder: "Votre e-mail",
      sign_in_form_error:
        "Votre adresse e-mail ou votre mot de passe est incorrect. Veuillez réessayer.",
      password_min_length_error: "La longueur minimale est de 8 caractères",
      recovery_pass_error:
        "Votre adresse e-mail incorrecte. Veuillez réessayer.",
      email_validation_error:
        "La valeur saisie ne correspond pas au format de l'e-mail",
      required_email: "Le champ e-mail est obligatoire",
      required_password: "Le champ mot de passe est obligatoire",
      change_password_error:
        "Votre mot de passe est incorrect. Veuillez réessayer",
      back_to_sign_in_btn: "Retour à la connexion",
      password_match_error:
        "Le mot de passe ne correspond pas. Veuillez réessayer.",
      new_password_placeholder: "Nouveau mot de passe",
      new_password_confirm_placeholder: "Confirmer le nouveau mot de passe",
      password_validation_rules:
        "Doit contenir 8 caractères, une majuscule, une minuscule, un chiffre et un caractère spécial",
      block_modal_title: "Votre compte est bloqué",
      block_modal_text_first_part:
        "Pour vos prochaines étapes, veuillez s'il vous plaît",
      block_modal_link_text: "contacter",
      block_modal_second_part: "un administrateur.",
      recovery_password_confirm_text:
        "Un e-mail avec des instructions a été envoyé à l'e-mail spécifié",
      profile_page_head: "Informations sur le profil",
      enregistrer: "Enregistrer",
      input_max_length: "Longueur maximale pour ce champ",
      first_name_placeholder: "Prénom",
      company_placeholder: "Société",
      phone_placeholder: "Téléphone",
      postal_code_placeholder: "Code postal",
      last_name_placeholder: "Nom de famille",
      company_position_placeholder: "Fonction dans l'entreprise",
      address_placeholder: "Adresse",
      city_placeholder: "Ville",
      nav_portfolio_overview: "Général",
      nav_portfolio_analyze: "Analyse",
      nav_portfolio_contacts: "Contrats",
      nav_portfolio_options: "Options",
      nav_portfolio_profiles: "Courbes de charge",
      nav_change_password: "Modifier le mot de passe",
      password_validation_length:
        "Le mot de passe est trop court - doit comporter au moins 8 caractères.",
      delete_avatar_modal_title: "Supprimer l'avatar",
      delete_avatar_modal_text:
        "Êtes-vous sûr de vouloir supprimer votre avatar?",
      change_old_password_placeholder: "Ancien mot de passe",
      change: "Sauvergarder",
      back_to_dashboard_link: "Retour au tableau de bord",
      profile_roll_out_drop_title: "Déposez ici vos courbes de charge",
      profile_roll_out_upload_btn: "Cliquez pour charger",
      roll_out_options_title: "Options",
      profile_roll_out_drop_subtitle:
        "unité kW - heure ou intervalle de 15 minutes",
      roll_out_options_year: "Année cible",
      switzerland_label: "Suisse",
      germany_label: "Allemagne",
      all_germany: "Toute l'Allemagne",
      all_switzerland: "Toute la Suisse",
      city: "Ville",
      scale_ratio: "Rapport d'échelle",
      output_profile: "Profil de sortie",
      roll_out_sum_option: "Aggréger les résultats en un seul fichier",
      calculate_btn: "Calculer",
      clear: "Réinitialiser",
      rollout_list_title: "Liste des courbes de charge",
      export_btn_label: "Exporter",
      region: "Région",
      without_name: "Sans nom",
      faq_rollout_btn:
        "Pour agrandir le graphique, pincez le pavé tactile avec deux doigts ou utilisez la molette de la souris. /n /n Pour naviguer à gauche et à droite dans le graphique, appuyez sur le bouton gauche de la souris ou sur le côté gauche de la barre tactile et déplacez votre doigt.  /n /n Pour réinitialiser le niveau de zoom , appuyez sur le bouton droit de la souris ou sur la partie droite du pavé tactile.",
      file_without_name: "Fichier sans nom",
      rollout_processing_status_error:
        "Une erreur est survenue. Veuillez contacter un administrateur ou réessayer plus tard",
      roll_out_files_error: "Erreur de fichier",
      portfolio_profile_content_text:
        "Si une courbe de charge existe dans la base de données, elle sera remplacée",
      portfolio_profile_filename:
        "Enregistré dans la base de données : Courbe de charge",
      energy: "Énergie",
      power_max: "Puissance maximale",
      power_min: "Puissance minimale",
      number_hours_base: "Nombre d'heures Base",
      number_hours_peak: "Nombre d'heures Peak",
      profile_load_data: "Profil de chargement des données",
      portfolio_profile_empty:
        "Aucune courbe de charge dans la base de données pour l'année",
      portfolio_profile_override_text:
        "Êtes-vous sûr de vouloir remplacer la courbe de charge pour l'année {{year}}. Le profil que vous aviez précédemment sera supprimé",
      portfolio_profile_override_title: "Remplacer la courbe de charge",
      description: "Description",
      width: "Largeur",
      target: "Cible",
      actual_year: "Année réelle",
      date: "Date",
      year: "Année",
      role: "Rôle",
      hedging_method: "Méthode de hedging",
      corridor_parameters: "Paramètres du couloir d'approvisionnement",
      quarter_label_profile_options:
        "Trimestre auquel le corridor d'approvisionnement atteint la valeur cible (de l'année précédant l'année de livraison)",
      last_quarter: "Dernier ^trimestre",
      quarter: "Trimestre",
      quartal: "Trimestre",
      contracts_header_title: "Contrats",
      contracts_header_title_new: "Nouveau contrat",
      new_contract_btn_title: "Nouveau contrat",
      contract_export_all_btn_title: "Tout exporter",
      create_contract_btn_title: "Enregistrer",
      create_contract_btn_title_return: "Retour aux contrats",
      new_portfolio_contract_date: "Date",
      new_portfolio_contract_deal: "Nature",
      new_portfolio_contract_year: "Année",
      new_portfolio_contract_type: "Type",
      new_portfolio_contract_power: "Puissance (MW)",
      new_portfolio_contract_supplier: "Fournisseur",
      new_portfolio_contract_amount: "Quantité (MWh)",
      new_portfolio_contract_currency: "Devise",
      new_portfolio_contract_input_price: "Prix d'entrée",
      new_portfolio_contract_period: "Période",
      january_datepicker: "Janvier",
      february_datepicker: "Février",
      march_datepicker: "Mars",
      april_datepicker: "Avril",
      may_datepicker: "Mai",
      june_datepicker: "Juin",
      july_datepicker: "Juillet",
      august_datepicker: "Août",
      september_datepicker: "Septembre",
      october_datepicker: "Octobre",
      november_datepicker: "Novembre",
      december_datepicker: "Décembre",
      January: "Jan",
      February: "Fév",
      March: "Mar",
      April: "Avr",
      May: "Mai",
      June: "Jui",
      July: "Juil",
      August: "Aoû",
      September: "Sep",
      October: "Oct",
      November: "Nov",
      December: "Déc",
      january: "Janvier",
      february: "Février",
      march: "Mars",
      april: "Avril",
      may: "Mai",
      june: "Juin",
      july: "Juillet",
      august: "Août",
      september: "Septembre",
      october: "Octobre",
      november: "Novembre",
      december: "Décembre",
      contracts_table_header_date: "Date",
      contracts_table_header_deal: "Nature",
      contracts_table_header_year: "Année",
      contrats_table_header_type: "Type",
      contracts_table_header_power: "Puissance (MW)",
      contracts_table_header_supplier: "Fournisseur",
      contracts_table_header_currency: "Devise",
      contracts_table_header_amount: "Quantité (MWh)",
      contracts_table_header_price_chf: "Prix (CHF)",
      contracts_table_header_price_eur: "Prix (EUR)",
      contracts_table_header_worth_chf: "Valeur (CHF)",
      contracts_table_header_worth_eur: "Valeur (EUR)",
      contracts_table_header_period: "Période",
      loading: "Chargement",
      delete_contract_modal_title: "Supprimer le contrat",
      delete_contract_modal_text: "Voulez-vous vraiment supprimer ce contrat ?",
      edit_contract_btn_title: "Sauvegarder",
      save_contract_modal_title: "Enregistrer le contrat",
      save_contract_modal_text:
        "Êtes-vous sûr de vouloir mettre à jour ce contrat ?",
      contracts_header_title__edit: "Contrats (Modifier)",
      input_number_validation_error: "S'il vous plait, entrez un nombre valide",
      input_year_validation_error: "Veuillez saisir une année valide",
      monthly: "Mensuellement",
      annually: "Annuellement",
      quarterly: "Trimestriellement",
      hedge_table_title: "Poste libre",
      hedge_error_title:
        "Un profil de charge et des données de marché sont nécessaires pour afficher un graphique du hedge.",
      export_btn_title: "Exporter",
      contract: "Contrat",
      for_portfolio: "pour le portefeuille",
      export_contract_filename: "_Contrats_au_actual_date",
      signals_title: "Signaux",
      see_all_btn_text: "Voir tout",
      empty_signals_text: "Aucun signal trouvé pour cette année",
      hedge_error_text: "Profil non chargé",
      no_profile_text: "Aucun profil",
      signals_period: "Période",
      signals_hide_btn: "Cacher",
      signal_year_base_title: "Année Base",
      signal_year_peak_title: "Année Peak",
      signal_quartal_base_title: "Trimestre Bae",
      signal_quartal_peak_title: "Trimestre Peak",
      signal_month_peak_title: "Mois Peak",
      signal_month_base_title: "Mois Base",
      export_day_hpfc: "Journée d'exportation hpfc",
      export_data_history: "Exporter l'historique des données",
      no_market_data_text:
        "Il n'y a pas de données de marché pour la période sélectionnée !",
      prices_of: "Prix de",
      no_signal: "Pas de signal",
      buy: "Acheter!",
      sell: "Vendre!",
      corridor_title_text: "Couloir stratégique de l'année de livraison",
      corridor_performance_title: "Indicateurs de performance",
      portfolio_price: "Prix du Portfolio",
      portfolio_benchmark: "Prix du Benchmark",
      analyze_plot_btn: "Afficher les contrats",
      show_details_btn_title: "Afficher les détails",
      tool: "Outil",
      dashboard_tool_description:
        "Estimer un profil de charge sur la base de profils historiques",
      analyze_unplot_btn: "Masquer les contrats",
      plot_contracts_empty_signal_message:
        "Il n'y a pas de contrats pour le signal sélectionné",
      upper_bound_corridor: "Limite supérieure",
      lower_bound_corridor: "Limite inférieure",
      ideal_profile_corridor: "Profil benchmark",
      your_profile_corridor: "Votre profil",
      plotting_portfolio_btn: "Contrats de terrain",
      ok_btn: "Bien",
      scenario_title: "Scénario",
      first_scenario_description: "Vue d'aujourd'hui",
      second_scenario_description: "Transition énergétique réussie",
      third_scenario_description: "Transition énergétique retardée",
      longterm_prices_chart_error_message: "Quelque chose s'est mal passé. Veuillez recharger la page pour réessayer.",
      summary: "Résumé",
    },
  },
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: initialRes,
    fallbackLng: ["en", "de", "fr"],
    debug: false,
    lng: "en",
    load: "languageOnly",
    defaultNS: "translation",
    fallbackNS: "translation",
    detection: {
      order: ["querystring", "cookie", "navigator"],
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    react: {
      bindI18n: "languageChanged",
      bindI18nStore: "",
      transEmptyNodeValue: "",
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ["br", "strong", "i"],
      useSuspense: true,
    },
  });

export default i18n;
