import axios from "axios";
import dayjs from "dayjs";
import { IUser, UserMarket } from "features/user/slice";
import { getAccessToken } from "interceptors";
import jwtDecode from "jwt-decode";
import React from "react";
import Swal, { SweetAlertIcon, SweetAlertResult } from "sweetalert2";
import convert from "convert";
export interface IAccessDecodedPayload {
  token_type: string;
  exp: number;
  iat: number;
  jti: string;
  user_id: number;
}

const token = localStorage.getItem("access");

export const decodeAccessToken = (): IAccessDecodedPayload | null => {
  return getAccessToken() ? jwtDecode(getAccessToken() as string) : null;
};

export const instance = axios.create();

if (token) {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}

export type NavItemLabel =
  | "nav_dashboard"
  | "nav_portfolio"
  | "nav_profile_roll_out"
  | "nav_profile"
  | "nav_market_data"
  | "nav_longterm_prices"
  | "nav_logout"
  | "nav_portfolio_overview"
  | "nav_portfolio_analyze"
  | "nav_portfolio_contacts"
  | "nav_portfolio_options"
  | "nav_portfolio_profiles";

export interface INavItem {
  id: any;
  label: NavItemLabel | string;
  link: string;
  children?: INavItem[];
  isEnabled?: boolean;
}

export const portfolioChildren: INavItem[] = [
  {
    id: 0,
    label: "nav_portfolio_overview",
    link: "/app/portfolio/overview/?id",
  },
  { id: 1, label: "nav_portfolio_analyze", link: "/app/portfolio/analyze/?id" },
  {
    id: 2,
    label: "nav_portfolio_contacts",
    link: "/app/portfolio/contacts/?id",
  },
  {
    id: 4,
    label: "nav_portfolio_profiles",
    link: "/app/portfolio/profiles/?id",
  },
  { id: 3, label: "nav_portfolio_options", link: "/app/portfolio/options/?id" },
];

export const initialNavItemsState: INavItem[] = [
  { id: 1, label: "nav_dashboard", link: "/app/dashboard", isEnabled: true },
  {
    id: 2,
    label: "nav_market_data",
    link: "/app/market-data",
  },
  {
    id: 3,
    label: "nav_profile_roll_out",
    link: "/app/profile-roll-out",
  },
  {
    id: 4,
    label: "nav_longterm_prices",
    link: "/app/longterm-prices",
  },
];

export const getIsEnabledNavItem = (navItem: INavItem, user: IUser) => {
  switch (navItem.label) {
    case "nav_market_data":
      return !!user?.portfolio?.length;
    case "nav_profile_roll_out":
      return !!user?.roll_out_access;
    case "nav_longterm_prices":
      return !!user?.scenario_access;
    default:
      return !!navItem?.isEnabled;
  }
};

export const headerNavItems: INavItem[] = [
  { id: 12121213, label: "nav_profile", link: "/app/profile" },
  {
    id: 1212121244,
    label: "nav_change_password",
    link: "/app/change-password",
  },
  { id: 1212121322555, label: "nav_logout", link: "/app/logout" },
];

export const privateNavigation = [
  "/app",
  "/app/dashboard",
  "/app/market-data",
  "/app/portfolio",
  "/app/profile-roll-out",
  "/app/profile",
  "/app/longterm-prices",
  "/app/logout",
  "/app/portfolio/overview",
  "/app/portfolio/analyze",
  "/app/portfolio/contacts",
  "/app/portfolio/options",
  "/app/portfolio/profiles",
];

interface IShowDialogProps {
  title: string;
  icon?: SweetAlertIcon;
  html?: string | HTMLElement | JQuery | undefined;
  showCloseButton?: boolean;
  showCancelButton?: boolean;
  focusConfirm?: boolean;
  confirmButtonText?: string;
  cancelButtonText?: string;
  text?: string;
  confirmButtonColor?: string;
  showConfirmButton?: boolean;
}
export const showDialogue = ({
  title,
  icon,
  text,
  html,
  showCloseButton = true,
  showCancelButton = true,
  focusConfirm,
  confirmButtonText = "OK",
  cancelButtonText = "CANCEL",
  showConfirmButton = true,
  confirmButtonColor,
}: IShowDialogProps): Promise<SweetAlertResult<any>> => {
  return Swal.fire({
    title,
    text,
    icon,
    html,
    showCloseButton,
    showCancelButton,
    showConfirmButton,
    focusConfirm,
    confirmButtonText,
    cancelButtonText,
    confirmButtonColor,
    customClass: "rion-alert",
  });
};
export interface ILanguage {
  title: "En" | "Fr" | "De";
  value: "en" | "fr" | "de";
}
export const languages: ILanguage[] = [
  { title: "En", value: "en" },
  { title: "Fr", value: "fr" },
  { title: "De", value: "de" },
];

export const usePrevious = <T extends unknown>(value: T): T | undefined => {
  const ref = React.useRef<T>();
  React.useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export const defaultLettersForAvatar = (user: IUser) => {
  const { first_name, last_name, email } = user;
  const match1 = first_name && last_name;
  const match2 = first_name;
  const match3 = last_name;
  const match4 = email;

  if (match1) {
    return first_name.charAt(0) + last_name.charAt(0);
  } else if (match2) {
    return first_name.charAt(0);
  } else if (match3) {
    return last_name.charAt(0);
  } else if (match4) {
    return email.charAt(0);
  } else {
    return "";
  }
};

export const switzerland_cities = {
  all_switzerland: 0,
  AG: 1,
  AI: 2,
  AR: 3,
  BE: 4,
  BL: 5,
  BS: 6,
  FR: 7,
  GE: 8,
  GL: 9,
  GR: 10,
  JU: 11,
  LU: 12,
  NE: 13,
  NW: 14,
  OW: 15,
  SG: 16,
  SH: 17,
  SO: 18,
  SZ: 19,
  TG: 20,
  TI: 21,
  UR: 22,
  VD: 23,
  VS: 24,
  ZG: 25,
  ZH: 26,
};

export const german_cities = {
  all_germany: 0,
  "Baden-Württemberg": 1,
  Bayern: 2,
  Berlin: 3,
  Brandenburg: 4,
  Breme: 5,
  Hamburg: 6,
  Hessen: 7,
  "Mecklenburg-Vorpommern": 8,
  Niedersachsen: 9,
  "Nordrhein-Westfalen": 10,
  "Rheinland-Pfalz": 11,
  Saarland: 12,
  "Sachsen-Anhalt": 13,
  Sachsen: 14,
  "Schleswig-Holstein": 15,
  Thüringen: 16,
};

export function formatBytes(bytes: number, decimals = 2) {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

export const getCurrentYear = () => {
  return dayjs().year();
};

export interface IAddScript {
  src: string;
  id: string;
  onLoad: () => void;
}

export const addScript = ({ src, id, onLoad }: IAddScript) => {
  const script = document.createElement("script");
  script.src = src;
  script.id = id;
  script.onload = onLoad;
  document.body.appendChild(script);
};

export interface IRemoveScript {
  id: string;
}

export const removeScript = ({ id }: IRemoveScript) => {
  const existing = document.getElementById(id);
  if (existing) {
    existing.remove();
  }
};

export const addSpaceEveryNth = (text: string | number) => {
  return text?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};

export const makeYearValidation = (year: string) => {
  return dayjs("YYYY", year).isValid();
};

export const convertWatt = (
  num?: number,
  suffix?: string,
  watt?: any,
  fixedNum?: number,
  prefix?: string
) => {
  const result = convert(num || 0, watt ? watt : "W").to("best");
  const resultNumber =
    fixedNum !== undefined
      ? result?.quantity.toFixed(fixedNum)
      : result?.quantity;
  return result
    ? `${resultNumber || 0} ${prefix || ""}${result?.unit}${
        suffix ? suffix : ""
      }`
    : 0;
};

export const uploadFile = (url: string, filename: string) => {
  const link = document.createElement("a");
  document.body.appendChild(link);
  link.href = url;
  link.download = filename;
  link.click();
  link.remove();
};

export const capitalizeFirstLetter = (string: string) =>
  string.charAt(0).toUpperCase() + string.slice(1);

export const arrayMax = (arr: number[]) => {
  return arr.reduce(function (p, v) {
    return p > v ? p : v;
  });
};

export const getCurrencyByMarket = (market: UserMarket.DE | UserMarket.CH) =>
  market === UserMarket.CH ? "CHF" : "EUR";
