import { useAppSelector } from "app/hooks";
import { SuccessRecovery } from "components/successRecovery";
import { PublicTemplate } from "containers/publicTemplate";
import { RecoveryForm } from "containers/recoveryForm";
import { isRecoveryConfirmed$ } from "features/auth/authSlice";
import { useTranslation } from "react-i18next";
import style from "./recoveryPassword.module.scss";

export const PasswordRecovery = () => {
  const { t } = useTranslation();
  const isRecoveryConfirmed = useAppSelector(isRecoveryConfirmed$);
  return (
    <PublicTemplate>
      <>
        <div className={style.form}>
          <div className={style.title}>{t("recovery_page_title")}</div>
          {!isRecoveryConfirmed && <RecoveryForm />}
          {isRecoveryConfirmed && <SuccessRecovery />}
        </div>
        <footer className={style.footer}>{t("sign_in_copyright")}</footer>
      </>
    </PublicTemplate>
  );
};
