import { Api } from "api";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { PortfolioContractCreator } from "components/portfolioContractCreator";
import { PortfolioContractsTable } from "components/portfolioContractsTable";
import dayjs from "dayjs";
import {
  contracts$,
  createPortfolioContract,
  deletePortfolioContract,
  editPortfolioContract,
  fetchPortfolioContracts,
  IPortfolioContractCreateRequest,
  isLoading$,
} from "features/portfolioContracts/slice";
import { user$, UserMarket } from "features/user/slice";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import style from "./portfolioContractContent.module.scss";
import { keyObject, valueObject } from "./utils";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { makeYearValidation, showDialogue, uploadFile } from "utils";
import i18n from "i18n";
import axios from "axios";

export const PortfolioContractContent = () => {
  dayjs.extend(customParseFormat);
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const [isCreateNew, setNewCreate] = useState(false);
  const [portfolio_id, setPortFolioId] = useState<string | null>(null);
  const [tableObject, setTableObject] = useState<{ [value: number]: string }>(
    valueObject
  );
  const [isDownload, setDownload] = useState(false);
  const [idForSave, setIdForSave] = useState<string | null>(null);
  const [isEdit, setEdit] = useState(false);
  const contracts = useAppSelector(contracts$);
  const isLoading = useAppSelector(isLoading$);
  const user = useAppSelector(user$);

  useEffect(() => {
    if (searchParams.get("id") !== portfolio_id) {
      const id = searchParams.get("id");
      setPortFolioId(id);
    }
  }, [searchParams, portfolio_id]);

  useEffect(() => {
    if (portfolio_id) {
      dispatch(fetchPortfolioContracts({ id: portfolio_id }));
    }
  }, [dispatch, portfolio_id]);

  const { t } = useTranslation();

  const handleNewObjectChange = useCallback((value: string, id: number) => {
    setTableObject((prevObject) => ({ ...prevObject, [id]: value }));
  }, []);

  const handleNewCreate = useCallback(() => {
    setNewCreate(true);
  }, []);

  const handleReturn = useCallback(() => {
    setNewCreate(false);
  }, []);

  const handleApply = useCallback(() => {
    setNewCreate(false);

    const keys = Object.keys(tableObject);
    let answer = {} as IPortfolioContractCreateRequest;
    keys.forEach((key) => {
      const keyValue = (keyObject as any)![key as any]! as any;
      answer = {
        ...answer,
        ...{
          [keyValue.key]:
            keyValue.type === "string"
              ? String(tableObject[key as any])
              : Number(tableObject[key as any]),
        },
      };
    });

    const result = {
      ...answer,
      portfolio: portfolio_id!,
    };
    dispatch(
      createPortfolioContract(result as IPortfolioContractCreateRequest)
    );
  }, [dispatch, portfolio_id, tableObject]);

  const handleExportAllClick = async () => {
    setDownload(true);

    const currentPortfolio = user!.portfolio.find(
      (portfolio) => portfolio.id === portfolio_id
    );

    const currentPortfolioName = currentPortfolio?.name;

    const res = await axios({
      url: `${Api.DownloadPortfolioContracts}${portfolio_id}/contracts/file/download/`,
      method: "GET",
      responseType: "blob",
    });

    const url = window.URL.createObjectURL(new Blob([res.data]));

    uploadFile(
      url,
      currentPortfolioName + i18n.t("export_contract_filename") + ".xlsx"
    );

    setDownload(false);
  };

  const handleDelete = (id: string) => {
    showDialogue({
      title: t("delete_contract_modal_title"),
      text: t("delete_contract_modal_text"),
      confirmButtonText: t("yes"),
      cancelButtonText: t("no"),
      confirmButtonColor: "#3F1FED",
    }).then((result) => {
      if (result?.isConfirmed) {
        dispatch(deletePortfolioContract({ id }));
      }
    });
  };

  const handleEdit = (id: string) => {
    if (!contracts) return;
    const currentEditItem = contracts.find((item) => item.id === id);
    if (!currentEditItem) return;
    setIdForSave(id);
    const newData = {
      0: currentEditItem.date,
      1: currentEditItem.deal,
      2: currentEditItem.year,
      3: currentEditItem.period,
      4: currentEditItem.type,
      5: currentEditItem.power,
      6: currentEditItem.supplier,
      7: currentEditItem.currency,
      8: currentEditItem.price_EUR,
    };

    setTableObject(newData as any);
    setEdit(true);
  };

  const handleSave = () => {
    showDialogue({
      title: t("save_contract_modal_title"),
      text: t("save_contract_modal_text"),
      confirmButtonText: t("yes"),
      cancelButtonText: t("no"),
      confirmButtonColor: "#3F1FED",
    }).then((result) => {
      if (result?.isConfirmed && idForSave) {
        const keys = Object.keys(tableObject);
        let answer = {} as IPortfolioContractCreateRequest;
        keys.forEach((key) => {
          const keyValue = (keyObject as any)![key as any]! as any;
          answer = {
            ...answer,
            ...{
              [keyValue.key]:
                keyValue.type === "string"
                  ? String(tableObject[key as any])
                  : Number(tableObject[key as any]),
            },
          };
        });

        const result = {
          ...answer,
          portfolio: portfolio_id!,
          // date: dayjs(answer.date).format().format("YYYY-MM-DD"),
          id: idForSave,
        };

        dispatch(editPortfolioContract(result));
        setEdit(false);
      }
    });
  };

  const handleEditReturn = () => {
    setEdit(false);
  };

  const isDisableApply = useMemo(() => {
    if (
      isNaN(+tableObject[2]) ||
      isNaN(+tableObject[5]) ||
      isNaN(+tableObject[8]) ||
      makeYearValidation(tableObject[2]) ||
      +tableObject[2] < 2000 ||
      +tableObject[2] > 2100
    )
      return true;
    return false;
  }, [tableObject]);

  return (
    <div className={style.root}>
      <header className={style.header}>
        <h2 className={style.head}>
          {!isCreateNew && !isEdit && t("contracts_header_title")}
          {isCreateNew && !isEdit && t("contracts_header_title_new")}
          {isEdit && t("contracts_header_title__edit")}
        </h2>

        {!isCreateNew && !isEdit && (
          <>
            <button className={style.newBtn} onClick={handleNewCreate}>
              {t("new_contract_btn_title")}
            </button>
            <button
              className={style.exportAllBtn}
              onClick={handleExportAllClick}
            >
              {isDownload ? t("loading") : t("contract_export_all_btn_title")}
            </button>
          </>
        )}

        {isCreateNew && !isEdit && (
          <>
            <button
              className={style.newBtn}
              onClick={handleApply}
              disabled={isDisableApply}
            >
              {t("create_contract_btn_title")}
            </button>
            <button className={style.applyBtn} onClick={handleReturn}>
              {t("create_contract_btn_title_return")}
            </button>
          </>
        )}

        {isEdit && (
          <>
            <button
              className={style.newBtn}
              onClick={handleSave}
              disabled={isDisableApply}
            >
              {t("edit_contract_btn_title")}
            </button>
            <button className={style.applyBtn} onClick={handleEditReturn}>
              {t("create_contract_btn_title_return")}
            </button>
          </>
        )}
      </header>
      <div className={style.content}>
        {(isCreateNew || isEdit) && (
          <PortfolioContractCreator
            valueObject={tableObject}
            onChange={handleNewObjectChange}
          />
        )}

        {!isCreateNew && !isEdit && (
          <PortfolioContractsTable
            items={contracts}
            isLoading={isLoading}
            hideSomeColumns={user?.market === UserMarket.DE}
            onDelete={handleDelete}
            onEdit={handleEdit}
          />
        )}
      </div>
    </div>
  );
};
