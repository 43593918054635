import { useMemo } from "react";
import cx from "classnames";
import { NavItemLabel } from "utils";
import style from "./navigationLogo.module.scss";
import { ReactComponent as DashboardLogo } from "assets/svg/nav/dashboard.svg";
import { ReactComponent as MarketDataLogo } from "assets/svg/nav/marketData.svg";
import { ReactComponent as PortfolioLogo } from "assets/svg/nav/portfolio.svg";
import { ReactComponent as ProfileLogo } from "assets/svg/nav/profile.svg";
import { ReactComponent as LogoutLogo } from "assets/svg/nav/logout.svg";
import { ReactComponent as RollOutLogo } from "assets/svg/nav/rollOut.svg";
import { ReactComponent as LongtermPricesLogo } from "assets/svg/nav/longtermPrices.svg";

interface IProps {
  name: NavItemLabel | string;
  isSelected: boolean;
  isPortfolio?: number;
}

export const NavigationLogo = ({ name, isSelected, isPortfolio }: IProps) => {
  const rootCX = cx(style.root, { [style.selected]: isSelected });
  const renderLogoByName = useMemo(() => {
    const logo: any = {
      nav_dashboard: <DashboardLogo className={rootCX} />,
      nav_market_data: <MarketDataLogo className={rootCX} />,
      nav_portfolio: <PortfolioLogo className={rootCX} />,
      nav_longterm_prices: <LongtermPricesLogo className={rootCX} />,
      nav_profile_roll_out: <RollOutLogo className={rootCX} />,
      nav_profile: <ProfileLogo className={rootCX} />,
      nav_logout: <LogoutLogo className={rootCX} />,
      nav_portfolio_overview: <></>,
      nav_portfolio_analyze: <></>,
      nav_portfolio_contacts: <></>,
      nav_portfolio_options: <></>,
      nav_portfolio_profiles: <></>,
    };

    return !!isPortfolio ? logo.nav_portfolio : logo[name];
  }, [isPortfolio, name, rootCX]);

  return renderLogoByName;
};
