import { createContext, memo, useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { accessToken$ } from "features/auth/authSlice";
import {
  fetchRollOutGraph,
  addRolloutFile,
  index$,
  setStatusError,
} from "features/rollout/slice";
import { setLoading } from "features/rollOutUploadFiles/slice";

import { useTranslation } from "react-i18next";
import useWebSocket from "react-use-websocket";

interface IProps {
  children: JSX.Element;
}

export const WebsocketContext = createContext("websocket");

export const WebsocketHandler = memo(({ children }: IProps) => {
  const accessToken = useAppSelector(accessToken$);
  const wsLink = useMemo(() => {
    const { hostname } = window.location;
    const isLocalhost = hostname === "localhost" || hostname === "";

    return `wss://${
      isLocalhost ? "dev.rion-energie.ch" : hostname
    }/ws/status/?token=${accessToken}`;
  }, [accessToken]);

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const index = useAppSelector(index$);
  const { lastMessage } = useWebSocket(wsLink, {
    shouldReconnect: (event) => !event?.wasClean,
    reconnectInterval: 1000,
  });

  useEffect(() => {
    if (lastMessage) {
      const answer =
        lastMessage.data !== "Connected" ? JSON.parse(lastMessage.data) : {};
      if (
        answer.action === "rollout_processing" &&
        answer.status === "finish"
      ) {
        const id = answer.id;

        if (id && index === 1) {
          setTimeout(
            () => dispatch(fetchRollOutGraph({ id, isLoading: true })),
            1000
          );
        }
      }
    }
  }, [dispatch, index, lastMessage]);

  useEffect(() => {
    if (lastMessage) {
      const answer =
        lastMessage.data !== "Connected" ? JSON.parse(lastMessage.data) : {};

      if (answer.action === "rollout_processing") {
        if (answer.status === "finish") {
          const consumption = answer.consumption
            ? (Number(answer.consumption) / 1000).toFixed()
            : 0;
          const result_file = answer.file;
          const id = answer.id;

          const file = { id, consumption, result_file } as any;

          dispatch(addRolloutFile(file as any));
        } else if (answer.status === "error") {
          dispatch(
            setStatusError(
              answer.text_error
                ? answer.text_error
                : t("rollout_processing_status_error")
            )
          );
          const id = answer.id;

          if (id) {
            const consumption = answer.consumption
              ? (Number(answer.consumption) / 1000).toFixed()
              : 0;
            const result_file = answer.file;

            const file = { id, consumption, result_file, error: true } as any;
            dispatch(addRolloutFile(file as any));
          }
          dispatch(setLoading(false));
        }
      }
    }
  }, [dispatch, lastMessage, t]);

  return (
    <WebsocketContext.Provider
      value={(lastMessage ? lastMessage : "") as string}
    >
      {children}
    </WebsocketContext.Provider>
  );
});
