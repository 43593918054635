import { LongtermPricesContent } from "containers/longtermPricesContent";
import style from "./longtermPrices.module.scss";

export const LongtermPrices = () => {
  return (
    <div className={style.root}>
      <LongtermPricesContent />
    </div>
  );
};
