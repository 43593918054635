import dayjs from "dayjs";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "app/hooks";

import { Toggler } from "components/toggler";
import { LongTermPricesTable } from "components/longTermPricesTable";
import { LongTermPricesChart } from "components/longTermPricesChart";
import { LongTermPricesYearPicker } from "components/longtermPricesYearPicker";
import {
  fetchScenarios,
  fetchScenariosDates,
  fetchScenariosByDate,
  fetchScenariosGraph,
  scenariosData$,
  scenariosDates$,
  graphData$,
  isScenariosLoading$,
  isGraphLoading$,
  isGraphError$,
} from "features/longtermPrices/slice";

import style from "./longtermPricesContent.module.scss";

export const LongtermPricesContent = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const scenariosDates = useAppSelector(scenariosDates$);
  const scenarios = useAppSelector(scenariosData$);
  const isScenariosLoading = useAppSelector(isScenariosLoading$);
  const graphData = useAppSelector(graphData$);
  const isGraphDataLoading = useAppSelector(isGraphLoading$);
  const isGraphError = useAppSelector(isGraphError$);

  const [selectedDate, setSelectedDate] = useState<string>("");
  const [minDate, setMinDate] = useState<string>();
  const [maxDate, setMaxDate] = useState<string>();
  const [selectedYear, setSelectedYear] = useState<number | string>("");
  const isSummarySelected = selectedYear === "summary";

  const getFormattedDate = (date: string) => {
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };

    return new Intl.DateTimeFormat("en-US", options).format(
      new Date(date as string)
    );
  };

  const getSelectedDate = useCallback(
    (index: number) => {
      if (scenariosDates) {
        const currentIndex = scenariosDates?.findIndex(
          (date: string) => getFormattedDate(date) === selectedDate
        );
        const selectedDateIndex = currentIndex - index;
        return scenariosDates[selectedDateIndex];
      }
    },
    [scenariosDates, selectedDate]
  );

  const handleDateChange = useCallback(
    (index: number) => {
      const selectedDate: string = getSelectedDate(index) ?? "";
      const formattedDate = getFormattedDate(selectedDate);

      setSelectedDate(formattedDate);
      dispatch(fetchScenariosByDate({ date: selectedDate }));
    },
    [getSelectedDate, dispatch]
  );

  const handleYearChange = useCallback(
    (year: number) => () => {
      setSelectedYear(year);
    },
    []
  );

  const onFetchSummaryGraphData = () => {
    const date = dayjs(selectedDate).format("YYYY-MM-DD");
    setSelectedYear("summary");
    dispatch(fetchScenariosGraph({ date, year: "summary" }));
  };

  useEffect(() => {
    dispatch(fetchScenariosDates());
    dispatch(fetchScenarios());
  }, [dispatch]);

  useEffect(() => {
    if (scenariosDates) {
      const minDate = getFormattedDate(
        scenariosDates[scenariosDates.length - 1]
      );
      const maxDate = getFormattedDate(scenariosDates[0]);

      setMinDate(minDate);
      setMaxDate(maxDate);
    }
  }, [scenariosDates]);

  useEffect(() => {
    if (selectedDate && selectedYear) {
      const date = dayjs(selectedDate).format("YYYY-MM-DD");
      dispatch(
        fetchScenariosGraph({
          date,
          year: selectedYear as number,
        })
      );
    }
  }, [dispatch, selectedDate, selectedYear]);

  useEffect(() => {
    if (scenarios && scenarios?.length > 0) {
      const selectedDate = getFormattedDate(scenarios[1].date);
      setSelectedYear("summary");
      setSelectedDate(selectedDate);
    }
  }, [scenarios, t]);

  return (
    <div className={style.root}>
      <div className={style.header}>
        <h2>{t("nav_longterm_prices")}</h2>
        <div className={style.datePicker}>
          <span className={style.datePicker__title}>{t("date")}:</span>
          <Toggler
            type="date"
            className={style.datePicker__toggler}
            value={selectedDate}
            onChange={handleDateChange}
            placeholder=""
            min={minDate}
            max={maxDate}
          />
        </div>
      </div>

      <LongTermPricesTable
        scenarios={scenarios}
        isLoading={isScenariosLoading}
      />

      <div className={style.yearPickerContainer}>
        <span className={style.yearPicker__title}>{t("year")}:</span>
        <LongTermPricesYearPicker
          years={scenarios?.[0].years}
          selectedYear={selectedYear}
          onChange={handleYearChange}
          onFetchSummary={onFetchSummaryGraphData}
          isSummarySelected={isSummarySelected}
        />
      </div>

      {selectedYear && (
        <LongTermPricesChart
          scenarios={scenarios}
          graphData={graphData}
          isLoading={isGraphDataLoading || isScenariosLoading}
          selectedDate={selectedDate}
          selectedYear={selectedYear}
          isGraphError={isGraphError}
          isSummarySelected={isSummarySelected}
        />
      )}
    </div>
  );
};
