import { memo, useEffect } from "react";
import { useAppDispatch } from "app/hooks";

import { OverviewPortfolioContractsTable } from "containers/overviewPortfolioContractsTable";
import { PortfolioWithChartData } from "containers/portfolioWithChartData";
import { initHedgeData } from "features/hedge/slice";
import { initContractsData } from "features/portfolioContracts/slice";
import { initAllPortfolioProfileData } from "features/portfolioProfile/slice";
import {
  setPlottingData,
  setChartData,
  setLoadedId,
} from "features/portfolioProfileGraph/slice";

import style from "./overviewContent.module.scss";
import { Hedge } from "containers/hedge";
import { YearPicker } from "containers/yearPicker";

export const OverviewContent = memo(() => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(initHedgeData());
      dispatch(initContractsData());
      dispatch(initAllPortfolioProfileData());
      // dispatch(initAllRollOutData());
      dispatch(setPlottingData(null));
      dispatch(setChartData(null));
      dispatch(setLoadedId(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={style.root}>
      <YearPicker />
      <PortfolioWithChartData />
      <Hedge />
      <OverviewPortfolioContractsTable />
    </div>
  );
});
