import React, { memo, useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { Routes, Route, useLocation } from "react-router-dom";
import { Dashboard } from "pages/dashboard";
import { PrivateHeader } from "containers/privateHeader";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { privateNavigation, showDialogue } from "utils";
import { fetchUserData } from "features/user/slice";
import style from "./private.module.scss";
import { Aside } from "containers/aside";
import { ChangePassword } from "pages/changePassword";
import cx from "classnames";
import {
  appLogout,
  needToDisconnect$,
  accessToken$,
} from "features/auth/authSlice";
import { useTranslation } from "react-i18next";
import { setTokenData } from "interceptors";
import { Profile } from "pages/profile";
import { MarketData } from "pages/marketData";
import { LongtermPrices } from "pages/longtermPrices";
import { Overview } from "pages/overview";
import { Analyze } from "pages/analyze";
import { Contacts } from "pages/contacts";
import { Options } from "pages/options";
import { Profiles } from "pages/profiles";
import { WebsocketHandler } from "containers/websocketHandler";
import { ProfileRollOut } from "pages/profileRollOut";

export const PrivateModule = memo(() => {
  const accessToken = useSelector(accessToken$);
  const dispatch = useAppDispatch();
  const [hideHeader, setHideHeader] = useState(false);
  const location = useLocation();
  const needToDisconnect = useAppSelector(needToDisconnect$);
  const { t } = useTranslation();

  useEffect(() => {
    if (needToDisconnect) {
      setTokenData({ refresh: "", access: "" });
      showDialogue({
        title: t("block_modal_title"),
        text: t("logout_modal_text"),
        showConfirmButton: false,
        showCloseButton: false,
        cancelButtonText: t("OK"),
        confirmButtonColor: "#3F1FED",
        html: `
          <div class="modal-content">
            ${t("block_modal_text_first_part")},
            <a
              href="https://rion-energie.herokuapp.com/de/impressum/"
              target="_blank"
              rel="noopener noreferrer"
            >
              ${t("block_modal_link_text")}
            </a>
            ${t("block_modal_second_part")}
          </div>`,
      }).then((result) => {
        if (!result?.isConfirmed) {
          dispatch(appLogout());
        }
      });
    }
  }, [dispatch, needToDisconnect, t]);

  useEffect(() => {
    const shouldHideHeader =
      !privateNavigation.includes(location.pathname) &&
      !privateNavigation.includes(location.pathname.slice(0, -1));
    setHideHeader(shouldHideHeader);
  }, [location.pathname]);

  useEffect(() => {
    if (accessToken) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
      dispatch(fetchUserData());
    }
  }, [accessToken, dispatch]);

  const WrapperCX = cx(style.wrapper, {
    [style.fullWidth]: hideHeader,
  });

  return (
    <WebsocketHandler>
      <div className={style.root}>
        {!hideHeader && <Aside />}
        <div className={WrapperCX}>
          {!hideHeader && <PrivateHeader />}
          <main className={style.main}>
            <Routes>
              <Route path="/app/dashboard" element={<Dashboard />} />
              <Route path="/app" element={<Dashboard />} />
              <Route path="/app/create-password" element={<ChangePassword />} />
              <Route
                path="/app/change-password"
                element={<ChangePassword isChangePassword />}
              />
              <Route path="/app/profile" element={<Profile />} />
              <Route path="/app/portfolio/overview" element={<Overview />} />
              <Route path="/app/portfolio/analyze" element={<Analyze />} />
              <Route path="/app/portfolio/contacts" element={<Contacts />} />
              <Route path="/app/portfolio/options" element={<Options />} />
              <Route path="/app/portfolio/profiles" element={<Profiles />} />
              <Route path="/app/portfolio" element={<Overview />} />
              <Route path="/app/market-data" element={<MarketData />} />
              <Route path="/app/longterm-prices" element={<LongtermPrices />} />
              <Route
                path="/app/profile-roll-out"
                element={<ProfileRollOut />}
              />
            </Routes>
          </main>
        </div>
      </div>
    </WebsocketHandler>
  );
});
