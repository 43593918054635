import style from "./publicTemplate.module.scss";
import { DropdownTranslation } from "components/dropdownTranslation";
import { useTranslation } from "react-i18next";
import { Logo } from "components/logo";

const navLinks = [
  // { label: "conditions", link: "/app/conditions" },
  {
    label: "contact_us",
    link: "https://www.rion-energie.ch/impressum/",
  },
  {
    label: "privacy",
    link: "https://www.rion-energie.ch/datenschutz/",
  },
];

interface IProps {
  children?: JSX.Element;
}
export const PublicTemplate = ({ children }: IProps) => {
  const { t } = useTranslation();

  return (
    <main className={style.root}>
      <div className={style.leftPart}>{children}</div>
      <div className={style.rightPart}>
        <Logo className={style.logo} />
        <nav className={style.nav}>
          {navLinks.map((link) => {
            return (
              <a
                href={link.link}
                className={style.navLink}
                key={link.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t(link.label)}
              </a>
            );
          })}
        </nav>
      </div>
      <div className={style.translation}>
        <DropdownTranslation />
      </div>
    </main>
  );
};
