import { AuthHandler } from "containers/authHandler";
import { memo } from "react";
import style from "./App.module.scss";
import "./App.scss";

export const App = memo(() => (
  <div className={style.root}>
    <AuthHandler />
  </div>
));
