import { useAppDispatch, useAppSelector } from "app/hooks";
import { Loader } from "components/loader";
import { currentProfileData$ } from "features/portfolioProfile/slice";
import {
  fetchSignals,
  isLoading$,
  formattedSignals$,
  IFormattedSignal,
  signals$,
  initAllSignalsData,
} from "features/signals/slice";
import { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import style from "./signalsList.module.scss";
import cx from "classnames";
import { FilteredSignalList } from "components/filteredSignalList";
import { SortedSignalList } from "components/sortedSignalList";
import {
  analyzeProducts$,
  fetchAnalyzeData,
  setAnalyzeChartDatatype,
  setAnalyzeChartTitle,
  setAnalyzeProducts,
  setPlotting,
} from "features/analyze/slice";
import { months } from "features/signals/utils";
import { capitalizeFirstLetter } from "utils";

interface IProps {
  portfolio_id: string | null;
  onSignalClick: () => void;
}

export const SignalsList: FC<IProps> = ({ portfolio_id, onSignalClick }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const currentProfileData = useAppSelector(currentProfileData$);
  const isLoading = useAppSelector(isLoading$);
  const formattedSignals = useAppSelector(formattedSignals$);
  const analyzeProducts = useAppSelector(analyzeProducts$);
  const signals = useAppSelector(signals$);
  const [isSeeAll, setSeeAll] = useState(false);

  const toggleClick = useCallback(() => {
    setSeeAll((prev) => !prev);
  }, []);

  useEffect(() => {
    if (
      portfolio_id &&
      currentProfileData?.year &&
      !currentProfileData?.missed_profile
    ) {
      dispatch(fetchSignals({ year: currentProfileData.year, portfolio_id }));
    }

    return () => {
      dispatch(initAllSignalsData());
    };
  }, [portfolio_id, currentProfileData, dispatch]);

  const BodyCX = cx(style.body, {
    [style.loading]: isLoading,
  });

  const handleSelectSignal = useCallback(
    (item: IFormattedSignal) => {
      dispatch(setPlotting([]));

      onSignalClick();

      const period = () => {
        if (item.products.includes("Quartal")) {
          const res = item.products.split(" ");
          return "Quarter" + res[1]?.charAt(0);
        } else if (months.includes(item.products.split(" ")[0])) {
          return item.products.split(" ")[0];
        } else {
          return "Year";
        }
      };

      dispatch(
        fetchAnalyzeData({
          period: period(),
          year: +item.start_date.slice(0, 4),
        })
      );

      dispatch(
        setAnalyzeChartTitle(
          `Analyze ${item.products} ${capitalizeFirstLetter(item.type)}`
        )
      );
      dispatch(setAnalyzeChartDatatype(item.type));
      dispatch(setAnalyzeProducts(item.products));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [analyzeProducts, dispatch]
  );

  return (
    <div className={style.root}>
      <header className={style.header}>
        <div className={style.head}>{t("signals_title")}</div>
        {!!signals.length && (
          <button className={style.btn} onClick={toggleClick}>
            {isSeeAll ? t("signals_hide_btn") : t("see_all_btn_text")}
          </button>
        )}
      </header>
      <div className={BodyCX}>
        {isLoading && (
          <div className={style.loaderContainer}>
            <Loader />
          </div>
        )}

        {!isLoading && !formattedSignals.length ? (
          <div className={style.emptyText}>{t("empty_signals_text")}</div>
        ) : null}

        {!isSeeAll && !isLoading && (
          <FilteredSignalList onSelect={handleSelectSignal} />
        )}
        {isSeeAll && !isLoading && (
          <SortedSignalList onSelect={handleSelectSignal} />
        )}
      </div>
    </div>
  );
};
