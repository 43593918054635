import { memo, useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { ISelectFullValue } from "components/dropdownSelect";
import { HedgeContent } from "containers/hedgeContent";
import { types } from "containers/hedgeContent/utils";
import {
  fetchPortfolioHedge,
  hedgeError$,
  hedgeXBase$,
  hedgeXPeak$,
  hedgeYBase$,
  hedgeYPeak$,
  isError$,
} from "features/hedge/slice";
import { currentProfileData$ } from "features/portfolioProfile/slice";

import { isLoading$ as isLoadingHedge$ } from "features/hedge/slice";
import { useSearchParams } from "react-router-dom";

export const Hedge = memo(() => {
  const dispatch = useAppDispatch();
  const [type, setType] = useState<ISelectFullValue>(types[0]);
  const [portfolio_id, setPortFolioId] = useState<string | null>(null);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get("id") !== portfolio_id) {
      const id = searchParams.get("id");
      setPortFolioId(id);
    }
  }, [searchParams, portfolio_id]);

  const isLoadingHedge = useAppSelector(isLoadingHedge$);
  const currentProfileData = useAppSelector(currentProfileData$);
  const hedgeXBase = useAppSelector(hedgeXBase$);
  const hedgeXPeak = useAppSelector(hedgeXPeak$);
  const hedgeYBase = useAppSelector(hedgeYBase$);
  const hedgeYPeak = useAppSelector(hedgeYPeak$);
  const hedgeError = useAppSelector(hedgeError$);
  const isError = useAppSelector(isError$);

  useEffect(() => {
    if (
      currentProfileData?.year &&
      portfolio_id &&
      !currentProfileData?.missed_profile
    ) {
      dispatch(
        fetchPortfolioHedge({
          year: currentProfileData?.year,
          portfolio_id,
          period: type.value as string,
        })
      );
    }
  }, [dispatch, currentProfileData, portfolio_id, type.value]);

  const handleTypeChange = useCallback((v: ISelectFullValue | any) => {
    setType(v);
  }, []);

  return (
    <HedgeContent
      type={type}
      hedgeXBase={hedgeXBase}
      hedgeXPeak={hedgeXPeak}
      hedgeYBase={hedgeYBase}
      hedgeYPeak={hedgeYPeak}
      isLoading={isLoadingHedge}
      hedgeError={hedgeError}
      isError={isError}
      portfolio_id={portfolio_id as string}
      year={currentProfileData?.year as number}
      handleTypeChange={handleTypeChange}
    />
  );
});
