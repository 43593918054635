import { PortfolioContractType } from "components/portfolioContractTableItem";

export const tableValues = [
  {
    id: 0,
    type: PortfolioContractType.date,
    label: "new_portfolio_contract_date",
  },
  {
    id: 1,
    type: PortfolioContractType.dropdown,
    label: "new_portfolio_contract_deal",
    items: [
      { label: "Buy", value: "Buy" },
      { label: "Sell", value: "Sell" },
    ],
  },
  {
    id: 2,
    type: PortfolioContractType.input,
    label: "new_portfolio_contract_year",
  },
  {
    id: 3,
    type: PortfolioContractType.dropdown,
    label: "new_portfolio_contract_period",
    items: [
      { label: "Year", value: "Year" },
      { label: "Quarter 1", value: "Quarter1" },
      { label: "Quarter 2", value: "Quarter2" },
      { label: "Quarter 3", value: "Quarter3" },
      { label: "Quartal 4", value: "Quarter4" },
      { label: "January", value: "January" },
      { label: "February", value: "February" },
      { label: "March", value: "March" },
      { label: "April", value: "April" },
      { label: "May", value: "May" },
      { label: "June", value: "June" },
      { label: "July", value: "July" },
      { label: "August", value: "August" },
      { label: "September", value: "September" },
      { label: "October", value: "October" },
      { label: "November", value: "November" },
      { label: "December", value: "December" },
    ],
  },
  {
    id: 4,
    type: PortfolioContractType.dropdown,
    label: "new_portfolio_contract_type",
    items: [
      { label: "Base", value: "Base" },
      { value: "Peak", label: "Peak" },
    ],
  },
  {
    id: 5,
    type: PortfolioContractType.input,
    label: "new_portfolio_contract_power",
  },
  {
    id: 6,
    type: PortfolioContractType.input,
    label: "new_portfolio_contract_supplier",
  },
  {
    id: 7,
    type: PortfolioContractType.dropdown,
    label: "new_portfolio_contract_currency",
    items: [
      { label: "EUR", value: "EUR" },
      { label: "CHF", value: "CHF" },
    ],
  },
  {
    id: 8,
    type: PortfolioContractType.input,
    label: "new_portfolio_contract_price",
  },
];
