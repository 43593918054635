import { ProfileContent } from "containers/profileContent";
import { useTranslation } from "react-i18next";
import style from "./profile.module.scss";

export const Profile = () => {
  const { t } = useTranslation();
  return (
    <div className={style.root}>
      <h2 className={style.head}>{t("profile_page_head")}</h2>
      <ProfileContent />
    </div>
  );
};
