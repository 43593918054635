import { useState } from "react";
import { useAppSelector } from "app/hooks";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  IPortfolioProfileData,
  isLoading$ as isLoadingProfile$,
} from "features/portfolioProfile/slice";
import {
  allHedgeError$,
  allHedgeXBase$,
  allHedgeXPeak$,
  allHedgeYBase$,
  allHedgeYPeak$,
  isError$,
  isLoading$,
} from "features/dashboardHedges/slice";
import { isLoading$ as isLoadingGraph$ } from "features/portfolioProfileGraph/slice";
import { isLoading$ as isLoadingHedge$ } from "features/hedge/slice";
import { types } from "containers/hedgeContent/utils";
import { HedgeContent } from "containers/hedgeContent";
import { ISelectFullValue } from "components/dropdownSelect";
import { PortfolioYearPicker } from "components/portfolioYearPicker";

import cx from "classnames";
import style from "./dashboardPortfolioContent.module.scss";

interface IProps {
  name: string;
  index: number;
  portfolio_id: string;
  currentYear: number;
  allProfileData: IPortfolioProfileData[];
  handleHedgeChange: (id: string, year: number, period: string) => void;
}

export const DashboardPortfolioContent = ({
  name,
  index,
  portfolio_id,
  currentYear,
  allProfileData,
  handleHedgeChange,
}: IProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [type, setType] = useState<ISelectFullValue>(types[0]);
  const [year, setYear] = useState<number>(currentYear);

  const isLoadingGraph = useAppSelector(isLoadingGraph$);
  const isLoadingProfile = useAppSelector(isLoadingProfile$);
  const isLoadingHedge = useAppSelector(isLoadingHedge$);

  const allHedgeXBase = useAppSelector(allHedgeXBase$);
  const allHedgeXPeak = useAppSelector(allHedgeXPeak$);
  const allHedgeYBase = useAppSelector(allHedgeYBase$);
  const allHedgeYPeak = useAppSelector(allHedgeYPeak$);
  const allHedgeError = useAppSelector(allHedgeError$);
  const isError = useAppSelector(isError$);
  const isLoading = useAppSelector(isLoading$);

  const RootCX = cx(style.root, {
    [style.loading]: isLoading[portfolio_id],
  });

  const navigateToProfile = () => {
    navigate(`/app/portfolio/overview/?id=${portfolio_id}`);
    window.scrollTo(0, 0);
  };

  const handleTypeChange = (v: ISelectFullValue | any, id?: string) => {
    setType(v);
    handleHedgeChange(id as string, year, v.value);
  };

  const handleChangeYear = (item: IPortfolioProfileData, id?: string) => {
    setYear(item.year);
    handleHedgeChange(
      id as string,
      item.year,
      type.value as ISelectFullValue | any
    );
  };

  return (
    <div className={RootCX}>
      <div className={style.header}>
        <h2>{name}</h2>
        <button className={style.btn} onClick={navigateToProfile}>
          {t("show_details_btn_title")}
        </button>
      </div>
      <div className={style.profileWrapper}>
        <PortfolioYearPicker
          year={year}
          isPageForDashboard
          portfolioId={portfolio_id}
          data={allProfileData}
          disable={isLoadingGraph || isLoadingProfile || isLoadingHedge}
          onClick={handleChangeYear}
        />
      </div>
      <HedgeContent
        type={type}
        year={year}
        isPageForDashboard
        portfolio_id={portfolio_id}
        hedgeXBase={allHedgeXBase[index]}
        hedgeXPeak={allHedgeXPeak[index]}
        hedgeYBase={allHedgeYBase[index]}
        hedgeYPeak={allHedgeYPeak[index]}
        handleTypeChange={handleTypeChange}
        hedgeError={allHedgeError[portfolio_id]}
        isLoading={isLoading[portfolio_id]}
        isError={isError[portfolio_id]}
      />
    </div>
  );
};
