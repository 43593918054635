import dayjs from "dayjs";
import html2canvas from "html2canvas";

export const portfolioChartOptions = {
  ylabel: "kW",
  labels: ["Date", "Point 1", "Point 2"],
  axes: {
    x: {
      axisLineWidth: 2,
      gridLinePattern: [7, 7],
      axisLineColor: "#6E7991",
      gridLineColor: "#6E7991",
      axisLabelFormatter: (x: Date | number) =>
        dayjs(x).format("MM/YYYY"),
    },
    y: {
      axisLineWidth: 2,
      gridLinePattern: [7, 7],
      axisLineColor: "#6E7991",
      gridLineColor: "#6E7991",
      axisLabelFormatter: (y: any) => {
        if (y >= 1000) {
          return `${(y / 1000).toFixed(0)}K`;
        }
        return y.toString();
      },
    },
  },
  colors: ["#8BBA05", "#66AC4E", "#198754"],
  showRangeSelector: true,
  animatedZooms: true,
  drawCallback: () => {
    const axisLabels = document.getElementsByClassName(
      "dygraph-axis-label"
    ) as unknown as HTMLElement[];

    Array.from(axisLabels).forEach((label) => {
      label.style.color = "#6E7991";
    });
  }
};

export const profileRolloutOptions = {
  ylabel: "kW",
  labels: ["Date", "Point 1", "Point 2"],
  axes: {
    x: {
      axisLineWidth: 2,
      gridLinePattern: [7, 7],
      axisLineColor: "#6E7991",
      gridLineColor: "#6E7991",
      axisLabelFormatter: (x: Date | number) =>
        dayjs(x).format("MM/DD hh:mm"),
    },
    y: {
      axisLineWidth: 2,
      gridLinePattern: [7, 7],
      axisLineColor: "#6E7991",
      gridLineColor: "#6E7991",
      axisLabelFormatter: (y: any) => {
        if (y >= 1000) {
          return `${(y / 1000).toFixed(0)}K`;
        }
        return y.toString();
      },
    },
  },
  pixelsPerLabel: 90,
  colors: ["#8BBA05", "#66AC4E", "#198754"],
  showRangeSelector: true,
  animatedZooms: true,
  drawCallback: () => {
    const axisLabels = document.getElementsByClassName(
      "dygraph-axis-label"
    ) as unknown as HTMLElement[];
    const axisLabelsX = document.getElementsByClassName(
      "dygraph-axis-label-x"
    ) as unknown as HTMLElement[];

    Array.from(axisLabels).forEach((label) => {
      label.style.color = "#6E7991";
    });

    Array.from(axisLabelsX).forEach((label) => {
      label.style.width = "85px";
    });
  }
};

export const onDownloadDyGraph = (id: string, fileName?: string) => {
  setTimeout(() => {
    const graphContainer = document.querySelector(
      `#${id}`
    ) as HTMLElement;
    html2canvas(graphContainer).then(function (canvas) {
      canvas.style.display = "none";
      document.body.appendChild(canvas);
      const image = canvas
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");
      const a = document.createElement("a");
      a.setAttribute("download", `${fileName}.png`);
      a.setAttribute("href", image);
      a.click();
    });
  }, 1000);
};