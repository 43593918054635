import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { refreshToken, setDisconnect } from "features/auth/authSlice";
import { RootState } from "../../app/store";
import { UPLOAD_ROLL_OUT_FILE } from "./constants";
import { Api } from "api";
import { setCalculate, setStatusError } from "features/rollout/slice";
import axios from "axios";

export interface RollOutState {
  list: IRollOutFile[];
  isLoading: boolean;
  isError: boolean;
}

export interface IRollOutFile {
  name: string;
  size: number;
  id: string;
}

const initialState: RollOutState = {
  list: [],
  isLoading: false,
  isError: false,
};

interface IRollOutRequest {
  file: File;
}
export const uploadRollOutFile = createAsyncThunk(
  UPLOAD_ROLL_OUT_FILE,
  async ({ file }: IRollOutRequest, api) => {
    try {
      let formData = new FormData();
      formData.append("file", file);
      const res = await axios.post(`${Api.UploadRollOutFile}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (!res) {
        throw new Error("Error");
      }
      api.dispatch(setStatusError(""));
      api.dispatch(setCalculate(false));

      return { id: res?.data?.id, name: file.name, size: file.size };
    } catch (error: any) {
      api.dispatch(setRollOutError(true));
      if (error?.request?.status === 403) {
        api.dispatch(setDisconnect(true));
      } else if (error?.request?.status === 401) {
        api.dispatch(
          refreshToken({ callback: uploadRollOutFile, parameter: file })
        );
      }
    }
  }
);

export const rollOutUploadFilesSlice = createSlice({
  name: "rollOutUploadFiles",
  initialState,
  reducers: {
    setRollOutError(state, action) {
      state.isError = action.payload;
    },
    setInitialUploadFiles(state) {
      state.list = [];
    },
    setInitAllRollOutData(state) {
      state.isError = false;
      state.isLoading = false;
      state.list = [];
    },
    setLoading(state, action) {
      state.isLoading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(uploadRollOutFile.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(uploadRollOutFile.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.isError = false;

        state.list = payload
          ? [...state.list, payload as IRollOutFile]
          : state.list;
      })
      .addCase(uploadRollOutFile.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      });
  },
});
export const {
  setRollOutError,
  setInitialUploadFiles,
  setInitAllRollOutData,
  setLoading,
} = rollOutUploadFilesSlice.actions;
export const fileList$ = (state: RootState) => state.rollOutUploadFiles.list;
export const isLoading$ = (state: RootState) =>
  state.rollOutUploadFiles.isLoading;
export const isError$ = (state: RootState) => state.rollOutUploadFiles.isError;

export default rollOutUploadFilesSlice.reducer;
