// A mock function to mimic making an async request for data
import { Api } from "api";
import { getRefreshToken } from "interceptors";
import axios from "axios";

export interface ILoginPromise {
  email: string;
  password: string;
  callback?: () => void;
}

export const getLogin = async ({ email, password }: ILoginPromise) => {
  const res = await axios.post(Api.Login, { email, password });
  return res;
};

export interface IRecoveryLoginPromise {
  email: string;
}

export const recoveryLogin = async ({ email }: IRecoveryLoginPromise) => {
  const res = await axios.post(Api.RecoveryPassword, { email });
  return res;
};

export const logout = async () => {
  const res = await axios.post(Api.Logout, { refresh: getRefreshToken() });
  return res;
};
