import { IRollOutFile } from "features/rollOutUploadFiles/slice";
import { useMemo } from "react";
import { formatBytes } from "utils";
import style from "./uploadFile.module.scss";

interface IProps {
  item: IRollOutFile | File;
}
export const UploadFile = ({ item }: IProps) => {
  const size = useMemo(() => {
    return formatBytes(item.size, -1);
  }, [item.size]);
  return (
    <div className={style.root}>
      <div className={style.size} title={size}>
        {size}
      </div>
      <div className={style.name} title={item.name}>
        {item.name}
      </div>
    </div>
  );
};
