export enum Api {
  Login = "/api/v1/auth/login/",
  RecoveryPassword = "/api/v1/auth/recovery-password/",
  User = "/api/v1/auth/user/",
  Logout = "/api/v1/auth/logout/",
  ChangePassword = "/api/v1/auth/change-password/",
  CreatePassword = "/api/v1/auth/create-password/",
  UploadUserAvatar = "/api/v1/auth/avatar/upload/",
  DeleteUserAvatar = "/api/v1/auth/avatar/upload/",
  RefreshToken = "/api/v1/auth/token/refresh/",
  UploadRollOutFile = "/api/v1/roll_out/file/upload/",
  ProcessingRollOutFile = "/api/v1/roll_out/processing_file/",
  RollOutGraph = "/api/v1/roll_out/graph/",
  RollOutFiles = "/api/v1/roll_out/files/",
  RollOutFile = "/api/v1/roll_out/file/download/",
  UploadProfilePortfolio = "/api/v1/portfolio/profile/file/",
  GetPortfolioProfile = "/api/v1/portfolio/",
  GetPortfolioProfiles = "/api/v1/portfolio/",
  GetPortfolioProfileGraph = "/api/v1/portfolio/profile/graph/",
  GetPortfolioOptions = "/api/v1/portfolio/options/",
  GetPortfolioContracts = "/api/v1/portfolio/contracts/",
  DownloadPortfolioContracts = "/api/v1/portfolio/",
  GetHedge = "/api/v1/portfolio/hedge/graph/",
  GetSignals = "/api/v1/portfolio/signals/",
  GetMarketData = "/api/v1/market/data/",
  GetMarketRange = "/api/v1/market/range/",
  GetCorridor = "/api/v1/portfolio/corridor/",
  GetProfileCorridor = "/api/v1/portfolio/profile",
  GetScenarios = "/api/v1/scenarios",
}
