import Dygraph from "dygraphs";

import { useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "app/hooks";
import { currentGraphId$, graphFiles$ } from "features/rollout/slice";
import { useElementSize } from "utils/hooks";

import { DygraphPortfolioChart } from "components/dygraphPortfolioChart";

import { addSpaceEveryNth } from "utils";
import {
  onDownloadDyGraph,
  profileRolloutOptions,
} from "components/dygraphPortfolioChart/utils";

import { ReactComponent as SaveIcon } from "assets/svg/save.svg";
import { ReactComponent as RefreshIcon } from "assets/svg/ic_round-refresh.svg";

import style from "./chart.module.scss";

interface IProps {
  data: (number | Date)[][] | null;
}

export const Chart = (props: IProps) => {
  const { t } = useTranslation();
  const currentGraphId = useAppSelector(currentGraphId$);
  const graphFiles = useAppSelector(graphFiles$);

  const [wrapperRef] = useElementSize();
  const [currentGraph, setCurrentGraph] = useState<Dygraph>();
  const ref = useRef<HTMLDivElement | null>(null);

  const fileName = useMemo(() => {
    const currentFile = graphFiles.find((file) => file.id === currentGraphId);

    if (currentFile?.result_file) {
      let result = currentFile.result_file;
      const indexOfCH = result.lastIndexOf("-CH");
      const indexOfDE = result.lastIndexOf("-DE");

      if (indexOfCH >= 0) {
        result = result.slice(0, indexOfCH);
      } else if (indexOfDE >= 0) {
        result = result.slice(0, indexOfDE);
      }

      return result || t("file_without_name");
    }
  }, [currentGraphId, graphFiles, t]);

  const quantityCounter = useMemo(() => {
    const currentFile = graphFiles.find((file) => file.id === currentGraphId);

    if (currentFile?.consumption) {
      const consumption = Number(currentFile?.consumption)?.toFixed();
      return addSpaceEveryNth(consumption) || 0;
    }
  }, [currentGraphId, graphFiles]);

  const currentFileName = useMemo(() => {
    const currentFile = graphFiles.find((file) => file.id === currentGraphId);

    if (currentFile?.result_file) {
      const result = currentFile.result_file;
      return result || t("file_without_name");
    }
  }, [currentGraphId, graphFiles, t]);

  const handleDownloadChart = () => {
    onDownloadDyGraph("rion-portfolio-chart", currentFileName);
  };

  const handleRefreshGraph = () => {
    currentGraph?.resetZoom();
  };

  return (
    <div className={style.root} id="root-chart-el" ref={wrapperRef}>
      <div className={style.wrapper} ref={ref}>
        <div className={style.subtitle}>
          Quantity of energy:
          <span className={style.data}>{`${quantityCounter || ""} MWh`}</span>
        </div>
        {/* <div className={style.filename}>{fileName}</div> */}
        <button
          onClick={handleDownloadChart}
          className={style.downloadBtn}
          data-html2canvas-ignore
        >
          <SaveIcon className={style.icon} />
        </button>
        <DygraphPortfolioChart
          data={props.data}
          setCurrentGraph={setCurrentGraph}
          fileName={fileName}
          options={profileRolloutOptions}
        />
        <button
          onClick={handleRefreshGraph}
          className={style.refreshBtn}
          data-html2canvas-ignore
        >
          <RefreshIcon className={style.icon} />
        </button>
      </div>
    </div>
  );
};
