import { ApexOptions } from "apexcharts";
import Chart from "react-apexcharts";
import { makeOptions } from "./utils";
export type HedgeType = "year" | "quarter" | "month";

export interface IChartData {
  name: string;
  data: { x: string; y: number };
}
interface IProps {
  tooltipSuffix?: string;
  tooltipPrefix?: string;
  dataType?: string;
  chartData: IChartData[];
  title: string;
  filename?: string;
  max?: number;
}

export interface yData {
  name: string;
  data: number[];
}

export const CorridorMultyChart = ({
  tooltipSuffix,
  tooltipPrefix,
  dataType,
  chartData,
  title,
  filename,
  max,
}: IProps) => {
  const options: ApexOptions = makeOptions({
    yTitle: dataType,
    tooltipPrefix,
    tooltipSuffix,
    title,
    filename,
    max,
  }) as any;

  return (
    <Chart
      options={options}
      series={chartData as any}
      type="area"
      height={491}
      width={"100%"}
    />
  );
};
