import { useAppDispatch, useAppSelector } from "app/hooks";
import { AnalyzeChart } from "components/analyzeChart";
import {
  analyzeBaseX$,
  analyzeBaseY$,
  analyzeChartTitle$,
  analyzeDataType$,
  analyzePeakX$,
  analyzePeakY$,
  analyzePlotting$,
  isError$,
  isLoading$,
  setPlotting,
} from "features/analyze/slice";
import {
  contracts$,
  fetchPortfolioContracts,
  isLoading$ as isLoadingContracts$,
} from "features/portfolioContracts/slice";
import {
  signals$,
  isLoading$ as isLoadingSignals$,
} from "features/signals/slice";
import { months } from "features/signals/utils";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { showDialogue } from "utils";

interface IProps {
  portfolio_id: string | null;
  isShowPlottingChart: boolean;
  showPlottingChart: (v: boolean | ((v: boolean) => boolean)) => void;
  year?: number;
}
export const AnalyzeChartContent = ({
  portfolio_id,
  isShowPlottingChart,
  showPlottingChart,
  year,
}: IProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const signals = useAppSelector(signals$);
  const analyzeBaseX = useAppSelector(analyzeBaseX$);
  const analyzeBaseY = useAppSelector(analyzeBaseY$);
  const analyzePeakY = useAppSelector(analyzePeakY$);
  const analyzePeakX = useAppSelector(analyzePeakX$);
  const analyzeDataType = useAppSelector(analyzeDataType$);
  const analyzeChartTitle = useAppSelector(analyzeChartTitle$);
  const isLoading = useAppSelector(isLoading$);
  const isError = useAppSelector(isError$);
  const contracts = useAppSelector(contracts$);
  const analyzePlotting = useAppSelector(analyzePlotting$);
  const isLoadingSignals = useAppSelector(isLoadingSignals$);
  const isLoadingContracts = useAppSelector(isLoadingContracts$);

  useEffect(() => {
    if (portfolio_id && year) {
      dispatch(
        fetchPortfolioContracts({
          id: portfolio_id!,
          year,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portfolio_id, year]);

  const handleBtnClick = () => {
    if (!isShowPlottingChart) {
      if (contracts?.length) {
        const dataFromTitle = analyzeChartTitle
          .split(" ")
          .filter((word) => word !== "Analyze")
          .join(" ");

        const period = () => {
          if (months.includes(dataFromTitle.split(" ")[0])) {
            return dataFromTitle.split(" ")[0];
          } else if (dataFromTitle.split(" ")[0].includes("Quartal")) {
            return "Quarter" + dataFromTitle.split(" ")[1][0];
          } else {
            return "Year";
          }
        };

        const contractsByDataType = contracts.filter(
          (cont) =>
            cont.period === period() &&
            analyzeDataType &&
            cont.type.toLowerCase() === (analyzeDataType as "base" | "peak")
        );

        const filteredContracts = contractsByDataType.map(
          ({ price_CHF, date }) => ({ x: date, y: Number(price_CHF) })
        );

        if (filteredContracts?.length) {
          dispatch(setPlotting(filteredContracts));
        } else {
          showDialogue({
            title: t("analyze_plot_btn"),
            text: t("plot_contracts_empty_signal_message"),
            confirmButtonText: t("ok_btn"),
            confirmButtonColor: "#3F1FED",
            showCancelButton: false,
          }).then(() => {
            showPlottingChart(false);
            dispatch(setPlotting([]));
          });
        }
      } else {
        showDialogue({
          title: t("analyze_plot_btn"),
          text: t("plot_contracts_empty_signal_message"),
          confirmButtonText: t("ok_btn"),
          confirmButtonColor: "#3F1FED",
          showCancelButton: false,
        }).then(() => {
          showPlottingChart(false);
          dispatch(setPlotting([]));
        });
      }
    } else {
      dispatch(setPlotting([]));
    }
    showPlottingChart((prev) => !prev);
  };

  return (
    <>
      {!!signals.length && (
        <AnalyzeChart
          baseX={analyzeBaseX}
          peakX={analyzePeakX}
          baseY={analyzeBaseY}
          peakY={analyzePeakY}
          type={analyzeDataType!}
          title={analyzeChartTitle}
          isLoading={isLoading || isLoadingSignals}
          isError={isError}
          onBtnClick={handleBtnClick}
          disableBtn={isLoadingContracts}
          plotting={analyzePlotting}
          btnText={
            !isShowPlottingChart
              ? t("analyze_plot_btn")
              : t("analyze_unplot_btn")
          }
        />
      )}
    </>
  );
};
