import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getSideMenuPosition, setSideMenuPosition } from "interceptors";
import { RootState } from "../../app/store";

export interface UserState {
  id: number;
  isOpenNavigation: boolean;
}

const initialState: UserState = {
  id: 0,
  isOpenNavigation: getSideMenuPosition(),
};

export const navSlice = createSlice({
  name: "nav",
  initialState,
  reducers: {
    setNavId: (state, action: PayloadAction<number>) => {
      state.id = action.payload;
    },
    setNavPosition: (state, action: PayloadAction<boolean>) => {
      state.isOpenNavigation = action.payload;
      setSideMenuPosition(action.payload);
    },
  },
});

export const { setNavId, setNavPosition } = navSlice.actions;

export const navId$ = (state: RootState) => state.nav.id;
export const isOpenNavigation$ = (state: RootState) =>
  state.nav.isOpenNavigation;

export default navSlice.reducer;
