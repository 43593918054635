import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { refreshToken, setDisconnect } from "features/auth/authSlice";
import { RootState } from "../../app/store";
import {
  GRAPH_ROLL_OUT_FILE,
  PROCESSING_ROLL_OUT_FILE,
  ROLLOUT_FILE,
  ROLLOUT_FILES,
} from "./constants";
import { Api } from "api";
import worker_script from "utils/chart";
import { uploadFile } from "utils";
import axios from "axios";
import i18n from "../../i18n";

export interface RollOutState {
  isLoading: boolean;
  isError: boolean;
  graphFiles: IGraphFile[];
  isProcessed: boolean;
  rolloutChartData: any[][];
  ids: string;
  index: null | number;
  length: null | number;
  consumption: string;
  statusError: string;
  currentGraphId: null | string;
  isLoadingChart: boolean;
  isCalculatePressed: boolean;
  isRecalculateLoading: boolean;
  isSum: boolean;
  errorText: string | null;
}
export interface IGraphFile {
  id: string;
  year: number;
  country: string;
  scale_ratio: string;
  sum_check: boolean;
  consumption: string | null;
  city: number;
  result_file: null | string;
  error?: boolean;
}

export type OutputType = "1h" | "15m"; 

const initialState: RollOutState = {
  isLoading: false,
  isError: false,
  graphFiles: [],
  isProcessed: false,
  rolloutChartData: [],
  ids: "",
  index: null,
  length: null,
  consumption: "",
  statusError: "",
  currentGraphId: null,
  isLoadingChart: false,
  isCalculatePressed: false,
  isRecalculateLoading: false,
  isSum: false,
  errorText: null,
};

export interface IProcessingRollOutRequest {
  file_to_processing: string | string[];
  year: number;
  country: string;
  scale_ratio: number;
  sum_check: boolean;
  consumption?: string;
  city: number;
  index?: number;
  length?: number;
  recalculate?: boolean;
  output_type: OutputType;
}

export const processingFile = createAsyncThunk(
  PROCESSING_ROLL_OUT_FILE,
  async (data: IProcessingRollOutRequest, api) => {
    api.dispatch(setIds(""));
    const { index, length, recalculate } = data;

    if (index === length! - 1) {
      api.dispatch(setFileData({ index: null, length: null }));
    }
    if (index === 0) {
      api.dispatch(setCalculate(true));
      if (recalculate) {
        api.dispatch(setStatusError(""));
        api.dispatch(setRecalculateLoadig(true));
      }
    }

    if (index === length! - 1) {
      api.dispatch(setFileData({ index: null, length: null }));
    }
    if (index === 0) {
      api.dispatch(setCalculate(true));
    }

    delete data.index;
    delete data.length;
    delete data.consumption;
    delete data.recalculate;
    try {
      const res = await axios.post(`${Api.ProcessingRollOutFile}`, data);

      if (!res) {
        throw new Error("Error");
      }

      const id = res?.data?.id;
      api.dispatch(setIds(id));
      // if (id && index === 1) {
      //   api.dispatch(fetchRollOutGraph({ id }));
      //   api.dispatch(setLoading(false));
      // }

      api.dispatch(setFileData({ index, length }));
      // api.dispatch(setLoading(false));

      // if (index === length! - 1) {
      //   setTimeout(() => {
      //     api.dispatch(addRolloutFile());
      //   }, 6000);
      // }

      return res?.data;
    } catch (error: any) {
      api.dispatch(setRollOutError(true));
      if (error?.request?.status === 403) {
        api.dispatch(setDisconnect(true));
      } else if (error?.request?.status === 401) {
        api.dispatch(
          refreshToken({ callback: processingFile, parameter: undefined })
        );
      }
    }
  }
);

interface IGetRolloutFile {
  id: string;
  consumption: string;
  result_file: string;
  error?: boolean;
}

export const addRolloutFile = createAsyncThunk(
  ROLLOUT_FILES,
  async ({ id, consumption, result_file, error }: IGetRolloutFile, api) => {
    // const ids = ids$(api.getState() as any);
    api.dispatch(setStatusError(""));

    // const res = await axios.get(`${Api.RollOutFiles}?ids=${ids}`);

    // api.dispatch(fetchRollOutGraph({ id: res.data[0].id }));
    const graphFiles = graphFiles$(api.getState() as RootState);

    const index = index$(api.getState() as RootState);
    const length = length$(api.getState() as RootState);
    api.dispatch(
      setFileData({
        index: index !== null && !error ? index + 1 : null,
        length: error ? null : length,
      })
    );

    if (error) {
      api.dispatch(setLoading(false));
      api.dispatch(setProcessing(true));
    }

    if (error && index === 0) {
      api.dispatch(setErrorText(i18n.t("rollout_processing_status_error")));
    }

    const resultFiles = error
      ? graphFiles.filter((f) => f.id !== id)
      : graphFiles;

    const result = [
      ...resultFiles,
      { id, consumption, result_file, error } as IGraphFile,
    ];
    return result;
  }
);

interface IGetFile {
  id: string;
}
export const fetchRollOutFile = createAsyncThunk(
  ROLLOUT_FILE,
  async ({ id }: IGetFile, api) => {
    try {
      const res = await axios.get(`${Api.RollOutFile}${id}/`);

      if (!res) {
        throw new Error("Error");
      }

      const url = res?.data?.result_file;

      uploadFile(url, "");

      return res?.data?.result_file;
    } catch (error: any) {
      api.dispatch(setRollOutError(true));
      if (error?.request?.status === 403) {
        api.dispatch(setDisconnect(true));
      } else if (error?.request?.status === 401) {
        api.dispatch(
          refreshToken({ callback: processingFile, parameter: undefined })
        );
      }
    }
  }
);

interface IRolloutGraphRequest {
  id: string;
  isLoading?: boolean;
}

export const fetchRollOutGraph = createAsyncThunk(
  GRAPH_ROLL_OUT_FILE,
  async ({ id, isLoading }: IRolloutGraphRequest, api) => {
    const index = index$(api.getState() as RootState);
    // first chart

    if (index === 0 || isLoading) {
      api.dispatch(setLoadingCharts(true));
    } else {
      // other charts
      if (index === null) {
        setTimeout(() => {
          api.dispatch(setCalculate(false));
        }, 2000);
      }
      api.dispatch(setLoadingCharts(false));
    }
    try {
      const res = await axios.post(`${Api.RollOutGraph}`, { id });

      if (!res) {
        throw new Error("Error");
      }
      api.dispatch(setCurrentFileId(id));

      const worker = new Worker(worker_script);

      worker.postMessage(res.data);
      worker.onmessage = (e) => {
        api.dispatch(setChartData(e.data));
        worker.terminate();
      };

      if (isRecalculateLoading$(api.getState() as RootState)) {
        setTimeout(() => {
          api.dispatch(setRecalculateLoadig(false));
        }, 1500);
      }

      if (isLoading) {
        api.dispatch(setLoadingCharts(false));
      }

      return true;
    } catch (error: any) {
      api.dispatch(setRollOutError(true));
      if (error?.request?.status === 403) {
        api.dispatch(setDisconnect(true));
      } else if (error?.request?.status === 401) {
        api.dispatch(
          refreshToken({ callback: processingFile, parameter: undefined })
        );
      }
    }
  }
);

export const rollOutSlice = createSlice({
  name: "rollOut",
  initialState,
  reducers: {
    setStatusError(state, action) {
      state.statusError = action.payload;
    },
    setCurrentFileId(state, action) {
      state.currentGraphId = action.payload;
    },
    setRollOutError(state, action) {
      state.isError = action.payload;
    },
    setChartData(state, action) {
      state.rolloutChartData = action.payload.result;
      state.isError = false;
      state.isLoading = false;
    },
    initRollout(state) {
      state.isError = false;
      state.isLoading = false;
      state.isProcessed = false;
      state.graphFiles = [];
      state.rolloutChartData = [];
      state.index = null;
      state.length = null;
      state.errorText = null;
    },
    setIds(state, action) {
      state.ids = !action.payload
        ? ""
        : `${state.ids?.length ? state.ids + "," : ""}${action.payload}`;
    },
    setLoading(state, action) {
      state.isLoading = action.payload;
    },
    setFileData(state, action) {
      state.index = action.payload.index;
      state.length = action.payload.length;
    },
    setFiles(state, action) {
      state.graphFiles = action.payload;
    },
    setLoadingCharts(state, action) {
      state.isLoadingChart = action.payload;
    },
    setCalculate(state, action) {
      state.isCalculatePressed = action.payload;
    },
    setRecalculateLoadig(state, action) {
      state.isRecalculateLoading = action.payload;
    },
    setSum(state, action) {
      state.isSum = action.payload;
    },
    setErrorText(state, action) {
      state.errorText = action.payload;
    },
    setProcessing(state, action) {
      state.isProcessed = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(processingFile.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(processingFile.fulfilled, (state) => {
        // state.isLoading = false;
        state.isError = false;
        state.isProcessed = true;
      })
      .addCase(processingFile.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      })
      .addCase(fetchRollOutGraph.pending, (_state) => {
        // state.isLoading = true;
      })
      .addCase(fetchRollOutGraph.fulfilled, (state, { payload }) => {
        state.isLoadingChart = !payload;
        state.isError = false;
      })
      .addCase(fetchRollOutGraph.rejected, (state) => {
        state.isLoadingChart = false;
        state.isError = true;
      })
      .addCase(addRolloutFile.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addRolloutFile.fulfilled, (state, { payload }) => {
        // state.isLoading = false;
        state.isError = false;
        state.graphFiles = payload;
      })
      .addCase(addRolloutFile.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      })
      .addCase(fetchRollOutFile.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchRollOutFile.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.isError = false;
      })
      .addCase(fetchRollOutFile.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      });
  },
});
export const {
  setRollOutError,
  setChartData,
  initRollout,
  setIds,
  setLoading,
  setFileData,
  setStatusError,
  setCurrentFileId,
  setFiles,
  setLoadingCharts,
  setCalculate,
  setRecalculateLoadig,
  setSum,
  setErrorText,
  setProcessing,
} = rollOutSlice.actions;
export const isLoading$ = (state: RootState) => state.rollOut.isLoading;
export const isError$ = (state: RootState) => state.rollOut.isError;
export const graphFiles$ = (state: RootState) => state.rollOut.graphFiles;
export const isProcessed$ = (state: RootState) => state.rollOut.isProcessed;
export const ids$ = (state: RootState) => state.rollOut.ids;
export const index$ = (state: RootState) => state.rollOut.index;
export const length$ = (state: RootState) => state.rollOut.length;
export const statusError$ = (state: RootState) => state.rollOut.statusError;
export const errorText$ = (state: RootState) => state.rollOut.errorText;
export const rolloutChartData = (state: RootState) =>
  state.rollOut.rolloutChartData;
export const rolloutChartData$ = createSelector(
  rolloutChartData,
  (data) => data
);
export const isChartDataReady$ = (state: RootState) =>
  !!rolloutChartData$(state)?.length;
export const currentGraphId$ = (state: RootState) =>
  state.rollOut.currentGraphId;
export const isLoadingChart$ = (state: RootState) =>
  state.rollOut.isLoadingChart;
export const isCalculatePressed$ = (state: RootState) =>
  state.rollOut.isCalculatePressed;
export const isRecalculateLoading$ = (state: RootState) =>
  state.rollOut.isRecalculateLoading;

export const isSum$ = (state: RootState) => state.rollOut.isSum;

export default rollOutSlice.reducer;
