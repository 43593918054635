import { SignalType } from "features/signals/slice";
import style from "./analyzeChart.module.scss";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { getOptions } from "./utils";
import { useTranslation } from "react-i18next";
import { Loader } from "components/loader";
import "./style.scss";
import { useAppSelector } from "app/hooks";
import { market$ } from "features/user/slice";
import { AnalyzePlot } from "features/analyze/slice";

interface IProps {
  baseX: string[];
  peakX: string[];
  baseY: number[];
  peakY: number[];
  type: "base" | "peak";
  title: string;
  isLoading: boolean;
  isError?: boolean;
  onBtnClick: () => void;
  disableBtn?: boolean;
  plotting: AnalyzePlot[];
  btnText: string;
}

export const AnalyzeChart = ({
  baseX,
  peakX,
  baseY,
  peakY,
  type,
  title,
  isLoading,
  isError,
  onBtnClick,
  disableBtn,
  plotting,
  btnText,
}: IProps) => {
  const { t } = useTranslation();
  const market = useAppSelector(market$);

  const baseData = baseX.map((x, index) => ({ x, y: baseY[index] }));
  const peakData = peakX.map((x, index) => ({ x, y: peakY[index] }));
  const series = plotting?.length
    ? [
        { name: "", data: type === SignalType.BASE ? baseData : peakData },
        { name: "plot", data: plotting },
      ]
    : [{ name: "", data: type === SignalType.BASE ? baseData : peakData }];

  const options: ApexOptions = getOptions({
    title,
    filename: `${title}_${type}`,
    isNeedToShowPoints: !!plotting?.length,
    market,
  });

  return (
    <div className={style.root}>
      <button className={style.btn} onClick={onBtnClick} disabled={disableBtn}>
        {btnText}
      </button>

      {isLoading && (
        <div className={style.loaderContainer}>
          <Loader />
        </div>
      )}

      {isError && !isLoading && (
        <div className={style.loaderContainer}>
          <div className={style.error}>
            {t("rollout_processing_status_error")}
          </div>
        </div>
      )}
      <Chart
        type="area"
        series={series}
        options={options}
        width="100%"
        height={500}
        className={"analyze-chart-rion"}
      />
    </div>
  );
};
