import style from "./radio.module.scss";
import cx from "classnames";

interface IProps {
  selected?: boolean;
  label?: string | JSX.Element;
  onChange?: (v: boolean) => void;
  disabled?: boolean;
  error?: boolean;
}

export const Radio = ({
  selected,
  label,
  onChange,
  disabled,
  error,
}: IProps) => {
  const handleClick = () => {
    if (disabled) return;
    onChange && onChange(!selected);
  };

  const CircleCX = cx(style.circle, {
    [style.selected]: selected,
    [style.error]: error,
  });

  const RootCX = cx(style.root, {
    [style.disabled]: disabled,
  });

  const LabelCX = cx(style.label, {
    [style.error]: error,
  });

  const WrapperCX = cx(style.wrapper, {
    [style.error]: error,
  });

  return (
    <div className={RootCX} onClick={handleClick}>
      <div className={WrapperCX}>
        <div className={CircleCX} />
      </div>
      {!!label && (
        <label className={LabelCX} title={label ? label.toString() : undefined}>
          {label}
        </label>
      )}
    </div>
  );
};
