import { useAppDispatch, useAppSelector } from "app/hooks";
import { DropdownSelect, ISelectFullValue } from "components/dropdownSelect";
import { SliderComponent } from "components/slider";
import { Toggler } from "components/toggler";
import {
  fetchPortfolioOptionById,
  IPortfolioOption,
  isLoading$,
  options$,
  setOption,
  updatePortfolioOptionById,
} from "features/portfolioOptions/slice";
import { user$ } from "features/user/slice";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { getCurrentYear } from "utils";
import style from "./optionsContent.module.scss";
import { UserRole } from "features/user/slice";
import { Loader } from "components/loader";
import cx from "classnames";

// const roles = [
//   { label: "Producer", value: "Producer" },
//   { label: "Consumer", value: "Consumer" },
// ];

const methods = [
  { label: "Value hedge", value: "Value hedge" },
  { label: "Quantity hedge", value: "Quantity hedge" },
  { label: "Value and Quantity hedge", value: "Value and Quantity hedge" },
];

export const OptionsContent = () => {
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const user = useAppSelector(user$);
  const isLoading = useAppSelector(isLoading$);
  const options = useAppSelector(options$);
  const [portfolio_id, setPortFolioId] = useState<string | null>(null);

  useEffect(() => {
    if (searchParams.get("id") !== portfolio_id) {
      const id = searchParams.get("id");
      setPortFolioId(id);
    }
  }, [searchParams, portfolio_id]);

  useEffect(() => {
    if (portfolio_id) dispatch(fetchPortfolioOptionById({ id: portfolio_id }));
  }, [dispatch, portfolio_id]);

  const handleChangeWidth = (val: number[]) => {
    updateParams(val[0], "width");
  };

  const handleChangeTarget = (val: number[]) => {
    updateParams(val[0], "target");
  };

  const updateParams = useCallback(
    (val: any, key: keyof IPortfolioOption) => {
      const newParams = { [key]: val };
      dispatch(setOption(newParams));
    },
    [dispatch]
  );

  const handlePeriodChange = (val: ISelectFullValue | string) => {
    const value = val as ISelectFullValue;
    updateParams(Number(value?.value), "period");
  };

  const handleHedgingChange = (val: ISelectFullValue | string) => {
    const value = val as ISelectFullValue;
    updateParams(value?.value, "hedging_method");
  };

  const handleYear = useCallback(
    (val: number) => {
      updateParams(val, "target_year");
    },
    [updateParams]
  );

  const periods = useMemo(
    () => [
      { value: 1, label: `1 ${t("quarter")}` },
      { value: 2, label: `2 ${t("quarter")}` },
      { value: 3, label: `3 ${t("quarter")}` },
      { value: 4, label: `4 ${t("quarter")}` },
    ],
    [t]
  );

  const period = useMemo(() => {
    const currentPeriod = periods.find(
      (p) => p.value?.toString() === options?.period?.toString()
    );

    return currentPeriod || periods[0];
  }, [options?.period, periods]);

  const handleSaveClick = useCallback(() => {
    if (portfolio_id && options)
      dispatch(updatePortfolioOptionById({ id: portfolio_id, item: options! }));
  }, [dispatch, options, portfolio_id]);

  const role: UserRole = useMemo(() => {
    return (
      user.portfolio?.find((portfolio) => portfolio.id === portfolio_id)
        ?.role || ("Consumer" as UserRole)
    );
  }, [portfolio_id, user.portfolio]);

  const minTargetValue = () => {
    if (role === "Producer") return -30;
    return 0;
  };

  const maxTargetValue = () => {
    if (role === "Producer") return 100;
    return 130;
  };

  const RootCX = cx(style.root, {
    [style.loading]: isLoading,
  });

  return (
    <div className={RootCX}>
      {isLoading && (
        <div className={style.loader}>
          <Loader />
        </div>
      )}

      <div className={style.title}>{t("actual_year")}</div>
      <Toggler
        type="year"
        value={options?.target_year || getCurrentYear()}
        onChange={handleYear}
        placeholder={""}
        min={2020}
        max={2030}
        className={style.year}
      />
      <div className={style.title}>{t("hedging_method")}</div>

      <DropdownSelect
        items={methods as any}
        isAllValueNeed
        value={{
          label: options?.hedging_method || "Value hedge",
          value: options?.hedging_method || "Value hedge",
        }}
        placeholder={""}
        onChange={handleHedgingChange}
        disabled={isLoading}
        className={style.hedging}
      />
      <div className={style.title}>{t("corridor_parameters")}</div>

      <SliderComponent
        title={t("width")}
        min={5}
        max={30}
        values={options?.width ? [options!.width!] : [5]}
        onChange={handleChangeWidth}
        suffix={"%"}
        step={5}
      />
      <SliderComponent
        title={t("target")}
        min={minTargetValue()}
        max={maxTargetValue()}
        values={
          options?.target
            ? [options?.target < 0 && role !== "Producer" ? 0 : options.target]
            : [0]
        }
        onChange={handleChangeTarget}
        suffix={"%"}
        step={5}
      />
      <div className={style.title} style={{ marginTop: 20 }}>
        {t("quarter_label_profile_options")}
      </div>
      <DropdownSelect
        items={periods as any}
        isAllValueNeed
        value={period}
        placeholder={""}
        onChange={handlePeriodChange}
        disabled={isLoading}
        className={style.hedging}
      />
      <button
        className={style.btn}
        onClick={handleSaveClick}
        disabled={isLoading}
      >
        {t("save")}
      </button>
    </div>
  );
};
