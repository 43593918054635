import { useEffect, useState } from "react";
import ContentLoader from "react-content-loader";

export const GraphListSkeleton = () => {
  const [containerWidth, setContainerWidth] = useState(270);

  const width = window.innerWidth;

  useEffect(() => {
    if (width < 1280) {
      setContainerWidth(150);
    } else {
      setContainerWidth(270);
    }
  }, [width]);
  return (
    <ContentLoader
      speed={1}
      width={containerWidth}
      height={20}
      viewBox={`0 0 ${containerWidth} ${20}`}
      backgroundColor="#f3f3f3"
      foregroundColor={"#ecebeb"}
    >
      <rect
        x="31"
        y="5"
        rx="3"
        ry="3"
        width={containerWidth - 30}
        height={10}
      />
      <circle cx="10" cy="10" r="7" />
    </ContentLoader>
  );
};
