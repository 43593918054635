// import { ReactComponent as LogoIcon } from "assets/svg/logo.svg";
import logo from "assets/webp/logo.webp";
import shortLogo from "assets/jpg/shortLogo.jpg";
interface IProps {
  className?: string;
  onClick?: () => void;
  isShort?: boolean;
}
export const Logo = ({ className, onClick, isShort }: IProps) => {
  return isShort ? (
    <img src={shortLogo} className={className} alt="shortLogo" />
  ) : (
    <img className={className} alt="logo" onClick={onClick} src={logo} />
  );
  // return <LogoIcon className={className} onClick={onClick} />;
};
