import { useCallback, useMemo } from "react";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import style from "./toggler.module.scss";
import { ReactComponent as ArrowLeftIcon } from "assets/svg/arrow-left.svg";
import { ReactComponent as ArrowRightIcon } from "assets/svg/arrow-right.svg";
import cx from "classnames";

interface IProps {
  type: "year" | "date";
  value: number | string;
  onChange: (v: number) => void;
  placeholder: string;
  disabled?: boolean;
  min?: number | string;
  max?: number | string;
  className?: string;
}

export const Toggler = ({
  type,
  value,
  onChange,
  placeholder,
  disabled,
  min,
  max,
  className,
}: IProps) => {
  const { t } = useTranslation();
  const calculate = (isPositive: boolean) => {
    if (disabled) return;

    if (typeof value === "number" && !isNaN(value) && type === "year") {
      const result = isPositive ? value + 1 : value - 1;
      onChange && onChange(result);
    }

    if (value && type === "date") {
      const result = isPositive ? 1 : -1;
      onChange && onChange(result);
    }
  };

  const handleLeftClick = () => {
    if (disabled) return;
    calculate(false);
  };

  const handleRightClick = () => {
    if (disabled) return;
    calculate(true);
  };

  const PlaceholderCX = cx(style.placeholder, {
    [style.valueSetted]: value,
  });

  const RootCX = cx(style.root, {
    [style.disabled]: disabled,
    [className ? className : ""]: className,
  });

  const isLeftButtonDisabled =
    (typeof min === "number" && !isNaN(min) && (value! as number) <= min!) ||
    (typeof max === "string" && min && new Date(value) <= new Date(min)) ||
    disabled;

  const isRightButtonDisabled =
    (typeof max === "number" && !isNaN(max) && (value! as number) >= max!) ||
    (typeof max === "string" && max && new Date(value) >= new Date(max)) ||
    disabled;

  const LeftBtnCX = cx(style.leftBtn, {
    [style.disabled]: isLeftButtonDisabled,
  });

  const RightBtnCX = cx(style.rightBtn, {
    [style.disabled]: isRightButtonDisabled,
  });

  const getTranslatedDate = useCallback(
    (date: string) =>
      date
        ? date
            .split(" ")
            .map((it, ind) => (ind === 0 ? t(it.toLowerCase()) : it))
            .join(" ")
        : "",
    [t]
  );

  const formattedValue = useMemo(
    () =>
      value && type === "date" ? getTranslatedDate(value as string) : value,
    [value, type, getTranslatedDate]
  );

  return (
    <div className={RootCX}>
      <button
        onClick={handleLeftClick}
        className={LeftBtnCX}
        disabled={isLeftButtonDisabled}
      >
        <ArrowLeftIcon className={style.icon} />
      </button>
      {value && (
        <div className={style.value} title={`${value}`}>
          {formattedValue}
        </div>
      )}
      <div className={PlaceholderCX}>{placeholder}</div>
      <button
        onClick={handleRightClick}
        className={RightBtnCX}
        disabled={isRightButtonDisabled}
      >
        <ArrowRightIcon className={style.icon} />
      </button>
    </div>
  );
};
