import { ApexOptions } from "apexcharts";
import { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { makeOptions } from "./utils";
import "./style.scss";
export type HedgeType = "year" | "quarter" | "month";

interface IProps {
  tooltipSuffix?: string;
  tooltipPrefix?: string;
  dataType?: string;
  x?: string[];
  y?: yData[];
  width?: number;
  title: string;
  filename?: string;
}

export interface yData {
  name: string;
  data: number[];
}

export const BarChartMulty = ({
  tooltipSuffix,
  tooltipPrefix,
  dataType,
  width,
  x,
  y,
  title,
  filename,
}: IProps) => {
  const [series, setSeries] = useState<ApexAxisChartSeries>([]);
  const [options, setOptions] = useState<ApexOptions>({});

  useEffect(() => {
    if (x?.length && y?.length) {
      setSeries(y);
      setOptions(
        makeOptions({
          categories: x,
          yTitle: dataType,
          tooltipPrefix,
          tooltipSuffix,
          mainTitle: title,
          filename,
        }) as any
      );
    }
  }, [dataType, tooltipPrefix, tooltipSuffix, x, y, title, filename]);

  return (
    <Chart
      options={options as any}
      series={series}
      type="bar"
      height={350}
      width={width || 300}
    />
  );
};
