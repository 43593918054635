import { Radio } from "components/radio";
import { IGraphFile } from "features/rollout/slice";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import style from "./graphListToggleItem.module.scss";

interface IProps {
  item: IGraphFile;
  selected?: boolean;
  onClick: (id: IGraphFile) => void;
}

export const GraphListToggleItem = ({ item, selected, onClick }: IProps) => {
  const { t } = useTranslation();

  const handleChange = useCallback(() => {
    onClick(item);
  }, [item, onClick]);

  const label = useMemo(() => {
    let result = item.result_file || "";
    const indexOfCH = result.lastIndexOf("-CH");
    const indexOfDE = result.lastIndexOf("-DE");

    if (indexOfCH >= 0) {
      result = result.slice(0, indexOfCH);
    } else if (indexOfDE >= 0) {
      result = result.slice(0, indexOfDE);
    }

    return result || t("file_without_name");
  }, [item.result_file, t]);

  return (
    <div className={style.root}>
      <Radio
        selected={selected}
        onChange={handleChange}
        label={label}
        error={item.error}
      />
    </div>
  );
};
