import dayjs from "dayjs";
import { getCurrentYear } from "utils";

export const valueObject = {
  0: dayjs().format("YYYY-MM-DD"),
  1: "Buy",
  2: getCurrentYear().toString(),
  3: "Quarter1",
  4: "Base",
  5: "10",
  6: "-",
  7: "EUR",
  8: "66",
};

export const keyObject = {
  0: { key: "date", type: "string" },
  1: { key: "deal", type: "string" },
  2: { key: "year", type: "number" },
  4: { key: "type", type: "string" },
  5: { key: "power", type: "number" },
  6: { key: "supplier", type: "string" },
  7: { key: "currency", type: "string" },
  8: { key: "input_price", type: "number" },
  3: { key: "period", type: "string" },
};
