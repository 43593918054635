import { ISelectValue } from "components/dropdownSelect";
import { PortfolioContractInput } from "components/portfolioContractInput";
import { PortfolioDatePicker } from "components/portfolioDatePicker";
import { PortfolioDropdown } from "components/portfolioDropdown";
import dayjs from "dayjs";
import { useCallback } from "react";

export enum PortfolioContractType {
  date = "date",
  dropdown = "dropdown",
  input = "input",
}

interface IProps {
  type: PortfolioContractType;
  value: string;
  items?: ISelectValue[];
  id: number;
  onChange: (value: string, id: number) => void;
}

export const PortfolioContractTableItem = ({
  type,
  items,
  id,
  value,
  onChange,
}: IProps) => {
  const handleChange = useCallback(
    (val: string) => {
      onChange(val, id);
    },
    [onChange, id]
  );

  const handleChangeDate = useCallback(
    (val: Date) => {
      const formattedValue = dayjs(val).format("YYYY-MM-DD");
      onChange(formattedValue, id);
    },
    [onChange, id]
  );

  const renderItemByType = useCallback(() => {
    switch (type) {
      case PortfolioContractType.date:
        return (
          <PortfolioDatePicker value={value} onChange={handleChangeDate} />
        );
      case PortfolioContractType.dropdown:
        return (
          <PortfolioDropdown
            value={value}
            items={items!}
            onChange={handleChange}
          />
        );
      case PortfolioContractType.input:
        return (
          <PortfolioContractInput
            value={value}
            onChange={handleChange}
            validation={id === 2 ? "year" : id !== 6 ? "number" : undefined}
          />
        );
      default:
        return null;
    }
  }, [handleChange, handleChangeDate, id, items, type, value]);

  return renderItemByType();
};
