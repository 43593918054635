import { useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { user$ } from "features/user/slice";
import {
  allCurrentProfileData$,
  allProfileData$,
  isLoading$,
  fetchAllPortfolioProfiles,
  initDashboardProfilesData,
} from "features/dashboardProfiles/slice";
import {
  fetchPortfolioHedgeById,
  fetchAllPortfolioHedge,
  initDashboardHedgeData,
} from "features/dashboardHedges/slice";
import { IPortfolioProfileData } from "features/portfolioProfile/slice";
import { Loader } from "components/loader";
import { DashboardTool } from "components/dashboardTool";
import { DashboardMarketData } from "components/dashboardMarketData";
import { DashboardPortfolioContent } from "components/dashboardPortfolioContent";
import style from "./dashboardContent.module.scss";

export const DashboardContent = () => {
  const dispatch = useAppDispatch();

  const user = useAppSelector(user$);
  const isLoading = useAppSelector(isLoading$);
  const allProfileData = useAppSelector(allProfileData$);
  const allCurrentProfileData = useAppSelector(allCurrentProfileData$);

  useEffect(() => {
    if (user?.portfolio.length) {
      dispatch(fetchAllPortfolioProfiles());
    }
  }, [dispatch, user]);

  useEffect(() => {
    if (allProfileData?.length) {
      dispatch(fetchAllPortfolioHedge());
    }
  }, [dispatch, allProfileData]);

  useEffect(() => {
    return () => {
      dispatch(initDashboardHedgeData());
      dispatch(initDashboardProfilesData());
    };
  }, [dispatch]);

  const handleHedgeChange = useCallback(
    (portfolio_id: string, year: number, period: string) => {
      dispatch(fetchPortfolioHedgeById({ portfolio_id, period, year }));
    },
    [dispatch]
  );

  const renderSkeleton = () => {
    return (
      <div className={style.skeletonContainer}>
        <div className={style.yearPicker}>
          <Loader />
        </div>
        <div className={style.hedge}>
          <Loader />
        </div>
      </div>
    );
  };

  return (
    <div className={style.root}>
      <DashboardMarketData />
      {allProfileData?.length
        ? allCurrentProfileData?.map((item, index) => (
            <DashboardPortfolioContent
              key={item.id}
              index={index}
              name={item.name}
              portfolio_id={item.id}
              currentYear={(item.data as IPortfolioProfileData)?.year}
              allProfileData={
                allProfileData[index].data as IPortfolioProfileData[]
              }
              handleHedgeChange={handleHedgeChange}
            />
          ))
        : isLoading
        ? renderSkeleton()
        : null}
      {user?.roll_out_access && <DashboardTool />}
    </div>
  );
};
