import style from "./checkbox.module.scss";
import { ReactComponent as CheckedIcon } from "assets/svg/checked.svg";
import cx from "classnames";
import { useCallback } from "react";

interface IProps {
  checked?: boolean;
  onChange?: () => void;
  label?: string;
  containerClasssName?: string;
  disabled?: boolean;
  secondaryStyle?: boolean;
}

export const Checkbox = ({
  checked,
  onChange,
  label,
  containerClasssName,
  disabled,
  secondaryStyle,
}: IProps) => {
  const RootCX = cx(style.root, {
    [containerClasssName || ""]: containerClasssName,
    [style.disabled]: disabled,
  });

  const CheckboxCX = cx(style.checkbox, {
    [style.checked]: checked,
    [style.notChecked]: !checked,
    [style.secondary]: secondaryStyle,
  });

  const handleChange = useCallback(() => {
    if (disabled) return;
    onChange && onChange();
  }, [disabled, onChange]);

  return (
    <div className={RootCX} onClick={handleChange}>
      <span className={style.wrapper}>
        <div className={CheckboxCX}>
          {!!checked && <CheckedIcon className={style.icon} />}
        </div>
      </span>
      {!!label && <label className={style.label}>{label}</label>}
    </div>
  );
};
