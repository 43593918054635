import { DropdownSelect, ISelectValue } from "components/dropdownSelect";
import { useMemo } from "react";

interface IProps {
  value: string;
  items: ISelectValue[];
  onChange: (value: string) => void;
}
export const PortfolioDropdown = ({ value, items, onChange }: IProps) => {
  const selectedValue = useMemo(() => {
    const index = items.findIndex((i) => (i as any).value === value);
    if (index >= 0) {
      return items[index];
    } else {
      return { value, label: value };
    }
  }, [items, value]);

  return (
    <DropdownSelect
      items={items as any}
      isAllValueNeed
      placeholder=""
      value={selectedValue}
      onChange={(val: any) => onChange(val.value) as any}
      small
    />
  );
};
