import { DashboardContent } from "containers/dashboardContent";
import style from "./dashboard.module.scss";

export const Dashboard = () => {
  return (
    <div className={style.root}>
      <DashboardContent />
    </div>
  );
};
