import { ChartOptions } from "containers/chartOptions";
import { ProfileRollOutContent } from "containers/profileRollOutContent";
import { useTranslation } from "react-i18next";
import style from "./profileRollOut.module.scss";

export const ProfileRollOut = () => {
  const { t } = useTranslation();
  return (
    <div className={style.root}>
      <h2 className={style.head}>{t("nav_profile_roll_out")}</h2>
      <div className={style.content}>
        <ProfileRollOutContent />
        <ChartOptions />
      </div>
    </div>
  );
};
