import React, { FC, useCallback, useMemo, useState, ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { Checkbox } from "components/checkbox";
import { DropdownSelect, ISelectFullValue } from "components/dropdownSelect";
import { Input } from "components/input";
import { Radio } from "components/radio";
import { Toggler } from "components/toggler";
import {
  graphFiles$,
  IProcessingRollOutRequest,
  isLoadingChart$,
  isLoading$ as isProcessingFileLoading$,
  processingFile,
  setErrorText,
  setFiles,
  setSum as setSumData,
  OutputType,
} from "features/rollout/slice";
import {
  fileList$,
  isLoading$ as isUploadFileLoading$,
} from "features/rollOutUploadFiles/slice";
import { german_cities, getCurrentYear, switzerland_cities } from "utils";
import style from "./RollOutOptions.module.scss";

interface CityOption {
  label: string;
  value: number;
}

export const RollOutOptions: FC = () => {
  const { t } = useTranslation();
  const uploadedFiles = useAppSelector(fileList$);
  const dispatch = useAppDispatch();
  const isUploadFileLoading = useAppSelector(isUploadFileLoading$);
  const isProcessingFileLoading = useAppSelector(isProcessingFileLoading$);
  const isLoadingChart = useAppSelector(isLoadingChart$);
  const graphFiles = useAppSelector(graphFiles$);
  const outputValues = [
    { label: "15m", value: 0 },
    { label: "1h", value: 1 },
  ];

  const [year, setYear] = useState<number>(getCurrentYear());
  const [locale, setLocale] = useState<boolean>(false);
  const [isNeedRatio, setIsNeedRatio] = useState<boolean>(false);
  const [ratio, setRatio] = useState<string>("1.0");
  const [outputSelectedValue, setOutputSelectedValue] = useState<
    ISelectFullValue | string
  >(outputValues[1]);
  const [isSum, setSum] = useState<boolean>(false);

  const [city, setCity] = useState<ISelectFullValue>({
    label: !locale ? t("all_switzerland") : t("all_germany"),
    value: 0,
  });

  const handleYearChange = useCallback((v: number) => {
    setYear(v);
  }, []);

  const sw_items = useMemo<CityOption[]>(
    () =>
      Object.keys(switzerland_cities).map((k, i) => ({
        label: t(k),
        value: i,
      })),
    [t]
  );

  const de_items = useMemo<CityOption[]>(
    () =>
      Object.keys(german_cities).map((k, i) => ({
        label: t(k),
        value: i,
      })),
    [t]
  );

  const items = useMemo<CityOption[]>(() => {
    return !locale ? sw_items : de_items;
  }, [de_items, locale, sw_items]);

  const selectSwLocale = useCallback(() => {
    setLocale(false);
    setCity({
      label: locale ? t("all_switzerland") : t("all_germany"),
      value: 0,
    });
  }, [locale, t]);

  const selectGeLocale = useCallback(() => {
    setLocale(true);
    setCity({
      label: locale ? t("all_switzerland") : t("all_germany"),
      value: 0,
    });
  }, [locale, t]);

  const handleCityChange = useCallback((v: ISelectFullValue | string) => {
    setCity(v as ISelectFullValue);
  }, []);

  const toggleRatioChange = useCallback(() => {
    setIsNeedRatio((prev) => {
      prev && setRatio("1.0");

      return !prev;
    });
  }, []);

  const handleRatioChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setRatio(e.target.value);
  }, []);

  const handleOutputChange = useCallback((value: ISelectFullValue | string) => {
    setOutputSelectedValue(value);
  }, []);

  const toggleSum = useCallback(() => {
    setSum((prev) => {
      dispatch(setSumData(!prev));
      return !prev;
    });
  }, [dispatch]);

  const handleCalculateClick = useCallback(() => {
    if (uploadedFiles?.length) {
      dispatch(setFiles([]));
      dispatch(setErrorText(null));

      const outputType =
        (outputSelectedValue as ISelectFullValue)?.label === "1h"
          ? "hour"
          : "quarter";

      if (!isSum) {
        uploadedFiles.forEach((it, ind, arr) => {
          const data: IProcessingRollOutRequest = {
            file_to_processing: [it.id],
            year: Number(year) || getCurrentYear(),
            country: !locale ? "CH" : "DE",
            scale_ratio: Number(ratio),
            sum_check: false,
            consumption: "",
            city: Number(city?.value),
            index: ind,
            length: arr.length,
            recalculate: !!graphFiles.length,
            output_type: outputType as OutputType,
          };
          dispatch(processingFile(data));
        });
      } else {
        const data: IProcessingRollOutRequest = {
          file_to_processing: uploadedFiles.map((f) => f.id),
          year: Number(year) || getCurrentYear(),
          country: !locale ? "CH" : "DE",
          scale_ratio: Number(ratio),
          sum_check: isSum,
          consumption: "",
          city: Number(city?.value),
          index: 0,
          length: 1,
          recalculate: !!graphFiles.length,
          output_type: outputType as OutputType,
        };
        dispatch(processingFile(data));
      }
    }
  }, [
    city?.value,
    dispatch,
    graphFiles.length,
    isSum,
    locale,
    ratio,
    uploadedFiles,
    year,
    outputSelectedValue,
  ]);

  return (
    <div className={style.root}>
      <div className={style.title}>{t("roll_out_options_title")}</div>
      <Toggler
        type="year"
        value={year}
        onChange={handleYearChange}
        placeholder={t("roll_out_options_year")}
        disabled={isUploadFileLoading}
      />
      <div className={style.locationContainer}>
        <Radio
          selected={!locale}
          onChange={selectSwLocale}
          label={t("switzerland_label")}
          disabled={isUploadFileLoading}
        />
        <Radio
          selected={locale}
          onChange={selectGeLocale}
          label={t("germany_label")}
          disabled={isUploadFileLoading}
        />
      </div>
      <div className={style.dropdown}>
        <DropdownSelect
          items={items}
          isAllValueNeed
          value={city}
          placeholder={!locale ? "Canton" : "State"}
          onChange={handleCityChange}
          disabled={isUploadFileLoading}
        />
      </div>
      <div className={style.ratio}>
        <Checkbox
          checked={isNeedRatio}
          onChange={toggleRatioChange}
          label={t("scale_ratio")}
          secondaryStyle
        />
        <Input
          className={style.ratioInput}
          value={ratio}
          disabled={!isNeedRatio}
          onChange={handleRatioChange}
        />
      </div>
      <div className={style.sum}>
        <Checkbox
          checked={isSum}
          label={t("roll_out_sum_option")}
          onChange={toggleSum}
          secondaryStyle
        />
      </div>
      <div className={style.outputProfile}>
        <DropdownSelect
          items={outputValues}
          isAllValueNeed
          value={outputSelectedValue}
          placeholder={t("output_profile")}
          onChange={handleOutputChange}
        />
      </div>
      <button
        className={style.btn}
        onClick={handleCalculateClick}
        disabled={
          isUploadFileLoading || isLoadingChart || isProcessingFileLoading
        }
      >
        {t("calculate_btn")}
      </button>
    </div>
  );
};
