const workercode = () => {
  // eslint-disable-next-line no-restricted-globals
  self.onmessage = function (e) {
    // self.importScripts("./testModule"); // eslint-disable-line no-restricted-globals
    // eslint-disable-line no-restricted-globals
    console.log("Message received from main script");
    let additionalData = {};
    if (!e.data?.additionalData?.length) {
      const result = e.data.date?.map((it, i) => {
        const r = [new Date(it), e.data?.value[i], 0];
        additionalData[it.toString()] = r;
        return r;
      });

      let workerResult = {
        result,
        additionalData,
      };

      console.info("Posting message back to main script - 1");
      self.postMessage(workerResult); // eslint-disable-line no-restricted-globals
    } else {
      const dates = Object.keys(e.data.date);

      const arrLength = dates.length;
      e.data.additionalData.forEach((element, ind) => {
        if (element[0] in e.data.date) {
          const index = dates.findIndex((it) => it === element[0]);

          const nextI = ind <= arrLength - 1 ? ind + 1 : ind;
          const nextInd = dates.findIndex(
            (it) => it === e.data.additionalData[nextI]?.[0]
          );

          e.data.date[element[0]][2] = element[1];
          if (nextInd !== -1) {
            for (let i = index - 1; i < dates.length - 1; i++) {
              if (dates[i] in e.data.date) {
                e.data.date[dates[i]][2] = element[1];
              }
            }
          }
        }
      });

      let workerResult = {
        result: Object.values(e.data.date),
      };

      console.info("Posting message back to main script - 2");

      // eslint-disable-next-line no-restricted-globals
      self.postMessage(workerResult);
    }
  };
};

let code = workercode.toString();
code = code.substring(code.indexOf("{") + 1, code.lastIndexOf("}"));

const blob = new Blob([code], { type: "application/javascript" });
const worker_script = URL.createObjectURL(blob);

export default worker_script;
