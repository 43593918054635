import cx from "classnames";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { Dropdown } from "components/dropdown";

import { ReactComponent as ArrowIcon } from "assets/svg/ep_arrow-right-bold.svg";

import style from "./longTermPricesYearPicker.module.scss";

export type IProps = {
  years?: number[];
  selectedYear: number | string;
  onChange: (year: number) => () => void;
  onFetchSummary: () => void;
  isSummarySelected: boolean;
};

export const LongTermPricesYearPicker = ({
  years,
  selectedYear,
  onChange,
  onFetchSummary,
  isSummarySelected,
}: IProps) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const handleClickContainer = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    const target = event.target as HTMLDivElement;
    const isYearClicked = target.classList.contains(style.yearItem);
    setIsOpen(!isYearClicked);
  };

  const SummaryCX = cx(style.yearItem, {
    [style.yearItem__selected]: isSummarySelected,
  });

  const dropdownContent = useMemo(() => {
    return (
      <div className={style.container} onClick={handleClickContainer}>
        <div className={SummaryCX} onClick={onFetchSummary}>
          {t("summary")}
        </div>
        {years?.map((year) => (
          <div
            key={year}
            className={cx(style.yearItem, {
              [style.yearItem__selected]: year === selectedYear,
            })}
            onClick={onChange(year)}
          >
            {year}
          </div>
        ))}
      </div>
    );
  }, [years, onChange, selectedYear, t, onFetchSummary, SummaryCX]);

  const onVisibleChange = (value: boolean) => {
    selectedYear && setIsOpen(value);
  };

  const ArrowCX = cx(style.arrow, {
    [style.open]: isOpen,
  });

  return (
    <Dropdown
      overlay={dropdownContent}
      onVisibleChange={onVisibleChange}
      visible={isOpen}
    >
      <button className={style.btn}>
        <span>
          {isSummarySelected ? t(selectedYear as string) : selectedYear}
        </span>
        <ArrowIcon className={ArrowCX} />
      </button>
    </Dropdown>
  );
};
