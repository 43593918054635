import { Avatar } from "components/avatar";
import { AvatarDropdownItem } from "components/avatarDropdownItem";
import { Dropdown } from "components/dropdown";
import { memo, useMemo } from "react";
import { headerNavItems } from "utils";
import style from "./avatarDropdown.module.scss";

export const AvatarDropdown = memo(() => {
  const dropdownContent = useMemo(() => {
    return (
      <div className={style.container}>
        <div className={style.wrapper}>
          <div className={style.angle} />
          {headerNavItems.map((hNavItem) => {
            return <AvatarDropdownItem item={hNavItem} key={hNavItem?.label} />;
          })}
        </div>
      </div>
    );
  }, []);

  return (
    <Dropdown overlay={dropdownContent}>
      <button className={style.btn}>
        <Avatar />
      </button>
    </Dropdown>
  );
});
