import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { changePassword, createPassword } from "./api";
import { CHANGE_PASSWORD, CREATE_PASSWORD } from "./constants";

export interface ChangePasswordState {
  isLoading: boolean;
  isError: boolean;
  isConfirmed: boolean;
}

export interface IUserPortfolio {
  id: number;
  name: string;
  created_at: string;
  market: string[];
}

const initialState: ChangePasswordState = {
  isLoading: false,
  isError: false,
  isConfirmed: false,
};

export interface ICreatePassword {
  token: string;
  password: string;
}

export interface IChangePassword {
  password: string;
  old_password: string;
  callback: () => void;
}

export const createPasswordByToken = createAsyncThunk(
  CREATE_PASSWORD,
  async ({ token, password }: ICreatePassword) => {
    const response = await createPassword(token, password);
    return response?.data;
  }
);

export const changePass = createAsyncThunk(
  CHANGE_PASSWORD,
  async ({ password, old_password, callback }: IChangePassword) => {
    const response = await changePassword(password, old_password);
    return response?.data;
  }
);

export const changePasswordSlice = createSlice({
  name: "user",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setAuthError(state, action) {
      state.isError = action.payload;
    },
    setInitial(state) {
      state.isConfirmed = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createPasswordByToken.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createPasswordByToken.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isConfirmed = true;
      })
      .addCase(createPasswordByToken.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      })
      .addCase(changePass.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(changePass.fulfilled, (state) => {
        state.isLoading = false;
        state.isError = false;
        state.isConfirmed = true;
      })
      .addCase(changePass.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      });
  },
});

export const { setAuthError, setInitial } = changePasswordSlice.actions;

export const isLoading$ = (state: RootState) => state.changePassword.isLoading;
export const isError$ = (state: RootState) => state.changePassword.isError;
export const isConfirmed$ = (state: RootState) =>
  state.changePassword.isConfirmed;

export default changePasswordSlice.reducer;
