import Tooltip from "rc-tooltip";
import { ChangeEvent, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import style from "./portfolioContractInput.module.scss";
import cx from "classnames";
import { makeYearValidation } from "utils";

interface IProps {
  value: string;
  onChange: (value: string) => void;
  validation?: "number" | "year";
}

export const PortfolioContractInput = ({
  value,
  validation,
  onChange,
}: IProps) => {
  const { t } = useTranslation();
  const [isNotValid, setIsNotValid] = useState(false);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      if (
        (validation === "number" && isNaN(+value)) ||
        (validation === "year" && makeYearValidation(value)) ||
        (validation === "year" && +value < 2000) ||
        (validation === "year" && +value > 2100)
      ) {
        setIsNotValid(true);
      } else {
        setIsNotValid(false);
      }
      onChange(e.target.value);
    },
    [onChange, validation]
  );

  const InputCX = cx(style.input, {
    [style.errorContainer]: isNotValid,
  });

  return (
    <div className={style.root}>
      <input value={value} onChange={handleChange} className={InputCX} />
      {isNotValid && (
        <Tooltip
          // overlayStyle={{ width: 200 }}
          placement="bottom"
          trigger={["hover"]}
          overlay={
            <span style={{ width: 100 }}>
              {validation === "year"
                ? t("input_year_validation_error")
                : t("input_number_validation_error")}
            </span>
          }
        >
          <div className={style.error}>!</div>
        </Tooltip>
      )}
    </div>
  );
};
