import style from "./chartPlaceholder.module.scss";
import cx from "classnames";
import ContentLoader from "react-content-loader";

interface IProps {
  width: number;
  height: number;
  text?: string;
  isError: boolean;
  className?: string;
}

export const ChartPlaceholder = ({
  width,
  height,
  text,
  isError,
  className,
}: IProps) => {
  const RootCX = cx(style.root, {
    [style.error]: isError,
    [className ? className : ""]: className,
  });

  return (
    <div className={RootCX}>
      {text && <div className={style.text}>{text}</div>}
      {!isError && (
        <ContentLoader
          speed={1}
          width={width}
          height={height}
          viewBox={`0 0 ${width} ${height}`}
          backgroundColor="#f3f3f3"
          foregroundColor={isError ? "#fceaea" : "#ecebeb"}
        >
          <rect x="5" y="70" rx="3" ry="3" width={width} height="186" />
        </ContentLoader>
      )}
    </div>
  );
};
