import dayjs from "dayjs";
import Dygraph from "dygraphs";
import { MutableRefObject, useEffect, useMemo, useRef } from "react";

import style from "./dygraphPortfolioChart.module.scss";

export type DygraphPortfolioChartPropsType = {
  fileName?: string;
  data: (number | Date)[][] | null;
  options: Record<any, any>;
  setCurrentGraph: (graph: Dygraph) => void;
};

export const DygraphPortfolioChart = ({
  fileName,
  data,
  options,
  setCurrentGraph,
}: DygraphPortfolioChartPropsType) => {
  const graphRef = useRef() as MutableRefObject<HTMLDivElement | null>;

  const visibility = useMemo(
    () => (data ? data.map(() => true) : null),
    [data]
  );

  useEffect(() => {
    if (graphRef?.current && !!data?.length) {
      const graph = new Dygraph(graphRef?.current, data, {
        ...options,
        height: 180,
        visibility,
        labels: ["Date", "Point 1", "Point 2"],
        legendFormatter: (data) => {
          const formattedDate = dayjs(data.x).format("HH:mm D MM YYYY");
          const values: string = data.series
            .map((item: { label: string; y: number }, index) =>
              index === 0 ? `Power: <b>${item.y?.toFixed(0)} KW</b>` : ""
            )
            .join("");

          return `<div>
            <div><b>${formattedDate}</b></div>
            ${values}
          </div>`;
        },
      });

      const legendContainer = graphRef.current.querySelector(
        ".dygraph-legend"
      ) as HTMLElement;

      if (legendContainer) {
        legendContainer.style.width = "135px";
        legendContainer.style.padding = "5px";
      }

      const yLabel = graphRef.current.querySelector(
        ".dygraph-ylabel"
      ) as HTMLElement;

      if (yLabel) {
        yLabel.style.color = "#6E7991";
      }

      setCurrentGraph(graph);

      return () => {
        graph?.destroy();
      };
    }
  }, [data, visibility, options, setCurrentGraph]);

  return (
    <div className={style.root} id="rion-portfolio-chart">
      {graphRef && (
        <>
          <div className={style.header}>
            {fileName && <h4 className={style.header__title}>{fileName}</h4>}
          </div>
          <div ref={graphRef}></div>
        </>
      )}
    </div>
  );
};
