import cx from "classnames";
import { useTranslation } from "react-i18next";

import {
  ScenariosData,
  ScenariosDescription,
  ScenariosTypeIds,
} from "features/longtermPrices/slice";
import { Loader } from "components/loader";

import { uploadFile } from "utils";

import style from "./longTermPricesTable.module.scss";

export type IProps = {
  scenarios: ScenariosData[] | null;
  isLoading: boolean;
};

export const LongTermPricesTable = ({ scenarios, isLoading }: IProps) => {
  const { t } = useTranslation();
  const onExportFile = (url: string) => () => uploadFile(url, "");

  const RootCX = cx(style.root, {
    [style.scenariosLoading]: isLoading,
  });

  const scenariosDescriptions: Record<number, string> = {
    [ScenariosTypeIds.FIRST_TYPE]:
      ScenariosDescription.FIRST_SCENARIO_DESCRIPTION,
    [ScenariosTypeIds.SECOND_TYPE]:
      ScenariosDescription.SECOND_SCENARIO_DESCRIPTION,
    [ScenariosTypeIds.THIRD_TYPE]:
      ScenariosDescription.THIRD_SCENARIO_DESCRIPTION,
  };

  return (
    <div className={RootCX}>
      {isLoading ? (
        <div className={style.loaderContainer}>
          <Loader />
        </div>
      ) : (
        <>
          <div className={style.tableHeader}>
            <div className={style.tableHeaderColumn}>{t("description")}</div>
          </div>
          <div className={style.tableBody}>
            {scenarios?.map(({ id, type, file }) => (
              <div className={style.tableBodyRow} key={id}>
                <div className={style.tableBodyColumn}>
                  {t(scenariosDescriptions[type])}
                </div>
                <div className={style.tableBodyColumn}>
                  <button onClick={onExportFile(file)}>
                    {`${t("scenario_title")} ${type}`}
                  </button>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};
