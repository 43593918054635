import { memo, useCallback, useEffect, useState } from "react";
import style from "./auth.module.scss";
import { useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { fetchAuth, isError$, isLoading$, setAuthError } from "./authSlice";
import { ReactComponent as EyeIcon } from "assets/svg/eye.svg";
import { ReactComponent as EyeClosedIcon } from "assets/svg/eyeClosed.svg";
import { useTranslation } from "react-i18next";
import { NavLink, useNavigate } from "react-router-dom";
import cx from "classnames";

export const Auth = memo(() => {
  const isLoading = useAppSelector(isLoading$);
  const isError = useAppSelector(isError$);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [showPass, setShowPass] = useState(false);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setFocus,
  } = useForm();

  const navigateToDashboard = () => {
    navigate("/app/dashboard");
  };

  const onSubmit = async (data: any) => {
    const { email, password } = data;
    // setStorageType(rememberMe);
    await dispatch(
      fetchAuth({ email, password, callback: navigateToDashboard })
    );
  };

  const handlePassClick = useCallback(() => {
    setShowPass((prev) => !prev);
  }, []);

  useEffect(() => {
    setFocus("email");
  }, [setFocus]);

  const handleFormClick = () => {
    if (isError) dispatch(setAuthError(false));
  };

  const inputEmailCX = cx(style.input, {
    [style.error]: isError || errors.email,
  });
  const inputPasswordCX = cx(style.input, {
    [style.error]: isError || errors.password,
  });

  const formCX = cx(style.root, {
    [style.error]: isError,
  });

  const returnEmailProps = useCallback(() => {
    return register("email", {
      required: t("required_email"),
      pattern: {
        value: /\S+@\S+\.\S+/,
        message: t("email_validation_error"),
      },
    });
  }, [register, t]);

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={formCX}
      onClick={handleFormClick}
    >
      <div className={style.inputContainer}>
        <label htmlFor="email" className={style.label}>
          {t("email_label")}
        </label>
        <input
          className={inputEmailCX}
          disabled={isLoading}
          placeholder={t("sign_in_email_placeholder")}
          autoComplete={"test"}
          id="email"
          {...returnEmailProps()}
          type="text"
        />
        {errors.email && (
          <div className={style.submitError}>{errors?.email?.message}</div>
        )}
      </div>
      <div className={style.inputContainer}>
        <label htmlFor="password" className={style.label}>
          {t("password_label")}
        </label>
        <input
          className={inputPasswordCX}
          disabled={isLoading}
          id="password"
          {...register("password", {
            required: t("required_password"),
            minLength: {
              value: 8,
              message: t("password_min_length_error"),
            },
          })}
          placeholder={t("sign_in_password_placeholder")}
          type={showPass ? "text" : "password"}
        />
        <div className={style.passIconContainer} onClick={handlePassClick}>
          {showPass ? (
            <EyeClosedIcon className={style.passIconClosed} />
          ) : (
            <EyeIcon className={style.passIcon} />
          )}
        </div>
        {errors.password && (
          <div className={style.submitError}>
            {t("password_min_length_error")}
          </div>
        )}
        {isError && (
          <div className={style.submitError}>{t("sign_in_form_error")}</div>
        )}
      </div>

      <footer className={style.formFooter}>
        <NavLink to="/app/login/recover" className={style.forget}>
          {t("forget_password")}
        </NavLink>
      </footer>
      <button
        type="submit"
        className={style.btn}
        disabled={isLoading || !watch("email") || !watch("password")}
      >
        {t("sign_in_btn_text")}
      </button>
    </form>
  );
});
