import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Api } from "api";
import { RootState } from "app/store";
import axios from "axios";
import { refreshToken, setDisconnect } from "features/auth/authSlice";
import { GET_MARKET_RANGE } from "./constants";

interface MarketRangeData {
  start_date: string;
  end_date: string;
}

interface MarketRangeState {
  data: MarketRangeData | null;
  isLoading: boolean;
  isError: boolean;
}

const initialState: MarketRangeState = {
  data: null,
  isLoading: false,
  isError: false,
};

export const fetchMarketRange = createAsyncThunk(
  GET_MARKET_RANGE,
  async (_, api) => {
    try {
      const res = await axios.get(Api.GetMarketRange);

      if (!res) {
        throw new Error("Error");
      }

      return res?.data || null;
    } catch (error: any) {
      api.dispatch(setMarketRangeError(true));
      if (error?.request?.status === 403) {
        api.dispatch(setDisconnect(true));
      } else if (error?.request?.status === 401) {
        api.dispatch(
          refreshToken({ callback: fetchMarketRange, parameter: null })
        );
      }
      return api.rejectWithValue("No Market Range found");
    }
  }
);

export const marketRangeSlice = createSlice({
  name: "marketRange",
  initialState,
  reducers: {
    setMarketRangeError(state, action) {
      state.isError = action.payload;
    },
    initMarketRange(state) {
      state.isError = false;
      state.isLoading = false;
      state.data = null;
    },
    setLoading(state, action) {
      state.isLoading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMarketRange.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchMarketRange.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.isError = false;
        state.data = payload || null;
      })
      .addCase(fetchMarketRange.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      });
  },
});

export const { setMarketRangeError, initMarketRange, setLoading } =
  marketRangeSlice.actions;

export const isLoading$ = (state: RootState) => state.marketRange.isLoading;
export const isError$ = (state: RootState) => state.marketRange.isError;
export const marketStartDate$ = (state: RootState) =>
  state.marketRange.data?.start_date;
export const marketEndDate$ = (state: RootState) =>
  state.marketRange.data?.end_date;

export default marketRangeSlice.reducer;
