import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { refreshToken, setDisconnect } from "features/auth/authSlice";
import { RootState } from "../../app/store";
import {
  GET_PORTFOLIO_CONTRACTS,
  CREATE_PORTFOLIO_CONTRACT,
  EDIT_PORTFOLIO_CONTRACT,
  DELETE_PORTFOLIO_CONTRACT,
} from "./constants";
import { Api } from "api";
import axios from "axios";

export interface PortfolioContractsState {
  isLoading: boolean;
  isError: boolean;
  data: IPortfolioContractsData[] | null;
}

export enum PortfolioContractDeal {
  BUY = "Buy",
  SELL = "Sell",
}

export enum PortfolioContractType {
  BASE = "Base",
  PEAK = "Peak",
}

export enum PortfolioContractCurrency {
  EUR = "EUR",
  CHF = "CHF",
}

export interface IPortfolioContractsData {
  id: string;
  portfolio: string | null;
  date: string | null;
  deal: PortfolioContractDeal;
  year: number | null;
  type: PortfolioContractType;
  power: number | null;
  supplier: string | null;
  amount: number | null;
  currency: PortfolioContractCurrency;
  price_CHF: string | null;
  price_EUR: string | null;
  worth_CHF: string | null;
  worth_EUR: string | null;
  period: string;
}

const initialState: PortfolioContractsState = {
  isLoading: false,
  isError: false,
  data: null,
};

interface IPortfolioContractsRequest {
  id: string;
  year?: number;
}

export const fetchPortfolioContracts = createAsyncThunk(
  GET_PORTFOLIO_CONTRACTS,
  async ({ id, year }: IPortfolioContractsRequest, api) => {
    try {
      const res = await axios.get(`${Api.GetPortfolioContracts}`, {
        params: {
          portfolio_id: id,
          year,
        },
      });

      if (!res) {
        throw new Error("Error");
      }

      return res?.data || null;
    } catch (error: any) {
      api.dispatch(setPortfolioContractsError(true));
      if (error?.request?.status === 403) {
        api.dispatch(setDisconnect(true));
      } else if (error?.request?.status === 401) {
        api.dispatch(
          refreshToken({
            callback: fetchPortfolioContracts,
            parameter: { id, year },
          })
        );
      }
    }
  }
);

export interface IPortfolioContractCreateRequest {
  portfolio: string | null;
  date: string | null;
  deal: PortfolioContractDeal;
  year: number | null;
  type: PortfolioContractType;
  power: number | null;
  supplier: string | null;
  currency: PortfolioContractCurrency;
  input_price: number | null;
  period: string;
}

export const createPortfolioContract = createAsyncThunk(
  CREATE_PORTFOLIO_CONTRACT,
  async (request: IPortfolioContractCreateRequest, api) => {
    try {
      const res = await axios.post(`${Api.GetPortfolioContracts}`, {
        ...request,
      });

      if (!res) {
        throw new Error("Error");
      }

      return res?.data || {};
    } catch (error: any) {
      api.dispatch(setPortfolioContractsError(true));
      if (error?.request?.status === 403) {
        api.dispatch(setDisconnect(true));
      } else if (error?.request?.status === 401) {
        api.dispatch(
          refreshToken({
            callback: createPortfolioContract,
            parameter: request,
          })
        );
      }
    }
  }
);
interface IIPortfolioId {
  id: string;
}

export interface IPortfolioEditType
  extends IPortfolioContractCreateRequest,
    IIPortfolioId {}

export const editPortfolioContract = createAsyncThunk(
  EDIT_PORTFOLIO_CONTRACT,
  async (request: Partial<IPortfolioEditType>, api) => {
    const { id } = request;

    delete (request as any).id;
    try {
      const res = await axios.put(`${Api.GetPortfolioContracts}${id}/`, {
        ...request,
      });

      if (!res) {
        throw new Error("Error");
      }

      return res?.data || {};
    } catch (error: any) {
      api.dispatch(setPortfolioContractsError(true));
      if (error?.request?.status === 403) {
        api.dispatch(setDisconnect(true));
      } else if (error?.request?.status === 401) {
        api.dispatch(
          refreshToken({
            callback: editPortfolioContract,
            parameter: request,
          })
        );
      }
    }
  }
);

export const deletePortfolioContract = createAsyncThunk(
  DELETE_PORTFOLIO_CONTRACT,
  async ({ id }: IPortfolioContractsRequest, api) => {
    try {
      const res = await axios.delete(`${Api.GetPortfolioContracts}${id}/`);

      if (!res) {
        throw new Error("Error");
      }

      return id;
    } catch (error: any) {
      api.dispatch(setPortfolioContractsError(true));
      if (error?.request?.status === 403) {
        api.dispatch(setDisconnect(true));
      } else if (error?.request?.status === 401) {
        api.dispatch(
          refreshToken({
            callback: deletePortfolioContract,
            parameter: { id },
          })
        );
      }
    }
  }
);

export const portfolioContractsSlice = createSlice({
  name: "portfolioContracts",
  initialState,
  reducers: {
    setPortfolioContractsError(state, action) {
      state.isError = action.payload;
    },
    initContractsData(state) {
      state.isError = false;
      state.isLoading = false;
      state.data = null;
    },
    setLoading(state, action) {
      state.isLoading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPortfolioContracts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchPortfolioContracts.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.isError = false;
        state.data = payload || null;
      })
      .addCase(fetchPortfolioContracts.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      })
      .addCase(createPortfolioContract.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createPortfolioContract.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.isError = false;
        state.data = state?.data ? [...state.data, payload] : [payload];
      })
      .addCase(createPortfolioContract.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      })
      .addCase(deletePortfolioContract.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deletePortfolioContract.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.isError = false;
        if (!state.data) return;
        state.data = state.data?.filter((contract) => contract.id !== payload);
      })
      .addCase(deletePortfolioContract.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      })
      .addCase(editPortfolioContract.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editPortfolioContract.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.isError = false;
        if (!state.data) return;
        const index = state.data.findIndex(
          (contract) => contract.id === payload.id
        );
        state.data[index] = payload;
      })
      .addCase(editPortfolioContract.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      });
  },
});
export const { setPortfolioContractsError, initContractsData, setLoading } =
  portfolioContractsSlice.actions;
export const isLoading$ = (state: RootState) =>
  state.portfolioContracts.isLoading;
export const isError$ = (state: RootState) => state.portfolioContracts.isError;
export const contracts$ = (state: RootState) => state.portfolioContracts.data;

export default portfolioContractsSlice.reducer;
