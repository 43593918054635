import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { MarketDataTable } from "components/marketDataTable";
import { MarketDataCharts } from "components/marketDataCharts";
import { MarketDataHeader } from "components/marketDataHeader";
import { PortfolioDatePicker } from "components/portfolioDatePicker";
import {
  currencyExchange$,
  fetchMarketData,
  filteredChartData$,
  isLoading$ as isMarketDataLoading$,
  MarketDataType,
  monthlyTableData$,
  quaterlyTableData$,
} from "features/marketData/slice";
import {
  fetchMarketRange,
  marketEndDate$,
  marketStartDate$,
  isLoading$ as isMarketRangeLoading$,
} from "features/marketRange/slice";
import { getCurrentDate, getFriday, isWeekDay } from "./utils";
import dayjs from "dayjs";

import style from "./marketDataContent.module.scss";

export const MarketDataContent = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const isMarketDataLoading = useAppSelector(isMarketDataLoading$);
  const currencyExchange = useAppSelector(currencyExchange$);
  const monthlyTableData = useAppSelector(monthlyTableData$);
  const quaterlyTableData = useAppSelector(quaterlyTableData$);
  const chartData = useAppSelector(filteredChartData$);
  const startDate = useAppSelector(marketStartDate$);
  const endDate = useAppSelector(marketEndDate$);
  const isMarketRangeLoading = useAppSelector(isMarketRangeLoading$);

  const isMarketRangeLoaded = useMemo(
    () => startDate && endDate && !isMarketRangeLoading,
    [startDate, endDate, isMarketRangeLoading]
  );

  const [date, setDate] = useState<string>("");

  const isDataLoaded = useMemo(
    () =>
      !isMarketDataLoading &&
      !!quaterlyTableData &&
      !!monthlyTableData &&
      !!chartData,
    [isMarketDataLoading, quaterlyTableData, monthlyTableData, chartData]
  );

  useEffect(() => {
    dispatch(fetchMarketRange());
  }, [dispatch]);

  useEffect(() => {
    if (isMarketRangeLoaded && !date) {
      const chartDate = isWeekDay(endDate as string)
        ? getFriday(endDate as string)
        : getCurrentDate(endDate as string);
      setDate(chartDate);
    }
  }, [dispatch, isMarketRangeLoaded, date, endDate]);

  useEffect(() => {
    if (date) {
      dispatch(fetchMarketData(date));
    }
  }, [dispatch, date]);

  const handleChangeDate = (value: Date) => {
    const selectedDate = dayjs(value).format("YYYY-MM-DD");
    setDate(selectedDate);
  };

  return (
    <div className={style.root}>
      <MarketDataHeader />

      <div className={style.pricesOfDateContainer}>
        <div className={style.datePickerContainer}>
          <label>{t("prices_of")}:</label>
          <PortfolioDatePicker
            value={date}
            minDate={new Date(startDate as string)}
            maxDate={new Date(endDate as string)}
            isMarketDataPage={true}
            isWeekDayDisabled={true}
            onChange={handleChangeDate}
          />
        </div>
        <label>
          EUR/CHF: {!isMarketDataLoading && currencyExchange?.toFixed(4)}
        </label>
      </div>

      {isMarketDataLoading || isDataLoaded ? (
        <>
          <MarketDataCharts data={chartData} isLoading={isMarketDataLoading} />
          <MarketDataTable
            tableData={quaterlyTableData}
            tableType={MarketDataType.QUARTERLY}
            isLoading={isMarketDataLoading}
          />
          <MarketDataTable
            tableData={monthlyTableData}
            tableType={MarketDataType.MONTHLY}
            isLoading={isMarketDataLoading}
          />
        </>
      ) : (
        <div className={style.emptyStateContainer}>
          <span>{t("no_market_data_text")}</span>
        </div>
      )}
    </div>
  );
};
