import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { useForm } from "react-hook-form";
import {
  isError$,
  isLoading$,
  recovery,
  setAuthError,
} from "features/auth/authSlice";
import style from "./recoveryForm.module.scss";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import { BackToSignIn } from "components/backToSignIn";

export const RecoveryForm = () => {
  const isLoading = useAppSelector(isLoading$);
  const isError = useAppSelector(isError$);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
    // reset,
    watch,
    setFocus,
  } = useForm();

  const onSubmit = async (data: any) => {
    const { email } = data;
    const res = await dispatch(recovery({ email }));
    return res;
    // reset();
  };

  useEffect(() => {
    setFocus("email");
  }, [setFocus]);

  const handleFormClick = () => {
    dispatch(setAuthError(false));
  };

  const FormCX = cx(style.root, {
    [style.error]: isError || !!errors.email,
  });

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={FormCX}
      onClick={handleFormClick}
    >
      <div className={style.inputContainer}>
        <input
          className={style.input}
          disabled={isLoading}
          placeholder={errors.email ? undefined : t("email_label")}
          autoComplete={"test"}
          id="email"
          {...register("email", {
            required: t("required_email"),
            pattern: {
              value: /\S+@\S+\.\S+/,
              message: t("email_validation_error"),
            },
          })}
          type="text"
        />
        {errors.email && (
          <div className={style.err}>{errors.email?.message}</div>
        )}
        {isError && <div className={style.err}>{t("recovery_pass_error")}</div>}
      </div>

      <button
        type="submit"
        className={style.btn}
        disabled={isLoading || !watch("email")?.length || errors.email}
      >
        {t("recovery_btn_text")}
      </button>
      <BackToSignIn />
    </form>
  );
};
