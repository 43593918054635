import { useAppDispatch, useAppSelector } from "app/hooks";
import { PortfolioContractsTable } from "components/portfolioContractsTable";
import {
  contracts$,
  fetchPortfolioContracts,
} from "features/portfolioContracts/slice";
import { currentProfileData$ } from "features/portfolioProfile/slice";
import { user$, UserMarket, isLoading$ } from "features/user/slice";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import style from "./overviewPortfolioContractsTable.module.scss";

export const OverviewPortfolioContractsTable = () => {
  const [searchParams] = useSearchParams();
  const [portfolio_id, setPortFolioId] = useState<string | null>(null);
  const dispatch = useAppDispatch();
  const user = useAppSelector(user$);
  const contracts = useAppSelector(contracts$);
  const isLoading = useAppSelector(isLoading$);
  const currentProfileData = useAppSelector(currentProfileData$);

  const { t } = useTranslation();

  useEffect(() => {
    if (searchParams.get("id") !== portfolio_id) {
      const id = searchParams.get("id");
      setPortFolioId(id);
    }
  }, [searchParams, portfolio_id]);

  useEffect(() => {
    if (portfolio_id && currentProfileData?.year) {
      dispatch(
        fetchPortfolioContracts({
          id: portfolio_id,
          year: currentProfileData?.year,
        })
      );
    }
  }, [currentProfileData?.year, dispatch, portfolio_id]);

  return (
    <div className={style.root}>
      <h4 className={style.title}>{t("contracts_header_title")}</h4>
      <div className={style.body}>
        <PortfolioContractsTable
          items={contracts}
          isLoading={isLoading}
          hideSomeColumns={user?.market === UserMarket.DE}
          onDelete={() => ""}
          onEdit={() => ""}
          isHideActions
        />
      </div>
    </div>
  );
};
