import { useAppSelector } from "app/hooks";
import { CorridorMultyChart } from "components/corridorChart";
import { Loader } from "components/loader";
import { ICorridorData } from "features/corridor/slice";
import { user$ } from "features/user/slice";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import worker_script from "utils/worker/corridorLines";
import style from "./corridor.module.scss";

interface IProps {
  portfolio_id: string | null;
  year?: number | null;
  isLoading?: boolean;
  data: ICorridorData | null;
}

interface ICorridorChartPoint {
  x: string;
  y: number;
}

export const Corridor = ({ portfolio_id, year, isLoading, data }: IProps) => {
  const { t } = useTranslation();
  const user = useAppSelector(user$);
  const [upLine, setUpLine] = useState<ICorridorChartPoint[]>([]);
  const [loLine, setLoLine] = useState<ICorridorChartPoint[]>([]);
  const [neutrLine, setNeutrLine] = useState<ICorridorChartPoint[]>([]);
  const [buildLine, setBuildLine] = useState<ICorridorChartPoint[]>([]);
  const [topLine, setTopLine] = useState<{ x: string; y: number }[]>([]);

  const processingLine = useCallback(
    (key1: string, key2: string, cb: (v: any) => void): any => {
      if (data) {
        const worker = new Worker(worker_script);

        worker.postMessage({ data, key1, key2 });
        worker.onmessage = (e) => {
          cb(e.data as any);
          worker.terminate();
        };
      } else {
        return [];
      }
    },
    [data]
  );

  const max = useMemo(() => {
    return Math.max.apply(Math, [
      ...upLine.map((d) => d.y),
      ...buildLine.map((d) => d.y),
    ]);
  }, [buildLine, upLine]);

  useEffect(() => {
    if (data) {
      processingLine("date", "upper_line", setUpLine);
      processingLine("date", "lower_line", setLoLine);
      processingLine("date", "neutral_line", setNeutrLine);
      processingLine("date_build_line", "value_build_line", setBuildLine);

      const filledArray = Array(data?.date?.length)
        .fill(max + 5)
        ?.map((y: number, index) => ({
          x: data.date[index],
          y,
        }));

      setTopLine(filledArray);
    }
  }, [data, max, processingLine]);

  const upperLineData = useMemo(
    () => ({
      name: t("upper_bound_corridor"),
      data: upLine,
    }),
    [upLine, t]
  );

  const lowerLineData = useMemo(
    () => ({
      name: t("lower_bound_corridor"),
      data: loLine,
    }),
    [loLine, t]
  );

  const neutralLine = useMemo(
    () => ({
      name: t("ideal_profile_corridor"),
      data: neutrLine,
    }),
    [neutrLine, t]
  );

  const topLineData = useMemo(
    () => ({
      name: "",
      data: topLine || [],
    }),
    [topLine]
  );

  const buildlLine = useMemo(
    () => ({
      name: t("your_profile_corridor"),
      data: buildLine,
    }),
    [buildLine, t]
  );

  const chartData = useMemo(
    () => [topLineData, upperLineData, neutralLine, buildlLine, lowerLineData],
    [buildlLine, lowerLineData, neutralLine, topLineData, upperLineData]
  );

  const name = useMemo(
    () =>
      user?.portfolio?.find((portfolio) => portfolio.id === portfolio_id)?.name,
    [portfolio_id, user?.portfolio]
  );

  return (
    <div className={style.root}>
      {isLoading && (
        <div className={style.loader}>
          <Loader />
        </div>
      )}
      {!isLoading && (
        <CorridorMultyChart
          dataType="%"
          chartData={chartData! as any}
          title={`${t("corridor_title_text")} ${year || ""}`}
          tooltipPrefix="%"
          filename={`corridor${name}_${year}`}
          max={max + 5}
        />
      )}
    </div>
  );
};
