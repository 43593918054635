import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { refreshToken, setDisconnect } from "features/auth/authSlice";
import { RootState } from "../../app/store";
import { GET_PORTFOLIO_PROFILES } from "./constants";
import { Api } from "api";
import {
  fetchPortfolioProfileGraph,
  initAllRollOutData,
  loadedId$,
} from "features/portfolioProfileGraph/slice";
import { getCurrentYear } from "utils";
import axios from "axios";
import { initHedgeData } from "features/hedge/slice";

export interface PortfolioProfileState {
  isLoading: boolean;
  isError: boolean;
  data: IPortfolioProfileData[] | null;
  current: IPortfolioProfileData | null;
  year: number;
}

export interface IPortfolioProfileData {
  id: number;
  year: number;
  energy?: string;
  power_max?: string;
  power_min?: string;
  base_hours?: number;
  peak_hours?: number;
  contracts_sum?: string;
  missed_profile?: boolean;
}

export const emptyProfile = {
  id: 0,
  year: getCurrentYear(),
  energy: "0",
  power_max: "0",
  power_min: "0",
  base_hours: 0,
  peak_hours: 0,
  contracts_sum: "0",
  missed_profile: true,
};

const initialState: PortfolioProfileState = {
  isLoading: false,
  isError: false,
  data: null,
  current: null,
  year: getCurrentYear(),
};

interface IPortfolioProfileRequest {
  id: string;
  year?: number;
}

export const fetchPortfolioProfile = createAsyncThunk(
  GET_PORTFOLIO_PROFILES,
  async ({ id, year }: IPortfolioProfileRequest, api) => {
    try {
      const res = await axios.get(`${Api.GetPortfolioProfile}${id}/profiles/`);

      if (!res) {
        throw new Error("Error");
      }

      const startYear = getCurrentYear();
      const emptyYearList = Array(4)
        .fill(1)
        .map((_, i) => i + startYear);

      const emptyResponse = emptyYearList.map((year, id) => ({
        id,
        year,
        missed_profile: true,
      }));

      const response = (res?.data as IPortfolioProfileData[])?.sort(
        (a, b) => a.year - b.year
      );

      if (!res?.data?.length) {
        api.dispatch(setCurrentProfileData(null));
        api.dispatch(initAllRollOutData());
        api.dispatch(initHedgeData());

        return emptyResponse;
      }

      if (response?.length && !year) {
        const id = response[0].id;
        const loadedId = loadedId$(api.getState() as RootState);
        if (id !== loadedId && id)
          setTimeout(
            () => api.dispatch(fetchPortfolioProfileGraph({ id })),
            1000
          );
      } else {
        if (year) {
          const currentProfile = response.find(
            (pf: IPortfolioProfileData) => pf.year === year
          );
          if (currentProfile) {
            setTimeout(() => {
              api.dispatch(
                fetchPortfolioProfileGraph({ id: currentProfile?.id })
              );

              api.dispatch(setCurrentProfileData(currentProfile));
            }, 1000);
          }
        }
      }

      return response || null;
    } catch (error: any) {
      api.dispatch(setPortfolioProfileError(true));
      if (error?.request?.status === 403) {
        api.dispatch(setDisconnect(true));
      } else if (error?.request?.status === 401) {
        api.dispatch(
          refreshToken({
            callback: fetchPortfolioProfile,
            parameter: { id },
          })
        );
      }
    }
  }
);

export const portfolioProfileSlice = createSlice({
  name: "portfolioProfile",
  initialState,
  reducers: {
    setPortfolioProfileError(state, action) {
      state.isError = action.payload;
    },
    initAllPortfolioProfileData(state) {
      state.isError = false;
      state.isLoading = false;
      state.data = null;
      state.current = null;
      state.year = getCurrentYear();
    },
    setLoading(state, action) {
      state.isLoading = action.payload;
    },
    setCurrentProfileData(state, action) {
      state.current = action.payload;
    },
    setYear(state, action) {
      state.year = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPortfolioProfile.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchPortfolioProfile.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.isError = false;
        state.data = payload || null;
        if (payload?.length) {
          state.current = payload[0];
        }
      })
      .addCase(fetchPortfolioProfile.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      });
  },
});
export const {
  setPortfolioProfileError,
  initAllPortfolioProfileData,
  setLoading,
  setCurrentProfileData,
  setYear,
} = portfolioProfileSlice.actions;
export const isLoading$ = (state: RootState) =>
  state.portfolioProfile.isLoading;
export const isError$ = (state: RootState) => state.portfolioProfile.isError;
export const profileData$ = (state: RootState) => state.portfolioProfile.data;
export const year$ = (state: RootState) => state.portfolioProfile.year;
export const currentProfileData$ = (state: RootState) =>
  state.portfolioProfile.current;

export default portfolioProfileSlice.reducer;
