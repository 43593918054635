export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

interface ProductsProps {
  products: string;
}

export const getProductsPeriod = ({ products }: ProductsProps) => {
  if (products?.includes("Quartal")) {
    const res = products.split(" ");
    return "Quarter" + res[1]?.charAt(0);
  } else if (months?.includes(products?.split(" ")[0])) {
    return products?.split(" ")[0];
  } else {
    return "Year";
  }
};
