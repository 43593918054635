import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { Api } from "api";
import {
  getAccessToken,
  getRefreshToken,
  setSideMenuPosition,
  setTokenData,
} from "interceptors";
import { RootState } from "../../app/store";
import { ILoginPromise, logout, recoveryLogin } from "./authAPI";
import { LOGIN, LOGOUT, RECOVERY, REFRESH_TOKEN } from "./constants";

export interface AuthState {
  accessToken: string | null;
  refreshToken: string | null;
  isLoading: boolean;
  isError: boolean;
  needToDisconnect: boolean;
  isRecoveryConfirmed: boolean;
}

const initialState: AuthState = {
  accessToken: getAccessToken(),
  refreshToken: getRefreshToken(),
  isLoading: false,
  isError: false,
  needToDisconnect: false,
  isRecoveryConfirmed: false,
};

export const fetchAuth = createAsyncThunk(
  LOGIN,
  async (data: ILoginPromise, api) => {
    try {
      const { email, password } = data;
      const res = await axios.post(Api.Login, {
        email: email.toLowerCase(),
        password,
      });

      if (!res) {
        throw new Error("Error");
      }
      data?.callback && data?.callback();
      return res.data;
    } catch (error: any) {
      api.dispatch(setAuthError(true));

      if (error?.request?.status === 403) {
        api.dispatch(setDisconnect(true));
      }
    }
  }
);

export const recovery = createAsyncThunk(
  RECOVERY,
  async ({ email }: { email: string }) => {
    const response = await recoveryLogin({ email: email.toLowerCase() });
    return response?.data;
  }
);

export const userLogout = createAsyncThunk(
  LOGOUT,
  async (callback: () => void, api) => {
    const response = await logout().catch((err) => err);
    if (response?.response?.status === 403) {
      api.dispatch(setDisconnect(true));
    }
    callback();
    window.location.reload();
    return response?.data;
  }
);

interface IRefreshProps {
  callback: (a: any, api: any) => void;
  parameter: any;
}

export const refreshToken = createAsyncThunk(
  REFRESH_TOKEN,
  async (data: IRefreshProps, api) => {
    const refresh = refreshToken$(api.getState() as RootState);
    if (!refresh || refresh === "undefined") {
      api.dispatch(appLogout());
    } else {
      try {
        const res = await axios.post(Api.RefreshToken, {
          refresh,
        });

        if (!res) {
          throw new Error("Error");
        }

        api.dispatch(data.callback(data.parameter, api) as any);

        return res.data;
      } catch (error: any) {
        api.dispatch(setAuthError(true));

        if (error?.request?.status === 403) {
          api.dispatch(setDisconnect(true));
        }

        if (error?.request?.status === 401) {
          api.dispatch(appLogout());
        } else {
          api.dispatch(data.callback(data.parameter, api) as any);
        }
      }
    }
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setAuthError(state, action) {
      state.isError = action.payload;
      state.isLoading = false;
    },
    setDisconnect(state, action) {
      state.needToDisconnect = action.payload;
    },
    appLogout(state) {
      state.accessToken = "";
      state.refreshToken = "";
      state.needToDisconnect = false;
      state.isRecoveryConfirmed = false;
      state.isLoading = false;
      state.isError = false;
      const data = {
        refresh: "",
        access: "",
      };
      setTokenData(data);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAuth.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchAuth.fulfilled, (state, action) => {
        state.isLoading = false;
        state.accessToken = action.payload?.access;
        state.refreshToken = action.payload?.refresh;
        setTokenData(action.payload);
        setSideMenuPosition(true);
      })
      .addCase(fetchAuth.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      })
      .addCase(recovery.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(recovery.fulfilled, (state) => {
        state.isLoading = false;
        state.isRecoveryConfirmed = true;
      })
      .addCase(recovery.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      })
      .addCase(userLogout.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(userLogout.fulfilled, (state) => {
        state.isLoading = false;

        const data = {
          refresh: "",
          access: "",
        };
        setTokenData(data);
        state.accessToken = "";
        state.refreshToken = "";
      })
      .addCase(userLogout.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(refreshToken.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(refreshToken.fulfilled, (state, action) => {
        const { refresh, access } = action.payload;

        const data = {
          refresh: state.refreshToken!,
          access,
        };
        setTokenData(data);
        state.isLoading = false;
        state.accessToken = access;
        state.refreshToken = refresh;
      })
      .addCase(refreshToken.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      });
  },
});

export const handleAuthAnswer = (api: any, error: any, callback: any) => {
  api.dispatch(setAuthError(true));

  if (error?.request?.status === 403) {
    api.dispatch(setDisconnect(true));
  }

  if (error?.request?.status === 401) {
    api.dispatch(appLogout());
  } else {
    api.dispatch(callback() as any);
  }
};

export const { setAuthError, setDisconnect, appLogout } = authSlice.actions;

export const accessToken$ = (state: RootState) => state.auth.accessToken;
export const refreshToken$ = (state: RootState) => state.auth.refreshToken;
export const isLoading$ = (state: RootState) => state.auth.isLoading;
export const isError$ = (state: RootState) => state.auth.isError;
export const needToDisconnect$ = (state: RootState) =>
  state.auth.needToDisconnect;

export const isRecoveryConfirmed$ = (state: RootState) =>
  state.auth.isRecoveryConfirmed;

export default authSlice.reducer;
