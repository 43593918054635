import { TableWithOneRow } from "components/tableWithOneRow";
import style from "./hedgeTable.module.scss";
import cx from "classnames";

interface IProps {
  title: string;
  name: string;
  leftTableHeaders: string[];
  leftTableData: string[] | number[];
  rightTableHeaders: string[];
  rightTableData: string[] | number[];
  isLoading?: boolean;
  isMonthly?: boolean;
}

export const HedgeTable = ({
  title,
  leftTableHeaders,
  leftTableData,
  rightTableData,
  rightTableHeaders,
  isLoading,
  isMonthly,
}: IProps) => {
  const TablesCX = cx(style.tables, {
    [style.isRow]: isMonthly,
  });

  const ContentCX = cx(style.content, {
    [style.isRow]: isMonthly,
  });

  return (
    <div className={style.root}>
      <h4 className={style.title}>{title}</h4>
      <div className={TablesCX}>
        <div className={ContentCX}>
          <TableWithOneRow
            headers={leftTableHeaders}
            data={leftTableData}
            firstHeader={"Base"}
            firstData={"MW"}
            isLoading={isLoading}
            rootClassName={style.table}
          />
        </div>
        <div className={ContentCX}>
          <TableWithOneRow
            headers={rightTableHeaders}
            data={rightTableData}
            firstHeader={"Peak"}
            firstData={"MW"}
            isLoading={isLoading}
            rootClassName={style.table}
          />
        </div>
      </div>
    </div>
  );
};
