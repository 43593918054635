import { useAppSelector } from "app/hooks";
import { NavigationItem } from "components/navigationItem";
import { IUserPortfolio, user$ } from "features/user/slice";
import React, { useCallback, useEffect, useState } from "react";
import {
  INavItem,
  getIsEnabledNavItem,
  initialNavItemsState,
  portfolioChildren,
  usePrevious,
} from "utils";
import style from "./navigation.module.scss";

interface IProps {
  isOpenNavigation: boolean;
}

export const Navigation = ({ isOpenNavigation }: IProps) => {
  const user = useAppSelector(user$);
  const [navigation, setNavigation] = useState(initialNavItemsState);
  const prevUser = usePrevious(user);

  const getNavigationWithProfile = useCallback(
    () =>
      user.portfolio.map(({ id, name }: IUserPortfolio) => {
        return {
          id,
          label: name,
          isEnabled: true,
          link: `/app/portfolio/overview/?id=${id}`,
          children: portfolioChildren.map((port) => ({
            ...port,
            id,
            link: `${port.link}=${id}`,
          })),
        };
      }),
    [user.portfolio]
  );

  const getNavigationWithAccess = useCallback(
    () =>
      navigation.map((navItem) => ({
        ...navItem,
        isEnabled: getIsEnabledNavItem(navItem, user),
      })),
    [navigation, user]
  );

  useEffect(() => {
    const navigationWithAccess = getNavigationWithAccess();
    const updatedNavigation: INavItem[] = [...navigationWithAccess];

    if (
      user?.portfolio?.length &&
      prevUser?.portfolio?.length !== user?.portfolio?.length
    ) {
      const navigationWithPortfolios: INavItem[] = getNavigationWithProfile();
      updatedNavigation.splice(2, 0, ...navigationWithPortfolios);
    }
    setNavigation(updatedNavigation);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    user?.roll_out_access,
    user?.scenario_access,
    user?.portfolio,
    prevUser?.portfolio,
  ]);

  return (
    <nav className={style.root}>
      {navigation.map((item) => (
        <React.Fragment key={item.id}>
          {item.isEnabled && (
            <NavigationItem
              key={item.id}
              {...item}
              isOpenNavigation={isOpenNavigation}
            />
          )}
        </React.Fragment>
      ))}
    </nav>
  );
};
