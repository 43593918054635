import html2canvas from "html2canvas";

import { memo, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "app/hooks";
import { currentProfileData$ } from "features/portfolioProfile/slice";
import {
  loadedProfileId$,
  loadedId$,
  isLoadingPlotting$,
} from "features/portfolioProfileGraph/slice";
import { isLoading$ as isLoadingProfileContracts$ } from "features/portfolioProfileContracts/slice";

import { ReactComponent as SaveIcon } from "assets/svg/save.svg";
import { ReactComponent as RefreshIcon } from "assets/svg/ic_round-refresh.svg";

import style from "./graphInternal.module.scss";

interface IProps {
  isLoading?: boolean;
  fileName?: string;
  isPlotting?: boolean;
  isShowPlottingChart?: boolean;
  onPlottingClick?: () => void;
  onRefreshGraph: () => void;
}

export const AdditionalGraph = memo(
  ({
    isLoading,
    fileName,
    isPlotting,
    isShowPlottingChart,
    onPlottingClick,
    onRefreshGraph,
  }: IProps) => {
    const { t } = useTranslation();
    const curentProfileData = useAppSelector(currentProfileData$);
    const loadedId = useAppSelector(loadedId$);
    const loadedProfileId = useAppSelector(loadedProfileId$);
    const isLoadingPlotting = useAppSelector(isLoadingPlotting$);
    const isLoadingProfileContracts = useAppSelector(
      isLoadingProfileContracts$
    );
    const [selectedYear, setSelectedYear] = useState<number | null>(null);

    const handleSaveGraph = () => {
      setTimeout(() => {
        const graphContainer = document.querySelector(
          "#rion-portfolio-chart"
        ) as HTMLElement;
        html2canvas(graphContainer).then(function (canvas) {
          canvas.style.display = "none";
          document.body.appendChild(canvas);
          const image = canvas
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");
          const a = document.createElement("a");
          a.setAttribute("download", `${fileName}.png`);
          a.setAttribute("href", image);
          a.click();
        });
      }, 1000);
    };

    const isNotLoading = useMemo(
      () => !isLoading && !isLoadingPlotting && !isLoadingProfileContracts,
      [isLoading, isLoadingPlotting, isLoadingProfileContracts]
    );

    const isIdEqual = useMemo(
      () => loadedProfileId === loadedId && loadedId !== null,
      [loadedId, loadedProfileId]
    );

    const plottingBtnContent = useMemo(() => {
      return isShowPlottingChart &&
        isNotLoading &&
        selectedYear === curentProfileData?.year &&
        isIdEqual
        ? t("analyze_unplot_btn")
        : t("plotting_portfolio_btn");
    }, [
      curentProfileData?.year,
      isIdEqual,
      isShowPlottingChart,
      isNotLoading,
      selectedYear,
      t,
    ]);

    const handlePlotting = useCallback(() => {
      setSelectedYear(curentProfileData?.year!);
      !!onPlottingClick && onPlottingClick();
    }, [curentProfileData?.year, onPlottingClick]);

    return (
      <>
        <button
          onClick={handleSaveGraph}
          className={style.downloadBtn}
          data-html2canvas-ignore
        >
          <SaveIcon className={style.icon} />
        </button>

        <button
          onClick={onRefreshGraph}
          className={style.refreshBtn}
          data-html2canvas-ignore
        >
          <RefreshIcon className={style.icon} />
        </button>

        {!!isPlotting && (
          <button
            className={style.plottingBtn}
            onClick={handlePlotting}
            disabled={!isNotLoading}
          >
            {plottingBtnContent}
          </button>
        )}
      </>
    );
  }
);
