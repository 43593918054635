// A mock function to mimic making an async request for data
import { Api } from "api";
import axios from "axios";

export const createPassword = async (token: string, password: string) => {
  delete axios.defaults.headers.common["Authorization"];

  const res = await axios({
    url: `${Api.CreatePassword}`,
    method: "POST",
    data: {
      token,
      password,
    },
  });

  return res;
};

export const changePassword = async (
  password_new: string,
  password_old: string
) => {
  const res = await axios.post(`${Api.ChangePassword}`, {
    password_new,
    password_old,
  });
  return res;
};
