import { useAppDispatch, useAppSelector } from "app/hooks";
import { DropArea } from "components/dropArea";
import { PortfolioProfileOptions } from "components/portfolioProfileOptions";
import { UploadFile } from "components/uploadFile";
import {
  emptyProfile,
  fetchPortfolioProfile,
  profileData$,
  setCurrentProfileData,
  setYear,
  year$,
} from "features/portfolioProfile/slice";
import {
  fetchPortfolioProfileGraph,
  initAllRollOutData,
  setChartData,
  setPortfolioGraphError,
} from "features/portfolioProfileGraph/slice";
import {
  isLoading$,
  updatePortfolioProfileFile,
  uploadPortfolioProfileFile,
} from "features/portfolioProfileUpload/slice";
import { memo, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { getCurrentYear, showDialogue } from "utils";
import style from "./portfolioProfileContent.module.scss";
import { isLoading$ as isLoadingGraph$ } from "features/portfolioProfileGraph/slice";
import { PortfolioWithChartData } from "containers/portfolioWithChartData";

export const PortfolioProfileContent = memo(() => {
  const [searchParams] = useSearchParams();
  const [portfolio_id, setPortFolioId] = useState<string | null>(null);
  const year = useAppSelector(year$);
  const profileData = useAppSelector(profileData$);
  const [files, setFiles] = useState<File[]>([]);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(isLoading$);
  const isLoadingGraph = useAppSelector(isLoadingGraph$);

  useEffect(() => {
    if (portfolio_id) dispatch(fetchPortfolioProfile({ id: portfolio_id }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portfolio_id]);

  useEffect(() => {
    if (searchParams.get("id") !== portfolio_id) {
      const id = searchParams.get("id");
      setPortFolioId(id);
    }
  }, [searchParams, portfolio_id]);

  useEffect(() => {
    return () => {
      dispatch(setYear(getCurrentYear()));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleYearChange = useCallback(
    (newYear: number) => {
      dispatch(initAllRollOutData());
      const targetYear = getCurrentYear();
      const maxTargetYear = targetYear + 3;
      if (newYear > maxTargetYear || newYear < targetYear) return;
      dispatch(setYear(newYear));
      setFiles([]);
      dispatch(setChartData(null));
      dispatch(setPortfolioGraphError(false));
      const newCurrentProfileData = profileData?.find(
        (pd) => pd?.year === newYear
      );

      if (newCurrentProfileData?.id) {
        dispatch(setCurrentProfileData(newCurrentProfileData));
        dispatch(
          fetchPortfolioProfileGraph({
            id: newCurrentProfileData?.id,
          })
        );
      } else {
        dispatch(setCurrentProfileData({ ...emptyProfile, year: newYear }));
      }
    },

    [dispatch, profileData]
  );

  const handleDropFile = useCallback((newFiles: File[]) => {
    setFiles(newFiles);
  }, []);

  const handleClearFiles = useCallback(() => {
    setFiles([]);
  }, []);

  const handleSaveClick = useCallback(() => {
    const profileByYear = profileData?.find((item) => item.year === year);

    if (profileByYear?.id && !profileByYear?.missed_profile) {
      showDialogue({
        title: t("portfolio_profile_override_title"),
        text: t("portfolio_profile_override_text", { year }),
        confirmButtonText: t("yes"),
        cancelButtonText: t("no"),
        confirmButtonColor: "#3F1FED",
      }).then((result) => {
        if (result?.isConfirmed) {
          dispatch(
            updatePortfolioProfileFile({
              file: files?.length ? files[0] : null,
              year,
              id: profileByYear?.id,
            })
          );
        }
      });
    } else {
      if (files?.length && portfolio_id) {
        dispatch(
          uploadPortfolioProfileFile({ file: files[0], year, portfolio_id })
        );
      }
    }
  }, [dispatch, files, portfolio_id, profileData, t, year]);

  return (
    <div className={style.root}>
      {!!files?.length && (
        <button className={style.btn} onClick={handleSaveClick}>
          {t("save")}
        </button>
      )}
      <div className={style.topLine}>
        <PortfolioProfileOptions
          year={year}
          min={getCurrentYear()}
          max={getCurrentYear() + 3}
          setYear={handleYearChange}
          text={t("portfolio_profile_content_text")}
          title={t("roll_out_options_title")}
          disabled={isLoading || isLoadingGraph}
        />
        <DropArea
          files={files}
          handleDrop={handleDropFile}
          onClear={handleClearFiles}
          isLoading={isLoading}
          multiple={false}
          className={style.dropArea}
          dropzoneClassName={style.dropzone}
        >
          <>
            {files?.map((file, ind) => {
              return <UploadFile item={file} key={ind} />;
            })}
          </>
        </DropArea>
      </div>
      <PortfolioWithChartData />
    </div>
  );
});
