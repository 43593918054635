import { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import cx from "classnames";
import { ReactComponent as UploadIcon } from "assets/svg/cloud-upload-outlined.svg";
import style from "./dropArea.module.scss";
import { useTranslation } from "react-i18next";
import { Loader } from "components/loader";
import { ReactComponent as RefreshIcon } from "assets/svg/ic_round-refresh.svg";

interface IProps {
  files: File[];
  handleDrop: (files: File[]) => void;
  onClear: () => void;
  isLoading?: boolean;
  children?: React.ReactNode;
  multiple?: boolean;
  className: string;
  dropzoneClassName?: string;
}
export const DropArea = ({
  files,
  handleDrop,
  onClear,
  isLoading,
  children,
  multiple,
  className,
  dropzoneClassName,
}: IProps) => {
  const { t } = useTranslation();

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      // Do something with the files
      if (acceptedFiles?.length) {
        handleDrop(acceptedFiles);
      }
    },
    [handleDrop]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple,
    accept: {
      "application/xlsx": [".xlsx", ".xls", ".csv"],
      "application/csv": [".csv"],
    },
  });

  const DropzoneCX = cx(style.dropzone, {
    [style.active]: isDragActive,
    [style.files]: files.length && !isLoading,
    [dropzoneClassName ? dropzoneClassName : ""]: dropzoneClassName,
  });

  const handleClearClick = useCallback(
    (e: any) => {
      e.stopPropagation();
      onClear();
    },
    [onClear]
  );

  const RootCX = cx(style.root, {
    [className]: className,
  });

  return (
    <div className={RootCX}>
      <div {...getRootProps()} className={DropzoneCX}>
        {!files?.length && !isLoading && (
          <>
            <UploadIcon className={style.icon} />
            <div className={style.title}>
              {t("profile_roll_out_drop_title")}
            </div>
            <div className={style.subtitle}>
              {t("profile_roll_out_drop_subtitle")}
            </div>
          </>
        )}
        <div className={style.uploadFiles}>{!isLoading && children}</div>

        {isLoading && (
          <div className={style.loader}>
            <Loader />
          </div>
        )}

        {!isLoading && files?.length ? (
          <button className={style.clearBtn} onClick={handleClearClick}>
            <RefreshIcon className={style.clearIcon} />
            <span className={style.text}>{t("clear")}</span>
          </button>
        ) : null}
        <input {...getInputProps()} />
      </div>
    </div>
  );
};
