import { useAppDispatch } from "app/hooks";
import { NavigationLogo } from "components/navigationLogo";
import { setNavId } from "features/nav/slice";
import { userLogout } from "features/auth/authSlice";
import { useTranslation } from "react-i18next";
import {
  NavLink,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import {
  INavItem,
  NavItemLabel,
  portfolioChildren,
  showDialogue,
  usePrevious,
} from "utils";
import cx from "classnames";
import style from "./navigationItem.module.scss";
import { useCallback, useEffect, useMemo, useState } from "react";
import "./style.scss";
import { NavigationChildItem } from "components/navigationChildItem";
interface IProps {
  id: number;
  label: NavItemLabel | string;
  link: string;
  children?: INavItem[];
  isOpenNavigation: boolean;
}

interface INavLinkProps {
  isSelected: boolean;
  currentNavId: string;
  id: number | string;
  link: string;
  label: NavItemLabel | string;
  onClick: () => void;
  children?: INavItem[];
  isOpenNavigation: boolean;
}

export const NavigationItemLink = ({
  isSelected,
  currentNavId,
  id,
  link,
  label,
  children,
  isOpenNavigation,
  onClick,
}: INavLinkProps) => {
  const { t } = useTranslation();

  const RootCX = cx(`sidebar-item-nav ${style.root}`, {
    [style.selected]: isSelected || String(currentNavId) === String(id),
    closed: !isOpenNavigation,
  });

  const LabelCX = cx(style.label, {
    [style.selected]: isSelected || String(currentNavId) === String(id),
    [style.longtermPrices]: label === "nav_longterm_prices",
  });

  return (
    <NavLink className={RootCX} to={link} onClick={onClick}>
      <>
        <NavigationLogo
          name={label}
          isSelected={isSelected}
          isPortfolio={children?.length}
        />
        {isOpenNavigation && <span className={LabelCX}>{t(label)}</span>}
      </>
    </NavLink>
  );
};

const childLinks = portfolioChildren.map((ch) => ch.link);

export const NavigationItem = ({
  id,
  label,
  link,
  children,
  isOpenNavigation,
}: IProps) => {
  const location = useLocation();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [currentNavId, setCurrentNavId] = useState<string | null>("empty");
  const [isExpanded, setExpanded] = useState(false);
  const prevLocation = usePrevious(location);

  useEffect(() => {
    const navId = searchParams.get("id");
    if (navId?.length) {
      setCurrentNavId(navId);
    } else {
      setCurrentNavId(null);
    }

    if (prevLocation && !childLinks.includes(prevLocation.pathname)) {
      setExpanded(true);
    }
    return () => {
      setExpanded(true);
    };
  }, [prevLocation, searchParams]);

  const navigateToSignInPage = useCallback(() => {
    navigate("/app");
  }, [navigate]);

  const handleClick = useCallback(() => {
    if (children?.length) {
      setExpanded((prev) => !prev);
    }
    dispatch(setNavId(id));
    if (link === "/app/logout") {
      showDialogue({
        title: t("logout_modal_title"),
        text: t("logout_modal_text"),
        confirmButtonText: t("yes"),
        cancelButtonText: t("no"),
        confirmButtonColor: "#3F1FED",
      }).then((result) => {
        if (result?.isConfirmed) {
          dispatch(userLogout(navigateToSignInPage));
        }
      });
    }
  }, [children?.length, dispatch, id, link, navigateToSignInPage, t]);

  const isSelected = useMemo(() => {
    return (
      location.pathname === link ||
      (label === "nav_dashboard" && location.pathname === "/app")
    );
  }, [label, link, location.pathname]);

  const renderLink = useCallback(() => {
    return (
      <NavigationItemLink
        onClick={handleClick}
        link={link}
        id={id}
        label={label}
        children={children}
        currentNavId={currentNavId!}
        isSelected={isSelected}
        isOpenNavigation={isOpenNavigation}
      />
    );
  }, [
    children,
    currentNavId,
    handleClick,
    id,
    isOpenNavigation,
    isSelected,
    label,
    link,
  ]);

  const ChildContentCX = cx(style.childContent, {
    [style.hidden]:
      `${currentNavId}` !== `${id}` || !isOpenNavigation || !isExpanded,
  });

  const WrapperCX = cx(style.navItemContainer, {
    [style.active]: !isOpenNavigation,
  });

  return (
    <div className={WrapperCX}>
      {renderLink()}
      <div className={ChildContentCX}>
        {children?.map((ch) => {
          return (
            <NavigationChildItem
              key={ch.id + ch.link}
              {...ch}
              isOpenNavigation={isOpenNavigation}
            />
          );
        })}
      </div>
    </div>
  );
};
