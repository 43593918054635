import dayjs from "dayjs";

export const getCurrentDate = (endDate: string) => {
  const currentDate: Date = new Date();
  return endDate && new Date(endDate) < currentDate
    ? endDate
    : dayjs(currentDate).format("YYYY-MM-DD");
};

export const getFriday = (endDate: string) => {
  const today = new Date(getCurrentDate(endDate));
  const fifth = today.getDate() - today.getDay() + 5;
  const friday = new Date(today.setDate(fifth));

  return dayjs(friday).format("YYYY-MM-DD");
};

export const isWeekDay = (endDate: string) => {
  const day = dayjs(getCurrentDate(endDate)).day();
  return day === 0 || day === 6;
};
