import cx from "classnames";
import dayjs from "dayjs";
import Dygraph from "dygraphs";
import {
  useEffect,
  useRef,
  useMemo,
  useState,
  useCallback,
  MutableRefObject,
} from "react";
import { useTranslation } from "react-i18next";
import html2canvas from "html2canvas";

import { ScenariosData } from "features/longtermPrices/slice";
import { Loader } from "components/loader";
import { ReactComponent as SaveIcon } from "assets/svg/save.svg";
import { ReactComponent as RefreshIcon } from "assets/svg/ic_round-refresh.svg";
import { legendColors, longTermPricesChartOptions } from "./utils";

import "dygraphs/dist/dygraph.min.css";
import style from "./longTermPricesChart.module.scss";

export type IProps = {
  scenarios: ScenariosData[] | null;
  graphData: (number | Date)[][] | null;
  isLoading: boolean;
  selectedDate: string;
  selectedYear: number | string;
  isGraphError: boolean;
  isSummarySelected: boolean;
};

export const LongTermPricesChart = ({
  scenarios,
  graphData,
  isLoading,
  selectedDate,
  selectedYear,
  isGraphError,
  isSummarySelected,
}: IProps) => {
  const { t } = useTranslation();
  const graphRef = useRef() as MutableRefObject<HTMLDivElement | null>;
  const [currentGraph, setCurrentGraph] = useState<Dygraph>();
  const [restructuredData, setRestructuredData] =
    useState<(number | Date)[][]>();
  const [legendState, setLegendState] = useState<Record<string, boolean>>();

  const visibility = useMemo(
    () =>
      scenarios && legendState
        ? scenarios.map((item: ScenariosData) => legendState[item.title])
        : null,
    [scenarios, legendState]
  );

  const toggleLegendItem = (item: string) => () => {
    setLegendState((prevState) => ({
      ...prevState,
      [item]: !(prevState as Record<string, boolean>)[item],
    }));
  };

  const setDefaultLegendState = useCallback(() => {
    const legendState: Record<string, boolean> = {};
    scenarios?.forEach(
      (item: ScenariosData) => (legendState[item.title] = true)
    );

    setLegendState(legendState);
  }, [scenarios]);

  const resetZoom = () => {
    currentGraph?.resetZoom();
    setDefaultLegendState();
  };

  const onSaveGraph = () => {
    setTimeout(() => {
      const graphContainer = document.querySelector(
        "#rion-longterm-prices-chart"
      ) as HTMLElement;
      html2canvas(graphContainer).then(function (canvas) {
        canvas.style.display = "none";
        document.body.appendChild(canvas);
        const image = canvas
          .toDataURL("image/png")
          .replace("image/png", "image/octet-stream");
        const a = document.createElement("a");
        a.setAttribute(
          "download",
          `longterm_prices_today_best_guess_for_${selectedYear}.png`
        );
        a.setAttribute("href", image);
        a.click();
      });
    }, 1000);
  };

  const renderContent = () =>
    !isGraphError ? (
      <>
        <div className={style.header}>
          <h4 className={style.header__title}>
            {isSummarySelected
              ? t(selectedYear as string)
              : `${t("year")} ${selectedYear}`}
          </h4>
          <div className={style.header__legend}>
            {scenarios?.map((item: ScenariosData) => (
              <div
                key={item.title}
                className={cx(style.header__legend_item, {
                  [style.header__legend_item_notActive]:
                    !legendState?.[item.title],
                })}
              >
                <div
                  style={{
                    backgroundColor: legendState?.[item.title]
                      ? legendColors[item.title]
                      : legendColors.isNotActive,
                  }}
                  className={style.header__legend_square}
                  onClick={toggleLegendItem(item.title)}
                ></div>
                <span>{`${t("scenario_title")} ${
                  item.title.split(" ")[1]
                }`}</span>
              </div>
            ))}
          </div>
        </div>

        <div className={style.graphContainer} ref={graphRef}></div>
        <RefreshIcon className={style.refreshIcon} onClick={resetZoom} />
        <SaveIcon className={style.saveIcon} onClick={onSaveGraph} />
      </>
    ) : (
      <div className={style.errorContainer}>
        <div>Oops...</div>
        <div>{t("longterm_prices_chart_error_message")}</div>
      </div>
    );

  useEffect(() => {
    if (graphData) {
      const result: (number | Date)[][] = graphData.map((itemByDate) => {
        return itemByDate.map((item, index) =>
          index === 0 ? new Date(item) : item
        );
      });
      setRestructuredData(result);
    }
  }, [graphData]);

  useEffect(() => {
    if (graphData && scenarios) {
      setDefaultLegendState();
    }
  }, [graphData, scenarios, selectedDate, selectedYear, setDefaultLegendState]);

  useEffect(() => {
    if (graphRef?.current && visibility && restructuredData && scenarios) {
      const graph = new Dygraph(graphRef?.current, restructuredData, {
        ...longTermPricesChartOptions,
        visibility,
        labels: ["Date", ...scenarios.map((scenario) => scenario.title)],
        legendFormatter: (data) => {
          const formattedDate = dayjs(data.x).format("D MMM YYYY HH:mm");
          const values: string[] = data.series
            .filter((series: { label: string }) => legendState?.[series.label])
            .map(
              (item: { label: string; y: number }) =>
                `${item.label}: ${item.y?.toFixed(0)} KW`
            );
          const scenariosValues = values
            .map((item) => `<div>${item}</div>`)
            .join("");
          return `<div>
            <div>${formattedDate}</div>
            ${scenariosValues}
          </div>`;
        },
        axes: {
          ...longTermPricesChartOptions.axes,
          x: {
            ...longTermPricesChartOptions.axes.x,
            axisLabelFormatter: (x: Date | number) =>
              dayjs(x).format(isSummarySelected ? "YYYY" : "MM/YYYY"),
          },
        },
      });

      const legendContainer = graphRef.current.querySelector(
        ".dygraph-legend"
      ) as HTMLElement;

      if (legendContainer) {
        legendContainer.style.width = "140px";
      }

      const yLabel = graphRef.current.querySelector(
        ".dygraph-ylabel"
      ) as HTMLElement;

      if (yLabel) {
        yLabel.style.color = "#6E7991";
        yLabel.style.position = "absolute";
        yLabel.style.transform = "rotate(90deg)";
        yLabel.style.left = "297px";
        yLabel.style.top = "30px";
      }

      setCurrentGraph(graph);

      return () => {
        graph?.destroy();
      };
    }
  }, [restructuredData, visibility, scenarios, legendState]);

  return (
    <div className={style.root} id="rion-longterm-prices-chart">
      {isLoading ? (
        <div className={style.loaderContainer}>
          <Loader />
        </div>
      ) : (
        renderContent()
      )}
    </div>
  );
};
