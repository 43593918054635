import style from "./contacts.module.scss";
import { PortfolioContractContent } from "containers/portfolioContractContent";

export const Contacts = () => {
  return (
    <div className={style.root}>
      <PortfolioContractContent />
    </div>
  );
};
