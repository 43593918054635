import dayjs from "dayjs";

interface IOptions {
  yTitle?: string;
  tooltipSuffix?: string;
  tooltipPrefix?: string;
  title?: string;
  filename?: string;
  max?: number;
}

export const makeOptions = ({
  yTitle,
  tooltipSuffix,
  tooltipPrefix,
  title,
  filename,
  max,
}: IOptions) => {
  return {
    legend: {
      show: false,
    },
    chart: {
      type: "area",
      height: 230,
      offsetX: 0,
      animations: {
        enabled: false,
      },
      fontFamily: "Poppins, Arial, sans-serif",
      toolbar: {
        export: {
          svg: {
            filename,
          },
          png: {
            filename,
          },
          csv: {
            filename,
          },
        },
      },
      zoom: {
        enabled: true,
        type: "x",
        autoScaleYaxis: false,
        zoomedArea: {
          fill: {
            color: "#90CAF9",
            opacity: 0.4,
          },
          stroke: {
            color: "#0D47A1",
            opacity: 0.4,
            width: 1,
          },
        },
      },
    },
    title: {
      text: title,
      align: "left",
      margin: 10,
      offsetX: 0,
      offsetY: -2,
      floating: false,
      style: {
        fontSize: "20px",
        fontWeight: 600,
        color: "#000",
      },
    },
    // colors: ["#8BBA05", "#3F1FED"],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      colors: ["#fff", "#000", "#000", "#8BBA05", "#000"],
      width: 1,
      curve: "stepline",
      dashArray: [0, 0, 2, 0, 0],
    },
    markers: {},
    xaxis: {
      type: "datetime",
      // categories,
      axisBorder: {
        show: true,
        color: "#6E7991",
        height: 2,
        offsetX: 0,
        offsetY: 0,
      },

      axisTicks: {
        show: false,
      },
      labels: {
        formatter: function (value: any) {
          return dayjs(value).format("MMM DD (YY)"); // The formatter function overrides format property
        },
        style: {
          colors: "#6E7991",
          fontSize: "12px",
          fontFamily: "Poppins, Arial, sans-serif",
          fontWeight: 400,
        },
      },
    },
    yaxis: {
      showAlways: true,
      max,
      min: 0,
      tickAmount: 4,
      title: {
        text: yTitle,
        rotate: 0,
        offsetX: 30,
        offsetY: -210,
        style: {
          color: "#6E7991",
          fontSize: "12px",
          fontFamily: "Poppins, Arial, sans-serif",
          fontWeight: 500,
        },
      },
      labels: {
        formatter: (v: any) => v.toFixed(0),
        style: {
          colors: "#6E7991",
          fontSize: "12px",
          fontFamily: "Poppins, Arial, sans-serif",
          fontWeight: 400,
        },
      },
      axisBorder: {
        show: true,
        color: "#6E7991",
        offsetX: 0,
        offsetY: 0,
        width: 2,
      },
      lines: {
        show: true,
      },
    },
    grid: {
      show: true,
      borderColor: "rgba(110, 121, 145, 0.2)",
      strokeDashArray: 7,
      xaxis: {
        lines: {
          show: true,
        },
      },
    },
    fill: {
      opacity: 1,
      colors: [
        "#6E7991",
        "rgba(255,255,255, 1)",
        "rgba(255,255,255, 1)",
        "rgba(255,255,255, 0)",
        "#6E7991",
      ],
      type: ["gradient", "solid", "solid", "solid", "gradient"],

      gradient: {
        shade: "light",
        type: "vertical",
        shadeIntensity: 0.4,
        gradientToColors: [
          "rgba(110, 121, 145, 0.35)",
          "rgba(110, 121, 145, 0)",
        ],
        inverseColors: false,
        opacityFrom: [0.3, 0, 0, 0, 0.2],
        opacityTo: [0.1, 0, 0, 0, 0.3],
        stops: [10, 100],
        colorStops: [],
      },
      pattern: {
        style: "verticalLines",
        width: 4,
        height: 6,
        strokeWidth: 20,
      },
    },
    tooltip: {
      y: {
        formatter: function (val: string) {
          const suffixProp = tooltipSuffix || "";
          const prefixProp = tooltipPrefix || "";
          return `${suffixProp} ${
            val === undefined ? "0.00" : Number(val)?.toFixed(2)
          } ${prefixProp}`;
        },
      },
    },
  };
};

export const monthCategories = [
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
];

export const yearCategories = ["All Year"];
