import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { refreshToken, setDisconnect } from "features/auth/authSlice";
import { RootState } from "../../app/store";
import { GET_PORTFOLIO_PROFILE_CONTRACTS } from "./constants";
import { Api } from "api";
import worker_script from "utils/chart";
import axios from "axios";
import {
  portfolioProfileDataForPlotting$,
  setChartData,
  setLoadedProfileId,
} from "features/portfolioProfileGraph/slice";

export interface PortfolioContractsState {
  isLoading: boolean;
  isError: boolean;
  data: (string | number)[][] | null;
}

const initialState: PortfolioContractsState = {
  isLoading: false,
  isError: false,
  data: null,
};

interface IPortfolioProfileContractsRequest {
  id: string | number;
}

export const fetchPortfolioProfileContracts = createAsyncThunk(
  GET_PORTFOLIO_PROFILE_CONTRACTS,
  async ({ id }: IPortfolioProfileContractsRequest, api) => {
    try {
      const res = await axios.get(`${Api.GetProfileCorridor}/${id}/corridor/`);

      if (!res) {
        throw new Error("Error");
      }

      if (res?.data) {
        const additionalData: any = Object.keys(res?.data).map((key) => [
          key as string,
          res?.data[key] as number,
        ]);

        const plotting = portfolioProfileDataForPlotting$(
          api.getState() as RootState
        );

        const resp = {
          date: plotting?.[id as any] ? plotting?.[id] : [],
          additionalData,
        };
        const worker = new Worker(worker_script);
        worker.postMessage(resp);
        worker.onmessage = (e) => {
          api.dispatch(setChartData({ id, data: e.data.result }));
          api.dispatch(setLoadedProfileId(id));
          api.dispatch(setLoading(false));
          worker.terminate();
        };
      }

      return res?.data
        ? Object.keys(res?.data).map((key) => [
            key as string,
            res?.data[key] as number,
          ])
        : null;
    } catch (error: any) {
      api.dispatch(setPortfolioContractsError(true));
      if (error?.request?.status === 403) {
        api.dispatch(setDisconnect(true));
      } else if (error?.request?.status === 401) {
        api.dispatch(
          refreshToken({
            callback: fetchPortfolioProfileContracts,
            parameter: { id },
          })
        );
      }

      return api.rejectWithValue("Without portfolio plot data");
    }
  }
);

export const portfolioProfileContractsSlice = createSlice({
  name: "portfolioProfileContracts",
  initialState,
  reducers: {
    setPortfolioContractsError(state, action) {
      state.isError = action.payload;
    },
    initContractsData(state) {
      state.isError = false;
      state.isLoading = false;
      state.data = null;
    },
    setLoading(state, action) {
      state.isLoading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPortfolioProfileContracts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        fetchPortfolioProfileContracts.fulfilled,
        (state, { payload }) => {
          state.isError = false;
          state.data = payload || null;
        }
      )
      .addCase(fetchPortfolioProfileContracts.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      });
  },
});
export const { setPortfolioContractsError, initContractsData, setLoading } =
  portfolioProfileContractsSlice.actions;
export const isLoading$ = (state: RootState) =>
  state.portfolioProfileContracts.isLoading;
export const isError$ = (state: RootState) =>
  state.portfolioProfileContracts.isError;
export const profileContracts$ = (state: RootState) =>
  state.portfolioProfileContracts.data;

export default portfolioProfileContractsSlice.reducer;
