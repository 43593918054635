import { memo, useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { GraphInternal } from "components/graphInternal";
import { isLoading$ as isLoadingPortfolioProfile$ } from "features/portfolioProfileUpload/slice";
import { fetchPortfolioProfileContracts } from "features/portfolioProfileContracts/slice";
import {
  fetchPortfolioProfileGraph,
  IPortfolioGraphItem,
  isLoading$ as isLoadingGraph$,
  isLoadingPlotting$,
  loadedId$,
  loadedProfileId$,
  portfolioProfileGraph$,
  setLoadedProfileId,
} from "features/portfolioProfileGraph/slice";
import { currentProfileData$ } from "features/portfolioProfile/slice";
import { isError$ } from "features/portfolioProfileGraph/slice";

export const PortfolioChart = memo(() => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const portfolioProfileGraph = useAppSelector(portfolioProfileGraph$);
  const isLoadingGraph = useAppSelector(isLoadingGraph$);
  const isLoadingPortfolioProfile = useAppSelector(isLoadingPortfolioProfile$);
  const isError = useAppSelector(isError$);
  const currentProfileData = useAppSelector(currentProfileData$);
  const loadedId = useAppSelector(loadedId$);
  const loadedProfileId = useAppSelector(loadedProfileId$);
  const isLoadingPlotting = useAppSelector(isLoadingPlotting$);

  useEffect(() => {
    return () => dispatch(setLoadedProfileId(null) as any);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showPortfolioGraph = useMemo(() => {
    return (
      portfolioProfileGraph !== null &&
      !isLoadingGraph &&
      !isLoadingPortfolioProfile &&
      !isError
    );
  }, [
    isError,
    isLoadingPortfolioProfile,
    isLoadingGraph,
    portfolioProfileGraph,
  ]);

  const isIdEqual = useMemo(
    () => loadedId === loadedProfileId && loadedId,
    [loadedId, loadedProfileId]
  );

  const filename = useMemo(
    () =>
      `${t("portfolio_profile_filename")} ${currentProfileData?.year || ""}`,
    [t, currentProfileData]
  );

  const portfolioGraph =
    currentProfileData?.id && portfolioProfileGraph?.[currentProfileData.id]
      ? (portfolioProfileGraph?.[
          currentProfileData.id
        ] as IPortfolioGraphItem[])
      : [];

  const handlePlotting = useCallback(() => {
    if (isLoadingPortfolioProfile || isLoadingGraph) return;
    if (currentProfileData?.id) {
      if (!isIdEqual) {
        dispatch(
          fetchPortfolioProfileContracts({ id: currentProfileData.id! })
        );
      } else {
        dispatch(fetchPortfolioProfileGraph({ id: currentProfileData.id! }));
      }
    }
  }, [
    currentProfileData?.id,
    dispatch,
    isIdEqual,
    isLoadingPortfolioProfile,
    isLoadingGraph,
  ]);

  const isLoading = useMemo(
    () => isLoadingPortfolioProfile || isLoadingGraph || isLoadingPlotting,
    [isLoadingPortfolioProfile, isLoadingGraph, isLoadingPlotting]
  );

  return (
    <>
      {showPortfolioGraph && (
        <GraphInternal
          data={portfolioGraph}
          fileName={filename}
          isLoading={isLoading}
          isPlotting
          onPlottingClick={handlePlotting}
          isShowPlottingChart={!!isIdEqual}
        />
      )}
    </>
  );
});
