import { Loader } from "components/loader";
import { PortfolioContractsTableHeader } from "components/portfolioContractsTableHeader";
import { PortfolioContractsTableItem } from "components/PortfolioContractsTableItem";
import { IPortfolioContractsData } from "features/portfolioContracts/slice";
import { useEffect, useRef, useState } from "react";
import style from "./portfolioContractsTable.module.scss";
import cx from "classnames";
import { Size, useWindowSize } from "utils/hooks";
import { usePrevious } from "utils";

interface IProps {
  items: IPortfolioContractsData[] | null;
  isLoading?: boolean;
  hideSomeColumns?: boolean;
  onDelete: (id: string) => void;
  onEdit: (id: string) => void;
  isDisabled?: boolean;
  isHideActions?: boolean;
}

export const PortfolioContractsTable = ({
  items,
  isLoading,
  hideSomeColumns,
  onDelete,
  onEdit,
  isDisabled,
  isHideActions,
}: IProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [hasScroll, setHasScroll] = useState(false);
  const size: Size = useWindowSize();
  const prevSize = usePrevious(size);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (ref?.current) {
      const hasScr = ref?.current?.scrollHeight > ref?.current?.clientHeight;
      setHasScroll(hasScr);
    }
  });

  useEffect(() => {
    if (prevSize?.width !== size.width) {
      if (!ref?.current) return;
      const hasScr = ref?.current?.scrollHeight > ref?.current?.clientHeight;

      setHasScroll(hasScr);
    }
  }, [prevSize?.width, size.width]);

  const ContentCX = cx(style.content, {
    [style.hasScroll]: hasScroll,
  });

  return (
    <div className={style.root}>
      {isLoading && (
        <div className={style.loaderContainer}>
          <Loader />
        </div>
      )}

      <PortfolioContractsTableHeader
        hideSomeColumns={hideSomeColumns}
        hasScroll={hasScroll}
        isHideActions={isHideActions}
      />

      <div className={ContentCX} ref={ref}>
        {items?.map((item) => (
          <PortfolioContractsTableItem
            key={item?.id}
            item={item}
            hideSomeColumns={hideSomeColumns}
            onDelete={onDelete}
            onEdit={onEdit}
            isDisabled={isDisabled}
            hasScroll={hasScroll}
            isHideActions={isHideActions}
          />
        ))}
      </div>
    </div>
  );
};
