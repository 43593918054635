import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { refreshToken, setDisconnect } from "features/auth/authSlice";
import { RootState } from "app/store";
import {
  UPLOAD_PORTFOLIO_PROFILES,
  UPDATE_UPLOAD_PORTFOLIO_PROFILES,
} from "./constants";
import { Api } from "api";
import {
  fetchPortfolioProfileGraph,
  setChartData,
} from "features/portfolioProfileGraph/slice";
import { fetchPortfolioProfile } from "features/portfolioProfile/slice";
import axios from "axios";

export interface UploadPortfolioProfileState {
  isLoading: boolean;
  isError: boolean;
}

const initialState: UploadPortfolioProfileState = {
  isLoading: false,
  isError: false,
};

interface IPortfolioProfileUploadRequest {
  file: File;
  year: number;
  portfolio_id: string;
}

export const uploadPortfolioProfileFile = createAsyncThunk(
  UPLOAD_PORTFOLIO_PROFILES,
  async ({ file, year, portfolio_id }: IPortfolioProfileUploadRequest, api) => {
    try {
      api.dispatch(setChartData(null));
      let formData = new FormData();
      formData.append("file", file);
      formData.append("year", year.toString());
      formData.append("portfolio_id", portfolio_id.toString());
      const res = await axios.post(`${Api.UploadProfilePortfolio}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      api.dispatch(fetchPortfolioProfile({ id: portfolio_id, year }));

      if (!res) {
        throw new Error("Error");
      }

      return true;
    } catch (error: any) {
      api.dispatch(setRollOutError(true));
      if (error?.request?.status === 403) {
        api.dispatch(setDisconnect(true));
      } else if (error?.request?.status === 401) {
        api.dispatch(
          refreshToken({
            callback: uploadPortfolioProfileFile,
            parameter: { file, year, portfolio_id },
          })
        );
      }
    }
  }
);

interface IPortfolioProfileUpdateRequest {
  file: File | null;
  year: number;
  id: number;
}

export const updatePortfolioProfileFile = createAsyncThunk(
  UPDATE_UPLOAD_PORTFOLIO_PROFILES,
  async ({ file, year, id }: IPortfolioProfileUpdateRequest, api) => {
    try {
      let formData = new FormData();
      !!file && formData.append("file", file);
      formData.append("year", year.toString());
      formData.append("id", id.toString());
      const res = await axios.put(`${Api.UploadProfilePortfolio}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (!res) {
        throw new Error("Error");
      }

      id && api.dispatch(fetchPortfolioProfileGraph({ id }));

      return true;
    } catch (error: any) {
      api.dispatch(setRollOutError(true));
      if (error?.request?.status === 403) {
        api.dispatch(setDisconnect(true));
      } else if (error?.request?.status === 401) {
        api.dispatch(
          refreshToken({
            callback: uploadPortfolioProfileFile,
            parameter: { file, year, id },
          })
        );
      }
    }
  }
);

export const portfolioProfileUploadSlice = createSlice({
  name: "portfolioProfileUpload",
  initialState,
  reducers: {
    setRollOutError(state, action) {
      state.isError = action.payload;
    },
    setInitAllRollOutData(state) {
      state.isError = false;
      state.isLoading = false;
    },
    setLoading(state, action) {
      state.isLoading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(uploadPortfolioProfileFile.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(uploadPortfolioProfileFile.fulfilled, (state) => {
        state.isLoading = false;
        state.isError = false;
      })
      .addCase(uploadPortfolioProfileFile.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      })
      .addCase(updatePortfolioProfileFile.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updatePortfolioProfileFile.fulfilled, (state) => {
        state.isLoading = false;
        state.isError = false;
      })
      .addCase(updatePortfolioProfileFile.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      });
  },
});
export const { setRollOutError, setInitAllRollOutData, setLoading } =
  portfolioProfileUploadSlice.actions;
export const isLoading$ = (state: RootState) =>
  state.portfolioProfileUpload.isLoading;
export const isError$ = (state: RootState) =>
  state.portfolioProfileUpload.isError;

export default portfolioProfileUploadSlice.reducer;
