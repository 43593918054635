import Dygraph from "dygraphs";
import { useRef, useState } from "react";

import { useElementSize } from "utils/hooks";
import { AdditionalGraph } from "components/graphInternal/additionalGraph";
import { DygraphPortfolioChart } from "components/dygraphPortfolioChart";

import style from "./graphInternal.module.scss";
import { portfolioChartOptions } from "components/dygraphPortfolioChart/utils";

interface IProps {
  isLoading?: boolean;
  data: (number | Date)[][] | null;
  fileName?: string;
  isPlotting?: boolean;
  onPlottingClick?: () => void;
  isShowPlottingChart?: boolean;
}

export const GraphInternal = ({
  isLoading,
  data,
  fileName,
  isPlotting,
  isShowPlottingChart,
  onPlottingClick,
}: IProps) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [currentGraph, setCurrentGraph] = useState<Dygraph>();
  const [wrapperRef] = useElementSize();

  const onRefreshGraph = () => {
    currentGraph?.resetZoom();
  };

  return (
    <div className={style.root} id={"root-chart-el"} ref={wrapperRef}>
      <div className={style.wrapper} ref={ref}>
        <AdditionalGraph
          isLoading={isLoading}
          fileName={fileName}
          isPlotting={isPlotting}
          isShowPlottingChart={isShowPlottingChart}
          onPlottingClick={onPlottingClick}
          onRefreshGraph={onRefreshGraph}
        />
        <DygraphPortfolioChart
          data={data}
          fileName={fileName}
          options={portfolioChartOptions}
          setCurrentGraph={setCurrentGraph}
        />
      </div>
    </div>
  );
};
