import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ReactComponent as ProfileRollOutAvatar } from "assets/svg/profileRollOutAvatar.svg";

import style from "./dashboardTool.module.scss";

export const DashboardTool = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const navigateToProfileRollOut = () => {
    navigate("/app/profile-roll-out");
  };

  return (
    <div className={style.root}>
      <h2 className={style.heading}>{t("tool")}</h2>
      <div
        className={style.profileRollOutCard}
        onClick={navigateToProfileRollOut}
      >
        <div className={style.avatarContainer}>
          <ProfileRollOutAvatar />
        </div>
        <div className={style.description}>
          <h4>{t("nav_profile_roll_out")}</h4>
          <span>{t("dashboard_tool_description")}</span>
        </div>
      </div>
    </div>
  );
};
