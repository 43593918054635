import { RollOutDropArea } from "components/rollOutDropArea";
import { RollOutOptions } from "components/rollOutOptions";
import { useState } from "react";
import style from "./profileRollOutContent.module.scss";

export const ProfileRollOutContent = () => {
  const [files, setFiles] = useState<File[]>([]);
  return (
    <div className={style.root}>
      <RollOutDropArea files={files} setFiles={setFiles} />
      <RollOutOptions />
    </div>
  );
};
