import React, { memo, useEffect } from "react";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import { ChangePassword } from "pages/changePassword";
import { PasswordRecovery } from "pages/recoveryPassword";
import { SignInPage } from "pages/signIn";

const publicLinks = [
  "/",
  "/app",
  "/app/login/recover",
  "/app/change-password",
  "/app/create-password",
];

export const PublicModule = memo(() => {
  const location = useLocation();
  const navigate = useNavigate();
  const isPublicLink =
    publicLinks.includes(location.pathname) ||
    publicLinks.includes(location.pathname.slice(0, -1));

  useEffect(() => {
    if (!isPublicLink) {
      navigate("/app");
    }
  }, [isPublicLink, navigate]);

  return (
    <Routes>
      <Route path="/" element={<SignInPage />} />
      <Route path="/app" element={<SignInPage />} />
      <Route path="/app/login/recover" element={<PasswordRecovery />} />
      <Route path="/app/create-password" element={<ChangePassword />} />
      <Route
        path="/app/change-password"
        element={<ChangePassword isChangePassword />}
      />
    </Routes>
  );
});

export default PublicModule;
