import { Loader } from "components/loader";
import { MarketDataChartItem } from "components/marketDataChartItem";
import { ChartData } from "features/marketData/slice";
import style from "./marketDataCharts.module.scss";

interface IProps {
  data: ChartData[] | undefined;
  isLoading: boolean;
}

export const MarketDataCharts = ({ data, isLoading }: IProps) => {
  return (
    <div className={style.root}>
      {isLoading ? (
        <>
          <div className={style.loaderContainer}>
            <Loader />
          </div>
          <div className={style.loaderContainer}>
            <Loader />
          </div>
          <div className={style.loaderContainer}>
            <Loader />
          </div>
          <div className={style.loaderContainer}>
            <Loader />
          </div>
          <div className={style.loaderContainer}>
            <Loader />
          </div>
          <div className={style.loaderContainer}>
            <Loader />
          </div>
        </>
      ) : (
        <>
          {data?.map((item: ChartData, index: number) => (
            <MarketDataChartItem data={item} key={index} />
          ))}
        </>
      )}
    </div>
  );
};
