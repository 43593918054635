import { Api } from "api";
import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { refreshToken, setDisconnect } from "features/auth/authSlice";
import { GET_SCENARIOS_BY_DATE, GET_SCENARIOS, GET_SCENARIOS_GRAPH, GET_SCENARIOS_DATES } from "./constants";
import { RootState } from "app/store";

export enum ScenariosTypeIds {
  FIRST_TYPE = 1,
  SECOND_TYPE = 2,
  THIRD_TYPE = 3,
};

export enum ScenariosDescription {
  FIRST_SCENARIO_DESCRIPTION = "first_scenario_description",
  SECOND_SCENARIO_DESCRIPTION = "second_scenario_description",
  THIRD_SCENARIO_DESCRIPTION = "third_scenario_description",
}

export type ScenariosData = {
  date: string;
  description: string;
  file: string;
  id: number;
  title: string;
  years: number[];
  type: number;
}

export type GraphData = {
  title: string;
  graph_data: {
    date: string[];
    value: number[];
  }
}

export type LongtermPricesState = {
  scenarios: ScenariosData[] | null;
  isScenariosLoading: boolean;
  isScenariosError: boolean;
  dates: string[] | null;
  isDatesError: boolean;
  isDatesLoading: boolean;
  graph: (number | Date)[][] | null;
  isGraphLoading: boolean;
  isGraphError: boolean;
};

export type FetchScenariosGraphRequestData = {
  date: string;
  year: number | string;
}

const initialState: LongtermPricesState = {
  scenarios: null,
  isScenariosLoading: false,
  isScenariosError: false,
  dates: null,
  isDatesLoading: false,
  isDatesError: false,
  graph: null,
  isGraphLoading: false,
  isGraphError: false,
};

export const fetchScenariosDates = createAsyncThunk(
  GET_SCENARIOS_DATES,
  async (_, api) => {
    try {
      const res = await axios.get(`${Api.GetScenarios}/dates`);
      if (!res) {
        throw new Error("Error");
      }

      return res?.data || null;
    } catch (error: any) {
      api.dispatch(setScenariosError(true));
      if (error?.request?.status === 403) {
        api.dispatch(setDisconnect(true));
      } else if (error?.request?.status === 401) {
        api.dispatch(
          refreshToken({ callback: fetchScenarios, parameter: null })
        );
      }
      return api.rejectWithValue("No Scenarios Dates found");
    }
  }
);

export const fetchScenarios = createAsyncThunk(
  GET_SCENARIOS,
  async (_, api) => {
    try {
      const res = await axios.get(`${Api.GetScenarios}/`);
      if (!res) {
        throw new Error("Error");
      }

      return res?.data || null;
    } catch (error: any) {
      api.dispatch(setScenariosError(true));
      if (error?.request?.status === 403) {
        api.dispatch(setDisconnect(true));
      } else if (error?.request?.status === 401) {
        api.dispatch(
          refreshToken({ callback: fetchScenarios, parameter: null })
        );
      }
      return api.rejectWithValue("No Scenarios Data found");
    }
  }
);

export const fetchScenariosByDate = createAsyncThunk(
  GET_SCENARIOS_BY_DATE,
  async ({ date }: { date: string }, api) => {
    try {
      const res = await axios.get(`${Api.GetScenarios}?date=${date}`);
      if (!res) {
        throw new Error("Error");
      }

      return res?.data || null;
    } catch (error: any) {
      api.dispatch(setScenariosError(true));
      if (error?.request?.status === 403) {
        api.dispatch(setDisconnect(true));
      } else if (error?.request?.status === 401) {
        api.dispatch(
          refreshToken({ callback: fetchScenariosByDate, parameter: null })
        );
      }
      return api.rejectWithValue("No Scenarios Data found");
    }
  }
);

export const fetchScenariosGraph = createAsyncThunk(
  GET_SCENARIOS_GRAPH,
  async ({ date, year }: FetchScenariosGraphRequestData, api) => {
    try {
      const isSummary = year === 'summary'
      const url = `${Api.GetScenarios}/${date}${isSummary ? '/summary/' : `/graphs/${year}`}`;
      const res = await axios.get(url);
      if (!res) {
        throw new Error("Error");
      }

      return res?.data || null;
    } catch (error: any) {
      api.dispatch(setScenariosError(true));
      if (error?.request?.status === 403) {
        api.dispatch(setDisconnect(true));
      } else if (error?.request?.status === 401) {
        api.dispatch(
          refreshToken({ callback: fetchScenariosGraph, parameter: null })
        );
      }
      return api.rejectWithValue("No Scenarios Graph Data found");
    }
  }
);

export const longtermPrices = createSlice({
  name: "longtermPrices",
  initialState,
  reducers: {
    setScenariosError(state, action) {
      state.isScenariosError = action.payload;
    },
    initScenariosData(state) {
      state.isScenariosError = false;
      state.isScenariosLoading = false;
      state.scenarios = null;
    },
    setScenariosLoading(state, action) {
      state.isScenariosLoading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchScenarios.pending, (state) => {
        state.isScenariosLoading = true;
      })
      .addCase(fetchScenarios.fulfilled, (state, { payload }) => {
        state.isScenariosLoading = false;
        state.isScenariosError = false;
        state.scenarios = payload || null;
      })
      .addCase(fetchScenarios.rejected, (state) => {
        state.isScenariosLoading = false;
        state.isScenariosError = true;
      })
      .addCase(fetchScenariosByDate.pending, (state) => {
        state.isScenariosLoading = true;
      })
      .addCase(fetchScenariosByDate.fulfilled, (state, { payload }) => {
        state.isScenariosLoading = false;
        state.isScenariosError = false;
        state.scenarios = payload || null;
      })
      .addCase(fetchScenariosByDate.rejected, (state) => {
        state.isScenariosLoading = false;
        state.isScenariosError = true;
      })
      .addCase(fetchScenariosGraph.pending, (state) => {
        state.isGraphLoading = true;
      })
      .addCase(fetchScenariosGraph.fulfilled, (state, { payload }) => {
        state.isGraphLoading = false;
        state.isGraphError = false;
        state.graph = payload || null;
      })
      .addCase(fetchScenariosGraph.rejected, (state) => {
        state.isGraphLoading = false;
        state.isGraphError = true;
        state.graph = null;
      })
      .addCase(fetchScenariosDates.pending, (state) => {
        state.isDatesLoading = true;
      })
      .addCase(fetchScenariosDates.fulfilled, (state, { payload }) => {
        state.isDatesLoading = false;
        state.isDatesError = false;
        state.dates = payload.dates || null;
      })
      .addCase(fetchScenariosDates.rejected, (state) => {
        state.isDatesLoading = false;
        state.isDatesError = true;
      })
  },
});

export const { setScenariosError, initScenariosData, setScenariosLoading } =
  longtermPrices.actions;

export const isScenariosLoading$ = (state: RootState) => state.longtermPrices.isScenariosLoading;
export const isScenariosError$ = (state: RootState) => state.longtermPrices.isScenariosLoading;
export const scenariosData$ = (state: RootState) =>
  state.longtermPrices.scenarios;
export const graphData$ = (state: RootState) => state.longtermPrices.graph;
export const scenariosDates$ = (state: RootState) => state.longtermPrices.dates;
export const isGraphLoading$ = (state: RootState) => state.longtermPrices.isGraphLoading;
export const isGraphError$ = (state: RootState) => state.longtermPrices.isGraphError;

export default longtermPrices.reducer;