import { useAppSelector } from "app/hooks";
import { Loader } from "components/loader";
import { market$ } from "features/user/slice";
import { useTranslation } from "react-i18next";
import { getCurrencyByMarket } from "utils";
import style from "./corridorPerformanceIndicator.module.scss";

interface IProps {
  portfolioPrice?: number;
  portfolioBenchmark?: number;
  isLoading: boolean;
}
export const CorridorPerformanceIndicator = ({
  portfolioPrice,
  portfolioBenchmark,
  isLoading,
}: IProps) => {
  const { t } = useTranslation();
  const market = useAppSelector(market$);
  return (
    <div className={style.root}>
      {isLoading && (
        <div className={style.loader}>
          <Loader />
        </div>
      )}
      <div className={style.title}>{t("corridor_performance_title")}</div>
      {!isLoading && (
        <>
          <div className={style.indicatorItem}>
            <div className={style.name}>{`${t("portfolio_price")}:`}</div>
            <div className={style.count}>{portfolioPrice?.toFixed(2)}</div>
            <div className={style.units}>{`${getCurrencyByMarket(
              market
            )}/MWh`}</div>
          </div>
          <div className={style.indicatorItem}>
            <div className={style.name}>{`${t("portfolio_benchmark")}:`}</div>
            <div className={style.countBenchMark}>
              {portfolioBenchmark?.toFixed(2)}
            </div>
            <div className={style.units}>{`${getCurrencyByMarket(
              market
            )}/MWh`}</div>
          </div>
        </>
      )}
    </div>
  );
};
