import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { GET_ALL_PORTFOLIO_PROFILES } from "./constants";
import { user$ } from "features/user/slice";
import {
  fetchPortfolioProfile,
  IPortfolioProfileData,
} from "features/portfolioProfile/slice";

export interface DashboardProfilesState {
  data: IDashboardProfilesData[] | null;
  current: IDashboardProfilesData[] | null;
  isLoading: boolean;
}

const initialState: DashboardProfilesState = {
  data: null,
  current: null,
  isLoading: false,
};

export interface IDashboardProfilesData {
  id: string;
  name: string;
  data: IPortfolioProfileData[] | IPortfolioProfileData;
}

export const fetchAllPortfolioProfiles = createAsyncThunk(
  GET_ALL_PORTFOLIO_PROFILES,
  async (_, api) => {
    try {
      const stateUser = user$(api.getState() as RootState);

      const response: IDashboardProfilesData[] = [];
      const data = stateUser.portfolio.map((item) =>
        api.dispatch(fetchPortfolioProfile({ id: item.id }))
      );

      await Promise.all(data).then((result) => {
        result.forEach((item, index) => {
          response.push({
            id: stateUser.portfolio[index].id,
            name: stateUser.portfolio[index].name,
            data: item.payload as IPortfolioProfileData[],
          });
        });
      });

      return response;
    } catch (error: any) {}
  }
);

export const dashboardProfilesSlice = createSlice({
  name: "dashboardProfiles",
  initialState,
  reducers: {
    setAllCurrentProfilesData(state, action) {
      state.current = state.current?.map((item) => {
        return item.id === action.payload.portfolioId
          ? { ...item, data: action.payload.item }
          : item;
      }) as IDashboardProfilesData[];
    },
    initDashboardProfilesData(state) {
      state.data = null;
      state.current = null;
      state.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllPortfolioProfiles.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchAllPortfolioProfiles.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data = payload || null;
        state.current = payload?.map((item) => ({
          ...item,
          data: (item.data as IPortfolioProfileData[])[0],
        })) as IDashboardProfilesData[];
      })
      .addCase(fetchAllPortfolioProfiles.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const { setAllCurrentProfilesData, initDashboardProfilesData } =
  dashboardProfilesSlice.actions;

export const allProfileData$ = (state: RootState) =>
  state.dashboardProfiles.data;
export const allCurrentProfileData$ = (state: RootState) =>
  state.dashboardProfiles.current;
export const isLoading$ = (state: RootState) =>
  state.dashboardProfiles.isLoading;

export default dashboardProfilesSlice.reducer;
