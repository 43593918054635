import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { ChartData } from "features/marketData/slice";
import { getOptions } from "./utils";

import cx from "classnames";
import style from "./marketDataChartItem.module.scss";
import { getCurrencyByMarket } from "utils";
import { useAppSelector } from "app/hooks";
import { market$ } from "features/user/slice";

interface IProps {
  data: ChartData;
}

export const MarketDataChartItem = ({ data }: IProps) => {
  const series: ApexAxisChartSeries = [{ name: "", data: data.yAxis }];
  const market = useAppSelector(market$);

  const options: ApexOptions = getOptions(data, market);

  const PriceCX = cx(style.price, {
    [style.downTrend]: data.yAxis[0] < data.yAxis[1],
    [style.upTrend]: data.yAxis[0] > data.yAxis[1],
    [style.directTrend]: data.yAxis[0] === data.yAxis[1],
  });

  return (
    <div className={style.root}>
      <div className={style.pricePerMWhContainer}>
        <span className={PriceCX}>{data.yAxis[0]}</span>
        <label>{`${getCurrencyByMarket(market)}/MWh`}</label>
      </div>
      <Chart
        type="area"
        series={series}
        options={options}
        width="100%"
        height={200}
      />
    </div>
  );
};
