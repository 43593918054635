import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { refreshToken, setDisconnect } from "features/auth/authSlice";
import { RootState } from "app/store";
import { GET_CORRIDOR } from "./constants";
import { Api } from "api";
import axios from "axios";

export interface CorridorState {
  isLoading: boolean;
  isError: boolean;
  data: null | ICorridorData;
}

export interface ICorridorData {
  upper_line: number[];
  lower_line: number[];
  neutral_line: number[];
  date: string[];
  price_portfolio: number;
  price_benchmark: number;
  date_build_line: string[];
  value_build_line: number[];
}
const initialState: CorridorState = {
  isLoading: false,
  isError: false,
  data: null,
};

interface ICorridorRequest {
  id: string;
  year: number;
}

export const fetchCorridor = createAsyncThunk(
  GET_CORRIDOR,
  async ({ id, year }: ICorridorRequest, api) => {
    try {
      const res = await axios.get(`${Api.GetCorridor}${year}/${id}/`);

      if (!res) {
        throw new Error("Error");
      }

      return res?.data || null;
    } catch (error: any) {
      api.dispatch(setCorridorError(true));
      if (error?.request?.status === 403) {
        api.dispatch(setDisconnect(true));
      } else if (error?.request?.status === 401) {
        api.dispatch(
          refreshToken({
            callback: fetchCorridor,
            parameter: { id, year },
          })
        );
      }
      return api.rejectWithValue("No user found");
    }
  }
);

export const corridorSlice = createSlice({
  name: "corridor",
  initialState,
  reducers: {
    setCorridorError(state, action) {
      state.isError = action.payload;
    },
    initCorridor(state) {
      state.isError = false;
      state.isLoading = false;
      state.data = null;
    },
    setLoading(state, action) {
      state.isLoading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCorridor.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchCorridor.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.isError = false;
        state.data = payload || null;
      })
      .addCase(fetchCorridor.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      });
  },
});
export const { setCorridorError, initCorridor, setLoading } =
  corridorSlice.actions;
export const isLoading$ = (state: RootState) => state.corridor.isLoading;
export const isError$ = (state: RootState) => state.corridor.isError;
export const corridor$ = (state: RootState) => state.corridor.data;

export default corridorSlice.reducer;
