import { useAppDispatch } from "app/hooks";
import { userLogout } from "features/auth/authSlice";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { INavItem, showDialogue } from "utils";
import style from "./avatarDropdownItem.module.scss";

interface IProps {
  item: INavItem;
}
export const AvatarDropdownItem = ({ item }: IProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const navigateToSignInPage = useCallback(() => {
    navigate("/app");
  }, [navigate]);

  const handleClick = useCallback(() => {
    if (item?.label === "nav_logout") {
      showDialogue({
        title: t("logout_modal_title"),
        text: t("logout_modal_text"),
        confirmButtonText: t("yes"),
        cancelButtonText: t("no"),
        confirmButtonColor: "#3F1FED",
      }).then((result) => {
        if (result?.isConfirmed) {
          dispatch(userLogout(navigateToSignInPage));
        }
      });
    } else {
      navigate(item?.link);
    }
  }, [dispatch, item?.label, item?.link, navigate, navigateToSignInPage, t]);
  return (
    <div className={style.root} onClick={handleClick}>
      {t(item?.label)}
    </div>
  );
};
