import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import auth from "features/auth/authSlice";
import user from "features/user/slice";
import nav from "features/nav/slice";
import changePassword from "features/changePassword/slice";
import rollOut from "features/rollout/slice";
import rollOutUploadFiles from "features/rollOutUploadFiles/slice";
import portfolioProfileUpload from "features/portfolioProfileUpload/slice";
import portfolioProfile from "features/portfolioProfile/slice";
import dashboardProfiles from "features/dashboardProfiles/slice";
import dashboardHedges from "features/dashboardHedges/slice";
import portfolioProfileGraph from "features/portfolioProfileGraph/slice";
import portfolioOptions from "features/portfolioOptions/slice";
import portfolioContracts from "features/portfolioContracts/slice";
import hedge from "features/hedge/slice";
import signals from "features/signals/slice";
import marketData from "features/marketData/slice";
import marketRange from "features/marketRange/slice";
import corridor from "features/corridor/slice";
import analyze from "features/analyze/slice";
import portfolioProfileContracts from "features/portfolioProfileContracts/slice";
import longtermPrices from "features/longtermPrices/slice";

export const store = configureStore({
  reducer: {
    auth,
    user,
    nav,
    changePassword,
    rollOut,
    rollOutUploadFiles,
    portfolioProfileUpload,
    portfolioProfile,
    portfolioProfileGraph,
    portfolioOptions,
    portfolioContracts,
    dashboardProfiles,
    dashboardHedges,
    hedge,
    signals,
    marketData,
    marketRange,
    corridor,
    analyze,
    portfolioProfileContracts,
    longtermPrices,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
