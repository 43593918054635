import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { refreshToken, setDisconnect } from "features/auth/authSlice";
import i18n from "i18n";
import { RootState } from "../../app/store";
import { editUserConfig, uploadUserAvatar } from "./api";
import {
  USER,
  USER_DELETE,
  USER_UPDATE,
  USER_UPLOAD_AVATAR,
} from "./constants";
import { Api } from "api";
import "dayjs/locale/de";
import "dayjs/locale/fr";
import "dayjs/locale/en";
import dayjs from "dayjs";
import axios from "axios";

export interface IUser {
  id: number | null;
  username: string;
  email: string;
  first_name: string;
  last_name: string;
  localisation: string;
  market: UserMarket;
  portfolio: IUserPortfolio[];
  roll_out_access: null | boolean;
  scenario_access: null | boolean;
  position_company: string | null;
  company: string | null;
  phone: string | null;
  postal_code: string | null;
  address: string | null;
  city: string | null;
  image: string | null;
}

export enum UserRole {
  PRODUCER = "Producer",
  CONSUMER = "Consumer",
}

export enum UserMarket {
  DE = "DE",
  CH = "CH",
}
export interface UserState {
  user: IUser;
  isLoading: boolean;
  isError: boolean;
}

export interface IUserPortfolio {
  id: string;
  name: string;
  created_at: string;
  market: string[];
  role: UserRole;
}

const initialState: UserState = {
  user: {
    id: null,
    username: "",
    email: "",
    first_name: "",
    last_name: "",
    localisation: "",
    market: UserMarket.DE,
    portfolio: [],
    roll_out_access: null,
    scenario_access: null,
    position_company: null,
    company: null,
    phone: null,
    postal_code: null,
    address: null,
    city: null,
    image: null,
  },
  isLoading: false,
  isError: false,
};

export const fetchUserData = createAsyncThunk(USER, async (_, api) => {
  try {
    const res = await axios.get(`${Api.User}`);

    if (!res) {
      throw new Error("Error");
    }

    return res?.data;
  } catch (error: any) {
    api.dispatch(setUserError(true));
    if (error?.request?.status === 403) {
      api.dispatch(setDisconnect(true));
    } else if (error?.request?.status === 401) {
      api.dispatch(
        refreshToken({ callback: fetchUserData, parameter: undefined })
      );
    }
  }
});

export const deleteUserAvatar = createAsyncThunk(
  USER_DELETE,
  async (_, api) => {
    try {
      const res = await axios.delete(`${Api.DeleteUserAvatar}`);

      if (!res) {
        throw new Error("Error");
      }

      api.dispatch(deleteAvatar());

      return res?.data;
    } catch (error: any) {
      api.dispatch(setUserError(true));
      if (error?.request?.status === 403) {
        api.dispatch(setDisconnect(true));
      } else if (error?.request?.status === 401) {
        api.dispatch(
          refreshToken({ callback: deleteUserAvatar, parameter: undefined })
        );
      }
    }
  }
);

export const editUser = createAsyncThunk(
  USER_UPDATE,
  async (user: Partial<IUser>, api) => {
    const stateUser = user$(api.getState() as RootState);
    const requestData = { ...user, id: stateUser.id };

    try {
      const res = await editUserConfig(requestData);
      if (!res) {
        throw new Error("Error");
      }
      return res?.data;
    } catch (error: any) {
      api.dispatch(setUserError(true));
      if (error?.request?.status === 403) {
        api.dispatch(setDisconnect(true));
      } else if (error?.request?.status === 401) {
        api.dispatch(refreshToken({ callback: editUser, parameter: user }));
      }
    }
  }
);

export const uploadAvatar = createAsyncThunk(
  USER_UPLOAD_AVATAR,
  async (file: File, api) => {
    try {
      const res = await uploadUserAvatar(file);
      if (!res) {
        throw new Error("Error");
      }
      return res?.data?.image_url;
    } catch (error: any) {
      api.dispatch(setUserError(true));
      if (error?.request?.status === 403) {
        api.dispatch(setDisconnect(true));
      } else if (error?.request?.status === 401) {
        api.dispatch(refreshToken({ callback: uploadAvatar, parameter: file }));
      }
    }
  }
);

interface IUserPayload {
  payload: IUser;
}

const editUserState = (state: UserState, { payload }: IUserPayload) => {
  if (!payload) return;
  const keys = Object.keys(payload);
  if (!keys?.length) return { ...state };

  keys.forEach((el: any) => {
    (state.user[el as keyof IUser] as Partial<UserState>) = payload[
      el as keyof IUser
    ] as Partial<UserState>;
  });
  state.isLoading = false;
  state.isError = false;
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setUserError(state, action) {
      state.isError = action.payload;
    },
    deleteAvatar(state) {
      state.user.image = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchUserData.fulfilled, (state, { payload }) => {
        editUserState(state, { payload });

        if (payload?.localisation) {
          const lang = payload?.localisation;
          i18n.changeLanguage(lang?.toLowerCase());
          dayjs.locale(lang?.toLowerCase());
        }
      })
      .addCase(fetchUserData.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      })
      .addCase(editUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editUser.fulfilled, (state, { payload }) => {
        editUserState(state, { payload });

        if (payload?.localisation) {
          const lang = payload?.localisation;
          i18n.changeLanguage(lang?.toLowerCase());
        }
      })
      .addCase(editUser.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      })
      .addCase(uploadAvatar.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(uploadAvatar.fulfilled, (state, { payload }) => {
        state.user.image = payload;
        state.isLoading = false;
        state.isError = false;
      })
      .addCase(uploadAvatar.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      })
      .addCase(deleteUserAvatar.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteUserAvatar.fulfilled, (state) => {
        state.user.image = "";
        state.isLoading = false;
        state.isError = false;
      })
      .addCase(deleteUserAvatar.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      });
  },
});

export const { setUserError, deleteAvatar } = userSlice.actions;
export const user$ = (state: RootState) => state.user.user;
export const market$ = (state: RootState) => state.user.user.market;
export const isLoading$ = (state: RootState) => state.user.isLoading;
export const isError$ = (state: RootState) => state.user.isError;

export default userSlice.reducer;
