import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import cx from "classnames";
import style from "./dropdownSelect.module.scss";
import { Dropdown } from "components/dropdown";
import { ReactComponent as ArrowDown } from "assets/svg/arrow-bottom.svg";

export type ISelectFullValue = { label: string; value: string | number };
export type ISelectValue = ISelectFullValue | string;

interface IProps {
  onChange: (v: ISelectFullValue | string) => void;
  value: ISelectValue;
  items: ISelectValue[];
  isAllValueNeed?: boolean;
  disabled?: boolean;
  placeholder?: string;
  className?: string;
  small?: boolean;
  withoutBorder?: boolean;
}

export const DropdownSelect = ({
  onChange,
  value,
  items,
  isAllValueNeed,
  disabled,
  placeholder,
  className,
  small,
  withoutBorder,
}: IProps) => {
  const [index, setIndex] = useState<number | null>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const ref = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    if (!items.length) {
      setIndex(null);
    } else {
      const ind = items.findIndex((it) => {
        const i = value as any;
        const f = it as any;
        return isAllValueNeed ? i?.value === f?.value : i === f;
      });
      setIndex(ind === -1 ? null : ind);
    }
  }, [isAllValueNeed, items, value]);

  useEffect(() => {
    if (items.length && value) {
      ref?.current?.scrollTo();
    }
  }, [items.length, value, index]);

  const handleRootClick = () => {};

  const handleItemClick = useCallback(
    (item: ISelectValue, ind: number) => () => {
      setIndex(ind);
      onChange(item);
      setIsOpen(false);
    },
    [onChange]
  );

  const ItemCX = useCallback(
    (ind: number) =>
      cx(style.item, {
        [style.selected]: ind === index,
      }),
    [index]
  );

  const ContentCX = cx(style.content, {
    [style.small]: small,
  });

  const selectValue = useCallback(
    (selectItem: { label: string; value: string }, item: string) => {
      return !!isAllValueNeed ? selectItem?.value : (item as string);
    },
    [isAllValueNeed]
  );

  const renderDropdownOverlay = useCallback(() => {
    return (
      <div className={ContentCX}>
        <div className={style.wrapper}>
          {items.map((item: ISelectValue, ind) => {
            const selectItem = item as { label: string; value: string };
            return (
              <div
                ref={ind === index ? ref : undefined}
                className={ItemCX(ind)}
                key={selectValue(selectItem, item as string)}
                onClick={handleItemClick(item, ind)}
                title={isAllValueNeed ? selectItem?.label : (item as string)}
              >
                {isAllValueNeed ? selectItem?.label : (item as string)}
              </div>
            );
          })}
        </div>
      </div>
    );
  }, [
    ContentCX,
    ItemCX,
    handleItemClick,
    index,
    isAllValueNeed,
    items,
    selectValue,
  ]);

  const handleVisibleChnge = useCallback((visible: boolean) => {
    setIsOpen(visible);
  }, []);

  const allValue = useMemo(() => {
    return value as { value: string; label: string };
  }, [value]);

  const RootCX = cx(style.root, {
    [style.disabled]: disabled,
    [className ? className : ""]: className,
    [style.small]: !!small,
    [style.open]: isOpen,
    [style.withoutBorder]: !!withoutBorder,
  });

  const PlaceholderCX = cx(style.placeholder, {
    [style.settedValue]: isAllValueNeed ? allValue?.value?.toString() : value,
  });

  const ArrowCX = cx(style.arrow, {
    [style.open]: isOpen,
  });

  return (
    <div className={RootCX} onClick={handleRootClick}>
      <Dropdown
        overlay={renderDropdownOverlay()}
        onVisibleChange={handleVisibleChnge}
      >
        <button className={style.btn}>
          {Boolean(value) && (
            <div className={style.value}>
              {isAllValueNeed ? allValue?.label : (value as string)}
            </div>
          )}
          {!!small && <ArrowDown className={ArrowCX} />}
          <div className={PlaceholderCX}>{placeholder}</div>
        </button>
      </Dropdown>
    </div>
  );
};
