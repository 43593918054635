import { useAppDispatch, useAppSelector } from "app/hooks";
import { CorridorChartItem } from "components/corridorChartItem";
import { setPlotting } from "features/analyze/slice";
import { corridor$, fetchCorridor, isLoading$ } from "features/corridor/slice";
import { currentProfileData$ } from "features/portfolioProfile/slice";
import { useEffect } from "react";

interface IProps {
  portfolio_id: string | null;
}
export const CorridorContent = ({ portfolio_id }: IProps) => {
  const currentProfileData = useAppSelector(currentProfileData$);
  const isLoading = useAppSelector(isLoading$);
  const corridor = useAppSelector(corridor$);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (portfolio_id && currentProfileData?.year) {
      dispatch(
        fetchCorridor({ id: portfolio_id, year: currentProfileData!.year! })
      );

      dispatch(setPlotting([]));
    }
  }, [currentProfileData, dispatch, portfolio_id]);

  return (
    <CorridorChartItem
      portfolio_id={portfolio_id}
      isLoading={isLoading}
      data={corridor}
      year={currentProfileData?.year}
      priceBenchmark={corridor?.price_benchmark}
      pricePortfolio={corridor?.price_portfolio}
    />
  );
};
