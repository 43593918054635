import { useMemo, useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { ChartPlaceholder } from "components/chartPlaceholder";
import { GraphListToggler } from "components/graphListToggler";
import { Freeze } from "react-freeze";
import {
  currentGraphId$,
  errorText$,
  graphFiles$,
  isChartDataReady$,
  isLoadingChart$,
  isProcessed$,
  isRecalculateLoading$,
  rolloutChartData$,
  setErrorText,
  setLoading,
  statusError$,
} from "features/rollout/slice";
import { Chart } from "components/chart";
import { useWindowSize } from "utils/hooks";
import { useTranslation } from "react-i18next";

import style from "./chartOptions.module.scss";

export const ChartOptions = () => {
  const [isFreezeCharts, setFreeze] = useState(false);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const isProcessed = useAppSelector(isProcessed$);
  const graphFiles = useAppSelector(graphFiles$);
  const rolloutChartData = useAppSelector(rolloutChartData$);
  const isChartDataReady = useAppSelector(isChartDataReady$);
  const statusError = useAppSelector(statusError$);
  const isLoadingChart = useAppSelector(isLoadingChart$);
  const isRecalculateLoading = useAppSelector(isRecalculateLoading$);
  const errorText = useAppSelector(errorText$);
  const currentGraphId = useAppSelector(currentGraphId$);

  const [error, setError] = useState(false);

  const size = useWindowSize();

  useEffect(() => {
    if (isProcessed && graphFiles.length) {
      const currentFile = graphFiles.find(({ error }) => error);

      if (!!currentFile) {
        setError(true);
        dispatch(setLoading(false));
        dispatch(setErrorText(t("rollout_processing_status_error")));
      } else {
        setError(false);
      }
    } else {
      setError(false);
    }
  }, [currentGraphId, dispatch, graphFiles, isProcessed, t]);

  useEffect(() => {
    if (isChartDataReady) {
      setFreeze(true);
      setTimeout(() => setFreeze(false), 700);
    }
  }, [isChartDataReady]);

  const showChartPlaceholder = useMemo(() => {
    return (
      (isProcessed && !isChartDataReady && !error) ||
      (isProcessed && isFreezeCharts) ||
      isLoadingChart ||
      isRecalculateLoading
    );
  }, [
    isProcessed,
    isChartDataReady,
    error,
    isFreezeCharts,
    isLoadingChart,
    isRecalculateLoading,
  ]);

  const getWidth = useMemo(() => {
    return size?.width ? size.width - 750 : 0;
  }, [size]);

  const shouldRenderChart = useMemo(() => {
    return (
      isProcessed &&
      !!graphFiles.length &&
      isChartDataReady &&
      !isLoadingChart &&
      !isRecalculateLoading
    );
  }, [
    isProcessed,
    graphFiles.length,
    isChartDataReady,
    isLoadingChart,
    isRecalculateLoading,
  ]);

  return (
    <div className={style.root}>
      {showChartPlaceholder || error ? (
        <ChartPlaceholder
          width={getWidth}
          height={323}
          text={statusError || errorText || ""}
          isError={!!statusError.length || !!errorText?.length || error}
        />
      ) : null}

      {shouldRenderChart ? (
        <Freeze freeze={isFreezeCharts}>
          <Chart data={rolloutChartData} />
        </Freeze>
      ) : null}

      {showChartPlaceholder || !!graphFiles.length ? (
        <GraphListToggler list={graphFiles} />
      ) : null}
    </div>
  );
};
