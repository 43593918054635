import { PortfolioYearPicker } from "components/portfolioYearPicker";
import style from "./analyzeContent.module.scss";
import {
  currentProfileData$,
  fetchPortfolioProfile,
  initAllPortfolioProfileData,
  IPortfolioProfileData,
  isLoading$ as isLoadingProfile$,
  profileData$,
} from "features/portfolioProfile/slice";
import {
  initAllSignalsData,
  isLoading$ as isLoadingSignals$,
} from "features/signals/slice";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { SignalsList } from "components/signalsList";
import { initCorridor } from "features/corridor/slice";
import { initAnalyze, isLoading$ } from "features/analyze/slice";
import { CorridorContent } from "containers/corridorContent";
import { AnalyzeChartContent } from "containers/analyzeChartContent";

export const AnalyzeContent = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const [portfolio_id, setPortFolioId] = useState<string | null>(null);
  const [isShowPlot, setShowPlot] = useState(false);
  const profileData = useAppSelector(profileData$);
  const isLoadingSignals = useAppSelector(isLoadingSignals$);
  const isLoadingProfile = useAppSelector(isLoadingProfile$);
  const currentProfileData = useAppSelector(currentProfileData$);
  const isLoadingAnalyze = useAppSelector(isLoading$);

  useEffect(() => {
    if (searchParams.get("id") !== portfolio_id) {
      const id = searchParams.get("id");
      setPortFolioId(id);
    }
  }, [searchParams, portfolio_id]);

  useEffect(() => {
    return () => {
      dispatch(initAllPortfolioProfileData());
      dispatch(initCorridor());
      dispatch(initAnalyze());
      dispatch(initAllSignalsData());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleYearClick = useCallback((item: IPortfolioProfileData) => {}, []);

  const handleInitLoad = useCallback(() => {
    if (portfolio_id) dispatch(fetchPortfolioProfile({ id: portfolio_id }));
  }, [dispatch, portfolio_id]);

  const handleSignalClick = useCallback(() => {
    setShowPlot(false);
  }, []);

  return (
    <div className={style.root}>
      <PortfolioYearPicker
        data={profileData}
        year={currentProfileData?.year as number}
        onClick={handleYearClick}
        disable={isLoadingSignals || isLoadingProfile || isLoadingAnalyze}
        onInitLoad={handleInitLoad}
        portfolioId={portfolio_id as string}
        pageName="analyze"
        isLoading={isLoadingProfile}
      />
      <SignalsList
        portfolio_id={portfolio_id}
        onSignalClick={handleSignalClick}
      />
      <AnalyzeChartContent
        year={currentProfileData?.year}
        portfolio_id={portfolio_id}
        isShowPlottingChart={isShowPlot}
        showPlottingChart={setShowPlot}
      />
      <CorridorContent portfolio_id={portfolio_id} />
    </div>
  );
};
