import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { PublicTemplate } from "containers/publicTemplate";
import { ChangePasswordForm } from "containers/changePasswordForm";
interface IProps {
  isChangePassword?: boolean;
}

export const ChangePassword = ({ isChangePassword }: IProps) => {
  const [searchParams] = useSearchParams();
  const [token, setToken] = useState<string | null>(null);

  useEffect(() => {
    const tok = searchParams.get("token");
    if (tok?.length) {
      setToken(tok);
    }
  }, [searchParams]);

  return (
    <PublicTemplate>
      <ChangePasswordForm token={token} isChangePassword={isChangePassword} />
    </PublicTemplate>
  );
};
