import { useAppDispatch, useAppSelector } from "app/hooks";
import { savePDF } from "@progress/kendo-react-pdf";
import { setAllCurrentProfilesData } from "features/dashboardProfiles/slice";
import {
  IPortfolioProfileData,
  setCurrentProfileData,
} from "features/portfolioProfile/slice";
import { user$ } from "features/user/slice";
import { MutableRefObject, useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Yearitem } from "./components/yearItem";
import style from "./portfolioYearPicker.module.scss";
import { Loader } from "components/loader";

interface IProps {
  year: number;
  disable?: boolean;
  pageName?: string;
  portfolioId?: string;
  isPageForDashboard?: boolean;
  data: IPortfolioProfileData[] | null;
  rootElementRef?: MutableRefObject<HTMLDivElement | null>;
  isLoading?: boolean;
  onInitLoad?: () => void;
  onClick: (item: IPortfolioProfileData, id?: string) => void;
}

export const PortfolioYearPicker = ({
  year,
  data,
  disable,
  pageName,
  portfolioId,
  isPageForDashboard,
  isLoading,
  onClick,
  onInitLoad,
}: IProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (onInitLoad) onInitLoad();
  }, [onInitLoad]);

  const user = useAppSelector(user$);

  const name = useMemo(
    () =>
      user?.portfolio?.find((portfolio) => portfolio.id === portfolioId)?.name,
    [portfolioId, user?.portfolio]
  );

  const handleClick = useCallback(
    (item: IPortfolioProfileData) => {
      isPageForDashboard
        ? dispatch(setAllCurrentProfilesData({ item, portfolioId }))
        : dispatch(setCurrentProfileData(item));
      onClick(item, portfolioId);
    },
    [dispatch, onClick, isPageForDashboard, portfolioId]
  );

  const onExport = () => {
    const rootElement =
      pageName === "analyze"
        ? document.getElementById("analyze-container")
        : document.getElementById("overview-container");
    if (!rootElement) return;

    savePDF(rootElement as HTMLDivElement, {
      paperSize: "auto",
      fileName: `${name}_${pageName}_${year}`,
      margin: 10,
    });
  };

  return (
    <div className={style.root}>
      {!isPageForDashboard && (
        <button className={style.exportAllBtn} onClick={onExport}>
          {t("export_btn_title")}
        </button>
      )}
      {/* <h3 className={style.title}>{`${t("for_portfolio")} '${name}'`}</h3> */}
      <div className={style.content}>
        {data?.map((pdItem: IPortfolioProfileData) => {
          return (
            <Yearitem
              key={pdItem.year}
              item={pdItem}
              isSelected={year === pdItem.year}
              onClick={handleClick}
              disable={disable || pdItem?.missed_profile}
            />
          );
        })}

        {isLoading && (
          <div className={style.loader}>
            <Loader />
          </div>
        )}
      </div>
    </div>
  );
};
