import { useAppSelector } from "app/hooks";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useCallback, useMemo } from "react";
import dayjs from "dayjs";
import "./style.scss";
import { months, years, getMarketDataYears } from "./utils";
import { useTranslation } from "react-i18next";
import {
  marketEndDate$,
  marketStartDate$,
  isLoading$,
} from "features/marketRange/slice";
import style from "./portfolioDatePicker.module.scss";

interface IProps {
  value: string;
  onChange: (value: Date) => void;
  minDate?: Date;
  maxDate?: Date;
  isMarketDataPage?: boolean;
  isWeekDayDisabled?: boolean;
}

export const PortfolioDatePicker = ({
  value,
  onChange,
  minDate,
  maxDate,
  isMarketDataPage,
  isWeekDayDisabled,
}: IProps) => {
  const { t } = useTranslation();
  const startDate = useAppSelector(marketStartDate$);
  const endDate = useAppSelector(marketEndDate$);
  const isLoading = useAppSelector(isLoading$);

  const isMarketRangeLoaded = useMemo(
    () => endDate && startDate && !isLoading,
    [endDate, startDate, isLoading]
  );

  const onExcludeWeekday = (date: Date) => {
    const day = getDay(date);
    return day !== 0 && day !== 6;
  };

  const handleChange = useCallback(
    (date: Date | null | undefined) => {
      if (date) {
        onChange(date);
      }
    },
    [onChange]
  );

  const getYear = (y: Date) => {
    return dayjs(y).year();
  };

  const getMonth = (y: Date) => {
    return dayjs(y).month();
  };

  const getDay = (y: Date) => {
    return dayjs(y).day();
  };

  const datePickerYears =
    isMarketDataPage && isMarketRangeLoaded
      ? getMarketDataYears(startDate as string, endDate as string)
      : years;

  return (
    <DatePicker
      value={value}
      dateFormat="yyyy-MM-dd"
      selected={value ? new Date(value) : undefined}
      onChange={handleChange}
      className={"test-ccc"}
      showPopperArrow={false}
      filterDate={isWeekDayDisabled ? onExcludeWeekday : undefined}
      minDate={minDate}
      maxDate={maxDate}
      renderCustomHeader={({
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
      }) => (
        <div
          style={{
            margin: 10,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <button
            onClick={decreaseMonth}
            disabled={prevMonthButtonDisabled}
            className={style.btn}
          >
            {"<"}
          </button>
          <select
            value={getYear(date)}
            onChange={({ target: { value } }) => changeYear(Number(value))}
          >
            {datePickerYears.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>

          <select
            value={months[getMonth(date)]}
            onChange={({ target: { value } }) => {
              changeMonth(months.indexOf(value));
            }}
          >
            {months.map((option) => (
              <option key={option} value={option}>
                {t(option)}
              </option>
            ))}
          </select>

          <button
            onClick={increaseMonth}
            disabled={nextMonthButtonDisabled}
            className={style.btn}
          >
            {">"}
          </button>
        </div>
      )}
    />
  );
};
