import { useAppSelector } from "app/hooks";
import { currentProfileData$ } from "features/portfolioProfile/slice";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import { convertWatt } from "utils";
import style from "./portfolioProfileData.module.scss";

interface IItemProps {
  label: string;
  value?: string | number;
  suffix: string;
}
export const PortfolioProfileDataItem = memo(
  ({ value, label, suffix }: IItemProps) => {
    return (
      <div className={style.item}>
        <div className={style.label}>{label + ":"}</div>
        <div className={style.value}>{`${value || 0} ${suffix}`}</div>
      </div>
    );
  }
);

interface IProps {
  year: number;
}
export const PortfolioProfileData = ({ year }: IProps) => {
  const currentProfileData = useAppSelector(currentProfileData$);
  const { t } = useTranslation();
  return (
    <div className={style.root}>
      <div className={style.title}>{`${t("profile_load_data")} ${year}`}</div>

      <div className={style.items}>
        <PortfolioProfileDataItem
          label={t("energy")}
          value={
            convertWatt(Number(currentProfileData?.energy), "h", "MW", 2) || 0
          }
          suffix={""}
        />
        <PortfolioProfileDataItem
          label={t("power_max")}
          value={
            convertWatt(
              Number(currentProfileData?.power_max),
              undefined,
              "MW",
              2
            ) || 0
          }
          suffix={""}
        />
        <PortfolioProfileDataItem
          label={t("power_min")}
          value={
            convertWatt(
              Number(currentProfileData?.power_min),
              undefined,
              "MW",
              2
            ) || 0
          }
          suffix={""}
        />
        <PortfolioProfileDataItem
          label={t("number_hours_base")}
          value={currentProfileData?.base_hours}
          suffix={t("h")}
        />
        <PortfolioProfileDataItem
          label={t("number_hours_peak")}
          value={currentProfileData?.peak_hours}
          suffix={t("h")}
        />
      </div>
    </div>
  );
};
