import style from "./portfolioContractsTableHeader.module.scss";
import cx from "classnames";
import { headers, headersWithoutActions } from "./utils";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";

interface IProps {
  hideSomeColumns?: boolean;
  hasScroll: boolean;
  isHideActions?: boolean;
}
export const PortfolioContractsTableHeader = ({
  hideSomeColumns,
  hasScroll,
  isHideActions,
}: IProps) => {
  const RootCX = cx(style.root, {
    [style.hideSomeColumns]: hideSomeColumns,
    [style.hasScroll]: hasScroll,
    [style.isHideActions]: isHideActions,
  });

  const ItemCX = (index: number) =>
    cx(style.item, {
      [style.first]: index === 0,
      [style.last]: index === headers.length - 1,
    });

  const { t } = useTranslation();
  const items = useMemo(() => {
    return isHideActions ? headersWithoutActions : headers;
  }, [isHideActions]);

  return (
    <div className={RootCX}>
      {items.map((header, index) => {
        if (
          (hideSomeColumns && header === "contracts_table_header_price_chf") ||
          (hideSomeColumns && header === "contracts_table_header_worth_chf")
        )
          return null;
        return (
          <div key={header} className={ItemCX(index)}>
            {t(header)}
          </div>
        );
      })}
    </div>
  );
};
