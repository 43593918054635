import { IPortfolioProfileData } from "features/portfolioProfile/slice";
import style from "./yearItem.module.scss";
import cx from "classnames";
import { useCallback, useMemo } from "react";
import { convertWatt } from "utils";
import { useTranslation } from "react-i18next";

interface IProps {
  isSelected: boolean;
  item: IPortfolioProfileData;
  onClick: (item: IPortfolioProfileData) => void;
  disable?: boolean;
}

export const Yearitem = ({ isSelected, item, onClick, disable }: IProps) => {
  const RootCX = cx(style.root, {
    [style.selected]: isSelected,
    [style.disable]: disable,
  });

  const { t } = useTranslation();

  const percentage = useMemo(() => {
    const result = (Number(item.contracts_sum) / Number(item.energy!)) * 100;
    return `${result < 0 ? 0 : result === Infinity ? 0 : result.toFixed(1)}%`;
  }, [item]);

  const calculation = useMemo(() => {
    return `${convertWatt(
      Number(item?.contracts_sum),
      "h",
      "MW",
      2
    )}/${convertWatt(Number(item?.energy), "h", "MW", 2)}`;
  }, [item]);

  const handleClick = useCallback(() => {
    if (disable) return;
    onClick(item);
  }, [disable, item, onClick]);

  return (
    <div className={RootCX} onClick={handleClick}>
      <div className={style.year}>{item.year}</div>

      {item?.missed_profile && (
        <div className={style.calculation}>{t("no_profile_text")}</div>
      )}
      {!item?.missed_profile && (
        <>
          <div className={style.percentage}>{percentage}</div>
          <div className={style.calculation}>{calculation}</div>
        </>
      )}
    </div>
  );
};
