import { useMemo } from "react";
import style from "./tableWithOneRow.module.scss";
import cx from "classnames";
import { useTranslation } from "react-i18next";

interface IProps {
  headers: string[];
  firstHeader?: string;
  data: string[] | number[];
  firstData?: string | number;
  isLoading?: boolean;
  rootClassName?: string;
}

export const TableWithOneRow = ({
  headers,
  firstHeader,
  data,
  firstData,
  isLoading,
  rootClassName,
}: IProps) => {
  const minHeaderWidth = useMemo(() => {
    return firstHeader ? 50 + headers.length * 73 : headers.length * 73;
  }, [firstHeader, headers.length]);

  const RootCX = cx(style.root, {
    [style.loading]: isLoading,
    [rootClassName || ""]: !!rootClassName,
  });

  const { t } = useTranslation();

  return (
    <div
      className={RootCX}
      style={{
        minWidth: minHeaderWidth,
      }}
    >
      <div
        className={style.tableHead}
        style={{
          gridTemplateColumns: `${firstHeader ? "50px" : ""} repeat(${
            headers.length
          }, 1fr)`,
        }}
      >
        {!!firstHeader && <div>{firstHeader}</div>}
        {headers.map((th, i) => {
          return <div key={i}>{t(th)}</div>;
        })}
      </div>
      <div
        className={style.tableBody}
        style={{
          gridTemplateColumns: `${firstData ? "50px" : ""} repeat(${
            data.length
          }, 1fr)`,
        }}
      >
        {!!firstData && <div>{firstData}</div>}
        {data.map((td, i) => {
          return <div key={i}>{isNaN(+td) ? td : Number(td)?.toFixed(2)}</div>;
        })}
      </div>
    </div>
  );
};
