import { useCallback, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import cx from "classnames";
import {
  changePass,
  createPasswordByToken,
  isConfirmed$,
  isError$,
  isLoading$,
  setAuthError,
  setInitial,
} from "features/changePassword/slice";
import { accessToken$ } from "features/auth/authSlice";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { ReactComponent as EyeIcon } from "assets/svg/eye.svg";
import { ReactComponent as EyeClosedIcon } from "assets/svg/eyeClosed.svg";
import style from "./changePasswordForm.module.scss";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

interface IProps {
  token: string | null;
  isChangePassword?: boolean;
}
export const ChangePasswordForm = ({ token, isChangePassword }: IProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isLoading = useAppSelector(isLoading$);
  const isError = useAppSelector(isError$);
  const accessToken = useAppSelector(accessToken$);
  const isConfirmed = useAppSelector(isConfirmed$);
  const [showPass, setShowPass] = useState(false);
  const [showOldPass, setShowOldPass] = useState(false);
  const [showPass2, setShowPass2] = useState(false);

  const newPassSchema = yup.object({
    password: yup
      .string()
      .required(t("required_password"))
      .matches(
        // eslint-disable-next-line no-useless-escape
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
        t("password_validation_rules")
      ),
    password_repeat: yup
      .string()
      .required(t("required_password"))
      .oneOf([yup.ref("password"), null], t("password_match_error")),
  });

  const changePassSchema = yup.object({
    old_password: yup
      .string()
      .required(t("required_password"))
      .matches(
        // eslint-disable-next-line no-useless-escape
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
        t("password_validation_rules")
      ),
    password: yup
      .string()
      .required(t("required_password"))
      .matches(
        // eslint-disable-next-line no-useless-escape
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
        t("password_validation_rules")
      ),
    password_repeat: yup
      .string()
      .required(t("required_password"))
      .oneOf([yup.ref("password"), null], t("password_match_error")),
  });

  const validationOption = {
    resolver: yupResolver(isChangePassword ? changePassSchema : newPassSchema),
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = useForm(validationOption);

  const onButtonClick = () => {
    if (accessToken) {
      navigate("/app/dashboard");
      dispatch(setInitial());
    } else {
      navigate("/app");
    }
  };

  const handleOldPassClick = useCallback(() => {
    setShowOldPass((prev) => !prev);
  }, []);

  const handlePassClick = useCallback(() => {
    setShowPass((prev) => !prev);
  }, []);

  const handlePassClick2 = useCallback(() => {
    setShowPass2((prev) => !prev);
  }, []);

  const navigateToDashboard = () => {
    navigate("/app/dashboard");
  };

  const onSubmit = async (data: any) => {
    const { password } = data;
    if (token && !isChangePassword) {
      await dispatch(createPasswordByToken({ password, token }));
      reset();
    } else if (isChangePassword) {
      await dispatch(
        changePass({
          callback: navigateToDashboard,
          password,
          old_password: data?.old_password,
        })
      );
      reset();
    }
  };

  const renderSuccessMessage = () => {
    return (
      <div className={style.success}>
        <div className={style.title}>{t("change_password_title")}</div>
        <div className={style.subtitle}>
          {t("change_password_success_subtitle")}
        </div>
        <button className={style.btn} onClick={onButtonClick}>
          {t("go_to_main")}
        </button>
      </div>
    );
  };

  const handleFormClick = () => {
    dispatch(setAuthError(false));
  };

  const formCX = cx(style.root, {
    [style.error]: isError || errors?.password_repeat || errors?.password,
  });

  const password = useRef({});
  password.current = watch("password", "");

  const passwordRepeatCX = cx(style.inputContainer, {
    [style.inputContainer2]: errors?.password?.message,
  });

  return (
    <>
      {!isConfirmed && (
        <form
          className={formCX}
          onSubmit={handleSubmit(onSubmit)}
          onClick={handleFormClick}
        >
          <div className={style.title}>
            {isChangePassword
              ? t("nav_change_password")
              : t("change_password_head")}
          </div>
          <div className={style.inputContainer}>
            {!!isChangePassword && (
              <>
                <input
                  className={style.input}
                  disabled={isLoading}
                  id="old_password"
                  {...register("old_password", {
                    required: t("required_password"),
                    minLength: {
                      value: 8,
                      message: t("change_old_password_placeholder"),
                    },
                  })}
                  placeholder={t("change_old_password_placeholder")}
                  type={showOldPass ? "text" : "password"}
                />

                <div
                  className={style.passIconContainer}
                  onClick={handleOldPassClick}
                >
                  {showOldPass ? (
                    <EyeClosedIcon className={style.passIconClosed} />
                  ) : (
                    <EyeIcon className={style.passIcon} />
                  )}
                </div>
                {isError && (
                  <div className={style.err}>{t("change_password_error")}</div>
                )}
              </>
            )}
          </div>
          <div className={style.inputContainer}>
            <input
              className={style.input}
              disabled={isLoading}
              id="password"
              {...register("password", {
                required: t("required_password"),
                minLength: {
                  value: 8,
                  message: t("sign_in_password_placeholder"),
                },
              })}
              placeholder={t("new_password_placeholder")}
              type={showPass ? "text" : "password"}
            />
            <div className={style.passIconContainer} onClick={handlePassClick}>
              {showPass ? (
                <EyeClosedIcon className={style.passIconClosed} />
              ) : (
                <EyeIcon className={style.passIcon} />
              )}
            </div>
            {isError && (
              <div className={style.err}>{t("change_password_error")}</div>
            )}
          </div>
          <div className={passwordRepeatCX}>
            <input
              className={style.input}
              disabled={isLoading}
              placeholder={t("new_password_confirm_placeholder")}
              id="password_repeat"
              type={showPass2 ? "text" : "password"}
              {...register("password_repeat")}
            />
            <div className={style.passIconContainer} onClick={handlePassClick2}>
              {showPass2 ? (
                <EyeClosedIcon className={style.passIconClosed} />
              ) : (
                <EyeIcon className={style.passIcon} />
              )}
            </div>
            {isError && (
              <div className={style.err}>{t("change_password_error")}</div>
            )}

            {(errors?.password && !isError && !errors?.password_repeat) ||
            (errors?.password_repeat && !isError) ? (
              <div className={style.err}>
                {errors?.password_repeat?.message || errors?.password?.message}
              </div>
            ) : null}
          </div>
          <button
            type="submit"
            className={style.btn}
            disabled={
              !isChangePassword
                ? isLoading || !watch("password") || !watch("password_repeat")
                : isLoading ||
                  !watch("password") ||
                  !watch("password_repeat") ||
                  !watch("old_password")
            }
          >
            {!!isChangePassword ? t("change") : t("submit_button")}
          </button>
        </form>
      )}
      {isChangePassword && !isConfirmed ? (
        <div className={style.link} onClick={navigateToDashboard}>
          {t("back_to_dashboard_link")}
        </div>
      ) : null}
      {isConfirmed && renderSuccessMessage()}
      <footer className={style.footer}>{t("sign_in_copyright")}</footer>
    </>
  );
};
