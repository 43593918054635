import { getCurrentYear } from "utils";

export const months = [
  "january_datepicker",
  "february_datepicker",
  "march_datepicker",
  "april_datepicker",
  "may_datepicker",
  "june_datepicker",
  "july_datepicker",
  "august_datepicker",
  "september_datepicker",
  "october_datepicker",
  "november_datepicker",
  "december_datepicker",
];

export const years = [
  ...Array.from({ length: 21 }, (_, i) => getCurrentYear() + (i + 1) - 21),
  ...Array.from({ length: 3 }, (_, i) => getCurrentYear() + 1 + i++),
];

export const getMarketDataYears = (startDate: string, endDate: string) => {
  const firstYear = new Date(startDate).getFullYear();
  const lastYear = new Date(endDate).getFullYear();

  return Array(lastYear - firstYear + 1)
    .fill("")
    .map((_, index) => firstYear + index);
};
