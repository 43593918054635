import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { refreshToken, setDisconnect } from "features/auth/authSlice";
import { GET_ANALYZE_DATA } from "./constants";
import { RootState } from "app/store";
import { Api } from "api";
import axios from "axios";
import { IMarketData } from "features/marketData/slice";

export interface AnalyzePlot {
  x: string;
  y: number;
}
export interface AnalyzeState {
  isLoading: boolean;
  isError: boolean;
  baseX: string[];
  peakX: string[];
  baseY: number[];
  peakY: number[];
  chartTitle: string;
  dataType: "base" | "peak" | null | null;
  products: string;
  plotting: AnalyzePlot[];
}

const initialState: AnalyzeState = {
  isLoading: false,
  isError: false,
  baseX: [],
  peakX: [],
  baseY: [],
  peakY: [],
  chartTitle: "",
  plotting: [],
  dataType: null,
  products: "",
};

interface IAnalyzeRequest {
  period: string;
  year: number | string;
}

export const fetchAnalyzeData = createAsyncThunk(
  GET_ANALYZE_DATA,
  async (params: IAnalyzeRequest, api) => {
    // api.dispatch(updateSelectedSignal(null));
    try {
      const res = await axios.get(`${Api.GetMarketData}`, {
        params,
      });

      if (!res) {
        throw new Error("Error");
      }

      return res?.data || null;
    } catch (error: any) {
      api.dispatch(setAnalyzeError(true));
      if (error?.request?.status === 403) {
        api.dispatch(setDisconnect(true));
      } else if (error?.request?.status === 401) {
        api.dispatch(
          refreshToken({ callback: fetchAnalyzeData, parameter: params })
        );
      }
      return api.rejectWithValue("No Market Data found");
    }
  }
);

export const analyzeSlice = createSlice({
  name: "analyze",
  initialState,
  reducers: {
    setAnalyzeError(state, action) {
      state.isError = action.payload;
    },
    initAnalyze(state) {
      state.isError = false;
      state.isLoading = false;
      state.baseX = [];
      state.peakX = [];
      state.baseY = [];
      state.peakY = [];
      state.chartTitle = "";
      state.dataType = "base";
      state.products = "";
    },
    setLoading(state, action) {
      state.isLoading = action.payload;
    },
    setAnalyzeChartTitle(state, action) {
      state.chartTitle = action.payload;
    },
    setAnalyzeChartDatatype(state, action) {
      state.dataType = action.payload;
    },
    setAnalyzeProducts(state, action) {
      state.products = action.payload;
    },
    setPlotting(state, action) {
      state.plotting = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAnalyzeData.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.baseX = [];
        state.peakX = [];
        state.baseY = [];
        state.peakY = [];
        state.chartTitle = "";
        state.plotting = [];
        state.dataType = null;
        state.products = "";
      })
      .addCase(fetchAnalyzeData.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.isError = false;
        state.baseX = (payload.market_data as IMarketData["market_data"])
          .map((md) => (md.data?.length ? md.date : null))
          .filter(Boolean) as string[];

        state.peakX = (payload.market_data as IMarketData["market_data"])
          .map((md) => (md.data?.length ? md.date : null))
          .filter(Boolean) as string[];

        state.baseY = (payload.market_data as IMarketData["market_data"])
          .map((md) => md.data[0]?.base)
          .filter((v) => v !== null || v !== undefined);
        state.peakY = (payload.market_data as IMarketData["market_data"])
          .map((md) => md.data[0]?.peak)
          .filter((v) => v !== null || v !== undefined);
      })
      .addCase(fetchAnalyzeData.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      });
  },
});

export const {
  setAnalyzeError,
  initAnalyze,
  setLoading,
  setAnalyzeChartTitle,
  setAnalyzeChartDatatype,
  setAnalyzeProducts,
  setPlotting,
} = analyzeSlice.actions;

export const isLoading$ = (state: RootState) => state.analyze.isLoading;
export const isError$ = (state: RootState) => state.analyze.isError;
export const analyzeBaseX$ = (state: RootState) => state.analyze.baseX;
export const analyzeBaseY$ = (state: RootState) =>
  state.analyze.baseY?.filter(Boolean);
export const analyzePeakY$ = (state: RootState) =>
  state.analyze.peakY?.filter(Boolean);
export const analyzePeakX$ = (state: RootState) => state.analyze.peakX;
export const analyzeChartTitle$ = (state: RootState) =>
  state.analyze.chartTitle;

export const analyzePlotting$ = (state: RootState) => state.analyze.plotting;
export const analyzeDataType$ = (state: RootState) => state.analyze.dataType;
export const analyzeProducts$ = (state: RootState) => state.analyze.products;

export default analyzeSlice.reducer;
