import { AvatarDropdown } from "components/avatarDropdown";
import { CurrentDate } from "components/currentDate";
import { DropdownTranslation } from "components/dropdownTranslation";
import style from "./privateHeader.module.scss";

export const PrivateHeader = () => {
  return (
    <header className={style.root}>
      <CurrentDate />
      <div className={style.rightSide}>
        <DropdownTranslation />
        <div className={style.line} />
        <AvatarDropdown />
      </div>
    </header>
  );
};
