import { memo, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ChartPlaceholder } from "components/chartPlaceholder";
import {
  isLoading$ as isLoadingGraph$,
  isLoadingPlotting$,
  portfolioProfileGraph$,
} from "features/portfolioProfileGraph/slice";
import { isError$ } from "features/portfolioProfileGraph/slice";
import { isLoading$ as isLoadingPortfolioProfile$ } from "features/portfolioProfileUpload/slice";
import { currentProfileData$ } from "features/portfolioProfile/slice";
import { useAppSelector } from "app/hooks";
import { Size, useWindowSize } from "utils/hooks";
import style from "./portfolioChartPlaceholder.module.scss";

export const PortfolioChartPlaceholder = memo(() => {
  const { t } = useTranslation();
  const size: Size = useWindowSize();
  const currentProfileData = useAppSelector(currentProfileData$);
  const isError = useAppSelector(isError$);
  const isLoadingPortfolioProfile = useAppSelector(isLoadingPortfolioProfile$);
  const isLoadingGraph = useAppSelector(isLoadingGraph$);
  const isLoadingPlotting = useAppSelector(isLoadingPlotting$);
  const portfolioProfileGraph = useAppSelector(portfolioProfileGraph$);

  const showChartPlaceholder = useMemo(
    () =>
      isLoadingGraph ||
      isError ||
      isLoadingPortfolioProfile ||
      isLoadingPlotting,
    [isError, isLoadingPortfolioProfile, isLoadingGraph, isLoadingPlotting]
  );

  const getWidth = useMemo(() => {
    return size?.width ? size.width - 850 : 0;
  }, [size]);

  const showEmptyPlaceholderText = useMemo(() => {
    if (
      portfolioProfileGraph === null &&
      !isLoadingGraph &&
      !isLoadingPortfolioProfile &&
      !isLoadingPlotting &&
      !isError
    ) {
      setTimeout(() => {
        return true;
      }, 1000);
    } else {
      return false;
    }
  }, [
    isError,
    isLoadingPortfolioProfile,
    isLoadingGraph,
    portfolioProfileGraph,
    isLoadingPlotting,
  ]);

  const showEmptyPlaceholder = useMemo(
    () =>
      !portfolioProfileGraph &&
      !isLoadingGraph &&
      !isLoadingPortfolioProfile &&
      !isLoadingPlotting &&
      !isError,
    [
      isError,
      isLoadingPortfolioProfile,
      isLoadingGraph,
      portfolioProfileGraph,
      isLoadingPlotting,
    ]
  );

  return (
    <>
      {showChartPlaceholder && (
        <ChartPlaceholder
          isError={isError}
          width={getWidth}
          height={323}
          text={isError ? t("rollout_processing_status_error") : ""}
          className={style.placeholder}
        />
      )}

      {showEmptyPlaceholder && (
        <div className={style.emptyPlaceholder}>
          {(showEmptyPlaceholderText || currentProfileData?.missed_profile) && (
            <div className={style.emptyText}>
              {t("portfolio_profile_empty")}
              {` ${currentProfileData?.year || ""}`}
            </div>
          )}
        </div>
      )}
    </>
  );
});
