export const legendColors: Record<string, string> = {
  "Scenario 1": "#8BBA05",
  "Scenario 2": "#66AC4E",
  "Scenario 3": "#198754",
  isNotActive: "#6e7991",
};

export const longTermPricesChartOptions = {
  ylabel: "EUR/MWh",
  axes: {
    x: {
      axisLineWidth: 2,
      gridLinePattern: [7, 7],
      axisLineColor: "#6E7991",
      gridLineColor: "#6E7991",
    },
    y: {
      axisLineWidth: 2,
      gridLinePattern: [7, 7],
      axisLineColor: "#6E7991",
      gridLineColor: "#6E7991",
    },
  },
  colors: ["#8BBA05", "#66AC4E", "#198754"],
  showRangeSelector: true,
  animatedZooms: true,
  drawCallback: () => {
    const axisLabels = document.getElementsByClassName(
      "dygraph-axis-label"
    ) as unknown as HTMLElement[];
    Array.from(axisLabels).forEach((label) => {
      label.style.color = "#6E7991";
    });
  }
};