interface IOptions {
  categories: string[];
  yTitle?: string;
  tooltipSuffix?: string;
  tooltipPrefix?: string;
  mainTitle?: string;
  filename?: string;
}

export const makeOptions = ({
  categories,
  yTitle,
  tooltipSuffix,
  tooltipPrefix,
  mainTitle,
  filename,
}: IOptions) => {
  return {
    chart: {
      type: "bar",
      height: 230,
      width: 500,
      offsetX: -30,
      toolbar: {
        export: {
          svg: {
            filename,
          },
          png: {
            filename,
          },
          csv: {
            filename,
          },
        },
      },
      zoom: {
        enabled: true,
        type: "x",
        autoScaleYaxis: false,
        zoomedArea: {
          fill: {
            color: "#90CAF9",
            opacity: 0.4,
          },
          stroke: {
            color: "#0D47A1",
            opacity: 0.4,
            width: 1,
          },
        },
      },
    },
    title: {
      text: mainTitle,
      align: "left",
      margin: 10,
      offsetX: 60,
      offsetY: 0,
      floating: false,
      style: {
        fontSize: "14px",
        fontWeight: "bold",
        fontFamily: undefined,
        color: "#6E7991",
      },
    },
    colors: ["#8BBA05", "#3F1FED"],
    plotOptions: {
      bar: {
        horizontal: false,
        // columnWidth: 30,
        endingShape: "rounded",
        borderRadius: 2,
        rangeBarOverlap: false,
        barHeight: "70%",
        columnWidth: 30 + 60 / (1 + 30 * Math.exp(-categories.length / 3)),
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      colors: ["transparent"],
      width: 5,
    },
    xaxis: {
      tickPlacement: "on",
      categories,
      axisBorder: {
        show: true,
        color: "#6E7991",
        height: 1.5,
        width: "100%",
        offsetX: 0,
        offsetY: 0,
      },
    },
    yaxis: {
      showAlways: true,
      title: {
        text: yTitle,
        rotate: 0,
        offsetX: 25,
        offsetY: -140,
        style: {
          color: "#6E7991",
          fontSize: "14px",
          fontFamily: "Gilroy, Arial, sans-serif",
          fontWeight: 500,
        },
      },
      labels: {
        style: {
          colors: "#6E7991",
          fontSize: "14px",
          fontFamily: "Gilroy, Arial, sans-serif",
          fontWeight: 400,
        },
        formatter: (value: number) => value.toFixed(2),
      },
      axisBorder: {
        show: true,
        color: "#6E7991",
        offsetX: 0,
        offsetY: 0,
        width: 1.5,
      },
    },
    fill: {
      opacity: 1,
    },
    grid: {
      borderColor: "rgba(110, 121, 145, 0.2)",
      strokeDashArray: 7,
    },
    tooltip: {
      y: {
        formatter: function (val: string) {
          const suffixProp = tooltipSuffix || "";
          const prefixProp = tooltipPrefix || "";
          return `${suffixProp} ${val} ${prefixProp}`;
        },
      },
    },
  };
};

export const monthCategories = [
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
];

export const yearCategories = ["All Year"];
