import { useAppSelector } from "app/hooks";
import cx from "classnames";
import { analyzeChartTitle$ } from "features/analyze/slice";
import { IFormattedSignal } from "features/signals/slice";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { capitalizeFirstLetter } from "utils";
import style from "./signal.module.scss";

interface IProps {
  item: IFormattedSignal;
  onClick: (id: IFormattedSignal) => void;
  disabled?: boolean;
}

export const Signal = ({ item, onClick, disabled }: IProps) => {
  const chartTitle = useAppSelector(analyzeChartTitle$);
  const handleItemClick = () => {
    !disabled && onClick(item);
  };

  const currentSignalTitle = `Analyze ${item.products} ${capitalizeFirstLetter(
    item.type
  )}`;

  const isSelected = chartTitle && currentSignalTitle === chartTitle;

  const { t } = useTranslation();

  const RootCX = cx(style.root, {
    [style.selected]: isSelected,
    [style.disabled]: !!disabled,
  });

  const title = useMemo(() => {
    const translatedProducts = item.products
      .split(" ")
      .map((product) => t(product.toLowerCase()))
      .join(" ");
    return (
      translatedProducts +
      " " +
      item.type.charAt(0).toUpperCase() +
      item.type.slice(1)
    );
  }, [item.products, item.type, t]);

  const message = useMemo(() => {
    if (item.message === "No signal") return t("no_signal");
    return item.message
      .split(" ")
      .map((it, ind) => (ind === 0 ? t(it.slice(0, -1).toLowerCase()) : it))
      .join(" ");
  }, [item.message, t]);

  const MessageCX = cx(style.message, {
    [style.primary]: item.color === "primary",
    [style.success]: item.color === "success",
    [style.danger]: item.color === "danger",
  });

  return (
    <div className={RootCX} onClick={handleItemClick}>
      <div className={style.title}>{title}</div>
      <div className={MessageCX}>{message}</div>
    </div>
  );
};
