import { MarketDataContent } from "containers/marketDataContent";
import style from "./marketData.module.scss";

export const MarketData = () => {
  return (
    <div className={style.root}>
      <MarketDataContent />
    </div>
  );
};
