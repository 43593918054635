import { useTranslation } from "react-i18next";
import { Auth } from "features/auth/auth";
import style from "./signIn.module.scss";
import { PublicTemplate } from "containers/publicTemplate";

export const SignInPage = () => {
  const { t } = useTranslation();

  return (
    <PublicTemplate>
      <>
        <div className={style.form}>
          <div className={style.title}>{t("sign_in_title")}</div>
          <div className={style.subtitle}>{t("sign_in_subtitle")}</div>
          <Auth />
        </div>
        <footer className={style.footer}>{t("sign_in_copyright")}</footer>
      </>
    </PublicTemplate>
  );
};
