import { FC } from "react";
import { useAppSelector } from "app/hooks";
import { Signal } from "components/signal";
import { isLoading$ } from "features/analyze/slice";
import { IFormattedSignal } from "features/signals/slice";
import style from "./sortedSignalsWithTitle.module.scss";

interface IProps {
  title: string;
  signals: IFormattedSignal[];
  onSelect: (v: IFormattedSignal) => void;
}

export const SortedSignalsWithTitle: FC<IProps> = ({
  title,
  signals,
  onSelect,
}) => {
  const isLoading = useAppSelector(isLoading$);

  return (
    <div className={style.root}>
      <div className={style.title}>{title}</div>
      <div className={style.body}>
        {signals.map((signal) => {
          return (
            <Signal
              key={signal.id}
              item={signal}
              onClick={onSelect}
              disabled={isLoading}
            />
          );
        })}
      </div>
    </div>
  );
};
