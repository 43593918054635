import i18n from "i18n";
import axios from "axios";
import { Api } from "api";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { refreshToken, setDisconnect } from "features/auth/authSlice";
import { IPortfolioProfileData } from "features/portfolioProfile/slice";
import { allCurrentProfileData$ } from "features/dashboardProfiles/slice";
import { IPortfolioHedgeRequest, IProtfolioHedge } from "features/hedge/slice";
import { GET_HEDGE_CHART_BY_ID, GET_ALL_HEDGE_CHART } from "./constants";

interface DashboardHedgeState {
  data: IDashboardHedge[] | null;
  isLoading: HedgeLoadState;
  isError: HedgeLoadState;
  hedgeError: AllHedgeErrors;
}

const initialState: DashboardHedgeState = {
  data: null,
  isLoading: {},
  isError: {},
  hedgeError: {},
};

export interface IDashboardHedge extends IProtfolioHedge {
  portfolioId: string;
  isLoading?: boolean;
}

export interface AllHedgeErrors {
  [key: string]: string | boolean;
}

export interface HedgeLoadState {
  [key: string]: boolean;
}

export const fetchAllPortfolioHedge = createAsyncThunk(
  GET_ALL_HEDGE_CHART,
  async (_, api) => {
    try {
      const response: IDashboardHedge[] = [];
      const allCurrentProfileData = allCurrentProfileData$(
        api.getState() as RootState
      );

      if (allCurrentProfileData?.length) {
        const data = allCurrentProfileData?.map((item) =>
          api.dispatch(
            fetchPortfolioHedgeById({
              year: (item.data as IPortfolioProfileData).year,
              portfolio_id: item.id,
              period: "year",
            })
          )
        );

        await Promise.all(data as any).then((result) =>
          result.forEach((item, index) => {
            response.push({
              ...item.payload.data,
              portfolioId: allCurrentProfileData[index].id,
            });
          })
        );
      }

      return response || null;
    } catch (error: any) {}
  }
);

export const fetchPortfolioHedgeById = createAsyncThunk(
  GET_HEDGE_CHART_BY_ID,
  async ({ portfolio_id, year, period }: IPortfolioHedgeRequest, api) => {
    try {
      api.dispatch(setLoading({ portfolio_id, isLoading: true }));
      const res = await axios.get(`${Api.GetHedge}`, {
        params: {
          period,
          year,
          portfolio_id,
        },
      });

      if (!res) {
        throw new Error("Error");
      }
      api.dispatch(clearErrors({ id: portfolio_id }));
      api.dispatch(setLoading({ portfolio_id, isLoading: false }));

      return res ? { portfolioId: portfolio_id, data: res.data } : null;
    } catch (error: any) {
      api.dispatch(setLoading({ portfolio_id, isLoading: false }));

      api.dispatch(setPortfolioContractsError({ id: portfolio_id }));

      if (error?.request?.status === 403) {
        api.dispatch(setDisconnect(true));
      } else if (error?.request?.status === 400) {
        api.dispatch(
          setAllHedgeError({
            id: portfolio_id,
            errorText: i18n.t("rollout_processing_status_error"),
          })
        );
      } else if (error?.request?.status === 401) {
        api.dispatch(
          refreshToken({
            callback: fetchPortfolioHedgeById,
            parameter: { period, year, portfolio_id },
          })
        );
      }
      return api.rejectWithValue("No user found");
    }
  }
);

export const dashboardHedgeSlice = createSlice({
  name: "dashboardHedges",
  initialState,
  reducers: {
    setPortfolioContractsError(state, action) {
      state.isError[action.payload.id] = true;
    },
    setAllHedgeError(state, action) {
      state.hedgeError = {
        ...state.hedgeError,
        [action.payload.id]: action.payload.errorText,
      };
    },
    clearErrors(state, action) {
      state.hedgeError[action.payload.id] = false;
      state.isError[action.payload.id] = false;
    },
    setLoading(state, action) {
      state.isLoading[action.payload.portfolio_id] = action.payload.isLoading;
    },
    initDashboardHedgeData(state) {
      state.data = null;
      state.isLoading = {};
      state.hedgeError = {};
      state.isError = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllPortfolioHedge.pending, (state) => {
        state.isLoading = {};
        state.hedgeError = {};
      })
      .addCase(fetchAllPortfolioHedge.fulfilled, (state, { payload }) => {
        state.data = payload || null;
      })
      .addCase(fetchAllPortfolioHedge.rejected, (state) => {
        state.isLoading = {};
      })
      .addCase(fetchPortfolioHedgeById.fulfilled, (state, { payload }) => {
        state.data =
          state.data?.map((item) =>
            item.portfolioId === payload?.portfolioId
              ? { portfolioId: item.portfolioId, ...payload.data }
              : item
          ) || null;
      });
  },
});

export const allHedgeData$ = (state: RootState) => state.dashboardHedges.data;
export const allHedgeError$ = (state: RootState) =>
  state.dashboardHedges.hedgeError;
export const isError$ = (state: RootState) => state.dashboardHedges.isError;
export const isLoading$ = (state: RootState) => state.dashboardHedges.isLoading;

export const allHedgeXBase$ = (state: RootState) => {
  return (
    state.dashboardHedges.data?.map((item) =>
      item?.base?.x ? item?.base?.x : []
    ) || []
  );
};

export const allHedgeXPeak$ = (state: RootState) => {
  return (
    state.dashboardHedges.data?.map((item) =>
      item?.peak?.x ? item?.peak?.x : []
    ) || []
  );
};

export const allHedgeYBase$ = (state: RootState) => {
  return (
    state.dashboardHedges.data?.map((item) => {
      return item?.base?.val_1?.length && item?.base?.val_2?.length
        ? [
            { name: "Hedge", data: item?.base?.val_1 },
            { name: "Position", data: item?.base?.val_2 },
          ]
        : [];
    }) || []
  );
};

export const allHedgeYPeak$ = (state: RootState) => {
  return (
    state.dashboardHedges.data?.map((item) => {
      return item?.peak?.val_1?.length && item?.peak?.val_2?.length
        ? [
            { name: "Hedge", data: item?.peak?.val_1 },
            { name: "Position", data: item?.peak?.val_2 },
          ]
        : [];
    }) || []
  );
};

export const {
  setPortfolioContractsError,
  initDashboardHedgeData,
  setLoading,
  setAllHedgeError,
  clearErrors,
} = dashboardHedgeSlice.actions;

export default dashboardHedgeSlice.reducer;
