import { useAppDispatch } from "app/hooks";
import { BackToSignIn } from "components/backToSignIn";
import { appLogout } from "features/auth/authSlice";
import { useTranslation } from "react-i18next";
import style from "./successRecovery.module.scss";

export const SuccessRecovery = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const handleClick = () => {
    dispatch(appLogout());
  };

  return (
    <div className={style.root}>
      <div className={style.text}>{t("recovery_password_confirm_text")}</div>
      <div className={style.link} onClick={handleClick}>
        <BackToSignIn />
      </div>
    </div>
  );
};
