import dayjs from "dayjs";
import style from "./currentDate.module.scss";
import { ReactComponent as CalendarIcon } from "assets/svg/calendar.svg";
import { useEffect } from "react";
import { useAppSelector } from "app/hooks";
import { user$ } from "features/user/slice";
import "dayjs/locale/de";
import "dayjs/locale/fr";
import "dayjs/locale/en";
export const CurrentDate = () => {
  const user = useAppSelector(user$);

  useEffect(() => {
    if (user.localisation) {
      dayjs.locale(user.localisation.toLowerCase());
    }
  }, [user]);

  const currentDate = () => {
    return dayjs().format("MMMM DD, YYYY");
  };
  return (
    <div className={style.root}>
      <CalendarIcon className={style.icon} />
      <div className={style.title}>{currentDate()}</div>
    </div>
  );
};
